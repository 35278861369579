import React, { Fragment, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import axios from 'axios'

import Button from '../../../../controls/Button'
import AssetList from '../../../../controls/AssetList'
import AddressInputModal from '../../../../controls/AddressInputModal'
import { useTheme } from '../../../../contexts/Theme'
import { useError } from '../../../../contexts/Error'
import { tokenConfig, timeoutConfig } from '../../../../../utils'
import useProfileContext from '../ProfileContext'

const Cell = styled.div`
    display: table-cell;
`

const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &.step-enter { opacity: 0; }
    &.step-enter-active { 
        opacity: 1;
        transition: opacity 1000ms; 
    }
    &.step-exit { opacity: 1; }
    &.step-exit-active {
        opacity: 0;
        transition: opacity 1000ms;
    }
`

const MenuBar = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-${p => p.align};
`

const Kill = styled.img`
    margin-top: 10px;
    margin-right: 10px;
    width: 30px;
    height: 30px;
`

const AddShippingAddress = styled.div`
    width: 75px;
    height: 75px;
    margin-left: 50px;
    border-radius: 50%;
    border: 2px groove ${props => props.borderColor};
    background-image: url('${process.env.PUBLIC_URL}/images/add-address-${props => props.iconColor}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.touch { box-shadow: -2px 2px 10px 5px ${props => props.shadowColor}; }
    &.disabled {
        box-shadow: none; 
        opacity: 0.5; 
    }
`

const AddressManagerProxy = ({ onKill }) => {
    // State
    const [ addAddressTouch, setAddAddressTouch ] = useState(false)
    const [ addingAddress, setAddingAddress ] = useState(false)

    const { 
        activeClient, updateActiveClient, authToken, 
        setStatus, setSpinner, setStatusMessage, 
        setErrorMessage, setOnStatusOk, 
    } = useProfileContext() 
    const theme = useTheme()
    const { addressError } = useError()
    
    const { white, dark, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const [ iconColor, shadowColor, borderColor ] = theme.isDarkBackground() ? 
        [ white, white, rgbaWhiteBg ] : 
        [ dark, rgbDark, rgbaDarkBg ];

    // Add address handlers
    //
    const onAddAddressTouch = () => setAddAddressTouch(true)
    const onAddAddressRelease = () => setAddAddressTouch(false)
    const onAddAddressClick = () => setAddingAddress(true)

    const formatAddress = address => `${address.addr1}...`

    // Handler for modal address entry done
    //
    const onAddressModalReady = address => {
        setAddingAddress(false)
        onAddressReady(address)
    }

    // New shipping address ready handler
    //
    const onAddressReady = async address => {

        // Query address error first
        const { error, message } = addressError(address)
        if (error) {
            setErrorMessage(message)
            return
        }

        // Pass
        setErrorMessage(null)

        setOnStatusOk(() => () => setStatus(false))

        try {

            setStatus(true)
            setSpinner(true)

            address.isEnabled = true

            const shippingAddresses = [ ...activeClient.shippingAddresses, address ]
            
            // Update the client DB            
            await axios.patch(`/api/clients/${activeClient._id}`, { shippingAddresses }, timeoutConfig(tokenConfig(authToken)))
            
            setSpinner(false)
            setStatusMessage('Shipping address successfully saved.')

            // DB successfully updated, update the active client
            updateActiveClient("shippingAddresses", shippingAddresses)

        } catch(err) {

            if (err.response) { // status 400 (incl. authentication), 404 (not found), 500
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, addresses not updated.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, addresses not updated, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, addresses not updated`)
            }

            setSpinner(false)
        }
    }   

    // Address set as default handler
    //
    const onAddressSetDefault = async index => {

        setOnStatusOk(() => () => setStatus(false))

        try {

            setStatus(true)
            setSpinner(true)

            // Process the filtered list
            const filtered = activeClient.shippingAddresses.filter(addr => addr.isEnabled)
            filtered.forEach((address, i) => {
                address.isDefault = i === index ? !address.isDefault : false
            })

            // Get the patched list
            const shippingAddresses = [ ...activeClient.shippingAddresses ]
            
            // Update the client DB            
            await axios.patch(`/api/clients/${activeClient._id}`, { shippingAddresses }, timeoutConfig(tokenConfig(authToken)))

            // DB successfully updated, update the active client
            updateActiveClient("shippingAddresses", shippingAddresses)

            setSpinner(false)
            setStatusMessage(`Default shipping address ${filtered[index].isDefault ? 'set' : 'unset'}.`)

        } catch(err) {

            if (err.response) { // status 400 (incl. authentication), 404 (not found), 500
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, addresses not updated.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, addresses not updated, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, addresses not updated`)
            }

            setSpinner(false)
        }
    }

    // Shipping address delete handler
    //
    const onAddressDelete = async index => {

        setOnStatusOk(() => () => setStatus(false))

        try {

            setStatus(true)
            setSpinner(true)

            // First enabled address
            const filtered = activeClient.shippingAddresses.filter(addr => addr.isEnabled)
            const sa = filtered[index]

            // disable the shipping address
            sa.isEnabled = false
            sa.isDefault = false
            const shippingAddresses  = [ ...activeClient.shippingAddresses ]
            
            // Update the client DB            
            await axios.patch(`/api/clients/${activeClient._id}`, { shippingAddresses }, timeoutConfig(tokenConfig(authToken)))

            // DB successfully updated, update the active client
            updateActiveClient("shippingAddresses", shippingAddresses)

            setSpinner(false)
            setStatusMessage(`Shipping address ${sa.addr1}... removed.`)

        } catch(err) {

            if (err.response) { // status 400 (incl. authentication), 404 (not found), 500
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, addresses not updated.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, addresses not updated, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, addresses not updated`)
            }
            
            setSpinner(false)
        }
    }

    return (
        <Cell>
            <Container>
                <CSSTransition 
                    in={addingAddress} 
                    classNames="modal" 
                    timeout={1000}
                    unmountOnExit
                >
                    <AddressInputModal onKill={() => setAddingAddress(false)} onSubmit={onAddressModalReady} />
                </CSSTransition>
                <MenuBar align='end'>
                    <Kill onClick={onKill} src={`${process.env.PUBLIC_URL}/images/kill-${iconColor}.png`} />
                </MenuBar>
                <MenuBar align='start'>
                    <AddShippingAddress 
                        shadowColor={shadowColor} borderColor={borderColor} iconColor={iconColor} 
                        className={`${addAddressTouch ? 'touch' : ''} ${addingAddress ? 'disabled' : ''}`}
                        onTouchStart={onAddAddressTouch} onTouchEnd={onAddAddressRelease} 
                        onClick={addingAddress ? null : onAddAddressClick}
                    />
                </MenuBar>
                <AssetList 
                    assets={activeClient.shippingAddresses?.filter(address => address.isEnabled)} 
                    title='Shipping Addresses (Select Default)' assetName='shipping addresses'
                    caption='Tap the circles to select the default shipping address.' 
                    onDefaultChanged={onAddressSetDefault} formatLabel={formatAddress} 
                    marginTop={20} onTrash={onAddressDelete}
                />
            </Container>
        </Cell>
    )
}

export const AddressManager = () => {
    const { managingAddresses, setManagingAddresses } = useProfileContext()

    const onKill = () => setManagingAddresses(false)

    return (
        <CSSTransition 
            in={managingAddresses} 
            classNames="step" 
            timeout={500}
            unmountOnExit
        >
            <AddressManagerProxy onKill={onKill} />
        </CSSTransition>
    )
}

const ManageAddresses = () => {
    const { managingCards, managingAddresses, setManagingAddresses } = useProfileContext()
    const { scrollTop } = useError()

    const onManageAddresses = () => {
        setManagingAddresses(true)
        scrollTop(0)
    }

    return (
        <Fragment>
        {
            (!managingAddresses && !managingCards)
            &&
            <Button 
                onClick={onManageAddresses} label='Manage Addresses' 
                width={140} height={70} marginTop={20}
            />
        }
        </Fragment>
    )
}

export default ManageAddresses
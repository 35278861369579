import React, { createContext, useContext } from 'react'

const Home = createContext(undefined)

export const HomeProvider = ({ children, value }) => {
    return (
        <Home.Provider value={value}>{children}</Home.Provider>
    )
}

const useHomeContext = () => {
    const context = useContext(Home)

    if (context === undefined) throw new Error('useHomeContext must be used within a HomeProvider')

    return context;
}

export default useHomeContext
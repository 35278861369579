import { useDispatch } from 'react-redux'

import { isEmpty } from '../utils'
import {
    RESTORE_ACTIVE_MEMBER,
    RESTORE_CURRENT_CLIENT,
    RESTORE_CURRENT_MEMBER,
    RESTORE_ACTIVE_CLIENT,
    RESTORE_ACTIVE_ADMIN,
    RESTORE_HOST_MEMBER,
    RESTORE_TOKEN
} from '../redux/actions/types'

const useRecoverObjects = ({ activeMember, activeClient, activeAdmin, hostMember, currentMember, currentClient }) => {
    const dispatch = useDispatch()

    // Test for active member recovery, and possible current client or current member
    if (activeMember && isEmpty(activeMember)) {
        dispatch({ type: RESTORE_ACTIVE_MEMBER })
        if (currentClient) dispatch({ type: RESTORE_CURRENT_CLIENT })
        if (currentMember) dispatch({ type: RESTORE_CURRENT_MEMBER })
        dispatch({ type: RESTORE_TOKEN })

        return true;
    }

    // Test for lone current client recovery
    if (currentClient && isEmpty(currentClient)) {
        dispatch({ type: RESTORE_CURRENT_CLIENT })
        dispatch({ type: RESTORE_TOKEN })

        return true;
    }

    // Test for active client recovery
    if (activeClient && isEmpty(activeClient)) {
        dispatch({ type: RESTORE_ACTIVE_CLIENT })
        if (hostMember) dispatch({ type: RESTORE_HOST_MEMBER })
        dispatch({ type: RESTORE_TOKEN })

        return true;
    }

    // Test for active admin recovery
    if (activeAdmin && isEmpty(activeAdmin)) {
        dispatch({ type: RESTORE_ACTIVE_ADMIN })
        dispatch({ type: RESTORE_TOKEN })

        return true;
    }

    // Nothing was recovered
    return false;
}

export default useRecoverObjects
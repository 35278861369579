import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'

const LogoContainer = styled.div`
    position: relative;
    width: 50%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const ImageWithCaption = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const LogoImage = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background-image: url('../../../images/logo-${props => props.color}.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`

const LogoCaption = styled.div`
    width: ${props => props.width}px;
    height: auto;
    font: 12px verdana, sans-serif;
    text-align: center;
    color: ${p => p.textColor};
`

const SignIn = styled.div`
    text-decoration: none;
    font: 20px verdana, sans-serif;
    color: ${props => props.color};
`

const Logo = ({ onAboutRequest, onLogonRequest }) => {
    const [ logoWidth, setLogoWidth ] = useState(window.innerWidth * 0.15)
    const [ logoHeight, setLogoHeight ] = useState(window.innerWidth * 0.036)

    useEffect(() => {
        setLogoWidth(window.innerWidth * 0.15)
        setLogoHeight(window.innerWidth * 0.036)
    }, [window.innerWidth])

    // Destructure the theme
    const theme = useTheme()
    const { white, dark, rgbDark, rgbaWhiteBg, rgbaDarkBg } = theme
    const [ color, textColor, borderColor ] = theme.isDarkBackground() 
        ? 
        [ white, white, rgbaWhiteBg ] 
        : 
        [ dark, rgbDark, rgbaDarkBg ];

    return (
        <Fragment>
            <LogoContainer borderColor={borderColor}>
                <ImageWithCaption>
                    <LogoImage width={logoWidth} height={logoHeight} color={color} />
                    <LogoCaption width={logoWidth} textColor={textColor}>
                        Revolutionizing the Professional Salon Industry
                    </LogoCaption>
                </ImageWithCaption>
                <SignIn onClick={() => onLogonRequest()} color={textColor}>Sign In</SignIn>
            </LogoContainer>
        </Fragment>
    )
}

export default Logo
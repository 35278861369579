import React, { Fragment } from 'react'
import styled from 'styled-components'

export const ROUND = 'ROUND'
export const SQUARE = 'SQUARE'
export const RECTANGULAR = 'RECTANGULAR'

const Clipper = styled.div`
    position: relative;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    border-radius: ${props => props.shape === ROUND ? '50%' : '0'};
    overflow: hidden;
`

const EditedPhoto = styled.img`
    position: absolute;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    width: ${props => props.width}px;
`

const Photo = ({ photo, width, shape, onClick }) => {

    const scale = width / photo.clipWidth

    return (       
        <Fragment>
            <Clipper 
                width={Math.floor(photo.clipWidth*scale)} 
                height={Math.floor(photo.clipHeight*scale)}
                shape={shape ? shape : ROUND}
                onClick={onClick}
            >
                <EditedPhoto 
                    top={Math.floor(photo.yOffset*scale)}
                    left={Math.floor(photo.xOffset*scale)}
                    width={Math.floor(photo.width*scale)}
                    src={`${process.env.PUBLIC_URL}/photos/${photo.filename}`}
                />
            </Clipper>
        </Fragment>
    ) 
}

export default Photo
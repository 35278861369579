// Active member (the logged-in member)
export const SET_ACTIVE_MEMBER = 'SET_ACTIVE_MEMBER'
export const CLEAR_ACTIVE_MEMBER = 'CLEAR_ACTIVE_MEMBER'
export const BACKUP_ACTIVE_MEMBER = 'BACKUP_ACTIVE_MEMBER'
export const RESTORE_ACTIVE_MEMBER = 'RESTORE_ACTIVE_MEMBER'
export const SET_MEMBER_PROPERTY = 'SET_MEMBER_PROPERTY'
export const ADD_CLIENT_TO_MEMBER = 'ADD_CLIENT_TO_MEMBER'

// Current member (the member being processed)
export const SET_CURRENT_MEMBER = 'SET_CURRENT_MEMBER'
export const CLEAR_CURRENT_MEMBER = 'CLEAR_CURRENT_MEMBER'
export const BACKUP_CURRENT_MEMBER = 'BACKUP_CURRENT_MEMBER'
export const RESTORE_CURRENT_MEMBER = 'RESTORE_CURRENT_MEMBER'
export const SET_CURRENT_MEMBER_PROPERTY = 'SET_CURRENT_MEMBER_PROPERTY'
export const ADD_CLIENT_TO_CURRENT_MEMBER = 'ADD_CLIENT_TO_CURRENT_MEMBER'

// Host member
export const SET_HOST_MEMBER = 'SET_HOST_MEMBER'
export const CLEAR_HOST_MEMBER = 'CLEAR_HOST_MEMBER'
export const BACKUP_HOST_MEMBER = 'BACKUP_HOST_MEMBER'
export const RESTORE_HOST_MEMBER = 'RESTORE_HOST_MEMBER'

// Active client (the logged-in client)
export const SET_ACTIVE_CLIENT = 'SET_ACTIVE_CLIENT'
export const CLEAR_ACTIVE_CLIENT = 'CLEAR_ACTIVE_CLIENT'
export const RESTORE_ACTIVE_CLIENT = 'RESTORE_ACTIVE_CLIENT'
export const BACKUP_ACTIVE_CLIENT = 'BACKUP_ACTIVE_CLIENT'
export const SET_CLIENT_PROPERTY = 'SET_CLIENT_PROPERTY'

// Current client (the client being processed)
export const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT'
export const CLEAR_CURRENT_CLIENT = 'CLEAR_CURRENT_CLIENT'
export const BACKUP_CURRENT_CLIENT = 'BACKUP_CURRENT_CLIENT'
export const RESTORE_CURRENT_CLIENT = 'RESTORE_CURRENT_CLIENT'
export const SET_CURRENT_CLIENT_PROPERTY = 'SET_CURRENT_CLIENT_PROPERTY'

// Active admin (the logged-in admin)
export const SET_ACTIVE_ADMIN = 'SET_ACTIVE_ADMIN'
export const CLEAR_ACTIVE_ADMIN = 'CLEAR_ACTIVE_ADMIN'
export const BACKUP_ACTIVE_ADMIN = 'BACKUP_ACTIVE_ADMIN'
export const RESTORE_ACTIVE_ADMIN = 'RESTORE_ACTIVE_ADMIN'

export const SET_ADMIN_PROPERTY = 'SET_ADMIN_PROPERTY'

// Token
export const SET_TOKEN = 'SET_TOKEN'
export const CLEAR_TOKEN = 'CLEAR_TOKEN'
export const RESTORE_TOKEN = 'RESTORE_TOKEN'
export const SET_THEME = 'SET_THEME'
import React from 'react'

import HomeHeader from './parts/Header'
import Greeting from './parts/Greeting'
import Host from './parts/Host'
import Features from './parts/Features'
import CurrentHaircut from './parts/CurrentHaircut'
import Actions from './parts/Actions'
import Activity from './parts/Activity'
import { HomeProvider } from './HomeContext'
import useActiveClient from '../../../../hooks/useActiveClient'
import useHostMember from '../../../../hooks/useHostMember'
import useRecoverObjects from '../../../../hooks/useRecoverObjects'
import useLogoff from '../../../../hooks/useLogoff'

import Null from '../../../controls/Null'

// The proxy calls the hooks for this page and sets up its context
//
const HomeProxy = ({ children }) => {
    const { activeClient } = useActiveClient()
    const { hostMember, getHostMember, hostMemberLoading } = useHostMember()
    const { loggingOff, logoff } = useLogoff({ activeClient })

    // Check for and recover from page refresh
    if (useRecoverObjects({ activeClient })) return <Null />

    // Check for logoff
    if (loggingOff) return <Null />

    return (
        <HomeProvider value={{ activeClient, logoff, hostMember, getHostMember, hostMemberLoading }}>{children}</HomeProvider>
    )
}

// Component parts of the page, can be laid out any way we want
//
HomeProxy.Header = HomeHeader
HomeProxy.Greeting = Greeting
HomeProxy.Host = Host
HomeProxy.Features = Features
HomeProxy.CurrentHaircut = CurrentHaircut
HomeProxy.Actions = Actions
HomeProxy.Activity = Activity

export default HomeProxy
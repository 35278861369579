import React, { Fragment } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'
import Button from './Button'
import Spinner from './Spinner'

const Message = styled.h3`
    font: 18px verdana, sans-serif;
    text-align: center;
`

const Box = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: none;
    border-radius: 15px;
    background-image: linear-gradient(to bottom right, ${({ topColor }) => topColor}, ${({ bottomColor }) => bottomColor});
    color: ${props => props.color};
    box-shadow: 10px 10px 2px ${props => props.shadowColor};
    overflow: hidden;
    z-index: 4;
`

const YesNoContainer = styled.div`
    width: 80%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
`

//transition: transform 300ms;
const MessageWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    z-index: 10;

    &.message-enter {
        opacity: 0;
        transform: scale(0.5);
    }
    &.message-enter-active {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%);
        transition: opacity 500ms, transform 500ms;
    }
    &.message-exit {
        opacity: 1;
    }
    &.message-exit-active {
        opacity: 0;
        transform: scale(0.5) translateY(0);
        transition: opacity 500ms, transform 500ms;
    }
`

const MessageBox = ({ 
    spinner, message, onOk, label, onYes, yesLabel, onNo, noLabel,
    canvas, width, height, lightBackground, darkBackground 
}) => {
    const theme = useTheme()

    const { white, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const [ color, shadowColor, topColor, bottomColor ] = 
        theme.isLightBackground() ?  
        [ rgbDark, rgbaDarkBg, lightBackground.topColor, lightBackground.bottomColor ] :
        [ white, rgbaWhiteBg, darkBackground.topColor, darkBackground.bottomColor ];
    
    return (
        <Fragment>
            <MessageWrapper width={width} height={height}>
                <Box color={color} topColor={topColor} bottomColor={bottomColor} shadowColor={shadowColor}>
                    { canvas && canvas}
                    { spinner && <Spinner color={color} diameter={width/3} />}
                    { spinner || canvas ||
                        <Fragment> 
                            <Message>{message}</Message>                
                            {
                                onOk ?
                                <Button width={150} height={50} onClick={onOk} label={label} /> :
                                <YesNoContainer>
                                    <Button width={120} height={50} onClick={onYes} label={yesLabel} />
                                    <Button width={120} height={50} onClick={onNo} label={noLabel} />
                                </YesNoContainer>
                            }
                        </Fragment>
                    }
                </Box>
            </MessageWrapper>
        </Fragment>
    )
}

export default MessageBox
import React, { useState, useRef, useReducer } from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'

import Null from '../controls/Null'
import useSwitchTheme from '../../hooks/useSwitchTheme'
import { usePhone } from '../../hooks/useMedia'
import { useError } from '../contexts/Error'
import AdminProfilePhone from '../media/AdminProfilePhone'
import AdminProfileDesktop from '../media/AdminProfileDesktop'
import { 
    CLEAR_ACTIVE_ADMIN, 
    CLEAR_TOKEN, 
    SET_ACTIVE_ADMIN,
    RESTORE_ACTIVE_ADMIN, 
    RESTORE_TOKEN
} from '../../redux/actions/types'
import { isEmpty, tokenConfig, timeoutConfig, encryptPassword } from '../../utils'

const handleInputChange = (e, key, dispatch, ref) => {
    dispatch({
        type: SET_UPDATED_PROPERTY,
        payload: {
            key: key,
            value: e.target.value
        }
    })

    ref.current = e.target.value
}

const handleValueChange = (v, key, dispatch) => {
    dispatch({
        type: SET_UPDATED_PROPERTY,
        payload: {
            key: key,
            value: v
        }
    })
}

const SET_UPDATED_PROPERTY = "SET_UPDATED_PROPERTY"
const adminReducer = (admin, action) => {
    switch (action.type) {
        case SET_UPDATED_PROPERTY: 
            admin = { ...admin, [action.payload.key]: action.payload.value }
            return admin
        default: 
            return admin
    }
}

const AdminProfile = () => {
    // State
    const [ errorMessage, setErrorMessage ] = useState(null)
    const [ password, setPassword ] = useState('')
    const [ repeatPassword, setRepeatPassword ] = useState('')
    const [ messageBoxVisible, setMessageBoxVisible ] = useState(false)
    const [ spinnerVisible, setSpinnerVisible ] = useState(false)
    const [ statusMessage, setStatusMessage ] = useState('')
    const [ submittedData, setSubmittedData ] = useState(false)
    const [ updatedAdmin, adminDispatch ] = useReducer(adminReducer, {})
    const [ loggingOff, setLoggingOff ] = useState(false)

    // Other hooks
    const errors = useRef(false)
    const { passwordError, questionAndAnswerRequiredError, questionAndAnswerError, scrollTop } = useError()
    const dispatch = useDispatch()
    const activeAdmin = useSelector(state => state.activeAdmin)
    const authToken = useSelector(state => state.token)
    const phoneValue = useRef(activeAdmin.phone)
    const emailValue = useRef(activeAdmin.email)
    const answerValue = useRef(activeAdmin.secretQuestionAnswer)
    const refreshed = useRef(false)
    const isPhone = usePhone()
    useSwitchTheme()

    // Head back to home page
    submittedData && <Navigate  to="/admin-home" replace />

    // Logoff doesn't need to re-render
    loggingOff && <Navigate to='/' replace />

    // Check for and recover from page refresh
    if (isEmpty(activeAdmin)) {
        // Restore active admin and token from local storage post-refresh
        dispatch({ type: RESTORE_ACTIVE_ADMIN })
        dispatch({ type: RESTORE_TOKEN })

        // Set flag to initialize values correctly
        refreshed.current = true

        // Don't want to render with missing data
        return <Null />
    } else if (refreshed.current) { // Correct initialization post-refresh
        phoneValue.current = activeAdmin.phone
        emailValue.current = activeAdmin.email

        refreshed.current = false
    }

    // Handlers
    const onPasswordChange = e => setPassword(e.target.value)
    const onRepeatPasswordChange = e => setRepeatPassword(e.target.value)
    const onPhoneChange = e => handleInputChange(e, 'phone', adminDispatch, phoneValue)
    const onEmailChange = e => handleInputChange(e, 'email', adminDispatch, emailValue)
    const onQuestionChange = i => handleValueChange(i, 'secretQuestion', adminDispatch)
    const onAnswerChange = e => handleInputChange(e, 'secretQuestionAnswer', adminDispatch, answerValue)

    // Accumulate error messages over form validations, return true if valid form else false
    //
    const validateForm = () => { 
        let message = '', error = false;

        // Check password and repeat
        if (password) { // Password was entered
            const { error: err, message: msg } = passwordError(password, repeatPassword)
            if (err) {
                message = msg
                error = true
            }
        }

        if (activeAdmin.firstVisit) { // Must pick a question and an answer
            const { error: err, message: msg } = questionAndAnswerRequiredError(
                updatedAdmin.secretQuestion,
                updatedAdmin.secretQuestionAnswer
            )            
            if (err) {
                message = `${message}${message ? ', ' : ''}${msg}`
                error = true
            }
        }
            
        // Check consistent secret question and answer
        const { error: err, message: msg } = questionAndAnswerError(
            updatedAdmin.secretQuestion, 
            updatedAdmin.secretQuestionAnswer
        )            
        if (err) {
            message = `${message}${message ? ', ' : ''}${msg}`
            error = true
        }
        
        if (error) {
            setErrorMessage(message)
            scrollTop(0)
            return false
        }

        // No error
        setErrorMessage(null)
        return true
    }

    const onSubmit = async e => {
        e.preventDefault()

        if (!validateForm()) // Invalid form
            return;

        // Form is valid ...
        
        try {
            
            // Check passwords if applicable
            if (password) {
                const hash = await encryptPassword(password)
                updatedAdmin.password = hash
            }

            // First visit done
            updatedAdmin.firstVisit = false

            // Save the page data
            setMessageBoxVisible(true)
            setSpinnerVisible(true)

            let rsp = await axios.patch(
                `/api/admins/${activeAdmin._id}`, updatedAdmin, timeoutConfig(tokenConfig(authToken))
            )

            // Make this the active admin
            dispatch({
                type: SET_ACTIVE_ADMIN,
                payload: rsp.data
            })

            setSpinnerVisible(false)
            
            setStatusMessage(`Admin successfully updated.`)

        } catch(err) {
            
            if (err.response) { // status 400, 404 (not found), 500
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, admin not updated.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, admin not updated, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, admin not updated`)
            }

            setSpinnerVisible(false)
            errors.current = true            
        }
    }
    const handleMessageBoxOk = e => {
        setMessageBoxVisible(false)
        if (errors.current) {
            errors.current = false
        } else {
            setSubmittedData(true)
        }
    }

    const onBack = activeAdmin.firstVisit
        ?
        () => { // Logging off
            setLoggingOff(true)
            dispatch({ type: CLEAR_ACTIVE_ADMIN })
            dispatch({ type: CLEAR_TOKEN })
        }
        :
        null;

    return  (
        isPhone 
        ?
        <AdminProfilePhone
            onSubmit={onSubmit} onSubmitOk={handleMessageBoxOk} onBack={onBack} 
            title={activeAdmin.firstVisit ? 'Set Password' : 'Profile'}
            backTo={activeAdmin.firstVisit ? ['/', 'Logoff'] : ['/admin-home', 'Home']}
            onPasswordChange={onPasswordChange} onRepeatPasswordChange={onRepeatPasswordChange} 
            onPhoneChange={onPhoneChange} onEmailChange={onEmailChange} onQuestionChange={onQuestionChange} 
            onAnswerChange={onAnswerChange} messageBoxVisible={messageBoxVisible} spinnerVisible={spinnerVisible} 
            statusMessage={statusMessage} errorMessage={errorMessage} 
            phoneValue={phoneValue.current} emailValue={emailValue.current}
            passwordRequired={activeAdmin.firstVisit}
        />
        :
        <AdminProfileDesktop
            onSubmit={onSubmit} onSubmitOk={handleMessageBoxOk} onBack={onBack} 
            title={activeAdmin.firstVisit ? 'Set Password' : 'Profile'}
            backTo={activeAdmin.firstVisit ? ['/', 'Logoff'] : ['/admin-home', 'Home']}
            onPasswordChange={onPasswordChange} onRepeatPasswordChange={onRepeatPasswordChange} 
            onPhoneChange={onPhoneChange} onEmailChange={onEmailChange} onQuestionChange={onQuestionChange} 
            onAnswerChange={onAnswerChange} messageBoxVisible={messageBoxVisible} spinnerVisible={spinnerVisible} 
            statusMessage={statusMessage} errorMessage={errorMessage} 
            phoneValue={phoneValue.current} emailValue={emailValue.current}
            passwordRequired={activeAdmin.firstVisit}
        />
    )
}

export default AdminProfile
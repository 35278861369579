import React from 'react'

import Main from './Main'

function App() {
	
    return (
		<div>
			<div className="container">
				{/* Router routes definitions */}
				<Main />
			</div> 
		</div>
    )
}

export default App;

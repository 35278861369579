import React from 'react'

import OrderHeader from './parts/Header'
import OrderRibbon from './parts/Ribbon'
import OrderInvoice from './parts/Invoice'
import PayButton from './parts/PayButton'
import PayStatus from './parts/PayStatus'
import AddressPrompt from './parts/AddressPrompt'
import PaymentPrompt from './parts/PaymentPrompt'
import { OrderProvider } from './OrderContext'
import useOrder from './useOrder'
import useActiveClient from '../../../../hooks/useActiveClient'
import useHostMember from '../../../../hooks/useHostMember'
import useRecoverObjects from '../../../../hooks/useRecoverObjects'

import Null from '../../../controls/Null'

// The proxy calls the hooks for this page and sets up its context
//
const OrderProxy = ({ children }) => {
    const { ...rest } = useOrder()
    const { activeClient, ...restClient } = useActiveClient()
    const { hostMember } = useHostMember()

    // Check for and recover from page refresh
    if (useRecoverObjects({ activeClient })) return <Null />

    return (
        <OrderProvider value={{ hostMember, activeClient, ...restClient, ...rest }}>{children}</OrderProvider>
    )
}

// Component parts of the page, can be laid out any way we want
//
OrderProxy.Header = OrderHeader
OrderProxy.Ribbon = OrderRibbon
OrderProxy.Invoice = OrderInvoice
OrderProxy.PayButton = PayButton
OrderProxy.PayStatus = PayStatus
OrderProxy.AddressPrompt = AddressPrompt
OrderProxy.PaymentPrompt = PaymentPrompt

export default OrderProxy
import { combineReducers } from 'redux'
import activeAdmin from './adminReducer'
import activeMember from './memberReducer'
import activeClient from './clientReducer'
import currentMember from './currentMemberReducer'
import currentClient from './currentClientReducer'
import hostMember from './hostMemberReducer'
import token from './tokenReducer'

export default combineReducers({ 
    activeMember, activeClient, currentMember, currentClient, activeAdmin, hostMember, token 
})
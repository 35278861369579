import React from 'react'

import Footer from './parts/Footer'
import Header from './parts/Header'
import Instruction from './parts/Instruction'
import Introduction from './parts/Introduction'
import Status from './parts/Status'
import Technicals from './parts/Technicals'
import Templates from './parts/Templates'
import Topographies from './parts/Topographies'
import Mix from './parts/Mix'
import Photo from './parts/Photo'
import NameAndSave from './parts/NameAndSave'
import { AddHaircutProvider } from './AddHaircutContext'
import useAddHaircut from './useAddHaircut'
import useActiveMember from '../../../../hooks/useActiveMember'
import useCurrentMember from '../../../../hooks/useCurrentMember'
import useCurrentClient from '../../../../hooks/useCurrentClient'
import useRecoverObjects from '../../../../hooks/useRecoverObjects'

import Null from '../../../controls/Null'

// The proxy calls the hooks for this page and sets up its context
//
const AddHaircutProxy = ({ children }) => {
    const { ...allHaircut } = useAddHaircut()
    const { activeMember, updateActiveMember } = useActiveMember()
    const { currentMember, isCurrentMember } = useCurrentMember()
    const { currentClient, ...client } = useCurrentClient()

    // Check for and recover from page refresh
    if (useRecoverObjects({ activeMember, currentMember, currentClient })) return <Null />

    return (
        <AddHaircutProvider value={{ 
            activeMember, updateActiveMember, currentMember, isCurrentMember, 
            currentClient, ...client, ...allHaircut 
        }}>{children}</AddHaircutProvider>
    )
}

// Component parts of the page, can be laid out any way we want
//
AddHaircutProxy.Header = Header
AddHaircutProxy.Instruction = Instruction
AddHaircutProxy.Introduction = Introduction
AddHaircutProxy.Status = Status
AddHaircutProxy.Technicals = Technicals
AddHaircutProxy.Templates = Templates
AddHaircutProxy.Topographies = Topographies
AddHaircutProxy.Mix = Mix
AddHaircutProxy.Photo = Photo
AddHaircutProxy.NameAndSave = NameAndSave
AddHaircutProxy.Footer = Footer

export default AddHaircutProxy
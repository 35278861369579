import React, { useState, useRef, useMemo, useEffect, Fragment } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'
import ThemeMenuItem from '../controls/ThemeMenuItem'
import HelpMenuItem from '../controls/HelpMenuItem'
import { lightBlue } from '../../overlayColors'

const MenuIcon = styled.div`
    position: absolute;
    top: 50%;
    right: ${props => props.right}%;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    margin-top: ${props => props.marginTop}px;
    background-image: url('${process.env.PUBLIC_URL}/images/menu-desktop-${props => props.color}.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`

const Menu = styled.div`
    position: absolute;
    top: 57px;
    left: 0;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: ${props => props.bgColor};
    box-shadow: 10px 10px 2px ${props => props.shadowColor};
    z-index: 5;

    &.menu-enter {
        opacity: 0;
        transform: scale(0.8) translateY(-50px);
    }
    &.menu-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 500ms, transform 500ms;
    }
    &.menu-exit {
        opacity: 1;
    }
    &.menu-exit-active {
        opacity: 0;
        transform: scale(0.8) translateY(-50px);
        transition: opacity 500ms, transform 500ms;
    }
`

// Sizing
const MENU_WIDTH = 150;
const MENU_HEIGHT = 250;
const MENU_ICON_WIDTH = 75;
const MENU_ICON_HEIGHT = 57;

// top: distance in pixels from top of screen
// right: distance in percent from right edge of screen
//
const MenuDesktop = ({ top, right }) => {
    // State
    const [ menuVisible, setMenuVisible ] = useState(false)
    const [ themesDisplayed, setThemesDisplayed ] = useState(false)
    const [ refresh, setRefresh ] = useState(false)
    
    // Other hooks
    const hideThemes = useRef(null)

    const theme = useTheme()
    const { rgbaDarkBg, rgbaWhiteBg, white, dark } = theme
    const [ color, itemColor, bgColor, shadowColor ] = theme.isDarkBackground() 
        ? 
        [ white, dark, white, rgbaWhiteBg ] 
        : 
        [ dark, white, lightBlue.topColor, rgbaDarkBg ];

    const handleRefreshMe = () => setRefresh(true)

    useEffect(() => {
        setRefresh(false)
    }, [ refresh ])

    const themeMenuItem = useMemo(() => <ThemeMenuItem 
        onRefresh={handleRefreshMe} size={80} color={itemColor} bgColor={bgColor} hideMe={f => hideThemes.current = f} 
    />, [itemColor, bgColor])

    const helpMenuItem = useMemo(() => <HelpMenuItem size={80} />, [])

    const onMenuIcon = e => {
        // Entire menu        
        setMenuVisible(v => !v)

        // Themes menu
        if (themesDisplayed) {
            setThemesDisplayed(false)
            hideThemes.current()
        } 
    }

    return (
        <Fragment>
            <MenuIcon 
                onClick={onMenuIcon} marginTop={-Math.floor(MENU_ICON_HEIGHT/2)} right={right} 
                width={MENU_ICON_WIDTH} height={MENU_ICON_HEIGHT} color={color}
            >
                <CSSTransition 
                    in={menuVisible} 
                    classNames="menu" 
                    timeout={500}
                    unmountOnExit
                >
                    <Menu 
                        onClick={e => e.stopPropagation()} 
                        height={MENU_HEIGHT} 
                        width={MENU_WIDTH} 
                        bgColor={bgColor}
                        shadowColor={shadowColor}
                    >
                        {themeMenuItem}
                        {helpMenuItem}
                    </Menu>
                </CSSTransition>
            </MenuIcon>
        </Fragment>
    )
}

export default MenuDesktop
import React, { useState, useEffect } from 'react'

import IconInput from '../../../../controls/IconInput'
import { isEmpty } from '../../../../../utils'
import useSignupContext from '../SignupContext'
import useSignupFormContext from '../SignupFormContext'

let lastEmail = ''
let emailEdited = false

const Email = () => {
    const [ email, setEmail ] = useState('')

    // Hooks
    const { addResetFunction } = useSignupContext()
    const { activeMember, onEmailChange } = useSignupFormContext()

    // Page mounted/portrait-to-landscape effect
    //
    useEffect(() => {
        addResetFunction(resetBackup)
        lastEmail && setEmail(lastEmail)
    }, [])

    // Active member loaded effect
    useEffect(() => {
        if (isEmpty(activeMember)) return;
        if (!emailEdited) {
            setEmail(activeMember.email)
            lastEmail = activeMember.email
        }
    }, [ activeMember ])

    const resetBackup = () => {
        lastEmail = ''
        emailEdited = false
    }
    
    const onChange = e => {
        onEmailChange(e)
        setEmail(e.target.value)
        lastEmail = e.target.value
        emailEdited = true
    }

    return (
        <IconInput
            type='text' 
            iconUrl='email' 
            name='email'
            value={email} 
            placeholder='Your e-mail'
            marginTop={30} 
            onChange={onChange}
            caption='Your e-mail for info and updates. (not required)'
        />
    )
}

export default Email
import React, { Fragment } from 'react'
import styled from 'styled-components'

import Separator from '../../../../controls/Separator'
import { useTheme } from '../../../../contexts/Theme'
import useClientContext from '../ClientContext'

const TotalsContainer = styled.div`
    width: ${p => p.pctWidth ? p.pctWidth : '80'}%;
    height: auto;
    margin-top: ${props => props.marginTop}px;
    margin-left: -20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font: 64px verdana, sans-serif;
    color: ${props => props.color};
`

const Total = styled.div`
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const Scissors = styled.img`
    width: 25px;
    height: auto;
    margin-left: -10px;
`

const Cart = styled.img`
    width: 50px;
    height: auto;
    margin-left: -20px;
`

const ActivityCount = ({ pctWidth }) => {
    // Hooks
    const { currentClient } = useClientContext()
    const theme = useTheme()

    // Destructure the theme
    const { white, dark, rgbDark, rgbaWhiteBg, rgbaDarkBg } = theme
    const [ color, iconColor, sepColor ] = theme.isDarkBackground() 
        ? [ white, white, rgbaWhiteBg ] 
        : [ rgbDark, dark, rgbaDarkBg ];

    return (
        <Fragment>
            <TotalsContainer color={color} marginTop={10} pctWidth={pctWidth}>
                <Total>{currentClient.haircuts.length}</Total>
                <Scissors src={`${process.env.PUBLIC_URL}/images/scissors-${iconColor}.png`} />
                <Total>{currentClient.orders.length}</Total>
                <Cart src={`${process.env.PUBLIC_URL}/images/shopping-cart-${iconColor}.png`} />
            </TotalsContainer>
            <Separator color={sepColor} marginTop={10} />
        </Fragment>
    )
}

export default ActivityCount
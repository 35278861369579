import React, { useState, useEffect } from 'react'

import IconInput from '../../../../controls/IconInput'
import { isEmpty } from '../../../../../utils'
import useSignupContext from '../SignupContext'
import useSignupFormContext from '../SignupFormContext'

let lastPhone = ''
let phoneEdited = false

const Phone = () => {
    // Local state
    const [ phone, setPhone ] = useState('')

    // Hooks
    const { addResetFunction } = useSignupContext()
    const { activeMember, onPhoneChange } = useSignupFormContext()

    // Page mounted/portrait-to-landscape effect
    //
    useEffect(() => {
        addResetFunction(resetBackup)
        lastPhone && setPhone(lastPhone)
    }, [])

    // Active member loaded effect
    useEffect(() => {
        if (isEmpty(activeMember)) return;
        if (!phoneEdited) {
            setPhone(activeMember.phone)
            lastPhone = activeMember.phone
        }
    }, [ activeMember ])

    const resetBackup = () => {
        lastPhone = ''
        phoneEdited = false
    }
    
    const onChange = e => {
        onPhoneChange(e)
        setPhone(e.target.value)
        lastPhone = e.target.value
        phoneEdited = true
    }

    return (
        <IconInput
            type='text' 
            iconUrl='phone' 
            name='phone'
            value={phone} 
            placeholder='Your cell'
            marginTop={30} 
            onChange={onChange}
            caption='Enter your mobile phone number. (required)'
            required
        />
    )
}

export default Phone
import React, { Fragment, useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import { Form } from 'reactstrap'

import Portrait from '../controls/Portrait'
import Column from '../controls/Column'
import Wallpaper from '../controls/Wallpaper'
import Header from '../controls/Header'
import HeaderDesktop from '../media/HeaderDesktop'
import IconInput from '../controls/IconInput'
import Dropdown from '../controls/Dropdown'
import Submit from '../controls/Submit'
import ErrorMessage from '../controls/ErrorMessage'
import MessageBox from '../controls/MessageBox'
import Null from '../controls/Null'
import { secretQuestions } from '../../constants'
import useSwitchTheme from '../../hooks/useSwitchTheme'
import { useLandscape } from '../../hooks/useMedia'
import { classicLight, classicDark } from '../../overlayColors'

const Inputs = styled.section`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`

const Half = styled.div`
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

const lightOverlay = classicLight
const darkOverlay = classicDark

const AdminProfileDesktop = ({ 
    onSubmit, onSubmitOk, errorMessage, messageBoxVisible, spinnerVisible, statusMessage,
    onPasswordChange, onRepeatPasswordChange, onPhoneChange, onEmailChange, onQuestionChange, 
    onAnswerChange, onBack, title, backTo, passwordRequired, phoneValue, emailValue
}) => {
    // Local state

    // Other hooks
    const isLandscape = useLandscape()
    useSwitchTheme()

    const ErrorMessageOrNot = errorMessage ? ErrorMessage : Null;

    return  (
        <Fragment>
        {
            isLandscape
            ?
            <Fragment>
                <Wallpaper />
                <HeaderDesktop height={100} leftContent={<Header arrow={{
                    color: 'red',
                    to: backTo[0],
                    destination: backTo[1]
                }} onBack={onBack} title={title} />} />
                <ErrorMessageOrNot>{errorMessage}</ErrorMessageOrNot>
                <CSSTransition 
                    in={messageBoxVisible} 
                    classNames="message" 
                    timeout={1000}
                    unmountOnExit
                >
                    <MessageBox 
                        spinner={spinnerVisible} message={statusMessage}
                        lightBackground={lightOverlay} darkBackground={darkOverlay} 
                        onOk={onSubmitOk} label='Ok'
                        width='320' height='240' 
                    />
                </CSSTransition>
                <Form onSubmit={onSubmit}>
                    <Column>
                        <Inputs>
                            <Half>
                                <IconInput 
                                    type='password' 
                                    iconUrl='password' 
                                    name='password'
                                    placeholder='New password'
                                    marginTop={30} 
                                    onChange={onPasswordChange}
                                    caption="Your password (required, must be at least eight characters)."
                                    required={passwordRequired}
                                />
                                <IconInput 
                                    type='password' 
                                    iconUrl='password' 
                                    name='repeatPassword' 
                                    placeholder='Repeat new password'
                                    marginTop={30} 
                                    onChange={onRepeatPasswordChange}
                                    required={passwordRequired}
                                />
                                <IconInput 
                                    type='text' 
                                    iconUrl='phone' 
                                    name='phone'
                                    value={phoneValue} 
                                    placeholder='Your cell'
                                    marginTop={30} 
                                    onChange={onPhoneChange}
                                    caption='Enter your mobile phone number. (required)'
                                    required
                                />
                            </Half>
                            <Half>
                                <IconInput 
                                    type='text' 
                                    iconUrl='email' 
                                    name='email'
                                    value={emailValue} 
                                    placeholder='Your e-mail'
                                    marginTop={30} 
                                    onChange={onEmailChange}
                                    caption='Your e-mail for info and updates. (not required)'
                                />
                                <Dropdown
                                    options={secretQuestions}
                                    caption='Pick a security reset question from the list.'
                                    name='secretQuestion'
                                    iconUrl='security-questions'
                                    placeholder='Security questions'
                                    onChange={onQuestionChange}
                                    marginTop={20} 
                                    alternating
                                />
                                <IconInput 
                                    type='text' 
                                    iconUrl='security-questions' 
                                    name='secretQuestionAnswer'
                                    placeholder='Security question answer'
                                    marginTop={20} 
                                    onChange={onAnswerChange}
                                    caption='Answer the security reset question.'
                                />
                            </Half>
                        </Inputs>
                        <Submit width={'100'} marginTop={'40'} />
                    </Column>
                </Form>
            </Fragment>
            :
            <Portrait>
                <Wallpaper />
                <HeaderDesktop height={100} leftContent={<Header arrow={{
                    color: 'red',
                    to: backTo[0],
                    destination: backTo[1]
                }} onBack={onBack} title={title} />} />
                <ErrorMessageOrNot>{errorMessage}</ErrorMessageOrNot>
                <CSSTransition 
                    in={messageBoxVisible} 
                    classNames="message" 
                    timeout={1000}
                    unmountOnExit
                >
                    <MessageBox 
                        spinner={spinnerVisible} message={statusMessage}
                        lightBackground={lightOverlay} darkBackground={darkOverlay} 
                        onOk={onSubmitOk} label='Ok'
                        width='320' height='240' 
                    />
                </CSSTransition>
                <Form onSubmit={onSubmit}>
                    <Column>
                        <IconInput 
                            type='password' 
                            iconUrl='password' 
                            name='password'
                            placeholder='New password'
                            marginTop={30} 
                            onChange={onPasswordChange}
                            caption="Your password (required, must be at least eight characters)."
                            required={passwordRequired}
                        />
                        <IconInput 
                            type='password' 
                            iconUrl='password' 
                            name='repeatPassword' 
                            placeholder='Repeat new password'
                            marginTop={30} 
                            onChange={onRepeatPasswordChange}
                            required={passwordRequired}
                        />
                        <IconInput 
                            type='text' 
                            iconUrl='phone' 
                            name='phone'
                            value={phoneValue} 
                            placeholder='Your cell'
                            marginTop={30} 
                            onChange={onPhoneChange}
                            caption='Enter your mobile phone number. (required)'
                            required
                        />
                        <IconInput 
                            type='text' 
                            iconUrl='email' 
                            name='email'
                            value={emailValue} 
                            placeholder='Your e-mail'
                            marginTop={30} 
                            onChange={onEmailChange}
                            caption='Your e-mail for info and updates. (not required)'
                        />
                        <Dropdown
                            options={secretQuestions}
                            caption='Pick a security reset question from the list.'
                            name='secretQuestion'
                            iconUrl='security-questions'
                            placeholder='Security questions'
                            onChange={onQuestionChange}
                            marginTop={20} 
                            alternating
                        />
                        <IconInput 
                            type='text' 
                            iconUrl='security-questions' 
                            name='secretQuestionAnswer'
                            placeholder='Security question answer'
                            marginTop={20} 
                            onChange={onAnswerChange}
                            caption='Answer the security reset question.'
                        />
                        <Submit width={'100'} marginTop={'40'} />
                    </Column>
                </Form>
            </Portrait>
        }
        </Fragment>
    )
}

export default AdminProfileDesktop
import React, { createContext, useContext } from 'react'

const Profile = createContext(undefined)

export const ProfileProvider = ({ children, value }) => {
    return (
        <Profile.Provider value={value}>{children}</Profile.Provider>
    )
}

const useProfileContext = () => {
    const context = useContext(Profile)

    if (context === undefined) throw new Error('useProfileContext must be used within a ProfileProvider')

    return context;
}

export default useProfileContext
import { SET_TOKEN, CLEAR_TOKEN, RESTORE_TOKEN } from '../actions/types'

const initialState = null

const token = (state = initialState, action) => {

    switch (action.type) {
        case SET_TOKEN: {
            const token = action.payload
            localStorage.setItem('token', token)
            return token
        }
        case CLEAR_TOKEN:
            localStorage.removeItem('token')
            return null
        case RESTORE_TOKEN: 
            return localStorage.getItem('token')
        default:
            return state
    }
}

export default token
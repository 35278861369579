import React, { useState, useEffect } from 'react'

import DatePicker from '../../../../controls/DatePicker'
import useClientSignupContext from '../ClientSignupContext'
import useClientSignupFormContext from '../ClientSignupFormContext'

let lastBirthday = null

const Birthday = () => {
    // State
    const [ birthday, setBirthday ] = useState(null)

    // Hooks
    const { onBirthdayReady } = useClientSignupFormContext()
    const { addResetFunction } = useClientSignupContext()

    useEffect(() => {
        addResetFunction(resetBackup)
        lastBirthday && setBirthday(lastBirthday)
    }, [])

    const resetBackup = () => {
        lastBirthday = null
    }

    const onReady = dateString => {
        onBirthdayReady(dateString)
        setBirthday(dateString)
        lastBirthday = dateString
    }

    return (
        <DatePicker
            caption='Select your birthday (Optional)'
            onDateReady={onReady}
            value={birthday}
            iconUrl='baby-carriage'
            marginTop={30}
            noYear
        />
    )
}

export default Birthday
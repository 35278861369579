import { useState, useEffect } from 'react'

import { useTheme } from '../components/contexts/Theme'

const useSwitchTheme = () => {
    const [ refreshPage, setRefreshPage ] = useState(false)

    const theme = useTheme()

    useEffect(() => {
        theme.refreshPage = () => setRefreshPage(true)
    }, [ theme ]) 

    useEffect(() => {
        setRefreshPage(false)
    }, [ refreshPage ])
}

export default useSwitchTheme
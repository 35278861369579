import React from 'react'

import Submit from '../../../../controls/Submit'

const ClientSignupSubmit = ({ marginBottom }) => {
    return (
        <Submit width={'100'} marginTop={'40'} marginBottom={marginBottom} text='Save' />
    )
}

export default ClientSignupSubmit
import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'

import { NAME } from '../useAddHaircut'
import Column from '../../../../controls/Column'
import IconInput from '../../../../controls/IconInput'
import Button from '../../../../controls/Button'
import Submit from '../../../../controls/Submit'
import { tokenConfig, timeoutConfig, useFromClients } from '../../../../../utils'
import useAddHaircutContext from '../AddHaircutContext'

let lastName = ''
let initialMount = true

const NameAndSave = ({ marginTop }) => {
    const [ name, setName ] = useState(lastName)
    const [ orderOrExit, setOrderOrExit ] = useState({ view: false })

    const { 
        activeMember, currentMember, isCurrentMember, currentClient, updatedClient, 
        newHaircut, haircutPhotos, photoFiles,
        stage, addResetFunction, runResetFunctions, authToken, updateActiveMember, updateCurrentClient, 
        setStatus, setStatusMessage, setSpinner, setOnStatusOk, 
        onNameChange, updateHideInstruction
    } = useAddHaircutContext()
    
    const fromClients = useFromClients()

    // Define the member being referenced
    const theMember = isCurrentMember() ? currentMember : activeMember;

    useEffect(() => {
        if (!initialMount) return;
        addResetFunction(resetBackup)
        initialMount = false
    }, [])

    const resetBackup = () => {
        lastName = ''
        initialMount = true
    }
    
    const onChange = e => {
        onNameChange(e)
        setName(e.target.value)
        lastName = e.target.value
    }

    const onSubmit = async e => {

        e.preventDefault()

        setOnStatusOk(() => () => setStatus(false))
        
        // "Finish" the haircut
        const haircuts = [{
                newHaircut: true,
                haircutDate: new Date().toISOString(),
                photos: [ ...haircutPhotos ],
                ...newHaircut
            },
            ...currentClient.haircuts
        ] 

        // "Finish" the client
        const client = {
            scalp: currentClient.scalp,
            texture: currentClient.texture,
            height: currentClient.height,
            width: currentClient.width,
            curl: currentClient.curl,
            haircuts: haircuts,
            ...updatedClient
        }

        try {
            
            setStatus(true)
            setSpinner(true)
            
            // Save the changes to the DB
            const savedClient = await axios.patch(`/api/clients/${currentClient._id}`, client, timeoutConfig(tokenConfig(authToken)))

            // Patch was ok, update the state
            updateCurrentClient( 'haircuts', savedClient.data.haircuts )
            updateCurrentClient( 'texture', client.texture )
            updateCurrentClient( 'scalp', client.scalp )
            updateCurrentClient( 'height', client.height )
            updateCurrentClient( 'width', client.width )
            updateCurrentClient( 'curl', client.curl )
            
            // Update the member's client list
            const summarizedClient = theMember.clients.find(client => client.clientId === currentClient._id)
            const updatedSummarizedClient = haircutPhotos.length === 0 
            ?
            { 
                ...summarizedClient, 
                lastHaircutDate: new Date().toISOString()
            }
            :            
            { 
                ...summarizedClient, 
                lastHaircutDate: new Date().toISOString(),
                samplePhoto: haircutPhotos[0]
            }

            const clients = [ 
                updatedSummarizedClient, 
                ...theMember.clients.filter(client => client.clientId !== currentClient._id)
            ]

            // Update the member collection with the new data
            await axios.patch(`/api/members/${theMember._id}`, { clients }, timeoutConfig(tokenConfig(authToken)))
                
            updateActiveMember( 'clients', clients )

            // Process the haircut photos
            if (!photoFiles || photoFiles.length === 0) { // We're done
                setSpinner(false)
                setStatusMessage(`Successfully saved the haircut.`)
                
                updateHideInstruction(true)
                setOrderOrExit({ view: true, order: true })
                return
            } 

            // There are haircut photos ...            

            // Upload the photos to the photos folder
            try {
                const formData = new FormData()
                for (let i = 0; i < photoFiles.length; i++)
                    formData.append('file', photoFiles[i])
                await axios.post('/upload/photos', formData, {
                    headers: { "Content-Type": "multipart/form-data" }
                })
                setSpinner(false)
                setStatusMessage(`Successfully saved the haircut data.`)
            } catch (e) { 
                /* Log error */
                setSpinner(false)
                setStatusMessage(`Could not upload haircut photos. Error reported to administrator`) 
            }
            
            updateHideInstruction(true)
            setOrderOrExit({ view: true, order: true })

        } catch(err) {
            
            if (err.response) { // Server error or status 400, 404
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, haircut data not saved.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, haircut data not saved, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, haircut data not saved`)
            }

            setSpinner(false)
            updateHideInstruction(true)
            setOrderOrExit({ view: true, order: false })
        }
    }

    return (
        <Fragment>
        {
            (stage === NAME) 
            &&
            ( 
                orderOrExit.view
                ?
                <Fragment>
                    <Button 
                        width={300} height={75} marginTop={50} fontSize={24} label='Exit Without Ordering' 
                        to={`/${fromClients ? 'clients' : 'client'}`} 
                        onClick={() => runResetFunctions()} 
                    />
                    <Button 
                        width={300} height={75} marginTop={50} fontSize={24} 
                        label='Order Matching Products' to='/client/order' 
                        enabled={orderOrExit.order} onClick={() => runResetFunctions()}
                    />
                </Fragment>
                :
                <form onSubmit={onSubmit}> 
                    <Column> 
                        <IconInput 
                            type='text' 
                            iconUrl='scissors-even' 
                            name='name'
                            value={name} 
                            placeholder="Haircut name" 
                            caption="What do you want to call this haircut?"
                            marginTop={40 ?? marginTop} 
                            onChange={onChange}
                            required
                        />          
                        <Submit 
                            text='Save Cut' width={200} height={75} fontSize={24} 
                            marginTop={50 ?? marginTop} marginBottom={40} 
                        />
                    </Column>
                </form>
            )
        }
        </Fragment>
    )
}

export default NameAndSave
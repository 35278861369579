import { useState, useEffect } from 'react'

import { useHelp } from '../components/contexts/Help'

const useActivateHelp = () => {
    const [ refreshPage, setRefreshPage ] = useState(false)

    const help = useHelp()

    useEffect(() => {
        help.refreshPage = () => setRefreshPage(true)
    }, [ help ]) 

    useEffect(() => {
        setRefreshPage(false)
    }, [ refreshPage ])
}

export default useActivateHelp
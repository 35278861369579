import React, { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Header from '../controls/Header'
import HeaderDesktop from '../media/HeaderDesktop'
import SideMenu from '../controls/SideMenu'
import Wallpaper from '../controls/Wallpaper'
import Null from '../controls/Null'
import { usePhone, useLandscape } from '../../hooks/useMedia'
import useSwitchTheme from '../../hooks/useSwitchTheme'
import {
    RESTORE_ACTIVE_ADMIN, 
    RESTORE_TOKEN
} from '../../redux/actions/types'
import { isEmpty, tokenConfig } from '../../utils'

const Orders = () => {

    // Other hooks
    const dispatch = useDispatch()
    const activeAdmin = useSelector(state => state.activeAdmin)
    const authToken = useSelector(state => state.token)
    const isPhone = usePhone()
    const isLandscape = useLandscape()
    useSwitchTheme()
    
    // Check for and recover from page refresh
    if (isEmpty(activeAdmin)) {
        // Restore active admin and token from local storage post-refresh
        dispatch({ type: RESTORE_ACTIVE_ADMIN })
        dispatch({ type: RESTORE_TOKEN })

        // Don't want to render with missing data
        return <Null />
    }

    return (
        <Fragment>
            <Wallpaper />
            {
                isPhone
                ?
                <Fragment>
                    <SideMenu top={isLandscape ? 50 : 0} />
                    <Header arrow={{
                        color: 'yellow',
                        to: '/admin-home',
                        destination: 'Home'
                    }} title='Orders' separator />
                </Fragment>
                :
                <HeaderDesktop height={100} leftContent={<Header arrow={{
                    color: 'yellow',
                    to: 'admin-home',
                    destination: 'Home'
                }} title='Orders' />} />
            }
        </Fragment>
    )
}

export default Orders
import styled from 'styled-components'

const Portrait = styled.section`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

export default Portrait
import React, { Fragment, useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import { Form } from 'reactstrap'

import Wallpaper from '../controls/Wallpaper'
import Portrait from '../controls/Portrait'
import Landscape from '../controls/Landscape'
import HeaderDesktop from './HeaderDesktop'
import Logo from './LogoDesktop'
import Column from '../controls/Column'
import Spinner from '../controls/Spinner'
import IconInput from '../controls/IconInput'
import Button from '../controls/Button'
import Submit from '../controls/Submit'
import Switch from '../controls/Switch'
import Story from '../controls/Story'
import ErrorMessage from '../controls/ErrorMessage'
import ShareButtons from '../controls/ShareButtons'
import useSwitchTheme from '../../hooks/useSwitchTheme'
import useActivateHelp from '../../hooks/useActivateHelp'
import { useTheme } from '../contexts/Theme'
import { useLandscape } from '../../hooks/useMedia'
import { classicDark, classicLight, lightBlue, darkBlue } from '../../overlayColors'
import { USER_LOADING, secretQuestions } from '../../constants'

const Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
` 

const LoginContainer = styled.div`
    position: absolute;
    top: ${props => props.top}px;
    left: 50%;
    width: ${props => props.width}px;
    height: auto;
    margin-top: 20px;
    margin-left: ${props => -props.width / 2}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: ${props => props.bgColor};
    box-shadow: 10px 10px 2px ${props => props.shadowColor};
    border-radius: 4px;
    z-index: 2; 

    &.login-enter {
        opacity: 0;
        transform: scale(0.5) translateY(0);
    }
    &.login-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 500ms, transform 500ms;
    }
    &.login-exit {
        opacity: 1;
    }
    &.login-exit-active {
        opacity: 0;
        transform: scale(0.5) translateY(0);
        transition: opacity 500ms, transform 500ms;
    }
`

const ForgotPassword = styled.div`
    width: 300px;
    height: auto;
    font: 18px verdana, sans-serif;
    text-align: right;
    margin-top: 15px;
    color: ${p => p.color};
`

const Conversation = styled.div`
    width: 300px;
    height: auto;
    font: 18px verdana, sans-serif;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    color: ${p => p.color};
`

const HEADER_HEIGHT = 100
const LOGIN_WIDTH = 350

const LoginDesktop = ({
    onPasswordChange, onUseridChange, onAnswerChange, onSubmit, onSubmitAnswer, 
    onOkReply, onForgotPassword, user, answerReply, onKeepMe, loadStatus, loginError
}) => {
    // Local state
    const [ menuRequested, setMenuRequested ] = useState(false)
    const [ logonRequested, setLogonRequested ] = useState(false)
    const [ keepMe, setKeepMe ] = useState(0)

    // Other hooks
    const theme = useTheme()
    const isLandscape = useLandscape()
    useSwitchTheme()
    useActivateHelp()

    useEffect(() => {
        window.addEventListener('resize', function(e) {
            window.location.reload()
        })
    }, [])

    const onKeepMeChange = e => {
        setKeepMe(e.target.value)
        onKeepMe(e)
    }

    const onStoryOver = () => setMenuRequested(false)
    const onLogonRequest = () => setLogonRequested(l => !l)

    // Destructure the theme
    const { rgbaWhiteBg, rgbaDarkBg, white, rgbDark } = theme
    const [ sepColor, loginColor, color ] = theme.isDarkBackground() 
        ? 
        [ rgbaWhiteBg, darkBlue.topColor, white ] 
        : 
        [ rgbaDarkBg, lightBlue.topColor, rgbDark ];

    return (
        <Fragment>
        {
            isLandscape
            ?
            <Landscape>
                <Wallpaper />
                <Content>
                    <HeaderDesktop height={HEADER_HEIGHT} leftContent={<Logo onLogonRequest={onLogonRequest} />} />
                    <CSSTransition 
                        in={logonRequested} 
                        classNames="login" 
                        timeout={1000}
                        unmountOnExit
                    >
                        <LoginContainer top={HEADER_HEIGHT} width={LOGIN_WIDTH} bgColor={loginColor} shadowColor={sepColor}>        
                            {loadStatus === USER_LOADING && <Spinner diameter={50} />}
                            {
                                user
                                ?
                                <Fragment>
                                    <Conversation color={color}>{secretQuestions[user.secretQuestion]}</Conversation>
                                    <IconInput 
                                        type='text' 
                                        name='secretAnswer' 
                                        placeholder='Answer'
                                        marginTop={20} 
                                        onChange={onAnswerChange}
                                        centered
                                        required
                                    />
                                    <Button 
                                        label="Submit answer" onClick={onSubmitAnswer} 
                                        width="200" height="50" marginTop={30} 
                                    /> 
                                </Fragment>
                                :
                                answerReply
                                ?
                                <Fragment>                        
                                    <Conversation color={color}>{answerReply}</Conversation>
                                    <Button 
                                        label="Ok" onClick={onOkReply} 
                                        width="100" height="50" marginTop={30} 
                                    />
                                </Fragment>
                                :
                                <Fragment>
                                    { loginError && (<ErrorMessage>{loginError}</ErrorMessage>) }
                                    <Form onSubmit={onSubmit}> 
                                        <Column>
                                            <IconInput 
                                                type='text' 
                                                iconUrl='email' 
                                                name='userid' 
                                                placeholder='Email Address'
                                                onChange={onUseridChange}
                                                marginTop={20}
                                                required
                                            />
                                            <IconInput 
                                                type='password' 
                                                iconUrl='password' 
                                                name='password' 
                                                placeholder='Password or Access Code'
                                                onChange={onPasswordChange}
                                                marginTop={20} 
                                                required
                                            />
                                            <ForgotPassword onClick={onForgotPassword} color={color}>forgot password?</ForgotPassword>
                                            <Submit 
                                                width={100} 
                                                height={50} 
                                                text='Enter'
                                                marginTop={20}
                                                marginBottom={20} 
                                            />
                                        </Column>
                                    </Form>
                                </Fragment>
                            }
                            <Switch 
                                width={60} height={30} label='Keep me logged in' onChange={onKeepMeChange} 
                                helpCaption='Switch on to make your session last 24 hours instead of 2 hours.' 
                                value={keepMe} marginTop={20} marginBottom={20}
                            />
                        </LoginContainer>
                    </CSSTransition>
                    <Story menuRequested={menuRequested} onStoryOver={onStoryOver} width={window.innerWidth} height={500} /> 
                    <ShareButtons iconSize={40} direction='row' /> 
                </Content>              
            </Landscape>
            :
            <Portrait>
                <Wallpaper />
                <Content>
                    <HeaderDesktop height={HEADER_HEIGHT} leftContent={<Logo onLogonRequest={onLogonRequest} />} />
                    <CSSTransition 
                        in={logonRequested} 
                        classNames="login" 
                        timeout={1000}
                        unmountOnExit
                    >
                        <LoginContainer top={HEADER_HEIGHT} width={LOGIN_WIDTH} bgColor={loginColor} shadowColor={sepColor}>        
                            {loadStatus === USER_LOADING && <Spinner diameter={50} />}
                            {
                                user
                                ?
                                <Fragment>
                                    <Conversation color={color}>{secretQuestions[user.secretQuestion]}</Conversation>
                                    <IconInput 
                                        type='text' 
                                        name='secretAnswer' 
                                        placeholder='Answer'
                                        marginTop={20} 
                                        onChange={onAnswerChange}
                                        centered
                                        required
                                    />
                                    <Button 
                                        label="Submit answer" onClick={onSubmitAnswer} 
                                        width="200" height="50" marginTop={30} 
                                    /> 
                                </Fragment>
                                :
                                answerReply
                                ?
                                <Fragment>                        
                                    <Conversation color={color}>{answerReply}</Conversation>
                                    <Button 
                                        label="Ok" onClick={onOkReply} 
                                        width="100" height="50" marginTop={30} 
                                    />
                                </Fragment>
                                :
                                <Fragment>
                                    { loginError && (<ErrorMessage>{loginError}</ErrorMessage>) }
                                    <Form onSubmit={onSubmit}> 
                                        <Column>
                                            <IconInput 
                                                type='text' 
                                                iconUrl='email' 
                                                name='userid' 
                                                placeholder='Email Address'
                                                onChange={onUseridChange}
                                                marginTop={20}
                                                required
                                            />
                                            <IconInput 
                                                type='password' 
                                                iconUrl='password' 
                                                name='password' 
                                                placeholder='Password or Access Code'
                                                onChange={onPasswordChange}
                                                marginTop={20} 
                                                required
                                            />
                                            <ForgotPassword onClick={onForgotPassword} color={color}>forgot password?</ForgotPassword>
                                            <Submit 
                                                width={100} 
                                                height={50} 
                                                text='Enter'
                                                marginTop={20}
                                                marginBottom={20} 
                                            />
                                        </Column>
                                    </Form>
                                </Fragment>
                            }
                            <Switch 
                                width={60} height={30} label='Keep me logged in' onChange={onKeepMeChange} 
                                helpCaption='Switch on to make your session last 24 hours instead of 2 hours.' 
                                value={keepMe} marginTop={20} marginBottom={20}
                            />
                        </LoginContainer>
                    </CSSTransition>
                    <Story menuRequested={menuRequested} onStoryOver={onStoryOver} width={700} height={800} vertical />                     
                    <ShareButtons iconSize={40} marginTop={50} direction='row' /> 
                </Content>
            </Portrait>
        }
        </Fragment>
    )
}

export default LoginDesktop
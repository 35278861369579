import React, { Fragment } from 'react'
import styled from 'styled-components'

import Separator from '../../../../controls/Separator'
import Photo from '../../../../controls/Photo'
import { useTheme } from '../../../../contexts/Theme'
import useClientContext from '../ClientContext'

const IdentifierContainer = styled.div`
    width: ${p => p.pctWidth ? p.pctWidth : '95'}%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
`

const PhotoContainer = styled.div`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
`

const Essentials = styled.div`
    width: 70%;
    height: auto;
    margin-right: 10px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: Verdana, sans-serif;
    color: ${props => props.color};
`

const ClientName = styled.div`
    font-size: 24px;
    text-align: center;
`

const LineItem = styled.div`
    margin-top: 5px;
    font-size: 16px;
    text-align: left;
`

const PHOTO_WIDTH = 80;

const Identifier = ({ pctWidth }) => {
    // Hooks
    const { currentClient } = useClientContext()
    const theme = useTheme()

    // Destructure the theme
    const { white, rgbDark, rgbaWhiteBg, rgbaDarkBg } = theme
    const [ color, sepColor ] = theme.isDarkBackground() ? [ white, rgbaWhiteBg ] : [ rgbDark, rgbaDarkBg ];

    // Destructure the current client
    const { name, email, phone } = currentClient
    const address = currentClient.shippingAddresses.filter(addr => addr.isDefault)?.[0]

    return (
        <Fragment>
            <IdentifierContainer pctWidth={pctWidth}>
                <PhotoContainer size={PHOTO_WIDTH}>
                    <Photo width={PHOTO_WIDTH} photo={getSamplePhoto(currentClient)} />
                </PhotoContainer>
                <Essentials color={color}>
                    <ClientName>{name}</ClientName>
                    <LineItem>{email}</LineItem>
                    <LineItem>{phone}</LineItem>
                    {
                        address && 
                        <Fragment>
                            <LineItem>{address.addr1}</LineItem>
                            {
                                address.addr2 && <LineItem>{address.addr2}</LineItem>
                            }
                            <LineItem>{`${address.city}, ${address.state} ${address.zip}`}</LineItem>
                            {
                                (address.country !== 'USA' && address.country !== 'US') &&
                                <LineItem>{address.country}</LineItem>
                            }
                        </Fragment>
                    }
                </Essentials>
            </IdentifierContainer>
            <Separator color={sepColor} marginTop={20} />
        </Fragment>
    )
}

const getSamplePhoto = client => {
    const { haircuts } = client

    for (let i = 0; i < haircuts.length; i++) {
        if (haircuts[i].photos.length > 0)
            return haircuts[i].photos[0]
    }

    // No photos of any haircuts
    return {
        width: 200,
        xOffset: 0,
        yOffset: 0,
        clipWidth: 200,
        clipHeight: 200,
        filename: 'anon-person.jpg'
    }
} 

export default Identifier
import React, { useState } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { useTheme } from '../contexts/Theme'
import Photo from './Photo'
import Haircut from './Haircut'
import OrderDetail from './OrderDetail'
import { standardDate } from './DatePicker'

const PHOTO_SIZE = 60

const LadderContainer = styled.div`
    width: 90%;
    height: ${props => props.height + 40}px;
    margin-top: ${props => props.marginTop}px;
    margin-bottom: ${props => props.marginBottom}px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: ${props => props.textColor};
    overflow: scroll;
`

const Title = styled.div`
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    font: 24px verdana, sans-serif;
    text-align: center;
    flex-shrink: 0;
`

const Ladder = styled.div`
    width: 100%;
    height: ${props => props.height}px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: visible;
    flex-shrink: 0;
`

const RightRung = styled.div`
    position: relative;
    left: 60px;
    width: 120px;
    height: 70px;
    border-color: ${props => props.borderColor};
    border-style: solid;
    border-width: 0 0 2px 2px;
    flex-shrink: 0;
`

const RightJoint = styled.div`
    position: absolute;
    left: -10px;
    bottom: -10px;
    height: 20px;
    width: 20px;
    background-color: rgb(255, 128, 64);
    border-radius: 50%;
    z-index: 2;
`

const RightLeaf = styled.div`
    position: absolute;
    right: -${Math.floor(PHOTO_SIZE/2)}px;
    bottom: -${Math.floor(PHOTO_SIZE/2)}px;
    height: ${PHOTO_SIZE}px;
    width: ${PHOTO_SIZE}px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 14px verdana, sans-serif;
    color: white;
    text-align: center;
    background-color: gray;
    border-radius: 50%;
    z-index: 2;

    &.pressed { box-shadow: -2px 2px 10px 5px ${props => props.shadow}; }
`

const RightLabel = styled.div`
    position: absolute;
    left: -115px;
    bottom: -20px;
    width: 90px;
    height: 40px;
    font: 14px verdana, sans-serif;
`

const RightStem = styled.div`
    position: absolute;
    bottom: -2px;
    left: -20px;
    width: 20px;
    height: 5px;
    border-style: solid;
    border-width: 0 0 2px 0;
`

const LeftRung = styled.div`
    position: relative;
    right: 60px;
    width: 120px;
    height: 70px;
    border-color: ${props => props.borderColor};
    border-style: solid;
    border-width: 0 2px 2px 0;
    flex-shrink: 0;
    font: 14px verdana, sans-serif;
`

const LeftJoint = styled.div`
    position: absolute;
    right: -10px;
    bottom: -10px;
    height: 20px;
    width: 20px;
    background-color: rgb(255, 128, 64);
    border-radius: 50%;
    z-index: 2;
`

const LeftLeaf = styled.div`
    position: absolute;
    left: -${Math.floor(PHOTO_SIZE/2)}px;
    bottom: -${Math.floor(PHOTO_SIZE/2)}px;
    height: ${PHOTO_SIZE}px;
    width: ${PHOTO_SIZE}px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font: 14px verdana, sans-serif;
    color: white;
    background-color: gray;
    border-radius: 50%;
    z-index: 2;

    &.pressed { box-shadow: -2px 2px 10px 5px ${props => props.shadow}; }
`

const LeftLabel = styled.div`
    position: absolute;
    right: -115px;
    bottom: -20px;
    width: 90px;
    height: 40px;
`

const LeftStem = styled.div`
    position: absolute;
    bottom: -2px;
    right: -20px;
    width: 20px;
    height: 5px;
    border-style: solid;
    border-width: 0 0 2px 0;
`

const LeafImage = styled.img`
    width: 80%;
    height: auto;
`

const NoData = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${props => props.color};
    font: 20px verdana, sans-serif;
`

const HistoryLadder = ({ itemGroups, postMerge, height, marginTop, marginBottom, title }) => {
    // State
    const [ items ] = useState(merge(itemGroups, postMerge))
    const [ leafPressed, setLeafPressed ] = useState(-1)
    
    // Other hooks
    const theme = useTheme()
    const { white, rgbDark, rgbaWhiteBg, rgbaDarkBg } = theme
    const [ textColor, borderColor, shadow ] = theme.isDarkBackground() ? 
        [ white, white, rgbaWhiteBg ] : 
        [ rgbDark, rgbDark, rgbaDarkBg ];
        
    return (
        <LadderContainer textColor={textColor} height={height} marginTop={marginTop} marginBottom={marginBottom}>
            <Title>{title ? title : 'History'}</Title>
            {
                items.data.length > 0 ? (                    
                    <Ladder height={height}>
                        {
                            items.data.map((item, i) => {
                                const label = `${item[items.index.description]} ${standardDate(item[items.index.date])}`
                                let photo, image;
                                if (typeof item[items.index.photo.name] !== 'undefined') { // Photo(s) here
                                    if (items.index.photo.isArray) {
                                        if (item[items.index.photo.name].length > 0) {
                                            // Is photo array, get first one
                                            photo = item[items.index.photo.name][0]
                                        }
                                    } else { // Single photo, not array
                                        photo = item[items.index.photo.name]
                                    }
                                } else if (typeof item[items.index.image] !== 'undefined') { // Image, not photo(s)
                                    image = item[items.index.image]
                                }
                                return (
                                    i % 2 === 0 ?
                                    <RightRung key={i} borderColor={borderColor}>
                                        <RightJoint />
                                        <RightLeaf 
                                            shadow={shadow} className={leafPressed === i ? 'pressed' : ''} 
                                            onTouchStart={() => setLeafPressed(i)} 
                                            onTouchEnd={() => setLeafPressed(-1)}
                                            onClick={item.onClick}
                                        >
                                        { item.insert && item.insert }
                                        {
                                            photo ? <Photo width={PHOTO_SIZE} photo={photo} /> : (
                                                !image && 'No photo'
                                            )
                                        }
                                        {
                                            image && <LeafImage 
                                                src={`${process.env.PUBLIC_URL}/images/${image}`} />
                                        }
                                        </RightLeaf>
                                        <RightLabel>{label}</RightLabel>
                                        <RightStem />
                                    </RightRung> :
                                    <LeftRung key={i} borderColor={borderColor}>
                                        <LeftJoint />
                                        <LeftLeaf 
                                            shadow={shadow} className={leafPressed === i ? 'pressed' : ''} 
                                            onTouchStart={() => setLeafPressed(i)} 
                                            onTouchEnd={() => setLeafPressed(-1)}
                                            onClick={item.onClick}
                                        >
                                        { item.insert && item.insert }
                                        {
                                            photo ? <Photo width={PHOTO_SIZE} photo={photo} /> : (
                                                !image && 'No photo'
                                            )
                                        }
                                        {
                                            image && <LeafImage 
                                                src={`${process.env.PUBLIC_URL}/images/${image}`} />
                                        }
                                        </LeftLeaf>
                                        <LeftLabel>{label}</LeftLabel>
                                        <LeftStem />
                                    </LeftRung>
                                )
                            })
                        }
                    </Ladder>
                ) :
                <NoData color={shadow}>No history yet</NoData>
            }
        </LadderContainer>
    )
}

const workingItems = []

const numItemsLeft = () => workingItems.reduce((total, items) => total += items.data.length, 0)

const initializeItemsToMerge = itemGroups => {
    itemGroups.forEach(item => workingItems.push(item))
}

const normalize = (item, i) => {
    const { index: { description, date, photo, image } } = workingItems[i]
    const normalizedItem = {
        ...item,
        description: item[description],
        date: item[date]
    }
    if (typeof item[photo] !== 'undefined') {
        normalizedItem.photo = photo.isArray ? item[photo] : [ item[photo] ]
    } else { // Not a photo, must be an image
        normalizedItem.image = item[image]
    }

    return normalizedItem
}

const getNextItem = () => {
    let item, index, date; 
    for (let i = 0; i < workingItems.length; i++) {
        const { data, index: legend } = workingItems[i]
        const [ firstItem ] = data
        if (data.length === 0) continue;
        if (typeof item === 'undefined') { // First item picked
            item = firstItem
            index = i
            date = new Date(item[legend.date])
            continue;
        }
        let itemDate = new Date(firstItem[legend.date])
        if (itemDate > date) { // Compare dates
            item = firstItem
            index = i
            date = itemDate
        }
    }

    // Remove item from working items
    workingItems[index].data.splice(0, 1)
    
    // Set it up for merged list
    return normalize(item, index)
}

const merge = (itemGroups, postMerge) => {
    const mergedItems = {
        data: [],
        index: {
            description: 'description',
            date: 'date',
            photo: {
                name: 'photos',
                isArray: true
            },
            image: 'image'
        }
    }

    initializeItemsToMerge(itemGroups)
    
    while (numItemsLeft() > 0) {
        mergedItems.data.push(getNextItem())
    }

    // Do any post-processing
    postMerge && postMerge(mergedItems.data)

    return mergedItems
}

export const clientHistorySetup =  ({ client }) => {
    const history = [
        { // Haircuts
            data: [],
            index: {
                description: 'haircutName',
                date: 'haircutDate',
                photo: {
                    name: 'photos',
                    isArray: true
                }
            }
        },
        { // Orders
            data: [],
            index: {
                description: 'total',
                date: 'orderDate',
                image: 'image'
            }
        }
    ]
    const [ { data: cuts }, { data: orders } ] = history

    // Put copies of the client haircuts and orders into the history array, with identifiers
    client.haircuts.forEach(haircut => cuts.push({ isHaircut: true, ...haircut }))
    client.orders.forEach(order => orders.push({ isOrder: true, user: client, ...order }))
    
    return history;
}

export const clientHistoryInserts = merged => {
    // Add popups, if any, to merged items
    merged.forEach((item, i) => {
        let refToClick
        item.onClick = () => refToClick.current.click()
        if (item.isHaircut) {
            item.id = `a${uuid()}`
            item.insert = (
                <Haircut 
                    id={item.id}
                    haircut={item} 
                    right={i % 2 === 0 ? true : false} 
                    left={i % 2 === 0 ? false : true}
                    getRefToClick={ref => refToClick = ref}
                />
            )
        } else if (item.isOrder) {
            item.image = 'shopping-cart-white.png'
            item.id = `a${uuid()}`
            item.insert = (
                <OrderDetail
                    id={item.id}
                    order={item}
                    right={i % 2 === 0 ? true : false} 
                    left={i % 2 === 0 ? false : true}
                    getRefToClick={ref => refToClick = ref}
                    client={item.user}
                />
            )
        }
    })
}

// SAMPLE TEST DATA
/*
const items = [
    {
        data: [
            { haircutName: 'Sue\'s layers', haircutDate: '2020-02-29T01:12:38.298Z',
            photos: [{"width": 320, "xOffset": -3, "yOffset": -8, "clipWidth": 200, "clipHeight": 200,
            "filename": "At_Chris_Wedding.jpg"} ], onClick: () => toClientHaircut.current.click() },
            { haircutName: 'Sue\'s bob', haircutDate: '2019-12-20T01:12:38.298Z',
            photos: [{"width": 320, "xOffset": -3, "yOffset": -8, "clipWidth": 200, "clipHeight": 200,
            "filename": "At_Chris_Wedding.jpg"} ], onClick: () => toClientHaircut.current.click() },
            { haircutName: 'Sue\'s bob 1', haircutDate: '2019-11-30T01:12:38.298Z',
            photos: [{"width": 320, "xOffset": -3, "yOffset": -8, "clipWidth": 200, "clipHeight": 200,
            "filename": "At_Chris_Wedding.jpg"} ] },
            { haircutName: 'Sue pixie 1', haircutDate: '2019-08-30T01:12:38.298Z',
            photos: [{"width": 320, "xOffset": -3, "yOffset": -8, "clipWidth": 200, "clipHeight": 200,
            "filename": "At_Chris_Wedding.jpg"} ] }
        ],
        index: {
            description: 'haircutName',
            date: 'haircutDate',
            photo: {
                name: 'photos',
                isArray: true
            }
        }
    },
    {
        data: [
            { total: '$276.32', orderDate: '2020-03-20T01:12:38.298Z', image: 'shopping-cart-white.png' },
            { total: '$122.50', orderDate: '2019-12-11T01:12:38.298Z', image: 'shopping-cart-white.png' },
            { total: '$280.37', orderDate: '2019-11-20T01:12:38.298Z', image: 'shopping-cart-white.png' }
        ],
        index: {
            description: 'total',
            date: 'orderDate',
            image: 'image'
        }
    }
]
*/

export default HistoryLadder
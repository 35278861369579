import React, { useState, useEffect } from 'react'

import IconInput from '../../../../controls/IconInput'
import { isEmpty } from '../../../../../utils'
import useSignupContext from '../SignupContext'
import useSignupFormContext from '../SignupFormContext'

let lastName = ''
let nameEdited = false

const Name = () => {
    // Local state
    const [ name, setName ] = useState('')

    // Hooks
    const { addResetFunction } = useSignupContext()
    const { activeMember, onNameChange } = useSignupFormContext()

    useEffect(() => {
        addResetFunction(resetBackup)
        lastName && setName(lastName)
    }, [])

    // Active member loaded effect
    useEffect(() => {
        if (isEmpty(activeMember)) return;
        if (!nameEdited) {
            setName(activeMember.name)
            lastName = activeMember.name
        }
    }, [ activeMember ])

    const resetBackup = () => {
        lastName = ''
        nameEdited = false
    }
    
    const onChange = e => {
        onNameChange(e)
        setName(e.target.value)
        lastName = e.target.value
        nameEdited = true
    }

    return (
        <IconInput 
            type='text' 
            iconUrl='person' 
            name='name'
            value={name} 
            placeholder='Your full name'
            marginTop={30} 
            onChange={onChange}
            caption="Your first and last name."
        />
    )
}

export default Name
import React, { Fragment } from 'react'

import Portrait from '../../../controls/Portrait'
import Wallpaper from '../../../controls/Wallpaper'
import ItemContainer, { ItemWrap } from '../../../controls/ItemContainer'
import SideMenu from '../../../controls/SideMenu'
import SignupProxy from './SignupProxy'
import { usePhone, useLandscape } from '../../../../hooks/useMedia'
import useSwitchTheme from '../../../../hooks/useSwitchTheme'

const Signup = () => {
    // Local state

    // Other hooks
    useSwitchTheme()
    const isPhone = usePhone()
    const isLandscape = useLandscape()

    return (
        <Fragment>
            <Wallpaper />
            {
                isPhone 
                ?
                (
                    isLandscape 
                    ?
                    <Portrait>
                        <SignupProxy> 
                            <SideMenu />
                            <ItemWrap height={80} width={700}>
                                <ItemContainer width={350}><SignupProxy.Header /></ItemContainer>
                                <SignupProxy.Status />
                            </ItemWrap>
                            <SignupProxy.Form>
                                <SignupProxy.Form.Done />
                                <ItemWrap height={600} width={700}>
                                    <ItemContainer width={350}><SignupProxy.Form.ProfilePhoto /></ItemContainer>
                                    <ItemContainer width={350}><SignupProxy.Form.Password /></ItemContainer>
                                    <ItemContainer width={350}><SignupProxy.Form.RepeatPassword /></ItemContainer>
                                    <ItemContainer width={350}><SignupProxy.Form.Name /></ItemContainer>
                                    <ItemContainer width={350}><SignupProxy.Form.Phone /></ItemContainer>
                                    <ItemContainer width={350}><SignupProxy.Form.Email /></ItemContainer>
                                    <ItemContainer width={350}><SignupProxy.Form.Salon /></ItemContainer>
                                    <ItemContainer width={350}><SignupProxy.Form.Security /></ItemContainer>
                                    <ItemContainer width={350}><SignupProxy.Form.Birthday /></ItemContainer>
                                    <ItemContainer width={350}><SignupProxy.Form.About /></ItemContainer>
                                    <ItemContainer width={350}><SignupProxy.Form.Submit /></ItemContainer>
                                </ItemWrap>
                            </SignupProxy.Form>
                        </SignupProxy>
                    </Portrait>
                    :
                    <Portrait>
                        <SignupProxy> 
                            <SideMenu />
                            <SignupProxy.Header />
                            <SignupProxy.Status />
                            <SignupProxy.Form>
                                <SignupProxy.Form.Done />
                                <ItemContainer width={350}><SignupProxy.Form.ProfilePhoto /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Password /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.RepeatPassword /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Name /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Phone /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Email /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Salon /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Security /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Birthday /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.About /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Submit /></ItemContainer>
                            </SignupProxy.Form>
                        </SignupProxy>
                    </Portrait>
                )
                :
                (
                    isLandscape 
                    ?
                    <Portrait>
                        <SignupProxy>
                            <SignupProxy.Header />
                            <SignupProxy.Status />
                            <SignupProxy.Form>
                                <SignupProxy.Form.Done />
                                <ItemWrap height={600} width={900}>
                                    <ItemContainer width={450}><SignupProxy.Form.ProfilePhoto /></ItemContainer>
                                    <ItemContainer width={450}><SignupProxy.Form.Password /></ItemContainer>
                                    <ItemContainer width={450}><SignupProxy.Form.RepeatPassword /></ItemContainer>
                                    <ItemContainer width={450}><SignupProxy.Form.Name /></ItemContainer>
                                    <ItemContainer width={450}><SignupProxy.Form.Phone /></ItemContainer>
                                    <ItemContainer width={450}><SignupProxy.Form.Email /></ItemContainer>
                                    <ItemContainer width={450}><SignupProxy.Form.Salon /></ItemContainer>
                                    <ItemContainer width={450}><SignupProxy.Form.Security /></ItemContainer>
                                    <ItemContainer width={450}><SignupProxy.Form.Birthday /></ItemContainer>
                                    <ItemContainer width={450}><SignupProxy.Form.About /></ItemContainer>
                                    <ItemContainer width={450}><SignupProxy.Form.Submit /></ItemContainer>
                                </ItemWrap>
                            </SignupProxy.Form>
                        </SignupProxy>
                    </Portrait>
                    :
                    <Portrait>
                        <SignupProxy>
                            <SignupProxy.Header />
                            <SignupProxy.Status />
                            <SignupProxy.Form>                                
                                <SignupProxy.Form.Done />
                                <ItemContainer width={350}><SignupProxy.Form.ProfilePhoto /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Password /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.RepeatPassword /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Name /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Phone /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Email /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Salon /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Security /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Birthday /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.About /></ItemContainer>
                                <ItemContainer width={350}><SignupProxy.Form.Submit /></ItemContainer>
                            </SignupProxy.Form>
                        </SignupProxy>
                    </Portrait>
                )
            }
        </Fragment>
    )
}

export default Signup
import React, { Fragment, useState, useEffect } from 'react'

import IconInput from '../../../../controls/IconInput'
import { isEmpty } from '../../../../../utils'
import useProfileContext from '../ProfileContext'
import useProfileFormContext from '../ProfileFormContext'

let lastName = ''
let nameEdited = false

const Name = () => {
    // Local state
    const [ name, setName ] = useState('')

    // Hooks
    const { addResetFunction, activeClient, managingAddresses, managingCards } = useProfileContext()
    const { onNameChange } = useProfileFormContext()

    useEffect(() => {
        addResetFunction(resetBackup)
        lastName && setName(lastName)
    }, [])

    // Active client loaded effect
    useEffect(() => {
        if (isEmpty(activeClient)) return;
        if (!nameEdited) {
            setName(activeClient.name)
            lastName = activeClient.name
        }
    }, [ activeClient ])

    const resetBackup = () => {
        lastName = ''
        nameEdited = false
    }
    
    const onChange = e => {
        onNameChange(e)
        setName(e.target.value)
        lastName = e.target.value
        nameEdited = true
    }

    return (
        <Fragment>
        {
            (!managingAddresses && !managingCards)
            &&
            <IconInput 
                type='text' 
                iconUrl='person' 
                name='name'
                value={name} 
                placeholder='Your full name'
                marginTop={30} 
                onChange={onChange}
                caption="Your first and last name."
            />
        }
        </Fragment>
    )
}

export default Name
import React from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'

const Disk = styled.div`
    position: absolute;
    left: 50%;
    margin-left: -${props => Math.floor(parseInt(props.diameter + 8)/2)}px;
    top: 50%;
    margin-top: -${props => Math.floor(parseInt(props.diameter + 8)/2)}px;
    width: ${props => props.diameter}px;
    height: ${props => props.diameter}px;
    border: 8px solid ${props => props.color}; 
    border-top: 8px solid rgba(0, 0, 0, 0); 
    border-radius: 50%;
    animation: spin 2s linear infinite;
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`

const Spinner = ({ color, diameter }) => {
    // Pull the current theme
    const theme = useTheme()
    const { white, rgbDark } = theme
    let spinnerColor = theme.isLightBackground() ? rgbDark : white;
    if (color) spinnerColor = color

    return (
        <>
            <Disk diameter={diameter} color={spinnerColor} />
        </>
    )
}

export default Spinner
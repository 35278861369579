import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { TECHNICALS } from '../useAddHaircut'
import RadioButtonGroup from '../../../../controls/RadioButtonGroup'
import Technical, { TEXTURE, SCALP, HEIGHT, WIDTH, CURL } from '../../../../controls/Technical'
import Button from '../../../../controls/Button'
import { tokenConfig, timeoutConfig } from '../../../../../utils'
import { useTheme } from '../../../../contexts/Theme'
import useAddHaircutContext from '../AddHaircutContext'

const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${p => p.marginTop ?? 0}px;
`

const TechnicalWithEdit = styled.div`
    width: 60%; 
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: ${props => props.marginTop}px;
`

const EditTool = styled.img`
    height: 40px;
    width: 40px;
`

const diameterOptions = [
    { image: 'hair-coarse.png', label: 'coarse'},
    { image: 'hair-medium.png', label: 'medium'},
    { image: 'hair-fine.png', label: 'fine'}
]
const scalpOptions = [
    { image: 'scalp-dry.png', label: 'dry'},
    { image: 'scalp-normal-to-dry.png', label: 'medium'},
    { image: 'scalp-normal-to-oily.png', label: 'oily'}
]
const heightOptions = [
    { image: 'facial-height-short.png', label: 'min'},
    { image: 'facial-height-medium.png', label: 'medium'},
    { image: 'facial-height-long.png', label: 'max'}
]
const widthOptions = [
    { image: 'facial-width-narrow.png', label: 'min'},
    { image: 'facial-width-medium.png', label: 'medium'},
    { image: 'facial-width-wide.png', label: 'max'}
]
const curlOptions = [
    { image: 'curl-low.png', label: 'low'},
    { image: 'curl-medium.png', label: 'medium'},
    { image: 'curl-high.png', label: 'high'}
]

let lastDiameterRequest = false
let lastScalpRequest = false
let lastHeightRequest = false
let lastWidthRequest = false
let lastCurlRequest = false

let lastDiameter = null
let lastScalp = null
let lastHeight = null
let lastWidth = null
let lastCurl = null

let initialMount = true;

const Technicals = ({ marginTop, marginBottom }) => {
    // Local state
    const [ reqDiameterEdit, setReqDiameterEdit ] = useState(lastDiameterRequest)
    const [ reqScalpEdit, setReqScalpEdit ] = useState(lastScalpRequest)
    const [ reqHeightEdit, setReqHeightEdit ] = useState(lastHeightRequest)
    const [ reqWidthEdit, setReqWidthEdit ] = useState(lastWidthRequest)
    const [ reqCurlEdit, setReqCurlEdit ] = useState(lastCurlRequest)
    const [ diameter, setDiameter ] = useState(lastDiameter)
    const [ scalp, setScalp ] = useState(lastScalp)
    const [ height, setHeight ] = useState(lastHeight)
    const [ width, setWidth ] = useState(lastWidth)
    const [ curl, setCurl ] = useState(lastCurl)

    // Other hooks
    const { 
        stage, onStageChange, currentClient, addResetFunction,
        setStatus, setStatusMessage, setSpinner, setOnStatusOk,
        authToken, 
        onDiameterChange, onEmoliencyChange, onHeightChange, onWidthChange, onCurlChange,
        updatePrime, updateTune
    } = useAddHaircutContext()
    const theme = useTheme()

    // Initial mount effect
    useEffect(() => {
        if (!initialMount) return;

        // Initial mount exclusively ...
        initialMount = false
        addResetFunction(resetBackup)
        lastDiameter = currentClient.texture
        lastScalp = currentClient.scalp
        lastHeight = currentClient.height
        lastWidth = currentClient.width
        lastCurl = currentClient.curl        
    })

    const resetBackup = () => {
        initialMount = true
        lastDiameterRequest = false
        lastScalpRequest = false
        lastHeightRequest = false
        lastWidthRequest = false
        lastCurlRequest = false
        lastDiameter = null
        lastScalp = null
        lastHeight = null
        lastWidth = null
        lastCurl = null
    }

    // Destructure the theme
    const { white, dark, rgbDark } = theme
    const [ color, iconColor, techColor, selectColor ] = theme.isDarkBackground() 
        ? [ white, white, 'rgb(134, 140, 223)', 'rgb(52, 63, 197)' ] 
        : [ rgbDark, dark, 'rgb(134, 140, 223)', 'rgb(52, 63, 197)'  ];

    const TechnicalOption = ({ request, value, options, onChange, onEdit, caption, name }) => {
        return (
            <Fragment>
            {
                request  
                ?
                <RadioButtonGroup
                    panelColor='rgba(0, 0, 0, 0)' selectColor={selectColor} buttonColor={techColor} labelColor={color} 
                    percentWidth={80} buttonWidth={60} value={value} options={options} onChange={onChange} marginTop={15} 
                />
                :
                <TechnicalWithEdit marginTop={15}>
                    <Technical caption={caption} width={140} name={name} bgColor={selectColor} value={value} />
                    <EditTool src={`${process.env.PUBLIC_URL}/images/edit-tool-${iconColor}.png`} onClick={onEdit} />
                </TechnicalWithEdit>
            }
            </Fragment>
        )
    }

    const onNext = async () => {
        try {

            setOnStatusOk(() => () => {
                setStatus(false)
                onStageChange(TECHNICALS)
            })
            setStatus(true)
            setSpinner(true)

            const req = { 
                texture: diameter ?? currentClient.texture, 
                scalp: scalp ?? currentClient.scalp, 
                height: height ?? currentClient.height, 
                width: width ?? currentClient.width
            }
            
            // Get the creator prime and tune
            let rsp = await axios.post( '/api/clients/creator', req, timeoutConfig(tokenConfig(authToken)) )

            // Set the correct creator prime and tune
            updatePrime(rsp.data.prime)
            updateTune(rsp.data.tune)

            setSpinner(false)
            setStatus(false)
            onStageChange(TECHNICALS)

        } catch(err) {

            if (err.response) {
                switch (err.response.status) {
                    case 500: // Server error
                        setStatusMessage('Server error, Creator products unavailable.')
                        break;
                    default: // 400, stripe customer error, session expired
                        console.log('Error! ', err.response.data)
                        setStatusMessage(`${err.response.data.message}, Creator products unavailable.`)
                }
            } else if (err?.code === 'ECONNABORTED') { // Timeout error
                setStatusMessage(`Server took too long to respond, Creator products unavailable, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}`)
            }           

            setSpinner(false)
        }
    }

    const onReqDiameterEdit = () => { setReqDiameterEdit(true); lastDiameterRequest = true }
    const onReqScalpEdit = () => { setReqScalpEdit(true); lastScalpRequest = true }
    const onReqHeightEdit = () => { setReqHeightEdit(true); lastHeightRequest = true }
    const onReqWidthEdit = () => { setReqWidthEdit(true); lastWidthRequest = true }
    const onReqCurlEdit = () => { setReqCurlEdit(true); lastCurlRequest = true }

    const handleDiameterChange = val => { setDiameter(val); lastDiameter = val; onDiameterChange(val) }
    const handleScalpChange = val => { setScalp(val); lastScalp = val; onEmoliencyChange(val) }
    const handleHeightChange = val => { setHeight(val); lastHeight = val; onHeightChange(val) }
    const handleWidthChange = val => { setWidth(val); lastWidth = val; onWidthChange(val) }
    const handleCurlChange = val => { setCurl(val); lastCurl = val; onCurlChange(val) }

    return (
        <Container marginTop={marginTop}>
        {
            (stage === TECHNICALS) &&
            <Fragment>
                <TechnicalOption 
                    request={reqDiameterEdit} value={diameter ?? currentClient.texture}
                    options={diameterOptions} onChange={handleDiameterChange} onEdit={onReqDiameterEdit}
                    caption="Hair diameter, coarse to fine." name={TEXTURE} 
                />
                <TechnicalOption 
                    request={reqScalpEdit} value={scalp ?? currentClient.scalp}
                    options={scalpOptions} onChange={handleScalpChange} onEdit={onReqScalpEdit}
                    caption="Scalp oiliness, dry to oily." name={SCALP} 
                />
                <TechnicalOption 
                    request={reqHeightEdit} value={height ?? currentClient.height}
                    options={heightOptions} onChange={handleHeightChange} onEdit={onReqHeightEdit}
                    caption="Hair elevation, minimum to maximum." name={HEIGHT} 
                />
                <TechnicalOption 
                    request={reqWidthEdit} value={width ?? currentClient.width}
                    options={widthOptions} onChange={handleWidthChange} onEdit={onReqWidthEdit}
                    caption="Hair volume, minimum to maximum." name={WIDTH} 
                />
                <TechnicalOption 
                    request={reqCurlEdit} value={curl ?? currentClient.curl}
                    options={curlOptions} onChange={handleCurlChange} onEdit={onReqCurlEdit}
                    caption="Hair torque, low to high." name={CURL} 
                />
                <Button 
                    onClick={onNext} label='Next' width={200} height={75} 
                    marginTop={marginTop ?? 30} marginBottom={marginBottom} fontSize={24} 
                />
            </Fragment>
        }
        </Container>
    )
}

export default Technicals
import React, { Fragment } from 'react'

import Portrait from '../../../controls/Portrait'
import Wallpaper from '../../../controls/Wallpaper'
import ItemContainer, { ItemWrap } from '../../../controls/ItemContainer'
import SideMenu from '../../../controls/SideMenu'
import ClientSignupProxy from './ClientSignupProxy'
import { usePhone, useLandscape } from '../../../../hooks/useMedia'
import useSwitchTheme from '../../../../hooks/useSwitchTheme'

const ClientSignup = () => {
    // Local state

    // Other hooks
    useSwitchTheme()
    const isPhone = usePhone()
    const isLandscape = useLandscape()

    return (
        <Fragment>
            <Wallpaper />
            {
                isPhone 
                ?
                (
                    isLandscape 
                    ?
                    <Portrait>
                        <ClientSignupProxy> 
                            <SideMenu />
                            <ClientSignupProxy.Footer leftPct={50} />
                            <ItemWrap height={80} width={700}>
                                <ItemContainer width={350}><ClientSignupProxy.Header /></ItemContainer>
                                <ClientSignupProxy.Status />
                            </ItemWrap>
                            <ClientSignupProxy.Form>
                                <ClientSignupProxy.Form.Done />
                                <ItemWrap height={600} width={700}>
                                    <ItemContainer width={350}><ClientSignupProxy.Form.Name /></ItemContainer>
                                    <ItemContainer width={350}><ClientSignupProxy.Form.Phone /></ItemContainer>
                                    <ItemContainer width={350}><ClientSignupProxy.Form.Email /></ItemContainer>
                                    <ItemContainer width={350}><ClientSignupProxy.Form.Birthday /></ItemContainer>
                                    <ItemContainer width={350}><ClientSignupProxy.Form.Diameter /></ItemContainer>
                                    <ItemContainer width={350}><ClientSignupProxy.Form.Emoliency /></ItemContainer>
                                    <ItemContainer width={350}><ClientSignupProxy.Form.Height leftBorder /></ItemContainer>
                                    <ItemContainer width={350}><ClientSignupProxy.Form.Width leftBorder /></ItemContainer>
                                    <ItemContainer width={350}><ClientSignupProxy.Form.Curl leftBorder /></ItemContainer>
                                    <ItemContainer width={350}><ClientSignupProxy.Form.Submit /></ItemContainer>
                                </ItemWrap>
                            </ClientSignupProxy.Form>
                        </ClientSignupProxy>
                    </Portrait>
                    :
                    <Portrait>
                        <ClientSignupProxy> 
                            <SideMenu />
                            <ClientSignupProxy.Footer />
                            <ClientSignupProxy.Header />
                            <ClientSignupProxy.Status />
                            <ClientSignupProxy.Form>
                                <ClientSignupProxy.Form.Done />
                                <ItemContainer width={350}><ClientSignupProxy.Form.Name /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Phone /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Email /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Birthday /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Diameter /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Emoliency /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Height /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Width /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Curl /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Submit marginBottom={50} /></ItemContainer>
                            </ClientSignupProxy.Form>
                        </ClientSignupProxy>
                    </Portrait>
                )
                :
                (
                    isLandscape 
                    ?
                    <Portrait>
                        <ClientSignupProxy>
                            <ClientSignupProxy.Footer leftPct={50} />
                            <ClientSignupProxy.Header />
                            <ClientSignupProxy.Status />
                            <ClientSignupProxy.Form>
                                <ClientSignupProxy.Form.Done />
                                <ItemWrap height={600} width={1000}>
                                    <ItemContainer width={450}><ClientSignupProxy.Form.Name /></ItemContainer>
                                    <ItemContainer width={450}><ClientSignupProxy.Form.Phone /></ItemContainer>
                                    <ItemContainer width={450}><ClientSignupProxy.Form.Email /></ItemContainer>
                                    <ItemContainer width={450}><ClientSignupProxy.Form.Birthday /></ItemContainer>
                                    <ItemContainer width={450}><ClientSignupProxy.Form.Diameter /></ItemContainer>
                                    <ItemContainer width={450}><ClientSignupProxy.Form.Emoliency /></ItemContainer>
                                    <ItemContainer width={450}><ClientSignupProxy.Form.Height leftBorder /></ItemContainer>
                                    <ItemContainer width={450}><ClientSignupProxy.Form.Width leftBorder /></ItemContainer>
                                    <ItemContainer width={450}><ClientSignupProxy.Form.Curl leftBorder /></ItemContainer>
                                    <ItemContainer width={450}><ClientSignupProxy.Form.Submit /></ItemContainer>
                                </ItemWrap>
                            </ClientSignupProxy.Form>
                        </ClientSignupProxy>
                    </Portrait>
                    :
                    <Portrait>
                        <ClientSignupProxy>
                            <ClientSignupProxy.Footer />
                            <ClientSignupProxy.Header />
                            <ClientSignupProxy.Status />
                            <ClientSignupProxy.Form>                                
                                <ClientSignupProxy.Form.Done />
                                <ItemContainer width={350}><ClientSignupProxy.Form.Name /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Phone /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Email /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Birthday /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Diameter /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Emoliency /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Height /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Width /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Curl /></ItemContainer>
                                <ItemContainer width={350}><ClientSignupProxy.Form.Submit marginBottom={50} /></ItemContainer>
                            </ClientSignupProxy.Form>
                        </ClientSignupProxy>
                    </Portrait>
                )
            }
        </Fragment>
    )
}

export default ClientSignup
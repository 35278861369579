import React, { useState, useEffect } from 'react'

import RadioButtonGroup from '../../../../controls/RadioButtonGroup'
import { useTheme } from '../../../../contexts/Theme'
import useClientSignupContext from '../ClientSignupContext'
import useClientSignupFormContext from '../ClientSignupFormContext'

let lastHeight = 1

let initialMount = true

const Height = ({ leftBorder }) => {
    const [ height, setHeight ] = useState(lastHeight)

    // Hooks
    const { addResetFunction } = useClientSignupContext()
    const { onHeightChange } = useClientSignupFormContext()

    // Destructure theme
    const theme = useTheme()
    const labelText = theme.isDarkBackground() ? "yellow" : "blue";

    // Page mounted effect
    //
    useEffect(() => {
        if (!initialMount) return;

        addResetFunction(resetBackup)
        initialMount = false
    }, [])

    const resetBackup = () => {
        lastHeight = 1
        initialMount = true
    }
    
    const onChange = v => {
        onHeightChange(v)
        setHeight(v)
        lastHeight = v
    }

    return (
        <RadioButtonGroup
            caption="The client's cut elevation, from flat to high."
            title='Height' panelColor='rgba(0, 0, 0, 0)' selectColor='rgb(86, 86, 171)' 
            buttonColor='rgb(112, 146, 190)' labelColor={labelText}
            options={[
                { image: 'facial-height-short.png', label: 'min'},
                { image: 'facial-height-medium.png', label: 'medium'},
                { image: 'facial-height-long.png', label: 'max'}
            ]}
            value={height} onChange={onChange} leftBorder={leftBorder}
        />
    )
}

export default Height
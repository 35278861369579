import React, { useState } from 'react'
import styled from 'styled-components'

import HelpBubble from '../controls/HelpBubble'
import { useTheme } from '../contexts/Theme'
import { useHelp } from '../contexts/Help'

const Container = styled.div`
    width: auto;
    height: auto;
    font: 20px verdana, sans-serif;
    color: ${props => props.textColor};
    margin-top: ${props => props.marginTop}px;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0}px;
    margin-left: ${p => p.marginLeft ?? 0}px;
    margin-right: ${p => p.marginRight ?? 0}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`

const Label = styled.div`
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;
`

const SliderWithHelp = styled.div`    
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const Slider = styled.input`
    -webkit-appearance: none;
    appearance: none;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background: ${props => props.bgColor};
    outline: none;
    border-radius: 100px;
    border: 3px solid ${props => props.borderColor};

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        border: none;
        width: ${props => props.thumbWidth}px;
        height: ${props => props.thumbHeight}px;
        background: ${props => props.thumbColor};
        border-radius: 50%; 
    }
`

const Switch = ({ label, width, height, onChange, value, marginTop, marginBottom, marginLeft, marginRight, helpCaption }) => {
    // State: on or off
    const [ isOn, setIsOn ] = useState(value ? value : false)

    const theme = useTheme()
    const { white, rgbDark, rgbaWhiteBg, rgbaDarkBg } = theme
    const [ textColor, thumbColor, onColor, offColor, transparent ] = theme.isDarkBackground() ? 
        [ white, rgbDark, 'rgb(0, 162, 232)', rgbaWhiteBg, 'rgba(0, 0, 0, 0)' ] : 
        [ rgbDark, white, 'rgb(0, 162, 232)', rgbaDarkBg, 'rgba(0, 0, 0, 0)' ];

    const help = useHelp()
    const helpRequested = help.helpWasRequested()

    const onSliderChange = e => setIsOn(s => !s)

    return (
        <Container 
            textColor={textColor} marginTop={marginTop} marginBottom={marginBottom} 
            marginLeft={marginLeft} marginRight={marginRight}
        >
            <SliderWithHelp>
                { helpCaption && helpRequested && <HelpBubble caption={helpCaption} marginBottom={5} /> }
                <Slider 
                    type="range" min="0" max="1" step="1" value={value ? value : 0} 
                    bgColor={isOn ? onColor : offColor} 
                    thumbColor={thumbColor} thumbWidth={height - 2} thumbHeight={height - 2}
                    borderColor={transparent} width={width} height={height} 
                    onChange={e => { onSliderChange(e); onChange?.(e); }}
                />
            </SliderWithHelp>
            <Label>{label}</Label> 
        </Container> 
    )
}

export default Switch;
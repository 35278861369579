import { 
    SET_ACTIVE_ADMIN, 
    SET_ADMIN_PROPERTY, 
    CLEAR_ACTIVE_ADMIN, 
    BACKUP_ACTIVE_ADMIN,
    RESTORE_ACTIVE_ADMIN
} from '../actions/types'

const initialState = {}

const activeAdmin = (state = initialState, action) => {

    switch (action.type) {
        case SET_ACTIVE_ADMIN:
        {
            const admin = { ...action.payload }
            localStorage.setItem('activeAdmin', JSON.stringify(admin))
            return admin
        }
        case BACKUP_ACTIVE_ADMIN:
        {
            const admin = { ...action.payload }
            localStorage.setItem('activeAdmin', JSON.stringify(admin))
            return admin
        }
        case SET_ADMIN_PROPERTY: {
            const admin = { ...state }
            admin[action.payload.key] = action.payload.value
            localStorage.setItem('activeAdmin', JSON.stringify(admin))
            return admin
        }
        case CLEAR_ACTIVE_ADMIN:
            localStorage.removeItem('activeAdmin')
            return {}
        case RESTORE_ACTIVE_ADMIN: {
            const admin = localStorage.getItem('activeAdmin')
            return JSON.parse(admin)
        }
        default:
            return state
    }
}

export default activeAdmin
import React, { Fragment, useState, useRef } from 'react'
import styled from 'styled-components'
import { useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'

import CardInput, { StripeError } from '../../../../controls/CardInput'
import Input from '../../../../controls/Input'
import Button from '../../../../controls/Button'
import useOrderContext from '../OrderContext'
import { useTheme } from '../../../../contexts/Theme'
import { tokenConfig, timeoutConfig } from '../../../../../utils'

const PayContainer = styled.div`
    width: ${p => p.pctWidth ? p.pctWidth : '95'}%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 50px;
`

const ZipContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`

const Explanation = styled.div`
    width: 80%;
    height: auto;
    color: ${props => props.color};
    font: 20px verdana, sans-serif;
    text-align: center;
    margin-top: ${props => props.marginTop}px;
`

const Checkout = ({ pctWidth }) => {

    // Global state
    const [ billingEqualsShipping, setBillingEqualsShipping ] = useState(true)
    const [ zip, setZip ] = useState('')
    const { 
        invoiceItems, reflectedItems, subtotal, tax, updateTax, shippingAndHandling, total, updateTotal, updateDiscount, 
        setPayStatus, setSpinner, setStatusMessage, 
        activeMember, currentMember, isCurrentMember, currentClient, updateCurrentClient, name, 
        defaultPaymentMethodSet, defaultShippingAddress, haircut, authToken
    } = useOrderContext()
           
    // Other hooks 
    const discountRef = useRef(0)
    const totalRef = useRef(0)
    const taxRate = useRef(0.085)
    const applicableDiscounts = useRef([])
    const stripe = useStripe()
    const theme = useTheme()
      
    // Destructure the theme
    const { white, rgbDark } = theme
    const color = theme.isDarkBackground() ? white : rgbDark;

    // Get the right member reference
    const theMember = isCurrentMember() ? currentMember : activeMember;

    // Destructure the active member
    const { email: memberEmail } = theMember

    // Destructure the current client
    const { stripeCustomerId, _id: clientId, email, memberName, salonName, prime, tune } = currentClient

    const getDiscounts = async () => {

        const discountQueryItems = []

        // Populate the items for applicable discounts query
        reflectedItems.current.forEach(item => {
            discountQueryItems.push({
                name: item.name,
                amount: item.price * item.quantity,
                quantity: item.quantity
            })
        })

        // Get applicable discounts
        try {

            const rsp = await axios.post('/api/discounts/applicable', {
                salonName: salonName,
                items: discountQueryItems
            }, timeoutConfig(tokenConfig(authToken)) )

            // Discounts, if any, retrieved, process them ... 

            applicableDiscounts.current = rsp.data

            // Apply each discount to calculate the total discount amount
            applicableDiscounts.current.forEach(discount => {
                if (discount.amountOff) {
                    discountRef.current += discount.amountOff;
                } else if (discount.percentOff) {
                    const amount = subtotal.current * discount.percentOff / 100;
                    discountRef.current += amount;
                }
            })

            // Adjust the tax and total
            if (applicableDiscounts.current.length) {
                discountRef.current > subtotal.current && (discountRef.current = subtotal.current);
                updateDiscount(discountRef.current)
                updateTax(taxRate.current * (subtotal.current - Math.round((discountRef.current + Number.EPSILON) * 100) / 100))
                totalRef.current = subtotal.current - discountRef.current + tax.current;
                updateTotal(totalRef.current)
            }

        } catch(err) { 
            /* Pass on this error */ 
        }
    }

    // Checkout credit card ready handler
    //
    const onCheckout = async (element, disable) => {

        // Use refs here in case the state changes
        totalRef.current = total

        // Disable card submission
        disable(true)

        // Get discounts and apply to totals
        await getDiscounts(applicableDiscounts)

        let paymentIntent

        // This goes to Stripe
        const payment = {
            customer: stripeCustomerId,
            amount: Math.round(totalRef.current.toFixed(2)*100),
            confirm: false,
            offSession: false,
            description: "Customer order",
            receiptEmail: email,
            metadata: {
                member: memberName,
                client: name,
                discount: `${discountRef.current.toFixed(2)}`,
                tax: `${tax.current.toFixed(2)}`
            }
        } 

        // This is for the orders collection and client orders subcollection
        const order = {
            orderDate: new Date().toISOString(),
            clientId: clientId,
            email, // So client gets email notification
            shippingAddress: defaultShippingAddress.current,
            items: [],
            clientName: name,
            clientEmail: email,
            memberName,
            memberEmail,
            salonName,
            prime,
            tune,
            version: haircut.version,
            subtotal: subtotal.current.toFixed(2),
            discount: discountRef.current.toFixed(2),
            shipping: shippingAndHandling.current.toFixed(2),
            tax: tax.current.toFixed(2),
            total: totalRef.current.toFixed(2)
        }
        
        // Populate the order items array and payment metadata
        reflectedItems.current.forEach(item => {
            order.items.push({
                itemName: item.name,
                unitPrice: item.price,
                quantity: item.quantity,
                itemTotal: (item.price * item.quantity).toFixed(2),
                image: invoiceItems.current.filter(i => i.name === item.name).map(m => m.image)?.[0]
            })
            payment.metadata[`${item.name}_quantity`] = `${item.quantity}`
            payment.metadata[`${item.name}_amount`] = `${(item.price * item.quantity).toFixed(2)}` 
        })
        
        // This is for the current client state
        const clientOrder = {
            orderDate: order.orderDate,
            total: `$${order.total}`
        }
        
        try {

            setPayStatus(true)
            setSpinner(true)
            
            // Create the stripe payment intent
            let rsp = await axios.post( '/pay/payment-intents/', payment, timeoutConfig(tokenConfig(authToken)) )

            // Payment intent successfully created ...
            
            paymentIntent = rsp.data.id
            
            let result = await stripe.confirmCardPayment(
                rsp.data.client_secret,
                { 
                    payment_method: { 
                        card: element,
                        billing_details: { name } 
                    } 
                }
            )

            if (result.error) throw new StripeError( { data: { message: result.error.message } } )
        
            // Retrieve the payment method object created by stripe            
            rsp = await axios.get(`/pay/payment-methods/${result.paymentIntent.payment_method}`)                
            
            // Dereference the response payment method
            const { id, card } = rsp.data
            const { brand: cardName, last4: lastFour } = card

            // Set the payment method id on the order 
            order.stripePaymentMethodId = id

            // Create the order in the array DB
            rsp = await axios.post('/api/orders/', order, timeoutConfig(tokenConfig(authToken)))
            
            // Order successfully saved ...
            
            clientOrder.orderId = rsp.data[0]._id
            
            // Order successfully saved, update current client
            updateCurrentClient('orders', [ clientOrder, ...currentClient.orders ])

            // Create a disabled payment method ...

            const pm = {
                paymentType: 'card',
                cardName,
                lastFour,
                stripePaymentMethodId: id,
                isEnabled: false
            }

            const paymentMethods = [ ...currentClient.paymentMethods, pm ]
            
            // Update the client DB            
            await axios.patch(`/api/clients/${clientId}`, { paymentMethods }, timeoutConfig(tokenConfig(authToken)))

            // DB successfully updated, update the current client
            updateCurrentClient('paymentMethods', paymentMethods)

            setSpinner(false)
            setStatusMessage(
                `The purchase has been successful. ${cardName} ending in ${lastFour} will be charged. ` +
                (applicableDiscounts.current.length ? 
                `Discounts ${applicableDiscounts.current.reduce((a, d, i) => a += `${i > 0 ? ', ' : ''}${d.code}`, '')} were applied. ` : '') + 
                'The client will receive an email confirmation in the next few minutes.'
            )

        } catch (err) {

            if (err.response) { // status 400, 402 (Stripe error), 404 (not found), 500
                switch (err.response.status) {
                    case 500: // Server error
                        setStatusMessage('Server error, order not completed.')
                        console.log('Error! ', 'Server error')
                        break;
                    case 402: { // Stripe payment intent error
                        const errObj = err.response.data.error
                        setStatusMessage(`Error: ${errObj?.raw.type}, order not completed.`)
                        break;
                    }
                    default: // 400, 404 (Client not found), session expired, stripe confirm error
                        console.log('Error! ', err.response.data)
                        setStatusMessage(`${err.response.data.message}, order not completed.`)
                }
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, order not completed, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, order not completed`)
            }

            setSpinner(false)

            // Cancel the payment intent
            paymentIntent && axios.post(`/pay/payment-intents/${paymentIntent}/cancel`)
        }
    } 

    const onCardError = message => null

    const onZipReady = e => {
        setBillingEqualsShipping(false)
    }
    
    return (
        <Fragment>
        {
            (total > 0) && !defaultPaymentMethodSet &&  
            <PayContainer pctWidth={pctWidth}>
                <Explanation color={color} marginTop={10}>
                    Enter card details below to checkout and complete this order.
                </Explanation>
                <CardInput 
                    marginTop={20} onReady={onCheckout} onError={onCardError} 
                    zip={billingEqualsShipping ? defaultShippingAddress.current?.zip : zip} 
                />
                <Explanation color={color} marginTop={20}>
                    Enter the billing zip code if different:
                </Explanation>
                <ZipContainer>
                    <Input 
                        type='text' name='zip' value={zip} width={120} 
                        placeholder='Zip' marginTop={20} onChange={e => setZip(e.target.value)}
                    />
                    <Button onClick={onZipReady} width={80} height={40} marginTop={20} label='Done' />
                </ZipContainer>
            </PayContainer>
        }
        </Fragment>
    )
}

export default Checkout
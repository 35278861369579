import { useState, useEffect, useRef, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'

import useClientSignupContext from './ClientSignupContext'
import { isEmpty, tokenConfig, timeoutConfig } from '../../../../utils'

let lastClient = {
    texture: 1,
    scalp: 1,
    height: 1,
    width: 1,
    curl: 1
}

const SET_CLIENT_PROPERTY = "SET_CLIENT_PROPERTY"
const RESTORE_CLIENT = "RESTORE_CLIENT"
const clientReducer = (client, action) => {
    switch (action.type) {
        case SET_CLIENT_PROPERTY: 
            return {...client, [action.payload.key]: action.payload.value}
        case RESTORE_CLIENT: 
            client = { ...lastClient }
            return client
        default: 
            return client
    }
}

const handleInputChange = (e, key, dispatch) => {
    dispatch({
        type: SET_CLIENT_PROPERTY,
        payload: {
            key: key,
            value: e.target.value
        }
    })
}

const handleValueChange = (v, key, dispatch) => {
    dispatch({
        type: SET_CLIENT_PROPERTY,
        payload: {
            key: key,
            value: v
        }
    })
}

const restoreClient = (dispatch) => {
    dispatch({ type: RESTORE_CLIENT })
}

const useClientSignupForm = () => {
    // State
    const [ dataSubmitted, setDataSubmitted ] = useState(false)
    const [ newClient, clientDispatch ] = useReducer(clientReducer, {
        texture: 1,
        scalp: 1,
        height: 1,
        width: 1,
        curl: 1
    })

    // Reference the higher-level hook
    const {
        activeMember, salon, authToken,
        currentMember, isCurrentMember, 
        setCurrentClient, addClientToMember,
        setStatus, setOnStatusOk, setErrorMessage,
        setStatusMessage, setSpinner,
        addResetFunction
    } = useClientSignupContext()

    // Other hooks
    const errors = useRef(false)

    // Define the target business object
    const theMember = isCurrentMember() ? currentMember : activeMember;

    // Page mounted/portrait-to-landscape effect
    //
    useEffect(() => {
        addResetFunction(resetBackup)
        if (!isEmpty(lastClient)) restoreClient(clientDispatch)
    }, [])

    const resetBackup = () => {
        lastClient = {}
    }
    
    const onNameChange = e => {
        handleInputChange(e, 'name', clientDispatch)
        lastClient = { ...lastClient, name: e.target.value }
    }
    
    const onPhoneChange = e => {
        handleInputChange(e, 'phone', clientDispatch)
        lastClient = { ...lastClient, phone: e.target.value }
    }
    
    const onEmailChange = e => {
        setErrorMessage(null)
        handleInputChange(e, 'email', clientDispatch)
        lastClient = { ...lastClient, email: e.target.value }
    }

    const onBirthdayReady = dateString => {
        handleValueChange(dateString, 'birthdate', clientDispatch)
        lastClient = { ...lastClient, birthdate: dateString }
    }

    const onDiameterChange = selected => {
        handleValueChange(selected, 'texture', clientDispatch)
        lastClient = { ...lastClient, texture: selected }
    }

    const onEmoliencyChange = selected => {
        handleValueChange(selected, 'scalp', clientDispatch)
        lastClient = { ...lastClient, scalp: selected }
    }

    const onHeightChange = selected => {
        handleValueChange(selected, 'height', clientDispatch)
        lastClient = { ...lastClient, height: selected }
    }

    const onWidthChange = selected => {
        handleValueChange(selected, 'width', clientDispatch)
        lastClient = { ...lastClient, width: selected }
    }

    const onCurlChange = selected => {
        handleValueChange(selected, 'curl', clientDispatch)
        lastClient = { ...lastClient, curl: selected }
    }

    const onSubmit = async e => {
        e.preventDefault()
        
        setOnStatusOk(() => onSubmitOk)

        try {

            // Complete the new client data
            newClient.joinDate = new Date().toISOString()
            newClient.memberId = theMember._id
            newClient.userid = newClient.email
            newClient.memberName = theMember.name
            newClient.salonName = salon.name
              
            // Save the page data
            setStatus(true)
            setSpinner(true)

            // Check userid availability
            await axios.get(`/api/login/${newClient.userid}`, timeoutConfig({}))

            // Create a stripe customer object
            const { name, phone, email } = newClient
            const post = { name, phone, email, description: `${name} customer object` }
            let rsp = await axios.post('/pay/customers', post, timeoutConfig(tokenConfig(authToken)))

            newClient.stripeCustomerId = rsp.data.id
            
            // Save the new client to the DB
            rsp = await axios.post('/api/clients', newClient, timeoutConfig(tokenConfig(authToken)))

            // Make this the current client for non-admin
            isCurrentMember() || setCurrentClient(rsp.data)

            // Save the client summary ...

            const filename = 'anon-person.jpg'

            // Save client summary
            const summary = {
                name: newClient.name,
                clientId: rsp.data._id,
                samplePhoto: {
                    width: 200,
                    xOffset: 0,
                    yOffset: 0,
                    clipWidth: 200,
                    clipHeight: 200,
                    filename: filename
                }
            }

            // Save the member's client list
            await axios.patch(
                `/api/members/${theMember._id}`,
                { clients: [ summary, ...theMember.clients ]}, 
                timeoutConfig(tokenConfig(authToken))
            )

            setSpinner(false)
            setStatusMessage(`Successfully saved the client data.`)

            // Update the state for non-admin active member
            isCurrentMember() || addClientToMember(summary)

        } catch(err) {

            if (err.response) {
                switch (err.response.status) {
                    case 403: // Userid in use
                        setErrorMessage(err.response.data.error)
                        setStatusMessage(`${err.response.data.error} and is invalid, see above.`)
                        break;
                    case 500: // Server error
                        setStatusMessage('Server error, client not registered.')
                        console.log('Error! ', 'Server error')
                        break;
                    case 412: // Twilio error
                        setStatusMessage('Message send error, see webmaster for access code.')
                        break;
                    default: // 400, stripe customer error, session expired
                        console.log('Error! ', err.response.data)
                        setStatusMessage(`${err.response.data.message}, client not registered.`)
                }
            } else if (err?.code === 'ECONNABORTED') { // Timeout error
                setStatusMessage(`Server took too long to respond, client not registered, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, client not registered`)
            }           

            setSpinner(false)
            errors.current = true
        }
    }
    
    const onSubmitOk = () => {
        setStatus(false)
        if (errors.current) {
            errors.current = false
            return;
        }

        // Data successfully submitted
        setDataSubmitted(true)
    }

    return {
        onNameChange, onPhoneChange, 
        onEmailChange, 
        onBirthdayReady,
        onDiameterChange,
        onEmoliencyChange,
        onHeightChange,
        onWidthChange,
        onCurlChange,
        onSubmit, dataSubmitted
    }
}

export default useClientSignupForm
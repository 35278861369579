import React from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'

const LogoContainer = styled.div`
    position: relative;
    width: 300px;
    height: 90px;
    margin-top: 20px;
    margin-bottom: 20px;
`

// width: 200px;
// height: 63px;
const LogoImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 48px;
    background-image: url('../../../images/logo-${props => props.color}.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`

const About = styled.a`
    position: absolute;
    bottom: 0;
    right: 0;
    text-decoration: none;
    font: 18px verdana, sans-serif;
    color: ${props => props.color};
`

const Logo = ({ onClick }) => {
    const theme = useTheme()
    const { white, dark, rgbDark } = theme
    const [ color, textColor ] = theme.isDarkBackground() ? [ white, white ] : [ dark, rgbDark ];

    return (
        <LogoContainer>
            <LogoImage color={color} />
            <About onClick={onClick} href='#' color={textColor}>Our Story</About>
        </LogoContainer>
    )
}

export default Logo
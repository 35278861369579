import React, { Fragment, useState, useEffect } from 'react'

import IconInput from '../../../../controls/IconInput'
import { isEmpty } from '../../../../../utils'
import useProfileContext from '../ProfileContext'
import useProfileFormContext from '../ProfileFormContext'

let lastPhone = ''
let phoneEdited = false

const Phone = () => {
    // Local state
    const [ phone, setPhone ] = useState('')

    // Hooks
    const { addResetFunction, activeClient, managingAddresses, managingCards } = useProfileContext()
    const { onPhoneChange } = useProfileFormContext()

    // Page mounted/portrait-to-landscape effect
    //
    useEffect(() => {
        addResetFunction(resetBackup)
        lastPhone && setPhone(lastPhone)
    }, [])

    // Active member loaded effect
    useEffect(() => {
        if (isEmpty(activeClient)) return;
        if (!phoneEdited) {
            setPhone(activeClient.phone)
            lastPhone = activeClient.phone
        }
    }, [ activeClient ])

    const resetBackup = () => {
        lastPhone = ''
        phoneEdited = false
    }
    
    const onChange = e => {
        onPhoneChange(e)
        setPhone(e.target.value)
        lastPhone = e.target.value
        phoneEdited = true
    }

    return (
        <Fragment>
        {
            (!managingAddresses && !managingCards)
            &&
            <IconInput
                type='text' 
                iconUrl='phone' 
                name='phone'
                value={phone} 
                placeholder='Your cell'
                marginTop={30} 
                onChange={onChange}
                caption='Enter your mobile phone number. (required)'
                required
            />
        }
        </Fragment>
    )
}

export default Phone
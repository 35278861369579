import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import Login from './components/pages/Login'
import MemberSignup from './components/pages/member/signup/Signup'
import Clients from './components/pages/member/clients/Clients'
import Members from './components/pages/member/members/Members'
import Client from './components/pages/member/client/Client'
import AddHaircut from './components/pages/member/addHaircut/AddHaircut'
import ClientSignup from './components/pages/member/clientSignup/ClientSignup'
import Order from './components/pages/member/order/Order'
import MemberMe from './components/pages/MemberMe'
import MemberProfile from './components/pages/MemberProfile'
import ClientHome from './components/pages/client/home/Home'
import ClientProfile from './components/pages/client/profile/Profile'
import ClientOrder from './components/pages/client/order/Order'
import AdminHome from './components/pages/AdminHome'
import AdminProfile from './components/pages/AdminProfile'
import AdminSignup from './components/pages/AdminSignup'
import AddMember from './components/pages/AddMember'
import AddSalon from './components/pages/AddSalon'
import Orders from './components/pages/Orders'
import SalonOrder from './components/pages/member/salonOrder/SalonOrder'

import './styles.css'

// Map front-end routes to page components
const routes = [
    { path: '/', Component: Login },
    { path: '/member-signup', Component: MemberSignup },
    { path: '/clients', Component: Clients },
    { path: '/members', Component: Members },
    { path: '/clients/add-haircut', Component: AddHaircut },
    { path: '/clients/order', Component: Order },
    { path: '/client', Component: Client },
    { path: '/client/add-haircut', Component: AddHaircut },
    { path: '/client/order', Component: Order },
    { path: '/client-signup', Component: ClientSignup },
    { path: '/me', Component: MemberMe },
    { path: '/me/profile', Component: MemberProfile },
    { path: '/client-home', Component: ClientHome },
    { path: '/client-profile', Component: ClientProfile },
    { path: '/client-order', Component: ClientOrder },
    { path: '/admin-home', Component: AdminHome },
    { path: '/admin-profile', Component: AdminProfile },
    { path: '/admin-signup', Component: AdminSignup },
    { path: '/add-member', Component: AddMember },
    { path: '/add-salon', Component: AddSalon },
    { path: '/orders', Component: Orders },
    { path: '/salon-order', Component: SalonOrder }
]

const Main = () => {
    const location = useLocation()

    return (
        <main>
            {/*routes.map(({ path, Component }) => (
                <Route key={path} exact path={path}>
                    {({ match }) => (
                        <CSSTransition in={match != null} timeout={1000} classNames="page" unmountOnExit>
                            <Component />
                        </CSSTransition>
                    )}
                </Route>
            ))*/}
            <Routes location={location}>
            {
                routes.map(({ path, Component }) => (
                    <Route key={path} path={path} element={ <Element match={true} Component={Component} /> } />
                ))
            }
            </Routes>
        </main>
    )
}

// The transition isn't working in router v6 but will work on this when I have time
//
const Element = ({ match, Component }) => {
    return (
        <CSSTransition in={match} timeout={1000} classNames="page" unmountOnExit>
            <Component />
        </CSSTransition>
    )
}

export const useIsClientsPage = () => [
    "/member-signup",
    "/clients",
    "/clients/add-haircut",
    "/clients/order",
    "/client",
    "/client/add-haircut",
    "/client/order",
    "/client-signup",
    "/client-order"
].includes(useLocation().pathname)

export const useIsMePage = () => [
    "/me",
    "/me/profile"
].includes(useLocation().pathname)

export default Main

import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import Incrementer from './Incrementer'

const InvoiceGrid = styled.div`
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 30% 20% 10% 20% 20%;
    grid-template-rows: 50px auto 35px auto 35px 35px 35px;
    grid-auto-flow: row;
    justify-content: center;
    align-content: start;
    margin-bottom: ${props => props.marginBottom}px;
`

const InvoiceHeader = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.headerBg};
    font: 24px verdana, sans-serif;
    color: ${props => props.headerText};
`

const InvoiceHeaderQty = styled.div`
    grid-column: 3 / span 2;
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.headerBg};
    font: 24px verdana, sans-serif;
    color: ${props => props.headerText};
`

const ItemProperty = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font: 16px verdana, sans-serif;
    color: ${props => props.color};
    border-width: 0 2px 2px 0;
    border-style: groove;
    border-color: ${props => props.color};
`

const ItemName = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ItemPrice = styled.div`
    width: 90%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const ItemQuantities = styled.div`
    grid-column: 3 / span 2;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-width: 0 2px 2px 0;
    border-style: groove;
    border-color: ${props => props.color};
`

const ItemQuantity = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Totals = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: ${props => props.color};
    font: 16px verdana, sans-serif;
    border-width: 0 0 2px 0;
    border-style: groove;
    border-color: ${props => props.color};
`

const Sum = styled.div`
    width: 100%;
    height: 40px;
    grid-column: 4;
    justify-self: right;
    font: 18px verdana, sans-serif;
    border-width: 0 2px 0 0;
    border-style: groove;
    color: ${props => props.color};
    border-color: ${props => props.color};
    text-align: right;
    padding-right: 5px;
    padding-top: 4px;

    &.subtotal { grid-row: 3; }
    &.discount { grid-row: 4; }
    &.tax { grid-row: 5; }
    &.shippingHandling { grid-row: 6; }
    &.total { 
        grid-row: 7;
        border-width: 5px 0 0 0; 
    }
    &.amount {
        grid-column: 5;
        border: none;
    }
    &.subbed {
        grid-column: 5;
        padding-right: 0;
        border: none;
    }
    &.grandtotal {
        grid-row: 7;
        grid-column: 5;
        border-width: 5px 0 0 0;
    }
` 

let initialMount = true

// items array: { name, price, quantity (default is 1), wasTapped }
// onChange: invoiceItems => { ... } where invoiceItems are [ { name, price, quantity }, ... ]
//
const Invoice = ({ items, discount, onChange, taxRate, headerBgColor, headerTextColor, marginBottom }) => {
    // State
    const [ itemSummary, setItemSummary ] = useState({
        itemNames: items.filter(item => item.wasTapped).map(item => item.name),
        itemPrices: items.filter(item => item.wasTapped).map(item => item.price),
        itemQuantities: items.filter(item => item.wasTapped).map(item => ({ 
            quantity: item.quantity !== undefined ? item.quantity : 1, id: uuid() 
        })),
        itemTotals: items.filter(item => item.wasTapped).map(item => (item.quantity !== undefined ? item.quantity : 1) * item.price),
        itemHandlers: items.filter(item => item.wasTapped).map((item, i) => val => onChangeItem(val, i))
    })
    
    // Destructure the item summary
    const { itemNames, itemPrices, itemTotals, itemHandlers, itemQuantities } = itemSummary
    
    // Other hooks
    const subtotal = useRef(itemTotals.reduce((acc, t) => acc + t))
    const tax = useRef(subtotal.current * taxRate)
    const bottleCount = useRef(0)
    const shippingAndHandling = useRef(10)
    const [ total, setTotal ] = useState(subtotal.current + tax.current + shippingAndHandling.current)

    // Initial mount effect
    //
    useEffect(() => {
        if (!initialMount) return;

        initialMount = false

        // Reflect startup change to caller
        if (onChange) {
            const invoiceItems = []
            itemNames.forEach((name, j) => {
                invoiceItems.push({
                    name: name,
                    price: itemPrices[j],
                    quantity: itemQuantities[j].quantity,
                })
            })
            onChange(invoiceItems)
        }

    }, [])
    
    // items change effect
    //
    useEffect(() => {
             
        // Update or init item quantities
        let names = [], prices = [], quantities = [], totals = [], handlers = [];
        items.filter(item => item.wasTapped).forEach((item, i) => {
            const n = itemNames.indexOf(item.name)
            names.push( item.name ) 
            prices.push( item.price )
            quantities.push( n >= 0 ? itemQuantities[n] : { quantity: 1, id: uuid() } )
            totals.push( n >= 0 ? itemTotals[n] : item.price )
            const h = val => onChangeItem(val, i)           
            handlers.push( h )
        })
        const newSummary = {
            itemNames: names,
            itemPrices: prices,
            itemQuantities: quantities,
            itemTotals: totals,
            itemHandlers: handlers
        }
        setItemSummary(newSummary)

        // Reflect change to caller
        if (onChange) {
            const invoiceItems = []
            names.forEach((name, j) => {
                invoiceItems.push({
                    name: name,
                    price: prices[j],
                    quantity: quantities[j].quantity,
                })
            })
            onChange(invoiceItems)
        }

        // Tally bottle count
        bottleCount.current = quantities.reduce((a, q) => a += q.quantity, 0)
        
        subtotal.current = newSummary.itemTotals.reduce((a, t) => a + t, 0)
        tax.current = subtotal.current * taxRate
        shippingAndHandling.current = bottleCount.current < 3 ? 10 : 0;
        setTotal( subtotal.current + tax.current + shippingAndHandling.current )
        
    }, [items])

    // Discount change effect
    //
    useEffect(() => {
        // Ignore zero discount
        if (!discount) return;

        // There is a nonzero discount ... 

        // Adjust amounts
        tax.current = (subtotal.current - discount) * taxRate
        setTotal(subtotal.current - discount + tax.current + shippingAndHandling.current)

    }, [ discount ])

    // Change handler for increment/decrement
    //
    const onChangeItem = (val, i) => {
        setItemSummary(summary => {
            const { itemNames, itemPrices, itemQuantities, itemTotals } = summary
            
            // Tally the bottle count
            bottleCount.current = itemQuantities.reduce((acc, item, j) => acc += (j === i ? val : item.quantity), 0)
            
            subtotal.current -= itemTotals[i]
            subtotal.current += itemPrices[i] * val
            tax.current = subtotal.current * taxRate
            shippingAndHandling.current = bottleCount.current < 3 ? 10 : 0;
            setTotal(subtotal.current + tax.current + shippingAndHandling.current)

            // Update caller
            if (onChange) {
                const invoiceItems = []
                itemNames.forEach((name, j) => {
                    invoiceItems.push({
                        name: name,
                        price: itemPrices[j],
                        quantity: i === j ? val : itemQuantities[j].quantity,
                    })
                })
                onChange(invoiceItems)
            }

            return {
                ...summary,
                itemTotals: itemTotals.map((t, j) => i === j ? itemPrices[j] * val : t),
                itemQuantities: itemQuantities.map((q, j) => i === j ? { quantity: val, id: q.id } : q)
            }
        })      
    }
    
    return (
        <InvoiceGrid marginBottom={marginBottom}>
            {/* Header */}
            <InvoiceHeader headerBg={headerBgColor} headerText={headerTextColor}>Items</InvoiceHeader>
            <InvoiceHeader headerBg={headerBgColor} headerText={headerTextColor}>UP</InvoiceHeader>
            <InvoiceHeaderQty headerBg={headerBgColor} headerText={headerTextColor}>Qty</InvoiceHeaderQty>
            <InvoiceHeader headerBg={headerBgColor} headerText={headerTextColor}>Amt</InvoiceHeader>
            {/* Item name column */}
            <ItemProperty color={headerBgColor}>
            {
                itemNames.map((name, i) => <ItemName key={i}>{name}</ItemName>)
            }
            </ItemProperty>
            {/* Price column */}
            <ItemProperty color={headerBgColor}>
            {
                itemPrices.map((p, i) => <ItemPrice key={i}>{p.toFixed(2)}</ItemPrice>)
            }
            </ItemProperty>
            {/* Incrementers column */ }
            <ItemQuantities color={headerBgColor}>
            {
                itemQuantities.map((q, i) =>
                    <ItemQuantity key={q.id}><Incrementer val={q.quantity} onChange={itemHandlers[i]} /></ItemQuantity>)
            }
            </ItemQuantities>
            <Totals color={headerBgColor}>
            {
                itemTotals.map((t, i) => <ItemPrice key={i}>{t.toFixed(2)}</ItemPrice>)
            }
            </Totals>
            <Sum className='subtotal' color={headerBgColor}>ST:</Sum>
            {
                discount
                &&
                <Sum className='discount' color={headerBgColor}>DC:</Sum>
            }
            <Sum className='tax' color={headerBgColor}>Tax:</Sum>
            <Sum className='shippingHandling' color={headerBgColor}>S&H:</Sum>
            <Sum className='total' color={headerBgColor}>Total:</Sum>
            <Sum className='subtotal amount' color={headerBgColor}>{subtotal.current.toFixed(2)}</Sum>
            {
                discount
                &&
                <Sum className='discount subbed' color={headerBgColor}>{`(${discount.toFixed(2)})`}</Sum>
            }
            <Sum className='tax amount' color={headerBgColor}>{tax.current.toFixed(2)}</Sum>
            <Sum className='shippingHandling amount' color={headerBgColor}>{shippingAndHandling.current.toFixed(2)}</Sum>
            <Sum className='grandtotal' color={headerBgColor}>{total.toFixed(2)}</Sum>
        </InvoiceGrid>
    )
}

export default Invoice;
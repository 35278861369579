import React from 'react'
import { CSSTransition } from 'react-transition-group'

import Ribbon, { TUNE1_16, TUNE2_16, TUNE3_16 } from '../../../../controls/Ribbon'
import useSalonOrderContext from '../SalonOrderContext'

const items = [
    TUNE1_16,
    TUNE2_16,
    TUNE3_16
]

const TuneRibbon = ({ marginTop }) => {
    const { menuSelection, onTuneSelected } = useSalonOrderContext()

    return (
        
        <CSSTransition 
            in={menuSelection.tuneSelected} 
            classNames="ribbon" 
            timeout={1000}
            unmountOnExit
        >
            <Ribbon items={items} onChange={onTuneSelected} marginTop={ marginTop ? marginTop : 10 } />
        </CSSTransition>
    )
}

export default TuneRibbon
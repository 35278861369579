import React, { useState, Fragment, useEffect } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import { useTheme } from '../contexts/Theme'

const Item = styled.div`
    position: relative;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    background-color: ${props => props.bgColor};
    background-image: url('${process.env.PUBLIC_URL}/images/themes-${props => props.color}.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`

const ThemeMenu = styled.div`
    position: absolute;
    right: ${props => props.right}px;
    top: ${props => props.top}px;
    width: ${props => props.width}px;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background-color: white;
    z-index: 5;
    
    &.themes-enter {
        opacity: 0;
        transform: scale(0.3) translateY(-100px) translateX(100px);
    }
    &.themes-enter-active {
        opacity: 1;
        transform: translateX(0) translateY(0);
        transition: opacity 1000ms, transform 1000ms;
    }
    &.themes-exit {
        opacity: 1;
    }
    &.themes-exit-active {
        opacity: 0;
        transform: scale(0.3) translateY(-100px) translateX(100px);
        transition: opacity 1000ms, transform 1000ms;
    }
`

const Theme = styled.img`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    margin: 5px;
`

const ThemeMenuItem = ({ onRefresh, size, color, bgColor, hideMe }) => {
    const [ themesVisible, setThemesVisible ] = useState(false)
    const [ ovalsVisible, setOvalsVisible ] = useState(false)

    const theme = useTheme()

    useEffect(() => {
        hideMe(() => setThemesVisible(false))
    }, [])

    const handleItemClick = () => setThemesVisible(!themesVisible)

    const handleDarkOvalTouch = e => {
        theme.setDarkOval()
        onRefresh() // To refresh the side menu
        theme.refreshPage && theme.refreshPage() // To refresh the current page
    }
    const handleLightOvalTouch = e => {
        theme.setLightOval()
        onRefresh()
        theme.refreshPage && theme.refreshPage()
    }
    const handleDarkUnderlineTouch = e => {
        theme.setDarkUnderline()
        onRefresh()
        theme.refreshPage && theme.refreshPage()
    }
    const handleLightUnderlineTouch = e => {
        theme.setLightUnderline()
        onRefresh()
        theme.refreshPage && theme.refreshPage()
    }

    return (
        <Fragment>
            <Item size={size} color={color} bgColor={bgColor} onClick={handleItemClick}>
                <CSSTransition
                    in={themesVisible} 
                    classNames="themes" 
                    timeout={1000}
                    unmountOnExit
                >
                    <ThemeMenu right={Math.floor(size/2)} top={Math.floor(size/2)} width={140}>
                        {
                            ovalsVisible &&
                            <Theme 
                                size={60} src={`${process.env.PUBLIC_URL}/images/dark-oval.jpg`} 
                                onTouchStart={handleDarkOvalTouch} onClick={handleDarkOvalTouch}
                            />
                        }
                        <Theme 
                            size={60} src={`${process.env.PUBLIC_URL}/images/light-underline.jpg`} 
                            onTouchStart={handleLightUnderlineTouch} onClick={handleLightUnderlineTouch}
                        />
                        {
                            ovalsVisible &&
                            <Theme 
                                size={60} src={`${process.env.PUBLIC_URL}/images/light-oval.jpg`} 
                                onTouchStart={handleLightOvalTouch} onClick={handleLightOvalTouch}
                            />
                        }
                        <Theme 
                            size={60} src={`${process.env.PUBLIC_URL}/images/dark-underline.jpg`} 
                            onTouchStart={handleDarkUnderlineTouch} onClick={handleDarkUnderlineTouch}
                        />
                    </ThemeMenu>
                </CSSTransition>
            </Item>
        </Fragment>
    )
}

export default ThemeMenuItem
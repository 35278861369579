import React, { Fragment } from 'react'
import styled from 'styled-components'

import Technical, { TEXTURE, CURL } from '../../../../controls/Technical'
import Separator from '../../../../controls/Separator'
import { useTheme } from '../../../../contexts/Theme'
import useHomeContext from '../HomeContext'

const Container = styled.div`
    width: 90%;
    height: auto;
    margin-top: ${props => props.marginTop}px;
    font: 20px verdana, sans-serif;
    color: ${props => props.textColor};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const Label = styled.div`
    width: 30%;
    height: 60px;
`

const Technicals = styled.div`
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 10px;
`

const Features = () => {
    // Hooks
    const { activeClient } = useHomeContext()
    const theme = useTheme()

    // Theme
    const { white, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const [ textColor, borderColor, techColor ] = theme.isDarkBackground() ? 
        [ white, rgbaWhiteBg, 'rgb(197, 99, 172)' ] : 
        [ rgbDark, rgbaDarkBg, 'rgb(52, 63, 197)' ];

    // Destructure the client
    const { texture, curl } = activeClient

    return (
        <Fragment>
            <Container textColor={textColor} marginTop={20}>
                <Label>Your hair features</Label>
                <Technicals borderColor={borderColor}>
                    <Technical width={120} name={TEXTURE} value={texture} bgColor={techColor} flipLabel />
                    <Technical width={120} name={CURL} value={curl} bgColor={techColor} marginTop={15} flipLabel />
                </Technicals>
            </Container>
            <Separator color={borderColor} marginTop={20} />
        </Fragment>
    )
}

export default Features
import React, { useRef, Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useTheme } from '../../../../contexts/Theme'
import useClientsContext from '../ClientsContext'

const OrderContainer = styled.div`
    width: ${p => p.pctWidth ? p.pctWidth : '90'}%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
`

const LinkTo = styled.img`
    width: 80px;
    height: auto;
    &.disabled { opacity: 0.5; }
`

const Cart = ({ pctWidth }) => {
    const { isCurrentMember } = useClientsContext()
    
    const toOrder = useRef(null)
    const theme = useTheme()

    // Destructure theme
    const { white, dark } = theme
    const iconColor = theme.isDarkBackground() ? white : dark;

    return (
        <Fragment>
            {
                !isCurrentMember() &&
                <Fragment>
                    <Link ref={toOrder} to="/salon-order" style={{display: 'none'}} />
                    <OrderContainer pctWidth={pctWidth}>
                        <LinkTo 
                            onClick={() => toOrder.current.click()} 
                            src={`${process.env.PUBLIC_URL}/images/shopping-cart-${iconColor}.png`} 
                            className={''}  
                        />
                    </OrderContainer>
                </Fragment>
            }
        </Fragment>
    )
}

export default Cart
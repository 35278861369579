import React, { useRef, useState, useEffect, useReducer, Fragment } from 'react'
import styled from 'styled-components'

import { ROUND } from './Photo'
import { useTheme } from '../contexts/Theme'

const Container = styled.div`
    position: relative;
    width: 320px;
    height: 200px;
    overflow: hidden;
    margin-top: ${props => props.marginTop}px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
`

const SelectedPhoto = styled.img.attrs(props => ({
    style: {
        top: props.top,
        left: props.left,
        width: props.width
    }
}))`
    position: absolute;
    touch-action: none;
    user-select: none;
    height: auto;
    opacity: 0.8;
`

const UploadIcon = styled.div`
    position: absolute;
    top: 0;
    left: 60px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-image: url('../../../images/upload-photo.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`

const Ok = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background-image: url('../../../images/ok-sign.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 2;
`

const Pan = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    touch-action: none;
    user-select: none;
    background-image: url('../../../images/pan.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 2;
`

const Zoom = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 40px;
    touch-action: none;
    user-select: none;
    background-image: url('../../../images/zoom.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 2;
`

const EditTool = styled.div`
    position: absolute;
    top: 0px;
    right: 30px;
    width: 60px;
    height: 60px;
    background-image: url('../../../images/edit-tool-${props => props.color}.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 2;
`

const Guide = styled.div`
    position: absolute;
    top: 0;
    left: 60px;
    width: 196px;
    height: 196px;
    border-radius: ${props => props.borderRadius};
    border: 2px solid red;
    z-index: -1;
`

const Clipper = styled.div`
    position: absolute;
    top: 0;
    left: 60px;
    width: 200px;
    height: 200px;
    border-radius: ${props => props.borderRadius};
    overflow: hidden;
`

const EditedPhoto = styled.img`
    position: absolute;
    top: ${props => props.top};
    left: ${props => props.left};
    width: ${props => props.width};
    height: auto;
`

const Next = styled.img`
    position: absolute;
    right: 0;
    top: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    z-index: 2;
`

const Previous = styled.img`
    position: absolute;
    left: 0;
    top: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    z-index: 2;
`

const YES_PHOTO = "YES_PHOTO";
const NO_PHOTO = "NO_PHOTO";
const PHOTOS_SELECTED = "PHOTOS_SELECTED";
const PHOTO_EDITED = "PHOTO_EDITED";

let pan, zoom;

const _URL = window.URL || window.webkitURL
let photoFiles = null
let photoImages = []
let editedPhotos = []
let photoIndex = 0

let initialLoad = true
let remount = false
let moving = false
let lastViewState = {}

// Reducer actions
//
const SET_POSITION = "SET_POSITION"
const SET_WIDTH = "SET_WIDTH"
const SET_PHOTOS_SELECTED = "SET_PHOTOS_SELECTED"
const SET_EDIT_ACCEPTED = "SET_EDIT_ACCEPTED"
const SET_PREVIOUS = "SET_PREVIOUS"
const SET_NEXT = "SET_NEXT"
const SAVE_VIEW = "SAVE_VIEW"
const RESET_VIEW = "RESET_VIEW"

const viewReducer = (viewState, action) => {
    switch (action.type) {
        case SET_POSITION:
            lastViewState = { ...viewState, top: action.payload.top, left: action.payload.left }
            return { ...viewState, top: action.payload.top, left: action.payload.left }
        case SET_WIDTH:
            lastViewState = { ...viewState, width: action.payload.width }
            return { ...viewState, width: action.payload.width }
        case SET_PHOTOS_SELECTED:
            lastViewState = { width: '320px', top: '0px', left: '0px', view: PHOTOS_SELECTED }
            return { width: '320px', top: '0px', left: '0px', view: PHOTOS_SELECTED }
        case SET_EDIT_ACCEPTED:
            lastViewState = { ...viewState, view: PHOTO_EDITED }
            return { ...viewState, view: PHOTO_EDITED }
        case SET_PREVIOUS:
            lastViewState = { 
                top: action.payload.top, 
                left: action.payload.left,
                width: action.payload.width, 
                view: PHOTO_EDITED 
            }
            return { 
                top: action.payload.top, 
                left: action.payload.left,
                width: action.payload.width,
                view: PHOTO_EDITED
            }
        case SET_NEXT:
            lastViewState = { 
                top: action.payload.top, 
                left: action.payload.left,
                width: action.payload.width, 
                view: PHOTO_EDITED 
            }
            return { 
                top: action.payload.top, 
                left: action.payload.left,
                width: action.payload.width,
                view: PHOTO_EDITED
            }
        case SAVE_VIEW:
            lastViewState = { 
                top: action.payload.top, 
                left: action.payload.left,
                width: action.payload.width,
                view: action.payload.view
            }
            return viewState
        case RESET_VIEW:
            return { ...lastViewState }
        default:
            return viewState
    }
}

const PhotoUpload = ({ value, setPhotoFiles, onPhotoReady, addResetFunction, marginTop, shape, multiple }) => {
    // State
    const [ viewState, dispatchView ] = useReducer(viewReducer, {
       view: value ? YES_PHOTO : NO_PHOTO,
       top: value ? value.xOffset + 'px' : '0px',
       left: value ? value.xOffset + 'px' : '0px',
       width: value ? value.width + 'px' : '320px'
    })

    // Destructure the view state
    const { view, top, left, width } = viewState
    const [ panWidth, setPanWidth ] = useState(width)
    const [ zoomWidth, setZoomWidth ] = useState(width)

    // Mount or remount effect
    useEffect(() => {
        if (initialLoad) { // Save backup initial view
            dispatchView({
                type: SAVE_VIEW,
                payload: {
                    view: value ? YES_PHOTO : NO_PHOTO,
                    top: value ? value.xOffset + 'px' : '0px',
                    left: value ? value.xOffset + 'px' : '0px',
                    width: value ? value.width + 'px' : '320px'
                }
            })

            // Add our reset function
            addResetFunction?.(resetBackup)

            initialLoad = false
        } else { // Remount, as in portrait-to-landscape shift, restore backup state
            remount = true
            dispatchView({ type: RESET_VIEW })
            
            if (lastViewState.view === PHOTOS_SELECTED || lastViewState.view === PHOTO_EDITED) {
                // Create image array
                const images = []
                for (let i = 0; i < photoFiles.length; i++) {
                    const image = new Image()
                    image.onload = function() {
                        thePhoto.current?.setAttribute('src', this.src)
                        editedPhoto.current?.setAttribute('src', this.src)
                    }
                    images.push(image)
                }
                photoImages = [ ...images ]
            } 
        }
    }, [])

    // Use edit or view change effect to trigger image load
    useEffect(() => {
        if (view === PHOTOS_SELECTED || (remount && view === PHOTO_EDITED)) {
            photoImages[photoIndex].src = _URL.createObjectURL(photoFiles[photoIndex])
            setPanWidth(width)
            setZoomWidth(width)
        }
    }, [view, width])

    const resetBackup = () => {
        photoFiles = null
        photoImages = []
        editedPhotos = []
        photoIndex = 0
        initialLoad = true
        remount = false
        moving = false
        lastViewState = {}
    }

    // Theme, appearance
    let borderRadius
    if (shape) {
        switch (shape) {
            case ROUND:
                borderRadius = '50%'
                break
            default:
                borderRadius = '0'
        }
    } else {
        borderRadius = '50%'
    }
    const theme = useTheme()
    const { white, dark } = theme
    const color = theme.isDarkBackground() ? white : dark;

    // Refs
    const filePicker = useRef(null)
    const thePhoto = useRef(null)
    const editedPhoto = useRef(null)
    const origX = useRef(null)
    const origY = useRef(null)

    // This gets called once, when photos are first selected
    //
    const handlePhotosSelected = e => {
        // Photos were taken or selected, save all the photo file data
        photoFiles = e.target.files

        // Create image array from the photo files
        const images = []
        for (let i = 0; i < photoFiles.length; i++) {
            const image = new Image()
            image.onload = function() {
                thePhoto.current?.setAttribute('src', this.src)
            }
            images.push(image)
        }
        photoImages = [ ...images ]
        editedPhotos = []
        photoIndex = 0

        // Update the client
        setPhotoFiles(photoFiles)

        // Dispatch the "photos selected" view
        dispatchView({ type: SET_PHOTOS_SELECTED })
    }

    const handlePrevious = () => {
        // Reset to previously edited photo
        --photoIndex
        dispatchView({
            type: SET_PREVIOUS,
            payload: {
                top: editedPhotos[photoIndex].top,
                left: editedPhotos[photoIndex].left,
                width: editedPhotos[photoIndex].width
            }
        })
    }

    const handleNext = () => {
        if (++photoIndex > editedPhotos.length - 1) { // Edit this one
            dispatchView({ type: SET_PHOTOS_SELECTED })
        } else { // View previously edited photo
            dispatchView({
                type: SET_NEXT,
                payload: {
                    top: editedPhotos[photoIndex].top,
                    left: editedPhotos[photoIndex].left,
                    width: editedPhotos[photoIndex].width
                }
            })
        }
    }

    const handleImageTouch = () => filePicker.current.click()

    const handleEditToolTouch = () => {
        dispatchView({ type: SET_PHOTOS_SELECTED })
    }

    const handleTouchStart = e => {
        e.preventDefault()

        origX.current = Math.floor(e.touches[0].clientX) - parseInt(left.replace('px', ''))
        origY.current = Math.floor(e.touches[0].clientY) - parseInt(top.replace('px', ''))
    }

    const handleTouchMove = e => {
        e.preventDefault()

        dispatchView({
            type: SET_POSITION,
            payload: {
                top: (Math.floor(e.touches[0].clientY) - origY.current) + 'px',
                left: (Math.floor(e.touches[0].clientX) - origX.current) + 'px'
            }
        })
    }

    const handleMouseDown = e => {
        e.preventDefault()

        moving = true

        origX.current = Math.floor(e.clientX) - parseInt(left.replace('px', ''))
        origY.current = Math.floor(e.clientY) - parseInt(top.replace('px', ''))
    }

    const handleMouseUp = e => {
        e.preventDefault()

        moving = false
    }

    const handleMouseMove = e => {
        e.preventDefault()

        if (!moving) return;

        dispatchView({
            type: SET_POSITION,
            payload: {
                top: (Math.floor(e.clientY) - origY.current) + 'px',
                left: (Math.floor(e.clientX) - origX.current) + 'px'
            }
        })
    }

    const handleOk = () => {
        // Update the client
        onPhotoReady({
            width: parseInt(width.replace('px', '')),
            xOffset: parseInt(left.replace('px', '')) - 60,
            yOffset: parseInt(top.replace('px', '')),
            clipWidth: 200,
            clipHeight: 200
        }, photoIndex)

        // Save edited attributes locally
        const editedPhoto = {
            top: top,
            left: left,
            width: width
        }
        editedPhotos.length - 1 < photoIndex ? editedPhotos.push(editedPhoto) : editedPhotos[photoIndex] = editedPhoto;

        dispatchView({ type: SET_EDIT_ACCEPTED })
    }

    const handlePanTouch = e => {
        e.preventDefault()

        pan = setInterval(() => {
            setPanWidth(w => {
                const nW = parseInt(w.replace('px', ''))
                if (nW < 150) clearInterval(pan)
                dispatchView({ type: SET_WIDTH, payload: { width: (nW - 5) + 'px' }})
                return (nW - 5) + 'px';
            })
        }, 100)
    }

    const handlePanRelease = e => {
        e.preventDefault()

        clearInterval(pan)
        setZoomWidth(panWidth)
    }

    const handleZoomTouch = e => {
        e.preventDefault()

        zoom = setInterval(() => {
            setZoomWidth(w => {
                const nW = parseInt(w.replace('px', ''))
                if (nW > 800) clearInterval(zoom)
                dispatchView({ type: SET_WIDTH, payload: { width: (nW + 5) + 'px' }})
                return (nW + 5) + 'px';
            })
        }, 100) 
    }

    const handleZoomRelease = e => {
        e.preventDefault()

        clearInterval(zoom)
        setPanWidth(zoomWidth)
    }

    return (
        <Container marginTop={marginTop}>
        {
            // Existing photo shown
            view === YES_PHOTO &&
            <Fragment>
                <input 
                    type='file' 
                    style={{ display: 'none' }} 
                    ref={filePicker} 
                    onChange={handlePhotosSelected} 
                    accept='image/*'
                />
                <Clipper borderRadius={borderRadius}>
                    <EditedPhoto 
                        top={top}
                        left={left}
                        width={width}
                        src={`${process.env.PUBLIC_URL}/photos/${value.filename}`}
                    />
                </Clipper>
                <EditTool color={color} onClick={() => filePicker.current.click()} />
            </Fragment>
        }
        {
            // No photo(s) yet, upload icon shown
            view === NO_PHOTO &&
            <Fragment>
                <input 
                    type='file' 
                    style={{ display: 'none' }} 
                    ref={filePicker} 
                    onChange={handlePhotosSelected} 
                    accept='image/*'
                    multiple={multiple ? true : false} 
                />
                <UploadIcon onClick={handleImageTouch} />
            </Fragment>
        }
        {
            // Photo presented for editing
            view === PHOTOS_SELECTED && 
            <Fragment>
                <SelectedPhoto 
                    ref={thePhoto}
                    top={top} 
                    left={left}
                    width={width}
                    onTouchStart={handleTouchStart}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchMove={handleTouchMove}
                    onMouseMove={handleMouseMove}
                />
                <Guide borderRadius={borderRadius} />
                <Ok onTouchStart={handleOk} onMouseDown={handleOk} />
                <Pan 
                    onTouchStart={handlePanTouch} onMouseDown={handlePanTouch} 
                    onTouchEnd={handlePanRelease} onMouseUp={handlePanRelease} 
                />
                <Zoom 
                    onTouchStart={handleZoomTouch} onMouseDown={handleZoomTouch} 
                    onTouchEnd={handleZoomRelease} onMouseUp={handleZoomRelease} 
                />
            </Fragment>
        }
        {
            // Editing complete for current photo
            view === PHOTO_EDITED &&
            <Fragment>
                { 
                    photoIndex > 0 
                    && 
                    <Previous
                        onClick={handlePrevious} 
                        src={`${process.env.PUBLIC_URL}/images/previous-${color}.png`} 
                    /> 
                }
                <Clipper borderRadius={borderRadius}>
                    <EditedPhoto  
                        ref={editedPhoto}
                        top={top}
                        left={(parseInt(left.replace('px', '')) - 60) + 'px'}
                        width={width}
                        src={photoImages[photoIndex].src}
                    />
                </Clipper>
                <EditTool color={color} onClick={handleEditToolTouch} />
                { 
                    (photoIndex < photoFiles.length - 1) 
                    &&
                    <Next 
                        onClick={handleNext}
                        src={`${process.env.PUBLIC_URL}/images/next-${color}.png`} 
                    /> 
                }
            </Fragment>
        }
        </Container>
    ) 
}

export default PhotoUpload
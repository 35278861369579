import React, { Fragment } from 'react'
import styled from 'styled-components'

import Portrait from '../../../controls/Portrait'
import Wallpaper from '../../../controls/Wallpaper'
import FlexTable from '../../../controls/FlexTable'
import PowerStrip from '../../../controls/PowerStrip'
import ItemContainer, { ItemWrap } from '../../../controls/ItemContainer'
import SideMenu from '../../../controls/SideMenu'
import ProfileProxy from './ProfileProxy'
import { usePhone, useLandscape } from '../../../../hooks/useMedia'
import useSwitchTheme from '../../../../hooks/useSwitchTheme'

const Profile = () => {
    // Local state

    // Other hooks
    useSwitchTheme()
    const isPhone = usePhone()
    const isLandscape = useLandscape()

    return (
        <Fragment>
            <Wallpaper />
            {
                isPhone 
                ?
                (
                    isLandscape 
                    ?
                    <Portrait>
                        <ProfileProxy> 
                            <SideMenu />
                            <ItemWrap height={80} width={700}>
                                <ItemContainer width={350}><ProfileProxy.Header /></ItemContainer>
                                <ProfileProxy.Status />
                            </ItemWrap>
                            <FlexTable width="700">
                                <ProfileProxy.Greeting />
                                <ProfileProxy.AddressManager />
                                <ProfileProxy.CardManager />
                            </FlexTable>
                            <ProfileProxy.Form>
                                <ItemWrap height={350} width={700}>                                    
                                    <ItemContainer width={350}><ProfileProxy.Form.Password /></ItemContainer>                                    
                                    <ItemContainer width={350}><ProfileProxy.Form.RepeatPassword /></ItemContainer>                                    
                                    <ItemContainer width={350}><ProfileProxy.Form.Name /></ItemContainer>                                              
                                    <ItemContainer width={350}><ProfileProxy.Form.Phone /></ItemContainer>                                              
                                    <ItemContainer width={350}><ProfileProxy.Form.Email /></ItemContainer>                                               
                                    <ItemContainer width={350}><ProfileProxy.Form.Security /></ItemContainer>                                               
                                    <ItemContainer width={350}><ProfileProxy.Form.Birthday /></ItemContainer>                                              
                                    <ItemContainer width={350}><ProfileProxy.Form.Submit /></ItemContainer>
                                </ItemWrap>
                            </ProfileProxy.Form>
                            <PowerStrip marginTop={20}>
                                <ProfileProxy.ManageAddresses />
                                <ProfileProxy.ManageCards />
                            </PowerStrip>
                        </ProfileProxy>
                    </Portrait>
                    :
                    <Portrait>
                        <ProfileProxy> 
                            <SideMenu />
                            <ProfileProxy.Header />
                            <ProfileProxy.Status />
                            <ProfileProxy.Greeting />
                            <ProfileProxy.Form>                                   
                                <ItemContainer width={350}><ProfileProxy.Form.Password /></ItemContainer>                                    
                                <ItemContainer width={350}><ProfileProxy.Form.RepeatPassword /></ItemContainer>                                    
                                <ItemContainer width={350}><ProfileProxy.Form.Name /></ItemContainer>                                              
                                <ItemContainer width={350}><ProfileProxy.Form.Phone /></ItemContainer>                                              
                                <ItemContainer width={350}><ProfileProxy.Form.Email /></ItemContainer>                                                
                                <ItemContainer width={350}><ProfileProxy.Form.Security /></ItemContainer>                                                
                                <ItemContainer width={350}><ProfileProxy.Form.Birthday /></ItemContainer>                                    
                                <ItemContainer width={350}><ProfileProxy.Form.Submit /></ItemContainer>
                            </ProfileProxy.Form>
                            <ProfileProxy.AddressManager />
                            <ProfileProxy.CardManager />
                            <PowerStrip marginTop={20}>
                                <ProfileProxy.ManageAddresses />
                                <ProfileProxy.ManageCards />
                            </PowerStrip>
                        </ProfileProxy>
                    </Portrait>
                )
                :
                (
                    isLandscape 
                    ?
                    <Portrait>
                        <ProfileProxy>
                            <ProfileProxy.Header />
                            <ProfileProxy.Status />
                            <FlexTable width="900">
                                <ProfileProxy.Greeting />
                                <ProfileProxy.AddressManager />
                                <ProfileProxy.CardManager />
                            </FlexTable>
                            <ProfileProxy.Form>
                                <ItemWrap height={350} width={900}>                                   
                                    <ItemContainer width={450}><ProfileProxy.Form.Password /></ItemContainer>                                    
                                    <ItemContainer width={450}><ProfileProxy.Form.RepeatPassword /></ItemContainer>                                    
                                    <ItemContainer width={450}><ProfileProxy.Form.Name /></ItemContainer>                                              
                                    <ItemContainer width={450}><ProfileProxy.Form.Phone /></ItemContainer>                                              
                                    <ItemContainer width={450}><ProfileProxy.Form.Email /></ItemContainer>                                               
                                    <ItemContainer width={450}><ProfileProxy.Form.Security /></ItemContainer>                                               
                                    <ItemContainer width={450}><ProfileProxy.Form.Birthday /></ItemContainer>                                              
                                    <ItemContainer width={450}><ProfileProxy.Form.Submit /></ItemContainer>
                                </ItemWrap>
                            </ProfileProxy.Form>
                            <PowerStrip marginTop={20}>
                                <ProfileProxy.ManageAddresses />
                                <ProfileProxy.ManageCards />
                            </PowerStrip>
                        </ProfileProxy>
                    </Portrait>
                    :
                    <Portrait>
                        <ProfileProxy>
                            <ProfileProxy.Header /> 
                            <ProfileProxy.Status />
                            <ProfileProxy.Greeting />
                            <ProfileProxy.Form>                                  
                                <ItemContainer width={450}><ProfileProxy.Form.Password /></ItemContainer>                                    
                                <ItemContainer width={450}><ProfileProxy.Form.RepeatPassword /></ItemContainer>                                    
                                <ItemContainer width={450}><ProfileProxy.Form.Name /></ItemContainer>                                              
                                <ItemContainer width={450}><ProfileProxy.Form.Phone /></ItemContainer>                                              
                                <ItemContainer width={450}><ProfileProxy.Form.Email /></ItemContainer>                                               
                                <ItemContainer width={450}><ProfileProxy.Form.Security /></ItemContainer>                                               
                                <ItemContainer width={450}><ProfileProxy.Form.Birthday /></ItemContainer>                                              
                                <ItemContainer width={450}><ProfileProxy.Form.Submit /></ItemContainer>
                            </ProfileProxy.Form>
                            <ProfileProxy.AddressManager />
                            <ProfileProxy.CardManager />
                            <PowerStrip marginTop={20}>
                                <ProfileProxy.ManageAddresses />
                                <ProfileProxy.ManageCards />
                            </PowerStrip>
                        </ProfileProxy>
                    </Portrait>
                )
            }
        </Fragment>
    )
}

export default Profile
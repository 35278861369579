import React, { Fragment, useState, useRef, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import { useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'

import CardInput, { StripeError } from '../../../../controls/CardInput'
import Input from '../../../../controls/Input'
import MessageBox from '../../../../controls/MessageBox'
import Button from '../../../../controls/Button'
import useOrderContext from '../OrderContext'
import { useTheme } from '../../../../contexts/Theme'
import { tokenConfig, timeoutConfig } from '../../../../../utils'
import { lightBlue, darkBlue } from '../../../../../overlayColors'

const PayContainer = styled.div`
    width: ${p => p.pctWidth ? p.pctWidth : '95'}%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 50px;
`

const ZipContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`

const Explanation = styled.div`
    width: 80%;
    height: auto;
    color: ${props => props.color};
    font: 20px verdana, sans-serif;
    text-align: center;
    margin-top: ${props => props.marginTop}px;
`

let lastDisclaimer = false
let lastDisclaimerAccepted = false
let lastMessage = ''
let lastSetupIntent = null
let lastZip = ''
let lastBillingEqualsShipping = true

const PaymentPrompt = ({ marginTop }) => {
    // State
    const [ disclaimer, setDisclaimer ] = useState(false)
    const [ disclaimerError, setDisclaimerError ] = useState(false)
    const [ message, setMessage ] = useState('')
    const [ spinner, setSpinner ] = useState(false)
    const [ disclaimerAccepted, setDisclaimerAccepted ] = useState(false)
    const [ billingEqualsShipping, setBillingEqualsShipping ] = useState(true)
    const [ zip, setZip ] = useState('')

    // Other hooks
    const {
        activeClient, updateActiveClient, authToken,
        setPayStatus, setSpinner: setStatusSpinner, setStatusMessage,
        defaultShippingAddressSet,
        defaultShippingAddress, 
        defaultPaymentMethodSet,
        addResetFunction
    } = useOrderContext()
    const setupIntent = useRef(null)
    const stripe = useStripe()
    const theme = useTheme()

    // Mount/remount effect to maintain state
    useEffect(() => {
        setDisclaimer(lastDisclaimer)
        setMessage(lastMessage)
        setDisclaimerAccepted(lastDisclaimerAccepted)
        setupIntent.current = lastSetupIntent
        setZip(lastZip)
        setBillingEqualsShipping(lastBillingEqualsShipping)
    }, [])

    // Add our reset function
    //
    useEffect(() => {
        addResetFunction && addResetFunction(resetBackup)
    }, [ addResetFunction ])

    const updateDisclaimer = val => {
        setDisclaimer(val)
        lastDisclaimer = val
    }
    const updateMessage = msg => {
        setMessage(msg)
        lastMessage = msg
    }
    const updateDisclaimerAccepted = val => {
        setDisclaimerAccepted(val)
        lastDisclaimerAccepted = val
    }
    const updateSetupIntent = val => {
        setupIntent.current = val
        lastSetupIntent = val
    }

    const updateZip = e => {
        setZip(e.target.value)
        lastZip = e.target.value
    }

    const onZipReady = e => {
        setBillingEqualsShipping(false)
        lastBillingEqualsShipping = false
    }

    const resetBackup = () => {
        lastDisclaimer = false
        lastDisclaimerAccepted = false
        lastMessage = ''
        lastSetupIntent = null
        lastZip = ''
        lastBillingEqualsShipping = true
    }
      
    // Destructure the theme
    const { white, rgbDark } = theme
    const color = theme.isDarkBackground() ? white : rgbDark;

    const onAddCard = () => {
        updateDisclaimer(true)
        updateMessage(
            'I authorise Omni Creator Inc. to send instructions to the financial institution that issued my card ' + 
            'to take payments from my card account in accordance with the terms of my agreement with you.'
        )
    }

    const onAcceptDisclaimer = async () => {
        setSpinner(true)

        try {
            
            // Create the stripe setup intent
            let rsp = await axios.post(
                '/pay/setup-intents/', { customer: activeClient.stripeCustomerId }, timeoutConfig(tokenConfig(authToken)) 
            )
            
            // Setup intent successfully created
            setSpinner(false)
            updateDisclaimer(false)
            updateDisclaimerAccepted(true)
            updateSetupIntent(rsp.data)

        } catch(err) {

            if (err.response) { // status 400 (incl. authentication), 404 (not found), 500
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                updateMessage(`${errMsg}, payment method not added.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                updateMessage(`Server took too long to respond, payment method not added, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message)
                updateMessage(`Error: ${err?.message}, payment method not added`)
            }
            
            setDisclaimerError(true)
            setSpinner(false)
        }
    }

    const onRejectDisclaimer = () => {
        updateDisclaimer(false)
    }

    const onDisclaimerErrorExit = () => {
        updateDisclaimer(false)
    }

    // New credit card ready handler
    //
    const onCardReady = async (element, disable) => {

        // Disable card submission
        disable(true)

        try {

            setPayStatus(true)
            setStatusSpinner(true)
            
            // Confirm the setup intent
            let result = await stripe.confirmCardSetup(
                setupIntent.current.client_secret,
                {
                    payment_method: {
                        card: element,
                        billing_details: {
                            name: activeClient.name
                        }
                    } 
                }
            )

            if (result.error) throw new StripeError( { data: { message: result.error.message } } ) 

            // No error, payment method created by stripe, save the info ...

            // Retrieve the payment method object created by stripe            
            result = await axios.get(`/pay/payment-methods/${result.setupIntent.payment_method}`)

            const { id, card } = result.data

            const pm = {
                paymentType: 'card',
                cardName: card.brand,
                lastFour: card.last4,
                stripePaymentMethodId: id,
                isEnabled: true,
                isDefault: true
            }

            const paymentMethods = [ pm, ...activeClient.paymentMethods ]
            
            // Update the client DB            
            await axios.patch(`/api/clients/${activeClient._id}`, { paymentMethods }, timeoutConfig(tokenConfig(authToken)))

            // DB successfully updated, update the active client
            updateActiveClient("paymentMethods", paymentMethods)

            setStatusSpinner(false)
            setStatusMessage(`${card.brand} ****-${card.last4} successfully saved.`)

        } catch(err) {

            if (err.response) { // status 400 (incl. authentication), 404 (not found), 500, stripe error
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, payment methods not updated.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, payment methods not updated, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, payment methods not updated`)
            }
            
            setStatusSpinner(false)
        }
    } 

    const onCardError = message => null

    return (
        <Fragment>
        {
            (!defaultPaymentMethodSet && defaultShippingAddressSet) &&
            <Fragment>
                <CSSTransition
                    in={disclaimer} 
                    classNames="message" 
                    timeout={1000}
                    unmountOnExit
                >
                {
                    disclaimerError
                    ?
                    <MessageBox 
                        message={message} lightBackground={lightBlue} darkBackground={darkBlue} 
                        onOk={onDisclaimerErrorExit} label='Ok' width='320' height='240' 
                    />
                    :
                    <MessageBox 
                        spinner={spinner} message={message}
                        lightBackground={lightBlue} darkBackground={darkBlue} 
                        onYes={onAcceptDisclaimer} yesLabel='Accept' onNo={onRejectDisclaimer} noLabel='Reject'
                        width='340' height='240' 
                    />
                }
                </CSSTransition>
                {
                    disclaimerAccepted
                    ?  
                    <PayContainer>
                        <Explanation color={color} marginTop={10}>
                            Enter card details below then tap DONE to save default payment method.
                        </Explanation>
                        <CardInput 
                            marginTop={20} onReady={onCardReady} onError={onCardError} 
                            zip={billingEqualsShipping ? defaultShippingAddress.current?.zip : zip} 
                        />
                        <Explanation color={color} marginTop={20}>
                            Enter the billing zip code if different:
                        </Explanation>
                        <ZipContainer>
                            <Input 
                                type='text' name='zip' value={zip} width={120} 
                                placeholder='Zip' marginTop={20} onChange={updateZip}
                            />
                            <Button onClick={onZipReady} width={80} height={40} marginTop={20} label='Done' />
                        </ZipContainer>
                    </PayContainer>
                    :
                    <Fragment>
                        <Explanation color={color} marginTop={marginTop ? marginTop : 0}>
                            You must set a default payment method before an order can be completed.
                        </Explanation>
                        <Button 
                            onClick={onAddCard} width={300} height={80} 
                            marginTop={20} label='Enter Default Payment Method' 
                        />
                    </Fragment>
                }
            </Fragment>
        }
        </Fragment>
    )
}

export default PaymentPrompt
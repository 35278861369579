import React from 'react'
import styled from 'styled-components'

const Table = styled.div`
    width: ${p => p.width}px;
    display: table;
`

const FlexTable = ({ width, children }) => {
    return (
        <Table width={width}>{children}</Table>
    )
}

export default FlexTable
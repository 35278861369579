import React, { Fragment, useState, useRef } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import axios from 'axios'

import Spinner from './Spinner'
import Portal from './Portal'
import { useTheme } from '../contexts/Theme'
import { standardDate } from './DatePicker'

const Container = styled.div`
    position: absolute;
    bottom: -200px;
    width: 250px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 8;

    &.left { left: 90px; } 
    &.right { right: 90px; }
`

const Detail = styled.div`
    width: 100%;
    height: auto;
    background-color: white;
    font: 18px verdana, sans-serif;
    color: ${props => props.color};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &.order-enter {
        opacity: 0;
        transform: scale(0.1);
    }
    &.order-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 500ms, transform 500ms;
    }
    &.order-exit {
        opacity: 1;
    }
    &.order-exit-active {
        opacity: 0;
        transform: scale(0.1);
        transition: opacity 500ms, transform 500ms;
    }
`

const Title = styled.div`
    width: 100%;
    height: auto;
    padding: 15px 0 15px 0;
    font: 22px verdana, sans-serif;
    color: rgb(136, 0, 21);
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: ${props => props.borderColor};
    text-align: center;
`

const OrderStatus = styled.div`
    width: 100%;
    height: auto;
    padding: 20px 0 20px 0;
    background-color: rgb(112, 146, 190);
    font: 21px verdana, sans-serif;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const Items = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    font: 16px verdana, sans-serif;
    color: ${props => props.color};
    padding: 10px 0 10px 0;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: ${props => props.color};
`

const ItemContainer = styled.div`
    position: relative;
    width: 100%;
    height: 50px;
`

const Item = styled.div`
    position: absolute;
    top: 5px;
    left: 40px;
    width: 200px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font: 14px verdana, sans-serif;
    color: ${props => props.color};
`

const ImageBox = styled.div`
    position: absolute;
    top: 5px;
    left: 0;
    width: 40px;
    height: 40px;
    background-image: url('${process.env.PUBLIC_URL}/images/${props => props.image}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
`

const TotalsContainer = styled.div`
    position: relative;
    width: 100%;
    height: 30px;
    font: 16px verdana, sans-serif;
    color: ${props => props.color};
`

const TotalsItem = styled.div`
    position: absolute;
    top: 5px;
    width: 70px;
    height: 30px;
    right: ${props => props.right}px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const Separator = styled.div`
    position: absolute;
    top: 10px;
    right: 5px;
    width: 150px;
    height: 5px;
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: ${props => props.color};
`

const Info = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 16px verdana, sans-serif;
    color: ${props => props.color};
    margin-top: ${props => props.marginTop}px;
`

const Footer = styled.div`
    width: 200px;
    height: 30px;
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: ${props => props.color};
    margin-top: 20px;
`
const ORDERED = 'ORDERED'
const SHIPPED = 'SHIPPED'
const DELIVERED = 'DELIVERED'
const orderStatus = order => 
    (order.orderDate && !order.shipDate) ? ORDERED
        :
    (order.shipDate && !order.deliveryDate) ? SHIPPED
        :
    order.deliveryDate ? DELIVERED : null;
      
const OrderDetail = ({ id, order, left, right, getRefToClick, client }) => {
    // State
    const [ visible, setVisible ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ loadError, setLoadError ] = useState(false)

    // Theme
    const theme = useTheme()
    const { rgbDark } = theme

    // Other hooks
    const orderDetail = useRef(null)
    const detail = useRef()
    getRefToClick(detail)

    // Use effect to load the order detail on initial render
    const load = async () => {
        try {
            setLoading(true)
            const rsp = await axios.get(`/api/orders/${order.orderId}`)
            orderDetail.current = rsp.data
            setLoading(false)
        } catch(err) {
            setLoading(false)
            setLoadError(true)
        }
    }

    // Dereference the order detail
    const { 
        items, _id: detailId, tax, shipping, total, orderDate, shipDate, 
        tracking, trackingURL, deliveryDate, shippingAddress, stripePaymentMethodId
    } = orderDetail.current ? orderDetail.current : {};
    const paymentMethod = stripePaymentMethodId ? 
        client.paymentMethods.filter(pm => pm.stripePaymentMethodId === stripePaymentMethodId)[0] : {};
    const { cardName, lastFour } = paymentMethod;

    return (
        <Portal id={id}>
            <Container
                ref={detail} 
                className={left ? 'left' : (right ? 'right' : '')}
                onClick={() => {
                    if (!visible) {
                        setVisible(true)
                        orderDetail.current || load()
                        return;
                    }
                    setVisible(false)
                }}
            >
                <CSSTransition 
                    in={visible} 
                    classNames="order" 
                    timeout={500}
                    unmountOnExit
                >
                    <Detail color={rgbDark}>
                        { loading && <Spinner color={rgbDark} diameter={100} /> }
                        { loadError && 'Unable to display order detail, please try again later.' }
                        {
                            orderDetail.current && (
                                <Fragment>
                                    <Title borderColor={rgbDark}>{`Order# ${detailId.slice(14)}`}</Title>
                                    {
                                        orderStatus(orderDetail.current) === ORDERED &&
                                        <OrderStatus>{`Ordered on ${standardDate(orderDate)}`}</OrderStatus>
                                            ||
                                        orderStatus(orderDetail.current) === SHIPPED &&
                                            <OrderStatus>
                                                {`Shipped ${standardDate(shipDate)}`}
                                                <a href={trackingURL} style={{ color: 'yellow', fontSize: '18px' }}>{tracking}</a>
                                            </OrderStatus>
                                            ||
                                        orderStatus(orderDetail.current) === DELIVERED &&
                                        <OrderStatus>{`Delivered on ${standardDate(deliveryDate)}`}</OrderStatus>
                                    }
                                    <Items color={rgbDark}>
                                    {
                                        items.map((item, i) => (
                                            <ItemContainer key={i}>
                                                <ImageBox image={item.image} />
                                                <Item>                                                
                                                    <div>{`${item.quantity} ${item.itemName}`}</div>
                                                    <div>{`$${item.itemTotal.toFixed(2)}`}</div>
                                                </Item>
                                            </ItemContainer>
                                        ))
                                    }
                                    </Items>
                                    <TotalsContainer color={rgbDark}>
                                        <TotalsItem right={10}>{`$${tax.toFixed(2)}`}</TotalsItem>
                                        <TotalsItem right={100}>Tax:</TotalsItem>
                                    </TotalsContainer>
                                    <TotalsContainer color={rgbDark}>
                                        <TotalsItem right={10}>{`$${shipping.toFixed(2)}`}</TotalsItem>
                                        <TotalsItem right={100}>{`S&H:`}</TotalsItem>
                                    </TotalsContainer>
                                    <TotalsContainer color={rgbDark}>
                                        <TotalsItem right={10}>{`$${total.toFixed(2)}`}</TotalsItem>
                                        <TotalsItem right={100}>Total:</TotalsItem>
                                    </TotalsContainer>
                                    <TotalsContainer>
                                        <Separator color={rgbDark} />
                                    </TotalsContainer>
                                    <Info color={rgbDark} marginTop={-10}>{`Shipped to ${shippingAddress.addr1}...`}</Info>
                                    <Info color={rgbDark}>{`Charged to ${cardName} *-${lastFour}`}</Info>
                                    <Footer color={rgbDark} />
                                </Fragment>
                            )
                        }
                    </Detail>
                </CSSTransition>
            </Container>
        </Portal>
    )
}  

export default OrderDetail;
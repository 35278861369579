import { useState, useCallback } from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'

import { timeoutConfig } from '../utils'
import { SET_HOST_MEMBER } from '../redux/actions/types'

const useHostMember = () => {
    // Local state
    const [ hostMemberLoading, setHostMemberLoading ] = useState(false)

    // Get the current client
    const hostMember = useSelector(state => state.hostMember)
    const dispatch = useDispatch()

    // Get the host member
    //
    const getHostMember = useCallback(async client => {
        try {
            setHostMemberLoading(true)
            const rsp = await axios.get(`/api/members/${client.memberId}`, timeoutConfig({}))
            dispatch({
                type: SET_HOST_MEMBER,
                payload: rsp.data
            })
            setHostMemberLoading(false)
        } catch(err) {
            setHostMemberLoading(false)
        }
    }, [ dispatch ])

    return {
        hostMember,
        hostMemberLoading,
        getHostMember
    }
}

export default useHostMember
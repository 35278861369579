import React, { Fragment } from 'react'

import Header from '../../../../controls/Header'
import HeaderDesktop from '../../../../media/HeaderDesktop'
import useOrderContext from '../OrderContext'
import { usePhone } from '../../../../../hooks/useMedia'
import { useTheme } from '../../../../contexts/Theme'

const OrderHeader = () => {
    // Other hooks
    const { 
        runResetFunctions,
        resetBackup, 
        resetClientBackup 
    } = useOrderContext()
    const isPhone = usePhone()
    const theme = useTheme()

    // Destructure theme
    const headerText = theme.isDarkBackground() ? "yellow" : "blue";

    const onBack = () => {
        runResetFunctions()
        resetBackup()
        resetClientBackup()
    }

    return (
        <Fragment>
        {
            isPhone
            ?
            <Header arrow={{
                color: headerText,
                to: '/client-home',
                destination: 'Home'
            }} title='Order' onBack={onBack} separator />
            :
            <HeaderDesktop height={100} leftContent={<Header arrow={{
                color: 'blue',
                to: '/client-home',
                destination: 'Home'
            }} title='Order Creator' onBack={onBack} />} />
        } 
        </Fragment>
    )
}

export default OrderHeader
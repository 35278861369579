import React, { Fragment } from 'react'

import Header from '../../../../controls/Header'
import HeaderDesktop from '../../../../media/HeaderDesktop'
import useSalonOrderContext from '../SalonOrderContext'
import { usePhone } from '../../../../../hooks/useMedia'
import { useTheme } from '../../../../contexts/Theme'

const SalonOrderHeader = () => {
    // Other hooks
    const { resetBackup } = useSalonOrderContext()
    const isPhone = usePhone()
    const theme = useTheme()

    // Destructure theme
    const headerText = theme.isDarkBackground() ? "yellow" : "blue";

    return (
        <Fragment>
        {
            isPhone
            ?
            <Header arrow={{
                color: headerText,
                to: `/clients`,
                destination: 'Clients'
            }} title='Order' onBack={resetBackup} separator />
            :
            <HeaderDesktop height={100} leftContent={<Header arrow={{
                color: 'blue',
                to: '/clients',
                destination: 'Clients'
            }} title='Order Creator' onBack={resetBackup} />} />
        } 
        </Fragment>
    )
}

export default SalonOrderHeader
import { useState, useEffect, useRef, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'

import { useError } from '../../../contexts/Error'
import useSignupContext from './SignupContext'
import { SET_ACTIVE_MEMBER } from '../../../../redux/actions/types'
import { isEmpty, tokenConfig, timeoutConfig, encryptPassword } from '../../../../utils'

let lastMember = {}

const SET_UPDATED_PROPERTY = "SET_UPDATED_PROPERTY"
const RESTORE_MEMBER = "RESTORE_MEMBER"
const memberReducer = (member, action) => {
    switch (action.type) {
        case SET_UPDATED_PROPERTY: 
            member = { ...member, [action.payload.key]: action.payload.value }
            return member
        case RESTORE_MEMBER: 
            member = { ...lastMember }
            return member
        default: 
            return member
    }
}

const handleInputChange = (e, key, dispatch) => {
    dispatch({
        type: SET_UPDATED_PROPERTY,
        payload: {
            key: key,
            value: e.target.value
        }
    })
}

const handleSearchSelect = (item, key, idField, dispatch) => {
    item &&
    dispatch({
        type: SET_UPDATED_PROPERTY,
        payload: {
            key: key,
            value: {
                name: item.name,
                [idField]: item._id
            }
        }
    })
}

const handleValueChange = (v, key, dispatch) => {
    dispatch({
        type: SET_UPDATED_PROPERTY,
        payload: {
            key: key,
            value: v
        }
    })
}

const restoreMember = (dispatch) => {
    dispatch({ type: RESTORE_MEMBER })
}

const useSignupForm = () => {
    // State
    const [ password, setPassword ] = useState('')
    const [ repeatPassword, setRepeatPassword ] = useState('')
    const [ photoValue, setPhotoValue ] = useState(null)
    const [ photoFiles, setPhotoFiles ] = useState(null)
    const [ dataSubmitted, setDataSubmitted ] = useState(false)
    const [ updatedMember, memberDispatch ] = useReducer(memberReducer, {})

    // Reference the higher-level hook
    const { 
        setStatus, setOnStatusOk, 
        setStatusMessage, setSpinner, setErrorMessage,
        addResetFunction
    } = useSignupContext()

    // Other hooks
    const dispatch = useDispatch()
    const activeMember = useSelector(state => state.activeMember)
    const authToken = useSelector(state => state.token)
    const { passwordError, salonError, questionAndAnswerRequiredError, scrollTop } = useError()
    const errors = useRef(false)

    // Page mounted/portrait-to-landscape effect
    //
    useEffect(() => {
        addResetFunction(resetBackup)
        if (!isEmpty(lastMember)) restoreMember(memberDispatch)
    }, [])

    // Active member loaded effect
    //
    useEffect(() => {
        if (isEmpty(activeMember)) return;
        setPhotoValue(activeMember?.profilePhoto)
    }, [ activeMember ])

    const resetBackup = () => {
        lastMember = {}
    }
    
    const onNameChange = e => {
        handleInputChange(e, 'name', memberDispatch)
        lastMember = { ...lastMember, name: e.target.value }
    }
    
    const onPhoneChange = e => {
        handleInputChange(e, 'phone', memberDispatch)
        lastMember = { ...lastMember, phone: e.target.value }
    }
    
    const onEmailChange = e => {
        handleInputChange(e, 'email', memberDispatch)
        lastMember = { ...lastMember, email: e.target.value }
    }

    const onPhotoReady = photo => {
        photo.filename = photoFiles[0].name
        handleValueChange(photo, 'profilePhoto', memberDispatch)
    }

    const onSelectSalon = salon => {
        handleSearchSelect(salon, "salon", "salonId", memberDispatch)
        lastMember = { ...lastMember, salon: { name: salon.name, salonId: salon._id }}
    }

    const onQuestionChange = i => {
        handleValueChange(i, 'secretQuestion', memberDispatch)
        lastMember = { ...lastMember, secretQuestion: i }
    }

    const onAnswerChange = e => {
        handleInputChange(e, 'secretQuestionAnswer', memberDispatch)
        lastMember = { ...lastMember, secretQuestionAnswer: e.target.value }
    }

    const onBirthdayReady = dateString => {
        handleValueChange(dateString, 'birthdate', memberDispatch)
        lastMember = { ...lastMember, birthdate: dateString }
    }

    const onAboutChange = e => {
        handleInputChange(e, 'about', memberDispatch)
        lastMember = { ...lastMember, about: e.target.value }
    }

    // Accumulate error messages over form validations, return true if valid form else false
    //
    const validateForm = () => { 
        let message = '', error = false;

        // Check passwords
        { 
            const { error: err, message: msg } = passwordError(password, repeatPassword)
            if (err) {
                message = msg
                error = true
            }
        }

        // Check security question and answer
        {
            const { error: err, message: msg } = questionAndAnswerRequiredError(
                updatedMember.secretQuestion,
                updatedMember.secretQuestionAnswer
            )            
            if (err) {
                message = `${message}${message ? ', ' : ''}${msg}`
                error = true
            }
        }
            
        // Check salon
        {
            const { error: err, message: msg } = salonError(updatedMember.salon)
            if (err) {
                message = `${message}${message ? ', ' : ''}${msg}`
                error = true
            }
        }
        
        if (error) {
            setErrorMessage(message)
            scrollTop(0)
            return false
        }

        // No error
        setErrorMessage(null)
        return true
    }

    const onSubmit = async e => {
        e.preventDefault()
        
        setOnStatusOk(() => onSubmitOk)

        if (!validateForm()) // Invalid form
            return;

        // Form is valid ...

        try {
            const hash = await encryptPassword(password)

            // Hash available
            updatedMember.password = hash
            
            // First visit done
            updatedMember.firstVisit = false
            
            // Save the page data
            setStatus(true)
            setSpinner(true)

            let rsp = await axios.patch(
                `/api/members/${activeMember._id}`, updatedMember, timeoutConfig(tokenConfig(authToken))
            )

            // Make this the active member
            dispatch({
                type: SET_ACTIVE_MEMBER,
                payload: rsp.data
            })

            // If no photo file, we are done
            if (!photoFiles) {
                setSpinner(false)
                setStatusMessage(`Successfully saved the member data.`)
                return;
            }

            // There is a profile photo ...

            const formData = new FormData()
            formData.append('file', photoFiles[0])
            rsp = await axios.post('/upload/photos', formData, timeoutConfig({
                headers: { "Content-Type": "multipart/form-data" }
            }))

            setSpinner(false)
            setStatusMessage(`Successfully saved the member data and uploaded profile photo.`)

        } catch(err) {

            if (err.response) { // status 400, 404 (not found), 500
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, member not updated.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, member not updated, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, member not updated`)
            }

            setSpinner(false)
            errors.current = true
        }
    }
    
    const onSubmitOk = () => {
        setStatus(false)
        if (errors.current) {
            errors.current = false
            return;
        }

        // Data successfully submitted
        setDataSubmitted(true)
    }

    return {
        activeMember,
        password, setPassword,
        repeatPassword, setRepeatPassword,
        onNameChange, onPhoneChange, 
        onEmailChange, onSelectSalon,
        photoValue, setPhotoFiles, onPhotoReady,
        onQuestionChange, onAnswerChange, 
        onBirthdayReady, onAboutChange,
        onSubmit, dataSubmitted
    }
}

export default useSignupForm
import React, { Fragment } from 'react'

import Header from '../../../../controls/Header'
import HeaderDesktop from '../../../../media/HeaderDesktop'
import useOrderContext from '../OrderContext'
import { firstName } from '../../../../../utils'
import { usePhone } from '../../../../../hooks/useMedia'
import { useTheme } from '../../../../contexts/Theme'

const OrderHeader = () => {
    // Other hooks
    const { 
        resetBackup, resetClientBackup, currentClient,
        isCurrentMember 
    } = useOrderContext()
    const isPhone = usePhone()
    const theme = useTheme()

    // Destructure theme
    const headerText = theme.isDarkBackground() ? "yellow" : "blue";

    // Destructure the client
    const { name } = currentClient;

    // Set return destination and label
    const to = isCurrentMember() ? '/clients' : '/client';
    const destination = isCurrentMember() ? 'Clients' : firstName(name);

    return (
        <Fragment>
        {
            isPhone
            ?
            <Header arrow={{
                color: headerText,
                to,
                destination
            }} title='Order' onBack={() => {resetBackup(); resetClientBackup()}} separator />
            :
            <HeaderDesktop height={100} leftContent={<Header arrow={{
                color: 'blue',
                to,
                destination
            }} title='Order Creator' onBack={() => {resetBackup(); resetClientBackup()}} />} />
        } 
        </Fragment>
    )
}

export default OrderHeader
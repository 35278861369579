import { useRef, useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'

import { tokenConfig, timeoutConfig } from '../utils'
import { SET_CURRENT_CLIENT_PROPERTY, SET_CURRENT_CLIENT } from '../redux/actions/types'

let lastDefaultShippingAddress = null

const useCurrentClient = () => {
    // State
    const [ defaultShippingAddressSet, setDefaultShippingAddressSet ]  = useState(false)
    const [ defaultPaymentMethodSet, setDefaultPaymentMethodSet ]  = useState(false)

    // Other hooks
    const defaultPaymentMethod = useRef(null)
    const defaultShippingAddress = useRef(null)

    // Get the current client
    const currentClient = useSelector(state => state.currentClient)
    const dispatch = useDispatch()
    const authToken = useSelector(state => state.token)
    
    // Destructure the client 
    const { name, haircuts, paymentMethods, shippingAddresses } = currentClient
    const haircut = haircuts?.[0]

    // Effect to set default payment method and shipping address
    //
    useEffect(() => {
        // Initialize default payment method and shipping address if they are defined
        defaultPaymentMethod.current = paymentMethods?.find(pm => pm.isDefault)
        defaultShippingAddress.current = shippingAddresses?.find(sa => sa.isDefault)
        if (defaultPaymentMethod.current) {
            setDefaultPaymentMethodSet(true)
        } 
        if (defaultShippingAddress.current) {
            setDefaultShippingAddressSet(true)
        } else if (lastDefaultShippingAddress) {
            defaultShippingAddress.current = lastDefaultShippingAddress
            setDefaultShippingAddressSet(true)
        } 
    }, [ currentClient, paymentMethods, shippingAddresses ])

    useEffect(() => {
        if (!lastDefaultShippingAddress) { // Ignore null backup
            return;
        }
        defaultShippingAddress.current = lastDefaultShippingAddress
    }, [])

    const updateDefaultShippingAddress = useCallback(async address => {

        defaultShippingAddress.current = address
        lastDefaultShippingAddress = address // Backup

        try {

            address.isEnabled = true
            address.isDefault = true

            const shippingAddresses = [ ...currentClient.shippingAddresses, address ]            
            await axios.patch(`/api/clients/${currentClient._id}`, { shippingAddresses }, timeoutConfig(tokenConfig(authToken)))
            updateCurrentClient("shippingAddresses", shippingAddresses)

        } catch(err) {}

    }, [])

    const updateCurrentClient = useCallback((key, value) => {
        dispatch({
            type: SET_CURRENT_CLIENT_PROPERTY,
            payload: {
                key: key,
                value: value
            }
        })
    }, [ dispatch ])

    // Change the current client
    //
    const setCurrentClient = useCallback(async client => {
        try {
            const rsp = await axios.get(`/api/clients/${client.clientId}`)
            dispatch({
                type: SET_CURRENT_CLIENT,
                payload: rsp.data
            })
        } catch(err) {}
    }, [ dispatch ])

    const resetClientBackup = () => {
        lastDefaultShippingAddress = null
    } 

    return {
        currentClient,
        authToken,
        updateCurrentClient,
        setCurrentClient,
        name,
        haircut,
        defaultPaymentMethod,
        defaultShippingAddress,
        defaultPaymentMethodSet,
        defaultShippingAddressSet,
        updateDefaultShippingAddress,
        resetClientBackup
    }
}

export default useCurrentClient
import React, { useContext } from 'react'

const HelpContext = React.createContext()

const values = {
    helpRequested: false
}

const queries = {
    helpWasRequested: function() {
        return this.helpRequested
    }
}

const sets = {
    setHelpRequested: function(help) {
        this.helpRequested = help
    }
}

export const HelpProvider = (props) => {
    const value = {
        ...values,
        ...queries,
        ...sets
    }

    return <HelpContext.Provider value={value} {...props} />
}

export const useHelp = () => {
    const helpContext = useContext(HelpContext)
    if (!helpContext) throw new Error('Help context not available.')

    return helpContext
}


import { useState, useEffect, useRef, useReducer } from 'react'

import { CREATOR_PRIME_NAME, CREATOR_TUNE_NAME } from '../../../../constants'

let lastResetFunctions = []

// Stages
export const START = "START"
export const TECHNICALS = "TECHNICALS"
export const TEMPLATES = "TEMPLATES"
export const TOPOGRAPHIES = "TOPOGRAPHIES"
export const MIX = "MIX"
export const PHOTO = "PHOTO"
export const NAME = "NAME"

const SET_NEW_PROPERTY = "SET_NEW_PROPERTY"
const SET_UPDATED_PROPERTY = "SET_UPDATED_PROPERTY"
const SET_NEW_TEMPLATE = "SET_NEW_TEMPLATE"
const SET_NEW_TOPOGRAPHY = "SET_NEW_TOPOGRAPHY"
const SET_NEW_MIX = "SET_NEW_MIX"

const handleInputChange = (e, key, dispatch) => {
    dispatch({
        type: SET_NEW_PROPERTY,
        payload: {
            key: key,
            value: e.target.value
        }
    })
}

const handleValueChange = (val, key, dispatch) => {
    dispatch({
        type: SET_NEW_PROPERTY,
        payload: {
            key: key,
            value: val
        }
    })
}

const handleClientChange = (val, key, dispatch) => {
    dispatch({
        type: SET_UPDATED_PROPERTY,
        payload: {
            key: key,
            value: val
        }
    })
}

const stageReducer = (stage) => {
    switch (stage) {
        case START:
            lastStage = TECHNICALS
            return TECHNICALS;
        case TECHNICALS:
            lastStage = TEMPLATES
            return TEMPLATES;
        case TEMPLATES:
            lastStage = TOPOGRAPHIES
            return TOPOGRAPHIES;
        case TOPOGRAPHIES:
            lastStage = MIX
            return MIX;
        case MIX:
            lastStage = PHOTO
            return PHOTO;
        case PHOTO:
            lastStage = NAME
            return NAME;
        case NAME:
            lastStage = START
            return START;
        default:
            return stage;
    }
}

const haircutReducer = (haircut, action) => {
    switch (action.type) {
        case SET_NEW_PROPERTY:
            lastHaircut = { ...lastHaircut, [action.payload.key]: action.payload.value } 
            haircut = { ...haircut, [action.payload.key]: action.payload.value }
            return haircut
        case SET_NEW_TEMPLATE:
        {
            lastHaircut.frames[action.payload.key].templates.push(action.payload.value)
            haircut.frames[action.payload.key].templates.push(action.payload.value)
            return haircut
        }
        case SET_NEW_TOPOGRAPHY:
        {
            lastHaircut.frames[action.payload.key] = { ...lastHaircut.frames[action.payload.key], ...action.payload.value }
            haircut.frames[action.payload.key] = { ...haircut.frames[action.payload.key], ...action.payload.value }
            return haircut
        }
        case SET_NEW_MIX:
        {
            lastHaircut.frames[action.payload.key] = { ...lastHaircut.frames[action.payload.key], ...action.payload.value }
            haircut.frames[action.payload.key] = { ...haircut.frames[action.payload.key], ...action.payload.value }
            return haircut
        }
        default: 
            return haircut
    }
}

const clientReducer = (client, action) => {
    switch (action.type) {
        case SET_UPDATED_PROPERTY: 
            lastClient = { ...lastClient, [action.payload.key]: action.payload.value } 
            client = { ...client, [action.payload.key]: action.payload.value }
            return client
        default: 
            return client
    }
}

let lastStage = START
let lastHaircut = { frames: [ { frame: 1, templates: [] }]}
let lastClient = {}
let lastSelectedTemplate = {}
let lastSelectedTopography = {}
let lastSelectedMix = {}
let photoFiles = null
let lastPrime = 'unavailable'
let lastTune = 'unavailable'
let lastHideInstruction = false

let haircutPhotos = []

let initialMount = true

const useAddHaircut = () => {
    // Reducers
    const [ stage, stageDispatch ] = useReducer(stageReducer, lastStage)
    const [ newHaircut, haircutDispatch ] = useReducer(haircutReducer, lastHaircut)
    const [ updatedClient, clientDispatch ] = useReducer(clientReducer, lastClient)

    // Local state
    const [ prime, setPrime ] = useState(lastPrime)
    const [ tune, setTune ] = useState(lastTune)
    const [ status, setStatus ] = useState(false)
    const [ spinner, setSpinner ] = useState(false)
    const [ statusMessage, setStatusMessage ] = useState('')
    const [ onStatusOk, setOnStatusOk ] = useState(null)
    const [ hideInstruction, setHideInstruction ] = useState(lastHideInstruction)
    
    const { current: resetFunctions } = useRef(lastResetFunctions)
    const { current: selectedTemplate } = useRef(lastSelectedTemplate)
    const { current: selectedTopography } = useRef(lastSelectedTopography)
    const { current: selectedMix } = useRef(lastSelectedMix)

    // Initial mount effect
    //
    useEffect(() => {
        if (!initialMount) return;

        initialMount = false
        addResetFunction(() => {
            lastStage = START
            lastHaircut = { frames: [ { frame: 1, templates: [] }]}
            lastClient = {}
            lastSelectedTemplate = {}
            lastSelectedTopography = {}
            lastSelectedMix = {}
            photoFiles = null
            lastPrime = 'unavailable'
            lastTune = 'unavailable'            
            haircutPhotos = []
            initialMount = true
            lastHideInstruction = false
        })
    }, [])

    useEffect(() => {
        
    }, [])

    const addResetFunction = func => {
        resetFunctions.push(func)
        lastResetFunctions.push(func)
    }

    const runResetFunctions = () => {
        resetFunctions.forEach(func => func?.())
        lastResetFunctions = []
    }

    const onPhotoReady = (photo, index) => {
        photo.filename = photoFiles[index].name
        index >= haircutPhotos.length ? 
            haircutPhotos = [...haircutPhotos, photo] :
            haircutPhotos[index] = photo
    }

    const updatePhotoFiles = files => {
        photoFiles = files
    }

    const updatePrime = p => {
        setPrime(`${CREATOR_PRIME_NAME} ${p}`)
        lastPrime = `${CREATOR_PRIME_NAME} ${p}`
    }

    const updateTune = t => {
        setTune(`${CREATOR_TUNE_NAME} ${t}`)
        lastTune = `${CREATOR_TUNE_NAME} ${t}`
    }

    const updateHideInstruction = h => {
        setHideInstruction(h)
        lastHideInstruction = h
    }

    // Client characteristics handlers
    const onEmoliencyChange = val => handleClientChange(val, 'scalp', clientDispatch)
    const onDiameterChange = val => handleClientChange(val, 'texture', clientDispatch)
    const onHeightChange = val => handleClientChange(val, 'height', clientDispatch)
    const onWidthChange = val => handleClientChange(val, 'width', clientDispatch)
    const onCurlChange = val => handleClientChange(val, 'curl', clientDispatch)

    // Haircut change handlers
    const onCutChange = val => handleValueChange(val, 'frames', haircutDispatch)
    const onTemplateChange = (i, s) => { 
        selectedTemplate.template = lastSelectedTemplate.template = i
        selectedTemplate.templateUsed = lastSelectedTemplate.templateUsed = s 
        haircutDispatch({ type: SET_NEW_TEMPLATE, payload: { key: 0, value: selectedTemplate } })
    }
    const onTopographyChange = (i, s) => {
        selectedTopography.topography = lastSelectedTopography.topography = i;
        selectedTopography.topographyUsed = lastSelectedTopography.topographyUsed = s; 
        haircutDispatch({ type: SET_NEW_TOPOGRAPHY, payload: { key: 0, value: selectedTopography } })
    }
    const onMixChange = (i, s) => {
        selectedMix.mix = lastSelectedMix.mix = i;
        selectedMix.mixUsed = lastSelectedMix.mixUsed = s; 
        haircutDispatch({ type: SET_NEW_MIX, payload: { key: 0, value: selectedMix } })
    }
    const onNameChange = e => handleInputChange(e, 'haircutName', haircutDispatch)

    // Stage change handler
    const onStageChange = val => stageDispatch(val)

    return {
        status, setStatus,
        spinner, setSpinner,
        statusMessage, setStatusMessage,
        onStatusOk, setOnStatusOk,
        addResetFunction, runResetFunctions,
        onEmoliencyChange, onDiameterChange, onHeightChange,
        onWidthChange, onCurlChange, 
        updatePrime, updateTune, prime, tune, onCutChange,
        onTemplateChange, onTopographyChange, onMixChange,
        onPhotoReady, updatePhotoFiles, haircutPhotos, photoFiles,
        onNameChange,
        onStageChange, stage,
        hideInstruction, updateHideInstruction,
        updatedClient, newHaircut        
    }
}

export default useAddHaircut
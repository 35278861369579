import React, { Fragment, useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import SearchBarService from '../../../../controls/SearchBarService'
import Photo from '../../../../controls/Photo'
import { useTheme } from '../../../../contexts/Theme'
import useMembersContext from '../MembersContext'

const MembersViewport = styled.div`
    width: ${p => p.pctWidth ?? '100'}%;
    height: 350px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 50px;
`

const MemberList = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const MemberContainer = styled.div`
    width: ${p => p.pctWidth ?? '95'}%;
    height: 90px;
    border-width: 0 0 2px 0;
    border-color: ${props => props.borderColor};
    border-style: groove;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
`

const PhotoAndText = styled.div`
    width: 240px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const NoPhoto = styled.div`
    width: 80px;
    height: 80px;
    background-color: gray;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: 18px verdana, sans-serif;
    color: rgba(255, 255, 255, 0.6);
`

const MemberName = styled.div`
    width: 160px;
    height: 100%;
    font: 22px verdana, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow-x: scroll;
    white-space: nowrap;
    color: ${p => p.textColor};
`

const AddClient = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-image: url('${process.env.PUBLIC_URL}/images/add-client.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.pressed { box-shadow: 2px 2px 2px 2px ${props => props.shadowColor} inset; }
`

let lastMemberSummaries = []

const MemberSearch = ({ viewportWidthPct, summaryWidthPct }) => {
    // State
    const [ memberSummaries, setMemberSummaries ] = useState(lastMemberSummaries)
    const [ addClientPressed, setAddClientPressed ] = useState(-1)
    const { salonMembers, setCurrentMember, addResetFunction } = useMembersContext()

    // Other hooks
    const toMember = useRef()
    const toAddClient = useRef()
    const theme = useTheme()

    // Add our reset function
    //
    useEffect(() => {
        addResetFunction && addResetFunction(resetBackup)
    }, [ addResetFunction ])

    // Theme
    const { white, rgbDark, rgbaDarkBg } = theme
    const [ textColor, shadowColor ] = theme.isDarkBackground() ? [ white, rgbaDarkBg ] : [ rgbDark, rgbaDarkBg ];

    const resetBackup = () => {
        lastMemberSummaries = []
    }

    const onSearch = searchTerm => {
        lastMemberSummaries = 
            searchTerm 
            ?
            salonMembers?.filter(member => member.name.toLowerCase().includes(searchTerm.toLowerCase()))
            :
            salonMembers;
        setMemberSummaries(lastMemberSummaries)
    }

    return (
        <Fragment>
            <Link ref={toMember} to="/clients" style={{display: "none"}} />
            <Link ref={toAddClient} to="/client-signup" style={{display: "none"}} />
            <SearchBarService 
                iconUrl='person' 
                name='members'
                marginTop='50'
                placeholder='Salon members'
                caption="Tap spy to show all salon members, or first enter search term to narrow the list."
                search={onSearch}
            />                   
            <MembersViewport pctWidth={viewportWidthPct}> 
                <MemberList>
                { 
                    memberSummaries.length > 0
                    &&
                    (
                        memberSummaries.map((member, i) => 
                            <MemberContainer key={i} pctWidth={summaryWidthPct}>
                                <PhotoAndText onClick={async () => {
                                            await setCurrentMember(member)
                                            toMember.current.click()
                                        }
                                    }>
                                    {
                                        member.profilePhoto 
                                        ?
                                        <Photo photo={member.profilePhoto} width={80} />
                                        :                                        
                                        <NoPhoto>No photo</NoPhoto>
                                    }
                                    <MemberName textColor={textColor}>{member.name}</MemberName>
                                </PhotoAndText>
                                <AddClient
                                    shadowColor={shadowColor}                                
                                    onTouchStart={() => setAddClientPressed(i)}
                                    onTouchEnd={() => setAddClientPressed(-1)}                                
                                    onMouseDown={() => setAddClientPressed(i)}
                                    onMouseUp={() => setAddClientPressed(-1)}
                                    className={ addClientPressed === i ? 'pressed' : '' }
                                    onClick={async () => {
                                        await setCurrentMember(member)
                                        toAddClient.current.click()
                                    }
                                }/>
                            </MemberContainer>
                        )
                    ) 
                }
                </MemberList>
            </MembersViewport>
        </Fragment>
    )
}

export default MemberSearch
import React, { useState, useRef, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'
import Caption from './Caption'
import Null from './Null'
import Spinner from './Spinner'

const UnderlineContainer = styled.div`
    position: relative;
    width: 320px;
    height: 40px;
    margin-top: ${props => props.marginTop}px;
`

const UnderlineIcon = styled.div`
    position: absolute;
    top: -10px;
    left: 0px;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0);
    background-image: url('../../../images/${props => props.iconUrl}-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const UnderlineInput = styled.div`
    position: absolute;
    top: -10px;
    left: 65px;
    width: 250px;
    height: 40px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
`

const Underline = styled.div`
    position: absolute;
    bottom: 0px;
    left: 60px;
    right: 0px;
    border-style: solid;
    border-width: 1px;
    border-color: ${props => props.color};
    background-color: ${props => props.color};
`

const OvalContainer = styled.div`
    width: 100%;
    height: 60px;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${props => props.marginTop}px;
`

const OvalIcon = styled.div`
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: ${props => props.bgColor};
    background-image: url('../../../images/${props => props.iconUrl}-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px 50px;
`

const OvalInput = styled.div`
    position: relative;
    width: 250px;
    height: 60px;
    margin-left: 10px;
    border-radius: 100px;
    border: none;
    background-color: ${props => props.bgColor};
`

const SpyIcon = styled.div`
    position: absolute;
    top: ${props => props.top};
    right: ${props => props.right};
    width: 40px;
    height: 50px;
    background-image: url('../../../images/spy-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Select = styled.input`
    position: absolute;
    top: 10px;
    left: 25px;
    width: 200px;
    height: 40px;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    font: 18px verdana, sans-serif;
    color: ${props => props.color};
    text-indent: 30px;
    
    &::placeholder {
        color: ${props => props.color};
        opacity: 0.6;
    }
`

const Options = styled.div`
    position: absolute;
    top: 50px;
    left: 25px;
    width: 200px;
    height: 200px;
    background-color: ${props => props.bgColor};
    font: 18px verdana, sans-serif;
    color: ${props => props.color};
    overflow: scroll;
    z-index: 2;

    &.options-enter {
        opacity: 0;
        transform: scale(0.8) translateY(-50px);
    }
    &.options-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 500ms, transform 500ms;
    }
    &.options-exit {
        opacity: 1;
    }
    &.options-exit-active {
        opacity: 0;
        transform: scale(0.8) translateY(-50px);
        transition: opacity 500ms, transform 500ms;
    }
`

const Banner = styled.div`
    width: 100%;
    height: auto;
    padding: 15px 0 15px 0;
    border-bottom: 1px solid ${props => props.borderColor};
    text-align: center;
    font: 20px verdana, sans-serif;
    color: ${props => props.color};
`

const Option = styled.div`
    width: 60%;
    height: 40px;
    background-color: rgba(0, 0, 0, 0);
    overflow: visible;
    padding: 5px 0 0 10px;

    &.selected { 
        background-color: rgb(38, 38, 255);
        color: white;
    }
`

// Search term changed since last spy tap
let valueChanged = false

const SearchBar = ({ 
    search, onSelect, nameField, caption, name, 
    value, marginTop, iconUrl, placeholder
}) => {
    const [ captionVisible, setCaptionVisible ] = useState(false)
    const [ resultsVisible, setResultsVisible ] = useState(false)
    const [ optionSelected, setOptionSelected ] = useState(-1)
    const [ spinnerVisible, setSpinnerVisible ] = useState(false)
    const [ searchTerm, setSearchTerm ] = useState(value ? value : '')
    const [ dataLoaded, setDataLoaded ] = useState(false)

    const options = useRef([])
    const rawData = useRef(null)

    useEffect(() => setSearchTerm(value), [value])

    const theme = useTheme()
    const { white, dark, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const bgColor = theme.isDarkBackground() ? rgbaWhiteBg : rgbaDarkBg;
    const iconColor = theme.isDarkBackground() ? white : dark;
    const textColor = theme.isDarkBackground() ? white : rgbDark;
    const optionsColor = theme.isDarkBackground() ? rgbDark : white;

    // Content
    const banner = () => `- ${options.current.length} match${options.current.length === 1 ? '' : 'es'} -`

    // Search stuff
    const onTouchSpy = async () => {
        // If value changed, leave results open and repeat search,
        // else close results
        if (resultsVisible && !valueChanged) { // Close results panel
            setResultsVisible(false)
            return
        }

        // If data was fetched and no value change, don't repeat the search
        //if (!firstSpyTap && !valueChanged) {
        if (dataLoaded && !valueChanged) {
            setResultsVisible(true)
            return
        }

        // Reset flag
        valueChanged = false

        try {
            // Kick off search
            setDataLoaded(false)
            if (!resultsVisible) setResultsVisible(true)
            setSpinnerVisible(true)
            let rsp = await search(searchTerm)

            // Success, map to options and save raw data
            //setOptions(rsp.data.map(datum => datum[nameField]))
            options.current = rsp.data.map(datum => datum[nameField])
            rawData.current = rsp.data

            // Log
            console.log(rsp)
            setSpinnerVisible(false)

            // Data was loaded
            setDataLoaded(true)

        } catch(err) {
            console.log(`Error! ${err}`)
            setSpinnerVisible(false)
        }
    }

    // Other handlers
    const onTouchIcon = e => setCaptionVisible(!captionVisible)
    const onOptionSelected = key => {
        setOptionSelected(key)
        setSearchTerm(options.current[key])
        onSelect(rawData.current[key])
    }
    const onOptionSelectedEnd = () => setResultsVisible(false)
    const onSearchTermChange = e => {
        setSearchTerm(e.target.value)
        valueChanged = true
    }
    
    // Theme conditionals
    const Container = theme.isInputOval() ? OvalContainer : UnderlineContainer;
    const Icon = theme.isInputOval() ? OvalIcon : UnderlineIcon;
    const Input = theme.isInputOval() ? OvalInput : UnderlineInput;
    const stemOffset = theme.isInputOval() ? '30' : '25';
    const spyTop = theme.isInputOval() ? '5px' : '-5px';
    const spyRight = theme.isInputOval() ? '10px' : '0px';

    // Visibility
    const CaptionOrNot = caption && captionVisible ? Caption : Null;
    const SpinnerOrNot = spinnerVisible ? Spinner : Null;

    return (
        <Container marginTop={marginTop}>
            <Icon bgColor={bgColor} iconUrl={iconUrl} color={iconColor} onClick={onTouchIcon}>
                <CaptionOrNot text={caption} width='200' bottom='60' left='0' stemOffset={stemOffset} />
            </Icon>
            <Input bgColor={bgColor}>
                <Select type='text' name={name} color={textColor} value={searchTerm} 
                    onChange={onSearchTermChange} placeholder={placeholder} />
                <CSSTransition 
                    in={resultsVisible} 
                    classNames="options" 
                    timeout={500}
                    unmountOnExit
                >
                    <Options color={textColor} bgColor={optionsColor}>
                        <SpinnerOrNot diameter={100} />
                        {
                            dataLoaded && <Banner color={textColor} borderColor={bgColor}>{banner()}</Banner>
                        }
                        { 
                            dataLoaded && options.current.map((option, i) => (
                                <Option key={i} 
                                    onTouchStart={e => onOptionSelected(i)}
                                    onTouchEnd={e => onOptionSelectedEnd()}
                                    onMouseDown={e => onOptionSelected(i)}
                                    onMouseUp={e => onOptionSelectedEnd()}
                                    className={i === optionSelected ? 'selected' : ''}
                                >{option}</Option>
                            ))
                        }
                    </Options>
                </CSSTransition>
                <SpyIcon color={iconColor} top={spyTop} right={spyRight} onClick={onTouchSpy} />
            </Input>
            {theme.isInputUnderline() && <Underline color={textColor} />} 
        </Container>
    )
}

export default SearchBar
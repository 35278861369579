import React, { useState, useEffect } from 'react'

import IconInput from '../../../../controls/IconInput'
import useClientSignupContext from '../ClientSignupContext'
import useClientSignupFormContext from '../ClientSignupFormContext'

let lastName = ''

const Name = () => {
    // Local state
    const [ name, setName ] = useState('')

    // Hooks
    const { addResetFunction } = useClientSignupContext()
    const { onNameChange } = useClientSignupFormContext()

    useEffect(() => {
        addResetFunction(resetBackup)
        lastName && setName(lastName)
    }, [])

    const resetBackup = () => {
        lastName = ''
    }
    
    const onChange = e => {
        onNameChange(e)
        setName(e.target.value)
        lastName = e.target.value
    }

    return (
        <IconInput 
            type='text' 
            iconUrl='person' 
            name='name'
            value={name} 
            placeholder="Full name (required)"
            marginTop={30} 
            onChange={onChange}
            caption="Client's first and last name."
            required
        />
    )
}

export default Name
import styled from 'styled-components'

const Segment = styled.div`
    position: relative;
    width: ${p => p.pctWidth}%;
    height: ${p => p.pctHeight}%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`

const LandscapeSegment = ({ pctWidth, pctHeight, children }) => {
    return (
        <Segment pctWidth={pctWidth} pctHeight={pctHeight}>{children}</Segment>
    )
}

export default LandscapeSegment
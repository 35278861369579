import React from 'react'

import FooterMenu from '../../../../controls/FooterMenu'

const Footer = ({ leftPct }) => {
    return (
        <FooterMenu items={ [
            { label: 'Clients', selected: true, to: '/clients' },
            { label: 'Me', selected: false, to: '/me' }
        ] } leftPct={leftPct} />
    )
}

export default Footer
import React, { createContext, useContext } from 'react'

const Members = createContext(undefined)

export const MembersProvider = ({ children, value }) => {
    return (
        <Members.Provider value={value}>{children}</Members.Provider>
    )
}

const useMembersContext = () => {
    const context = useContext(Members)

    if (context === undefined) throw new Error('useMembersContext must be used within a MembersProvider')

    return context;
}

export default useMembersContext
import React, { Fragment } from 'react'
import styled from 'styled-components'

import Separator from '../../../../controls/Separator'
import Technical, { TEXTURE, SCALP, HEIGHT, WIDTH, CURL } from '../../../../controls/Technical'
import { useTheme } from '../../../../contexts/Theme'
import useClientContext from '../ClientContext'

const Title = styled.div`
    width: 100%;
    height: 40px;
    text-align: center;
    font: 20px verdana, sans-serif;
    color: ${props => props.color};
    margin-top: ${props => props.marginTop ? props.marginTop: '0'}px;
`

const ClientTechnicals = styled.div`
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`

const Technicals = () => {
    // Hooks
    const { currentClient } = useClientContext()
    const theme = useTheme()

    // Destructure the theme
    const { white, rgbDark, rgbaWhiteBg, rgbaDarkBg } = theme
    const [ color, sepColor, techColor ] = theme.isDarkBackground() 
        ? [ white, rgbaWhiteBg, 'rgb(134, 140, 223)' ] 
        : [ rgbDark, rgbaDarkBg, 'rgb(52, 63, 197)' ];

    // Destructure the client
    const { texture, scalp, height, width, curl } = currentClient

    return (
        <Fragment>
            <Title color={color} marginTop={15}>{'Technicals'}</Title>
            <ClientTechnicals>
                <Technical width={60} name={TEXTURE} value={texture} bgColor={techColor} margin={10} vertical />
                <Technical width={60} name={SCALP} value={scalp} bgColor={techColor} margin={10} vertical />
                <Technical width={60} name={HEIGHT} value={height} bgColor={techColor} margin={10} vertical />
                <Technical width={60} name={WIDTH} value={width} bgColor={techColor} margin={10} vertical />
                <Technical width={60} name={CURL} value={curl} bgColor={techColor} margin={10} vertical />
            </ClientTechnicals>
            <Separator color={sepColor} marginTop={15} />
        </Fragment>
    )
}

export default Technicals
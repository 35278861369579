import React, { useState, Fragment } from 'react'
import styled from 'styled-components'

import Caption from './Caption'
import { useTheme } from '../contexts/Theme'

const Container = styled.div`
    position: relative;
    width: ${props => props.percentWidth}%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: ${props => props.marginTop}px;
    border-left-width: 3px;
    border-left-style: groove;
    border-left-color: {p => p.borderColor};
    border-left: ${p => !p.leftBorder && 'none'};
`

const Title = styled.h3`
    width: 100%;
    font: 22px verdana, sans-serif;
    color: ${props => props.color};
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
`

const Buttons = styled.div`
    width: 100%;
    height: auto;
    background-color: ${props => props.bgColor};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    border-radius: 6px;
    
    &.error { border: 2px solid red; }
`

const ButtonContainer = styled.div`
    width: ${props => props.width}px;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

const Button = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.width}px;
    border-radius: 50%;
    background-color: ${props => props.bgColor};
    background-image: url('${process.env.PUBLIC_URL}/images/${props => props.image}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Label = styled.div`
    width: 100%;
    height: 15px;
    margin-top: 5px;
    font: 12px verdana, sans-serif;
    color: ${props => props.color};
    text-align: center;
`

const RadioButtonGroup = ({ 
    value, onChange, title, options, panelColor, buttonColor, labelColor, selectColor, 
    caption, percentWidth, buttonWidth, error, marginTop, leftBorder
}) => {
    const [ selectedOption, setSelectedOption ] = useState(value !== undefined ? value : -1)
    const [ titleTapped, setTitleTapped ] = useState(false)

    const theme = useTheme()
    const { white, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const [ color, borderColor ] = theme.isDarkBackground() ? 
        [ white, rgbaWhiteBg ] : 
        [ rgbDark, rgbaDarkBg ];

    const ButtonAndLabel = ({ index, image, label, bgColor, color }) => {
        const handleClick = () => {
            setSelectedOption(index)
            onChange(index)
        }
        
        return (
            <ButtonContainer width={buttonWidth ? buttonWidth : 80}>
                <Button image={image} bgColor={bgColor} onClick={handleClick} width={buttonWidth ? buttonWidth : 80} />
                <Label color={color}>{label}</Label>
            </ButtonContainer>
        )
    }

    const onTitleTapped = () => setTitleTapped(t => !t)

    return (
        <Fragment>
            <Container 
                percentWidth={percentWidth ? percentWidth : 100} marginTop={marginTop} 
                leftBorder={leftBorder} borderColor={borderColor}
            >
                { title && caption && titleTapped && 
                    <Caption text={caption} bottomPct={95} leftPct={50} width={240} marginLeft={-120} stemOffset={120} /> 
                }
                { title && <Title onClick={onTitleTapped} color={color}>{title}</Title> }
                <Buttons bgColor={panelColor} className={error ? 'error' : ''} >
                    {
                        options.map((option, i) => <ButtonAndLabel key={i} index={i}
                            image={option.image} bgColor={i === selectedOption ? selectColor : buttonColor} 
                            label={option.label} color={labelColor} 
                        />)
                    }
                </Buttons>
            </Container>
        </Fragment>
    )
}

export default RadioButtonGroup
import { useState, useRef, useEffect } from 'react'
import axios from 'axios'

import {
    PRIME1_16, PRIME2_16, PRIME3_16,
    TUNE1_16, TUNE2_16, TUNE3_16,
    CREAM_SOFT, CREAM_SMOOTH, CREAM_TEXTURED,
    SERUM_RADIUS, SERUM_BLUR, SERUM_PLUMB, SERUM_STRATA, SERUM_TILT
} from '../../../controls/Ribbon'
import { useLandscape } from '../../../../hooks/useMedia'
import { timeoutConfig } from '../../../../utils'

let lastMenuSelection = {
    primeSelected: false, tuneSelected: false, creamsSelected: false, serumsSelected: false
}
let lastPrimeSelection = {
    oneSelected: false, twoSelected: false, threeSelected: false
}
let lastTuneSelection = {
    oneSelected: false, twoSelected: false, threeSelected: false
}
let lastCreamSelection = {
    softSelected: false, smoothSelected: false, texturedSelected: false
}
let lastSerumSelection = {
    radiusSelected: false, blurSelected: false, plumbSelected: false, strataSelected: false, tiltSelected: false
}
let lastInvoiceItems = [
    { name: "Prime 1", quantity: 1, wasTapped: false },
    { name: "Prime 2", quantity: 1, wasTapped: false },
    { name: "Prime 3", quantity: 1, wasTapped: false },
    { name: "Tune 1", quantity: 1, wasTapped: false },
    { name: "Tune 2", quantity: 1, wasTapped: false },
    { name: "Tune 3", quantity: 1, wasTapped: false },
    { name: "Cream Soft", quantity: 1, wasTapped: false },
    { name: "Cream Smooth", quantity: 1, wasTapped: false },
    { name: "Cream Textured", quantity: 1, wasTapped: false },
    { name: "Serum Radius", quantity: 1, wasTapped: false },
    { name: "Serum Blur", quantity: 1, wasTapped: false },
    { name: "Serum Plumb", quantity: 1, wasTapped: false },
    { name: "Serum Strata", quantity: 1, wasTapped: false },
    { name: "Serum Tilt", quantity: 1, wasTapped: false }
]
let lastReflectedItems = []
let lastSubtotal = 0
let lastDiscount = 0
let lastTax = 0
let lastShippingAndHandling = 0
let lastTotal = 0

let initialMount = true

const useSalonOrder = () => {
    // State
    const [ menuSelection, setMenuSelection ] = useState({
        primeSelected: false, tuneSelected: false, creamsSelected: false, serumsSelected: false
    })
    const [ primeSelection, setPrimeSelection ] = useState({
        oneSelected: false, twoSelected: false, threeSelected: false 
    })
    const [ tuneSelection, setTuneSelection ] = useState({
        oneSelected: false, twoSelected: false, threeSelected: false 
    })
    const [ creamSelection, setCreamSelection ] = useState({
        softSelected: false, smoothSelected: false, texturedSelected: false 
    })
    const [ serumSelection, setSerumSelection ] = useState({
        radiusSelected: false, blurSelected: false, plumbSelected: false, strataSelected: false, tiltSelected: false 
    })
    const [ payStatus, setPayStatus ] = useState(false)
    const [ spinner, setSpinner ] = useState(false)
    const [ statusMessage, setStatusMessage ] = useState(null)
    const [ discount, setDiscount ] = useState(null)
    const [ total, setTotal ] = useState(0)

    // Other hooks
    const isLandscape = useLandscape()
    const invoiceItems = useRef([
        { name: "Prime 1", quantity: 1, wasTapped: false },
        { name: "Prime 2", quantity: 1, wasTapped: false },
        { name: "Prime 3", quantity: 1, wasTapped: false },
        { name: "Tune 1", quantity: 1, wasTapped: false },
        { name: "Tune 2", quantity: 1, wasTapped: false },
        { name: "Tune 3", quantity: 1, wasTapped: false },
        { name: "Cream Soft", quantity: 1, wasTapped: false },
        { name: "Cream Smooth", quantity: 1, wasTapped: false },
        { name: "Cream Textured", quantity: 1, wasTapped: false },
        { name: "Serum Radius", quantity: 1, wasTapped: false },
        { name: "Serum Blur", quantity: 1, wasTapped: false },
        { name: "Serum Plumb", quantity: 1, wasTapped: false },
        { name: "Serum Strata", quantity: 1, wasTapped: false },
        { name: "Serum Tilt", quantity: 1, wasTapped: false }
    ])
    // Items updated in the invoice:
    const reflectedItems = useRef([])

    const subtotal = useRef(0)
    const tax = useRef(0)
    const shippingAndHandling = useRef(0)

    // Initial page mount effect, prices load
    //
    useEffect(() => {
        async function getPrices() {
            try {
                setSpinner(true)
                const rsp = await axios.get(`/api/prices`, timeoutConfig({}))
                setSpinner(false)
                invoiceItems.current.forEach((item, i) => {
                    lastInvoiceItems[i].price = item.price = rsp.data.filter(datum => datum.name === item.name)[0].price
                })
                initialMount = false
            } catch(err) {
                setPayStatus(true)
                if (err.response) {
                    switch (err.response.status) {
                        case 500: // Server error
                            setStatusMessage('Server error, product prices not available.')
                            console.log('Error! ', 'Server error')
                            break;
                        default: // 400, session expired
                            console.log('Error! ', err.response.data)
                            setStatusMessage(`${err.response.data.message}, product prices not available.`)
                    }
                } else if (err?.code === 'ECONNABORTED') { // Timeout error
                    setStatusMessage(`Server took too long to respond, product prices not available, try again later.`)
                } else { // Not likely
                    console.log('ERR: ', err?.code)
                    console.log('ERR: ', err?.message) 
                    setStatusMessage(`Error: ${err?.message}, product prices not available.`)
                }

                setSpinner(false)
            }
        }

        if (!initialMount) return;
        getPrices()
    }, [])

    // Recover from landscape <-> portrait change
    //
    useEffect(() => {
        if (initialMount) return;
        
        setMenuSelection(lastMenuSelection)
        setPrimeSelection(lastPrimeSelection)
        setTuneSelection(lastTuneSelection)
        setCreamSelection(lastCreamSelection)
        setSerumSelection(lastSerumSelection)
        invoiceItems.current = lastInvoiceItems
        reflectedItems.current = lastReflectedItems
        subtotal.current = lastSubtotal
        setDiscount(lastDiscount)
        tax.current = lastTax
        shippingAndHandling.current = lastShippingAndHandling
        setTotal(lastTotal)
    }, [ isLandscape ])

    // Main menu handlers

    const onPrimeClicked = () => {
        setMenuSelection({ primeSelected: true, tuneSelected: false, creamsSelected: false, serumsSelected: false })
        lastMenuSelection = { primeSelected: true, tuneSelected: false, creamsSelected: false, serumsSelected: false }
    }

    const onTuneClicked = () => {
        setMenuSelection({ primeSelected: false, tuneSelected: true, creamsSelected: false, serumsSelected: false })
        lastMenuSelection = { primeSelected: false, tuneSelected: true, creamsSelected: false, serumsSelected: false }
    }

    const onCreamsClicked = () => {
        setMenuSelection({ primeSelected: false, tuneSelected: false, creamsSelected: true, serumsSelected: false })
        lastMenuSelection = { primeSelected: false, tuneSelected: false, creamsSelected: true, serumsSelected: false }
    }

    const onSerumsClicked = () => {
        setMenuSelection({ primeSelected: false, tuneSelected: false, creamsSelected: false, serumsSelected: true })
        lastMenuSelection = { primeSelected: false, tuneSelected: false, creamsSelected: false, serumsSelected: true }
    }
    
    // Ribbon selection handlers

    const onPrimeSelected = selection => {
        switch (selection?.code) {
            case PRIME1_16:
                if (primeSelection.oneSelected) break;
                invoiceItems.current[0].wasTapped = true
                invoiceItems.current[0].image = selection.image
                lastInvoiceItems[0].wasTapped = true
                lastInvoiceItems[0].image = selection.image
                setPrimeSelection({ ...primeSelection, oneSelected: true })
                lastPrimeSelection = { ...lastPrimeSelection, oneSelected: true }
                break;
            case PRIME2_16:
                if (primeSelection.twoSelected) break;
                invoiceItems.current[1].wasTapped = true
                invoiceItems.current[1].image = selection.image
                lastInvoiceItems[1].wasTapped = true
                lastInvoiceItems[1].image = selection.image
                setPrimeSelection({ ...primeSelection, twoSelected: true })
                lastPrimeSelection = { ...lastPrimeSelection, twoSelected: true }
                break;
            case PRIME3_16:
                if (primeSelection.threeSelected) break;
                invoiceItems.current[2].wasTapped = true
                invoiceItems.current[2].image = selection.image
                lastInvoiceItems[2].wasTapped = true
                lastInvoiceItems[2].image = selection.image
                setPrimeSelection({ ...primeSelection, threeSelected: true })
                lastPrimeSelection = { ...lastPrimeSelection, threeSelected: true }
                break;
            default: break;
        }
                      
        invoiceItems.current = [ ...invoiceItems.current ]
    }

    const onTuneSelected = selection => {
        switch (selection?.code) {
            case TUNE1_16:
                if (tuneSelection.oneSelected) break;
                invoiceItems.current[3].wasTapped = true
                invoiceItems.current[3].image = selection.image
                lastInvoiceItems[3].wasTapped = true
                lastInvoiceItems[3].image = selection.image
                setTuneSelection({ ...tuneSelection, oneSelected: true })
                lastTuneSelection = { ...lastTuneSelection, oneSelected: true }
                break;
            case TUNE2_16:
                if (tuneSelection.twoSelected) break;
                invoiceItems.current[4].wasTapped = true
                invoiceItems.current[4].image = selection.image
                lastInvoiceItems[4].wasTapped = true
                lastInvoiceItems[4].image = selection.image
                setTuneSelection({ ...tuneSelection, twoSelected: true })
                lastTuneSelection = { ...lastTuneSelection, twoSelected: true }
                break;
            case TUNE3_16:
                if (tuneSelection.threeSelected) break;
                invoiceItems.current[5].wasTapped = true
                invoiceItems.current[5].image = selection.image
                lastInvoiceItems[5].wasTapped = true
                lastInvoiceItems[5].image = selection.image
                setTuneSelection({ ...tuneSelection, threeSelected: true })
                lastTuneSelection = { ...lastTuneSelection, threeSelected: true }
                break;
            default: break;
        }
                
        invoiceItems.current = [ ...invoiceItems.current ]
    }

    const onCreamSelected = selection => {
        switch (selection?.code) {
            case CREAM_SOFT:
                if (creamSelection.softSelected) break;
                invoiceItems.current[6].wasTapped = true
                invoiceItems.current[6].image = selection.image
                lastInvoiceItems[6].wasTapped = true
                lastInvoiceItems[6].image = selection.image
                setCreamSelection({ ...creamSelection, softSelected: true })
                lastCreamSelection = { ...lastCreamSelection, softSelected: true }
                break;
            case CREAM_SMOOTH:
                if (creamSelection.smoothSelected) break;
                invoiceItems.current[7].wasTapped = true
                invoiceItems.current[7].image = selection.image
                lastInvoiceItems[7].wasTapped = true
                lastInvoiceItems[7].image = selection.image
                setCreamSelection({ ...creamSelection, smoothSelected: true })
                lastCreamSelection = { ...lastCreamSelection, smoothSelected: true }
                break;
            case CREAM_TEXTURED:
                if (creamSelection.texturedSelected) break;
                invoiceItems.current[8].wasTapped = true
                invoiceItems.current[8].image = selection.image
                lastInvoiceItems[8].wasTapped = true
                lastInvoiceItems[8].image = selection.image
                setCreamSelection({ ...creamSelection, texturedSelected: true })
                lastCreamSelection = { ...lastCreamSelection, texturedSelected: true }
                break;
            default: break;
        }
                
        invoiceItems.current = [ ...invoiceItems.current ]
    }

    const onSerumSelected = selection => {
        switch (selection?.code) {
            case SERUM_RADIUS:
                if (serumSelection.radiusSelected) break;
                invoiceItems.current[9].wasTapped = true
                invoiceItems.current[9].image = selection.image
                lastInvoiceItems[9].wasTapped = true
                lastInvoiceItems[9].image = selection.image
                setSerumSelection({ ...serumSelection, radiusSelected: true })
                lastSerumSelection = { ...lastSerumSelection, radiusSelected: true }
                break;
            case SERUM_BLUR:
                if (serumSelection.blurSelected) break;
                invoiceItems.current[10].wasTapped = true
                invoiceItems.current[10].image = selection.image
                lastInvoiceItems[10].wasTapped = true
                lastInvoiceItems[10].image = selection.image
                setSerumSelection({ ...serumSelection, blurSelected: true })
                lastSerumSelection = { ...lastSerumSelection, blurSelected: true }
                break;
            case SERUM_PLUMB:
                if (serumSelection.plumbSelected) break;
                invoiceItems.current[11].wasTapped = true
                invoiceItems.current[11].image = selection.image
                lastInvoiceItems[11].wasTapped = true
                lastInvoiceItems[11].image = selection.image
                setSerumSelection({ ...serumSelection, plumbSelected: true })
                lastSerumSelection = { ...lastSerumSelection, plumbSelected: true }
                break;
            case SERUM_STRATA:
                if (serumSelection.strataSelected) break;
                invoiceItems.current[12].wasTapped = true
                invoiceItems.current[12].image = selection.image
                lastInvoiceItems[12].wasTapped = true
                lastInvoiceItems[12].image = selection.image
                setSerumSelection({ ...serumSelection, strataSelected: true })
                lastSerumSelection = { ...lastSerumSelection, strataSelected: true }
                break;
            case SERUM_TILT:
                if (serumSelection.tiltSelected) break;
                invoiceItems.current[13].wasTapped = true
                invoiceItems.current[13].image = selection.image
                lastInvoiceItems[13].wasTapped = true
                lastInvoiceItems[13].image = selection.image
                setSerumSelection({ ...serumSelection, tiltSelected: true })
                lastSerumSelection = { ...lastSerumSelection, tiltSelected: true }
                break;
            default: break;
        }
                
        invoiceItems.current = [ ...invoiceItems.current ]
    }

    const resetBackup = () => {
        lastMenuSelection = {
            primeSelected: false, tuneSelected: false, creamsSelected: false, serumsSelected: false
        }
        lastPrimeSelection = {
            oneSelected: false, twoSelected: false, threeSelected: false
        }
        lastTuneSelection = {
            oneSelected: false, twoSelected: false, threeSelected: false
        }
        lastCreamSelection = {
            softSelected: false, smoothSelected: false, texturedSelected: false
        }
        lastSerumSelection = {
            radiusSelected: false, blurSelected: false, plumbSelected: false, strataSelected: false, tiltSelected: false
        }
        lastInvoiceItems = [
            { name: "Prime 1", price: 39.00, quantity: 1, wasTapped: false },
            { name: "Prime 2", price: 39.00, quantity: 1, wasTapped: false },
            { name: "Prime 3", price: 39.00, quantity: 1, wasTapped: false },
            { name: "Tune 1", price: 39.00, quantity: 1, wasTapped: false },
            { name: "Tune 2", price: 39.00, quantity: 1, wasTapped: false },
            { name: "Tune 3", price: 39.00, quantity: 1, wasTapped: false },
            { name: "Cream Soft", price: 37.00, quantity: 1, wasTapped: false },
            { name: "Cream Smooth", price: 37.00, quantity: 1, wasTapped: false },
            { name: "Cream Textured", price: 37.00, quantity: 1, wasTapped: false },
            { name: "Serum Radius", price: 35.00, quantity: 1, wasTapped: false },
            { name: "Serum Blur", price: 35.00, quantity: 1, wasTapped: false },
            { name: "Serum Plumb", price: 35.00, quantity: 1, wasTapped: false },
            { name: "Serum Strata", price: 35.00, quantity: 1, wasTapped: false },
            { name: "Serum Tilt", price: 35.00, quantity: 1, wasTapped: false }
        ]
        lastReflectedItems = []
        lastSubtotal = 0
        lastDiscount = 0
        lastTax = 0
        lastShippingAndHandling = 0
        lastTotal = 0
        setTotal(0)

        initialMount = true
    }

    const updateReflectedItems = changedItems => {
        reflectedItems.current = [ ...changedItems ]
        lastReflectedItems = [ ...changedItems ]
    }

    const updateSubtotal = val => {
        subtotal.current = val
        lastSubtotal = val
    }

    const updateDiscount = val => {
        setDiscount(val)
        lastDiscount = val
    }

    const updateTax = val => {
        tax.current = val
        lastTax = val
    }

    const updateShippingAndHandling = val => {
        shippingAndHandling.current = val
        lastShippingAndHandling = val
    }

    const updateTotal = val => {
        setTotal(val)
        lastTotal = val
    }

    const updateInvoiceItems = changedItems => {
        changedItems.forEach(changedItem => {
            invoiceItems.current.forEach((invoiceItem, i) => {
                if (changedItem.name === invoiceItem.name) {
                    invoiceItem.quantity = changedItem.quantity
                    lastInvoiceItems[i].quantity = changedItem.quantity
                }
            })
        })
    }

    return { 
        menuSelection, 
        onPrimeClicked, onTuneClicked, onCreamsClicked, onSerumsClicked,
        onPrimeSelected, onTuneSelected, onCreamSelected, onSerumSelected,
        primeSelection, tuneSelection, creamSelection, serumSelection,
        payStatus, setPayStatus, spinner, setSpinner, statusMessage, setStatusMessage,
        invoiceItems, reflectedItems, updateInvoiceItems, updateReflectedItems, updateSubtotal, updateTax, 
        updateShippingAndHandling, updateTotal, subtotal, discount, tax, shippingAndHandling, total, updateDiscount,
        resetBackup
    }
}

export default useSalonOrder
import React, { createContext, useContext } from 'react'

const SignupForm = createContext(undefined)

export const SignupFormProvider = ({ children, value }) => {
    return (
        <SignupForm.Provider value={value}>{children}</SignupForm.Provider>
    )
}

const useSignupFormContext = () => {
    const context = useContext(SignupForm)

    if (context === undefined) throw new Error('useSignupFormContext must be used within a SignupFormProvider')

    return context;
}

export default useSignupFormContext
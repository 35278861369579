import { 
    SET_ACTIVE_CLIENT, 
    SET_CLIENT_PROPERTY, 
    CLEAR_ACTIVE_CLIENT, 
    BACKUP_ACTIVE_CLIENT,
    RESTORE_ACTIVE_CLIENT
} from '../actions/types'

const initialState = {}

const activeClient = (state = initialState, action) => {
    
    switch (action.type) {
        case SET_ACTIVE_CLIENT:
        {
            const client = { ...action.payload }
            localStorage.setItem('activeClient', JSON.stringify(client))
            return client
        }
        case BACKUP_ACTIVE_CLIENT:
        {
            const client = { ...action.payload }
            localStorage.setItem('activeClient', JSON.stringify(client))
            return client
        }
        case SET_CLIENT_PROPERTY: {
            const client = { ...state }
            client[action.payload.key] = action.payload.value
            localStorage.setItem('activeClient', JSON.stringify(client))
            return client
        }
        case CLEAR_ACTIVE_CLIENT:
            localStorage.removeItem('activeClient')
            return {}
        case RESTORE_ACTIVE_CLIENT: {
            const client = localStorage.getItem('activeClient')
            return JSON.parse(client)
        }
        default:
            return state
    }
}

export default activeClient
import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'

import Caption from './Caption'
import { useTheme } from '../contexts/Theme'

const HorizontalContainer = styled.div`
    position: relative;
    width: ${props => props.width}px;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font: ${props => Math.floor(props.width/10)}px verdana, sans-serif;
    color: ${props => props.color};
    margin-top: ${props => props.marginTop}px;
`

const VerticalContainer = styled.div`
    width: ${props => props.width}px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font: ${props => Math.floor(props.width/4)}px verdana, sans-serif;
    color: ${props => props.color};
    margin-left: ${props => props.margin ? props.margin : 0}px;
    margin-right: ${props => props.margin ? props.margin : 0}px;
`
const VerticalLabel = styled.div`
    width: 100%;
    height: ${props => Math.floor(props.width/4) + 4}px;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Label = styled.div`
    width: ${props => props.width}px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: ${props => props.marginLeft}px;
`

const Icon = styled.div`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    background-color: ${props => props.bgColor};
    background-image: url('${process.env.PUBLIC_URL}/images/${props => props.filename}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    margin-top: ${props => props.marginTop ? props.marginTop : 0}px;   
`

export const SCALP = 'SCALP'
export const TEXTURE = 'TEXTURE'
export const HEIGHT = 'HEIGHT'
export const WIDTH = 'WIDTH'
export const CURL = 'CURL'

const Technical = ({ name, value, width, bgColor, marginTop, margin, flipLabel, caption, vertical }) => {
    // State
    const [ label, setLabel ] = useState()
    const [ filename, setFilename ] = useState()
    const [ tapped, setTapped ] = useState(false)

    const theme = useTheme()
    const { white, rgbDark } = theme
    const color = theme.isDarkBackground() ? white : rgbDark;

    // Set label and icon file name on mount
    useEffect(() => {
        switch (name) {
            case TEXTURE: {
                switch (value) {
                    case 0:
                        setLabel('Coarse')
                        setFilename('hair-coarse')
                        break;
                    case 1:
                        setLabel('Medium')
                        setFilename('hair-medium')
                        break;
                    case 2:
                        setLabel('Fine')
                        setFilename('hair-fine')
                        break;
                    default:
                        throw new Error('Unsupported value for diameter')
                }
                break;
            }
            case SCALP: {
                switch (value) {
                    case 0:
                        setLabel('Dry')
                        setFilename('scalp-dry')
                        break;
                    case 1:
                        setLabel('Medium')
                        setFilename('scalp-normal-to-dry')
                        break;
                    case 2:
                        setLabel('Oily')
                        setFilename('scalp-normal-to-oily')
                        break;
                    default:
                        throw new Error('Unsupported value for emoliency')
                }
                break;
            }
            case HEIGHT: {
                switch (value) {
                    case 0:
                        setLabel('Short')
                        setFilename('facial-height-short')
                        break;
                    case 1:
                        setLabel('Medium')
                        setFilename('facial-height-medium')
                        break;
                    case 2:
                        setLabel('Tall')
                        setFilename('facial-height-long')
                        break;
                    default:
                        throw new Error('Unsupported value for facial height')
                }
                break;
            }
            case WIDTH: {
                switch (value) {
                    case 0:
                        setLabel('Narrow')
                        setFilename('facial-width-narrow')
                        break;
                    case 1:
                        setLabel('Medium')
                        setFilename('facial-width-medium')
                        break;
                    case 2:
                        setLabel('Wide')
                        setFilename('facial-width-wide')
                        break;
                    default:
                        throw new Error('Unsupported value for facial width')
                }
                break;
            }
            case CURL: {
                switch (value) {
                    case 0:
                        setLabel('Low')
                        setFilename('curl-low')
                        break;
                    case 1:
                        setLabel('Medium')
                        setFilename('curl-medium')
                        break;
                    case 2:
                        setLabel('High')
                        setFilename('curl-high')
                        break;
                    default:
                        throw new Error('Unsupported value for hair curl')
                }
                break;
            }
            default:
                throw new Error(`Unsupported technical category ${name}`)
        }
    })

    return (
        <Fragment>
        {
            vertical
            ?
            <VerticalContainer width={width} color={color} margin={margin}>
                <VerticalLabel width={width}>{label}</VerticalLabel>
                <Icon size={width} filename={filename} bgColor={bgColor} marginTop={5} />
            </VerticalContainer>
            :
            <HorizontalContainer onClick={() => setTapped(t => !t)} width={width} color={color} marginTop={marginTop}>
            {
                caption && tapped &&
                <Caption 
                    text={caption} bottomPct={110} leftPct={50} width={240} marginLeft={-120} 
                    stemOffset={flipLabel ? 50 : 150} 
                />
            }
            {
                flipLabel 
                ?
                <Fragment>
                    <Icon size={Math.floor(width/2)} filename={filename} bgColor={bgColor} />
                    <Label width={Math.floor(width/2)} marginLeft={10}>{label}</Label>
                </Fragment> 
                :
                <Fragment>
                    <Label width={Math.floor(width/2)}>{label}</Label>
                    <Icon size={Math.floor(width/2)} filename={filename} bgColor={bgColor} />
                </Fragment>
            }
            </HorizontalContainer>
        }
        </Fragment>
    )
}

export default Technical
import React, { Fragment, useState, useRef } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import Button from '../../../../controls/Button'
import Null from '../../../../controls/Null'
import useSalonOrderContext from '../SalonOrderContext'
import { useTheme } from '../../../../contexts/Theme'
import { tokenConfig, timeoutConfig } from '../../../../../utils'

const PayContainer = styled.div`
    width: ${p => p.pctWidth ? p.pctWidth : '95'}%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
`

const PayLabel = styled.span`
    font: 20px verdana, sans-serif;
    color: ${props => props.color};
    margin-left: 10px;
`

const PayButton = ({ pctWidth }) => {
    // Global state
    const { 
        invoiceItems, reflectedItems, subtotal, tax, shippingAndHandling, total,
        updateDiscount, updateTax, updateTotal, 
        setPayStatus, setSpinner, setStatusMessage, 
        activeMember, salon, authToken 
    } = useSalonOrderContext()

    // Local state
    const [ buttonDisabled, setButtonDisabled ] = useState(false)

    // Other hooks  
    const discountRef = useRef(0)
    const totalRef = useRef(0)
    const taxRate = useRef(0.085)
    const applicableDiscounts = useRef([])
    const theme = useTheme()

    // Destructure the theme
    const headerBg = theme.isDarkBackground() ? 'rgb(225, 225, 225)' : 'rgb(15, 18, 57)';

    // Check salon has been fetched
    if (!salon) return <Null />

    // Destructure the active member and salon
    const { stripeCustomerId, name: salonName, _id: salonId, addr1, addr2, city, state, zip, country } = salon
    const salonPayment = salon?.paymentMethods ? salon.paymentMethods[0] : null;

    // The pay button is meaningless without a saved payment method
    if (!salonPayment) return <Null />

    // Destructure the payment method
    const { cardName, lastFour, stripePaymentMethodId } = salonPayment;

    const getDiscounts = async () => {

        const discountQueryItems = []

        // Populate the items for applicable discounts query
        reflectedItems.current.forEach(item => {
            discountQueryItems.push({
                name: item.name,
                amount: item.price * item.quantity,
                quantity: item.quantity
            })
        })

        // Get applicable discounts
        try {

            const rsp = await axios.post('/api/discounts/applicable', {
                salonName: salonName,
                items: discountQueryItems
            }, timeoutConfig(tokenConfig(authToken)) )

            // Discounts, if any, retrieved, process them ... 

            applicableDiscounts.current = rsp.data

            // Apply each discount to calculate the total discount amount
            applicableDiscounts.current.forEach(discount => {
                if (discount.amountOff) {
                    discountRef.current += discount.amountOff;
                } else if (discount.percentOff) {
                    const amount = subtotal.current * discount.percentOff / 100;
                    discountRef.current += amount;
                }
            })

            // Adjust the tax and total
            if (applicableDiscounts.current.length) {
                discountRef.current > subtotal.current && (discountRef.current = subtotal.current);
                updateDiscount(discountRef.current)
                updateTax(taxRate.current * (subtotal.current - Math.round((discountRef.current + Number.EPSILON) * 100) / 100))
                totalRef.current = subtotal.current - discountRef.current + tax.current;
                updateTotal(totalRef.current)
            }

        } catch(err) { 
            /* Pass on this error */ 
        }
    }

    const onPay = async () => {

        // Use refs here in case the state changes
        totalRef.current = total

        // Disable the pay button
        setButtonDisabled(true)

        // Get discounts and apply to totals
        await getDiscounts(applicableDiscounts)
        
        let paymentIntent;

        // This goes to Stripe as a payment intent
        const payment = {
            customer: stripeCustomerId,
            amount: Math.round(totalRef.current.toFixed(2)*100),
            paymentMethod: stripePaymentMethodId,
            offSession: true,
            description: "Back bar order",
            receiptEmail: activeMember.email,
            metadata: {
                member: activeMember.name,
                salon: salonName,
                discount: `${discountRef.current.toFixed(2)}`,
                tax: `${tax.current.toFixed(2)}`
            }
        }

        // Package the address
        const shippingAddress = { addr1, addr2, city, state, zip, country }
        
        // This is for the orders collection
        const order = {
            orderDate: new Date().toISOString(),
            clientId: salonId,
            email: activeMember.email, // So member gets email notification
            shippingAddress,
            items: [],
            clientName: salonName,
            subtotal: subtotal.current.toFixed(2),
            discount: discountRef.current.toFixed(2),
            shipping: shippingAndHandling.current.toFixed(2),
            tax: tax.current.toFixed(2),
            total: totalRef.current.toFixed(2),
            stripePaymentMethodId,
            salonOrder: true
        }
        
        // Populate the order items array
        reflectedItems.current.forEach(item => {
            order.items.push({
                itemName: item.name,
                unitPrice: item.price,
                quantity: item.quantity,
                itemTotal: (item.price * item.quantity).toFixed(2),
                image: invoiceItems.current.filter(i => i.name === item.name).map(m => m.image)?.[0]
            })
            payment.metadata[`${item.name}_quantity`] = `${item.quantity}`
            payment.metadata[`${item.name}_amount`] = `${(item.price * item.quantity).toFixed(2)}`
        })
        
        try {

            setPayStatus(true)
            setSpinner(true)

            // Create the stripe payment intent
            let rsp = await axios.post('/pay/payment-intents/', payment, timeoutConfig(tokenConfig(authToken)) )

            // Payment intent successfully created ... 

            paymentIntent = rsp.data.id

            // Create the order in the array DB
            rsp = await axios.post('/api/orders/', order, timeoutConfig(tokenConfig(authToken)))

            // Order successfully saved ...

            setSpinner(false)
            setStatusMessage(
                'The purchase has been successful. ' +
                (applicableDiscounts.current.length ? 
                `Discounts ${applicableDiscounts.current.reduce((a, d, i) => a += `${i > 0 ? ', ' : ''}${d.code}`, '')} were applied. ` : '') +
                `${cardName} ending in ${lastFour} will be charged. ` + 
                `The stylist will receive an email confirmation in the next few minutes.`)

        } catch(err) {
            if (err.response) { // status 400, 402 (Stripe error), 404 (not found), 500
                switch (err.response.status) {
                    case 500: // Server error
                        setStatusMessage('Server error, order not completed.')
                        console.log('Error! ', 'Server error')
                        break;
                    case 402: { // Stripe payment intent error
                        const errObj = err.response.data.error
                        setStatusMessage(`Error: ${errObj.type}, order not completed.`)
                        break;
                    }
                    default: // 400, 404 (Client not found), session expired
                        console.log('Error! ', err.response.data)
                        setStatusMessage(`${err.response.data.message}, order not completed.`)
                }
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, order not completed, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, order not completed`)
            }

            setSpinner(false)

            // Cancel the payment intent
            paymentIntent && axios.post(`/pay/payment-intents/${paymentIntent}/cancel`)
        }
    }

    return (
        <Fragment>
        {
            (total > 0) && salon && salonPayment &&
            <PayContainer pctWidth={pctWidth}>
                <Button height={50} width={120} onClick={onPay} label='Pay' disabled={buttonDisabled} />
                <PayLabel color={headerBg}>{`with ${cardName} ****-${lastFour}`}</PayLabel>
            </PayContainer>
        }
        </Fragment>
    )
}

export default PayButton
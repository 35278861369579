import React from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'
import { useLandscape, usePhone } from '../../hooks/useMedia'

const StyledModal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: none;
    border-radius: 15px;
    background-color: ${props => props.bgColor};
    color: ${props => props.color};
    box-shadow: 10px 10px 2px ${props => props.shadowColor};
    overflow: hidden;
    z-index: 10;

    &.modal-enter {
        opacity: 0;
        transform: scale(0.5);
    }
    &.modal-enter-active {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%);
        transition: opacity 500ms, transform 500ms;
    }
    &.modal-exit {
        opacity: 1;
    }
    &.modal-exit-active {
        opacity: 0;
        transform: scale(0.5) translateY(0);
        transition: opacity 500ms, transform 500ms;
    }
`

const Modal = ({ children }) => {
    const theme = useTheme()

    const { white, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const [ color, bgColor, shadowColor ] = 
        theme.isLightBackground() ? [ rgbDark, white, rgbaDarkBg ] : [ white, rgbDark, rgbaWhiteBg ];
    
    return (
        <StyledModal color={color} bgColor={bgColor} shadowColor={shadowColor}>
        { 
            children 
        }
        </StyledModal>
    )
}

export default Modal
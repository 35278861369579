import React from 'react'

import Footer from './parts/Footer'
import OrderHeader from './parts/OrderHeader'
import OrderRibbon from './parts/OrderRibbon'
import OrderInvoice from './parts/OrderInvoice'
import PayButton from './parts/PayButton'
import Checkout from './parts/Checkout'
import PayStatus from './parts/PayStatus'
import AddressMissingPrompt from './parts/AddressMissingPrompt'
import { OrderProvider } from './OrderContext'
import useOrder from './useOrder'
import useActiveMember from '../../../../hooks/useActiveMember'
import useCurrentMember from '../../../../hooks/useCurrentMember'
import useCurrentClient from '../../../../hooks/useCurrentClient'
import useRecoverObjects from '../../../../hooks/useRecoverObjects'

import Null from '../../../controls/Null'

// The proxy calls the hooks for this page and sets up its context
//
const OrderProxy = ({ children }) => {
    const { ...rest } = useOrder()
    const { activeMember } = useActiveMember()
    const { currentMember, isCurrentMember } = useCurrentMember()
    const { currentClient, ...restClient } = useCurrentClient()

    // Check for and recover from page refresh
    if (useRecoverObjects({ activeMember, currentMember, currentClient })) return <Null />

    return (
        <OrderProvider value={{ 
            activeMember, currentMember, isCurrentMember,
            currentClient, ...restClient, ...rest 
        }}>{children}</OrderProvider>
    )
}

// Component parts of the page, can be laid out any way we want
//
OrderProxy.Header = OrderHeader
OrderProxy.Ribbon = OrderRibbon
OrderProxy.Invoice = OrderInvoice
OrderProxy.PayButton = PayButton
OrderProxy.Checkout = Checkout
OrderProxy.PayStatus = PayStatus
OrderProxy.Footer = Footer
OrderProxy.AddressMissingPrompt = AddressMissingPrompt

export default OrderProxy
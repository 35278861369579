import React from 'react'

import SignupHeader from './parts/Header'
import Status from './parts/Status'
import Form from './parts/SignupForm'
import useSignup from './useSignup'
import { SignupProvider } from './SignupContext'
import useActiveMember from '../../../../hooks/useActiveMember'
import useRecoverObjects from '../../../../hooks/useRecoverObjects'
import useLogoff from '../../../../hooks/useLogoff'

import Null from '../../../controls/Null'

// The proxy calls the hooks for this page and sets up its context
//
const SignupProxy = ({ children }) => {
    const { activeMember } = useActiveMember()
    const { ...signup } = useSignup()
    const { loggingOff, logoff } = useLogoff({ activeMember })

    // Check for and recover from page refresh
    if (useRecoverObjects({ activeMember })) return <Null />

    // Check for logoff
    if (loggingOff) return <Null />

    return (
        <SignupProvider value={{ logoff, ...signup }}>{children}</SignupProvider>
    )
}

// Component parts of the page, can be laid out any way we want
//
SignupProxy.Header = SignupHeader
SignupProxy.Status = Status
SignupProxy.Form = Form

export default SignupProxy
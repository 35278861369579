import React, { Fragment, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Separator from '../../../../controls/Separator'
import SwipeGroup from '../../../../controls/SwipeGroup'
import { useTheme } from '../../../../contexts/Theme'
import useHomeContext from '../HomeContext'

const Container = styled.div`
    width: ${p => p.pctWidth ? p.pctWidth : '90'}%;
    height: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

const Title = styled.div`
    width: 100%;
    height: 40px;
    text-align: center;
    font: 20px verdana, sans-serif;
    color: ${props => props.color};
`

const HaircutAndRelated = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
`

const CurrentHaircutFrame = styled.div`
    position: relative;
    width: 190px;
    height: 190px;
    overflow: hidden;
`

const NoPhoto = styled.div`
    width: 100%;
    height: 100%;
    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: 18px verdana, sans-serif;
    color: rgba(255, 255, 255, 0.6);
`

const HaircutMenu = styled.div`
    width: 100px;
    height: 190px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
`

const MenuItem = styled.div`
    width: 100%;
    height: 60px;
    margin-top: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
`

const LinkTo = styled.img`
    width: ${props => props.width}px;
    height: auto;
    &.disabled { opacity: 0.5; }
`

const Go = styled.img`
    width: 30px;
    height: 30px;
    &.disabled { opacity: 0.5; }
`

const CurrentHaircut = ({ pctWidth }) => {
    // Hooks
    const toOrder = useRef()
    const { activeClient } = useHomeContext()
    const theme = useTheme()

    // Destructure the theme
    const { white, dark, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const [ textColor, borderColor, iconColor ] = theme.isDarkBackground() ? 
        [ white, rgbaWhiteBg, white ] : 
        [ rgbDark, rgbaDarkBg, dark ];

    // Destructure the client
    const { haircuts } = activeClient
    const [ haircut ] = haircuts

    return (
        <Fragment>
            <Link ref={toOrder} to="/client-order" style={{display: 'none'}} />
            <Container pctWidth={pctWidth}>
                <Title color={textColor}>Your most recent haircut</Title>
                <HaircutAndRelated>
                    <CurrentHaircutFrame>
                    {
                        haircuts.length > 0 
                        ?
                        (
                            haircuts[0].photos.length > 0 
                            ?
                            <SwipeGroup size={190} photos={haircuts[0].photos} /> 
                            :
                            <NoPhoto>{'No photo available'}</NoPhoto>
                        )
                        :
                        <NoPhoto>{'No photo available'}</NoPhoto> 
                    }
                    </CurrentHaircutFrame>
                    <HaircutMenu>
                        <MenuItem>
                            <LinkTo 
                                width={50} onClick={haircut ? () => toOrder.current.click() : null} 
                                src={`${process.env.PUBLIC_URL}/images/shopping-cart-${iconColor}.png`} 
                                className={haircut ? '' : 'disabled'}  
                            />
                            <Go 
                                onClick={haircut ? () => toOrder.current.click() : null}
                                src={`${process.env.PUBLIC_URL}/images/go-${iconColor}.png`} 
                                className={haircut ? '' : 'disabled'}   
                            />
                        </MenuItem>
                    </HaircutMenu>
                </HaircutAndRelated>
            </Container>
            <Separator color={borderColor} marginTop={15} />
        </Fragment>
    )
}

export default CurrentHaircut
import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'

const IncrementContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center; 
    font: 20px verdana, sans-serif;
    color: ${props => props.color};
`

const Decrement = styled.div`
    width: 35px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.bgColor};
    border-radius: 50px 0px 0px 50px;
    border-width: 5px;
    border-style: outset none outset outset;

    &.pressed { border-style: inset; }
`

const Increment = styled.div`
    width: 35px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.bgColor};
    border-radius: 0px 50px 50px 0px;
    border-width: 5px;
    border-style: outset outset outset none;

    &.pressed { border-style: inset; }
`

const Value = styled.div`
    width: auto;
    height: 30px;
    padding: 0 4px 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.bgColor};
    border-radius: 0;
    border-width: 5px 0 5px 0;
    border-style: outset none outset none;
    font: 22px verdana, sans-serif;
`

const Incrementer = ({ onChange, val }) => {
    // State
    const [ value, setValue ] = useState(val !== undefined ? val : 1)
    const [ decPressed, setDecPressed ] = useState(false)
    const [ incPressed, setIncPressed ] = useState(false)
    
    // Other hooks
    const theme = useTheme()
    const { white, rgbDark } = theme
    const [ color, bgColor ] = theme.isDarkBackground() ?
        [ rgbDark, 'rgb(112, 146, 190)' ] :
        [ white, 'rgb(66, 48, 129)']

    // Decrement Handlers 
    // NOTE: touch handles both touch and mouse,
    // mouse handles only mouse
    const onDecTouchStart = () => {
        setDecPressed(true)
    }
    const onDecTouchEnd = () => setDecPressed(false)
    const onDecMouseDown = () => {
        setDecPressed(true)
        if (value > 0) {
            setValue(v => v - 1)
            onChange(value - 1)
        }
    }
    const onDecMouseUp = () => setDecPressed(false)

    // Increment Handlers
    const onIncTouchStart = () => {
        setIncPressed(true)
    }
    const onIncTouchEnd = () => setIncPressed(false)
    const onIncMouseDown = () => {
        setIncPressed(true)
        setValue(v => v + 1)
        onChange(value + 1)
    }
    const onIncMouseUp = () => setIncPressed(false)

    return (
        <Fragment>
            <IncrementContainer color={color}>
                <Decrement 
                    bgColor={bgColor} className={decPressed ? 'pressed' : ''}
                    onTouchStart={onDecTouchStart} onTouchEnd={onDecTouchEnd} 
                    onMouseDown={onDecMouseDown} onMouseUp={onDecMouseUp} 
                >
                    -
                </Decrement>
                <Value bgColor={bgColor}>{value}</Value>
                <Increment 
                    bgColor={bgColor} className={incPressed ? 'pressed' : ''}
                    onTouchStart={onIncTouchStart} onTouchEnd={onIncTouchEnd}
                    onMouseDown={onIncMouseDown} onMouseUp={onIncMouseUp} 
                >
                    +
                </Increment>
            </IncrementContainer>
        </Fragment>
    )
}

export default Incrementer;
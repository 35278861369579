import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import SwipeGroup from './SwipeGroup'
import Portal from './Portal'
import { useTheme } from '../contexts/Theme'
import { standardDate } from './DatePicker'

const Container = styled.div`
    position: absolute;
    bottom: -200px;
    width: 250px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 8;

    &.left { left: 90px; }
    &.right { right: 90px; }
`

const Stack = styled.div`
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &.haircut-enter {
        opacity: 0;
        transform: scale(0.1);
    }
    &.haircut-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 500ms, transform 500ms;
    }
    &.haircut-exit {
        opacity: 1;
    }
    &.haircut-exit-active {
        opacity: 0;
        transform: scale(0.1);
        transition: opacity 500ms, transform 500ms;
    }
`

const HaircutName = styled.div`
    width: 100%;
    height: 50px;
    color: ${props => props.color};
    font: 24px verdana, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`

const HaircutDate = styled.div`
    width: 100%;
    height: 40px;
    color: ${props => props.color};
    font: 20px verdana, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
`

const HaircutType = styled.div`
    width: 70%;
    height: auto;
    font: 20px verdana, sans-serif;
    color: ${props => props.color};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`

const Label = styled.div`
    width: 60px;
    height: 40px;
`

const Button = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: ${props => props.bgColor};
    background-image: url('${process.env.PUBLIC_URL}/images/${props => props.image}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const PhotoContainer = styled.div`
    width: 190px;
    height: 190px;
    margin: 20px;
`

const NoPhoto = styled.div`
    width: 100%;
    height: 100%;
    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: 18px verdana, sans-serif;
    color: rgba(255, 255, 255, 0.6);
`

const Haircut = ({ id, haircut, left, right, getRefToClick }) => {
    // State
    const [ visible, setVisible ] = useState(false)

    // Theme
    const theme = useTheme()
    const { rgbDark } = theme

    // Other hooks
    const stack = useRef()
    getRefToClick(stack)

    //const [ imageFile, label ] = technicalsOptions(haircut.technicals)
    
    return (
        <Portal id={id}>
            <Container
                ref={stack} 
                className={left ? 'left' : (right ? 'right' : '')}
                onClick={() => setVisible(!visible)}
            >
                <CSSTransition 
                    in={visible} 
                    classNames="haircut" 
                    timeout={500}
                    unmountOnExit
                >
                    <Stack>
                        <HaircutName color={rgbDark}>{haircut.haircutName}</HaircutName>
                        <HaircutDate color={rgbDark}>{standardDate(haircut.haircutDate)}</HaircutDate>
                        {/*<HaircutType color={rgbDark}>
                            <Label>{label}</Label>
                            <Button bgColor={'rgb(253, 91, 45)'} image={imageFile} />
                        </HaircutType>*/}
                        <PhotoContainer>
                        {
                            haircut.photos.length > 0 ? 
                                <SwipeGroup size={190} photos={haircut.photos} /> :
                                <NoPhoto>{'No photo available'}</NoPhoto> 
                        }
                        </PhotoContainer>
                    </Stack>
                </CSSTransition>
            </Container>
        </Portal>
    )
}

const technicalsOptions = value => {
    switch(value) {
        case 0: return [ 'bob-cut.png', 'bob']
        case 1: return [ 'layered-cut.png', 'layers' ]
        case 2: return [ 'blunt-cut.png', 'blunt' ]
        case 3: return [ 'pixie-cut.png', 'pixie' ]
        case 4: return [ 'shag-cut.png', 'shag' ]
        default: break;
    }
}

export default Haircut
import { useRef, useEffect } from 'react'

// Helper function, creates DOM element to be used as root
//
const createRootElement = id => {
    const rootContainer = document.createElement('div')
    rootContainer.setAttribute('id', id)
    return rootContainer
}

// Helper, appends element at end of document body
//
const addRootElement = rootElement => {
    document.body.insertBefore(
        rootElement,
        document.body.lastElementChild.nextElementSibling
    )
}

// Hook to create a React portal
//
const usePortal = id => {
    const rootRef = useRef(null)

    useEffect(() => {
        // See if parent exists, if not create it
        const existingParent = document.querySelector(`#${id}`)
        const parent = existingParent || createRootElement(id)

        // If created, now append it
        if (!existingParent) addRootElement(parent)

        // Attach the root element to the parent
        parent.appendChild(rootRef.current)

        // Cleanup function
        return () => {
            rootRef.current.remove()
            if (!parent.childCount) parent.remove()
        }

    }, [id])

    const getRootElement = () => {
        if (!rootRef.current) rootRef.current = document.createElement('div')
        return rootRef.current
    }

    return getRootElement()
}

export default usePortal
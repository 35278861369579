import React, { Fragment, useState, useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import axios from 'axios'
import { Form } from 'reactstrap'

import Header from '../controls/Header'
import Column from '../controls/Column'
import HeaderDesktop from '../media/HeaderDesktop'
import SideMenu from '../controls/SideMenu'
import Wallpaper from '../controls/Wallpaper'
import Null from '../controls/Null'
import MessageBox from '../controls/MessageBox'
import ErrorMessage from '../controls/ErrorMessage'
import IconInput from '../controls/IconInput'
import Switch from '../controls/Switch'
import Submit from '../controls/Submit'
import { usePhone, useLandscape } from '../../hooks/useMedia'
import useSwitchTheme from '../../hooks/useSwitchTheme'
import {
    RESTORE_ACTIVE_ADMIN, 
    RESTORE_TOKEN
} from '../../redux/actions/types'
import { isEmpty, tokenConfig, timeoutConfig } from '../../utils'
import { classicLight, classicDark } from '../../overlayColors'

const Halves = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`

const Half = styled.div`
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const Switches = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
`

const lightOverlay = classicLight
const darkOverlay = classicDark

const handleInputChange = (e, key, dispatch) => {
    dispatch({
        type: SET_NEW_PROPERTY,
        payload: {
            key: key,
            value: e.target.value
        }
    })
}

const SET_NEW_PROPERTY = "SET_NEW_PROPERTY"
const memberReducer = (member, action) => {
    switch (action.type) {
        case SET_NEW_PROPERTY: 
            member = { ...member, [action.payload.key]: action.payload.value }
            return member
        default: 
            return member
    }
}

const AddMember = () => {
    // Local state
    const [ newMember, memberDispatch ] = useReducer(memberReducer, { isAdmin: false, isBuyer: false })
    const [ messageBoxVisible, setMessageBoxVisible ] = useState(false)
    const [ spinnerVisible, setSpinnerVisible ] = useState(false)
    const [ statusMessage, setStatusMessage ] = useState('')
    const [ errorMessage, setErrorMessage ] = useState(null)

    // Other hooks
    const dispatch = useDispatch()
    const activeAdmin = useSelector(state => state.activeAdmin)
    const authToken = useSelector(state => state.token)
    const isPhone = usePhone()
    const isLandscape = useLandscape()
    useSwitchTheme()
    
    // Check for and recover from page refresh
    if (isEmpty(activeAdmin)) {
        // Restore active admin and token from local storage post-refresh
        dispatch({ type: RESTORE_ACTIVE_ADMIN })
        dispatch({ type: RESTORE_TOKEN })

        // Don't want to render with missing data
        return <Null />
    }

    // Handlers
    const onNameChange = e => handleInputChange(e, 'name', memberDispatch)
    const onUseridChange = e => {
        setErrorMessage(null)
        handleInputChange(e, 'userid', memberDispatch)
    }
    const onEmailChange = e => handleInputChange(e, 'email', memberDispatch)
    const onPhoneChange = e => handleInputChange(e, 'phone', memberDispatch)
    const onAdminChange = e => handleInputChange(e, 'isAdmin', memberDispatch)
    const onBuyerChange = e => handleInputChange(e, 'isBuyer', memberDispatch)

    const onSubmit = async e => {
        e.preventDefault()

        // Convert switches
        newMember.isAdmin = Boolean(newMember.isAdmin)
        newMember.isBuyer = Boolean(newMember.isBuyer)
        
        try {
            
            setMessageBoxVisible(true)
            setSpinnerVisible(true)

            // Check userid availability
            await axios.get(`/api/login/${newMember.userid}`, timeoutConfig({}))
            
            // Save the new member to the DB
            const rsp = await axios.post('/api/members', newMember, timeoutConfig(tokenConfig(authToken)))

            console.log(rsp)
            
            setSpinnerVisible(false)
            setStatusMessage(`Member successfully added.`)

        } catch (err) {
            
            if (err.response) {
                switch (err.response.status) {
                    case 403: // Userid in use
                        setErrorMessage(err.response.data.error)
                        setStatusMessage(`${err.response.data.error} and is invalid, see above.`)
                        break;
                    case 500:
                        setStatusMessage('Server error, member not registered.')
                        console.log('Error! ', 'Server error')
                        break;
                    case 412: // Twilio error
                        setStatusMessage('Message send error, see webmaster for access code.')
                        break;
                    default: // 400, session expired
                        console.log('Error! ', err.response.data)
                        setStatusMessage(`${err.response.data.message}, member not registered.`)
                }
            } else if (err?.code === 'ECONNABORTED') { // Timeout error
                setStatusMessage(`Server took too long to respond, member not registered, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, member not created`)
            }            

            setSpinnerVisible(false)
        }
    }

    const ErrorMessageOrNot = errorMessage ? ErrorMessage : Null;

    return (
        <Fragment>
            <Wallpaper />
            <CSSTransition 
                in={messageBoxVisible} 
                classNames="message" 
                timeout={1000}
                unmountOnExit
            >
                <MessageBox  
                    spinner={spinnerVisible} message={statusMessage}
                    lightBackground={lightOverlay} darkBackground={darkOverlay}
                    onOk={()=> setMessageBoxVisible(false)} label='Ok'
                    width='320' height='240' 
                />
            </CSSTransition>
            { /* Header section */
                isPhone
                ?
                <Fragment>
                    <SideMenu top={isLandscape ? 50 : 0} />
                    <Header arrow={{
                        color: 'yellow',
                        to: '/admin-home',
                        destination: 'Home'
                    }} title='Add Member' separator />
                </Fragment>
                :
                <HeaderDesktop height={100} leftContent={<Header arrow={{
                    color: 'yellow',
                    to: 'admin-home',
                    destination: 'Home'
                }} title='Add Member' />} />
            }
            <ErrorMessageOrNot>{errorMessage}</ErrorMessageOrNot>
            <Form onSubmit={onSubmit}>
            {
                isLandscape
                ?
                <Column>
                    <Halves>
                        <Half>           
                            <IconInput 
                                type='text' 
                                iconUrl='name' 
                                name='name'
                                value={newMember.name} 
                                placeholder='Member name (required)'
                                marginTop={30} 
                                onChange={onNameChange}
                                required
                            />
                            <IconInput 
                                type='text' 
                                iconUrl='userid' 
                                name='userid'
                                value={newMember.userid} 
                                placeholder='Member userid (required)'
                                marginTop={30} 
                                onChange={onUseridChange}
                                required
                            />
                        </Half>
                        <Half>
                            <IconInput 
                                type='text' 
                                iconUrl='email' 
                                name='email'
                                value={newMember.email} 
                                placeholder='Member email (required)'
                                marginTop={30} 
                                onChange={onEmailChange}
                                required
                            />
                            <IconInput 
                                type='text' 
                                iconUrl='phone' 
                                name='phone'
                                value={newMember.phone} 
                                placeholder='Member phone (required)'
                                marginTop={30} 
                                onChange={onPhoneChange}
                                required
                            />
                            <Switches>
                                <Switch 
                                    width={60} height={30} marginTop={30} marginRight={20} label='Admin'
                                    value={newMember.isAdmin} onChange={onAdminChange} 
                                />
                                <Switch 
                                    width={60} height={30} marginTop={30} label='Buyer'
                                    value={newMember.isBuyer} onChange={onBuyerChange}  
                                />
                            </Switches>
                        </Half>
                    </Halves>
                    <Submit width={'100'} marginTop={'40'} />
                </Column>
                :
                <Column>           
                    <IconInput 
                        type='text' 
                        iconUrl='name' 
                        name='name'
                        value={newMember.name} 
                        placeholder='Member name (required)'
                        marginTop={30} 
                        onChange={onNameChange}
                        required
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='userid' 
                        name='userid'
                        value={newMember.userid} 
                        placeholder='Member userid (required)'
                        marginTop={30} 
                        onChange={onUseridChange}
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='email' 
                        name='email'
                        value={newMember.email} 
                        placeholder='Member email (required)'
                        marginTop={30} 
                        onChange={onEmailChange}
                        required
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='phone' 
                        name='phone'
                        value={newMember.phone} 
                        placeholder='Member phone (required)'
                        marginTop={30} 
                        onChange={onPhoneChange}
                    />
                    <Switches>
                        <Switch 
                            width={60} height={30} marginTop={30} marginRight={20} label='Admin'
                            value={newMember.isAdmin} onChange={onAdminChange} 
                        />
                        <Switch 
                            width={60} height={30} marginTop={30} label='Buyer'
                            value={newMember.isBuyer} onChange={onBuyerChange}  
                        />
                    </Switches>
                    <Submit width={'100'} marginTop={'40'} />
                </Column> 
            }
            </Form>
        </Fragment>
    )
}

export default AddMember
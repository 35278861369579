import React from 'react'

import HistoryLadder, { clientHistorySetup, clientHistoryInserts } from '../../../../controls/HistoryLadder'
import useHomeContext from '../HomeContext'

const Activity = ({ marginTop }) => {
    // Hooks
    const { activeClient } = useHomeContext()

    return (
        <HistoryLadder 
            itemGroups={clientHistorySetup({ client: activeClient })}
            postMerge={clientHistoryInserts} 
            height={300} marginTop={marginTop ? marginTop : 20} marginBottom={100} title="Activity" 
        />
    )
}

export default Activity
import styled from 'styled-components'

const Separator = styled.div`
    width: 80%;
    height: 2px;
    margin-top: ${props => props.marginTop ? props.marginTop : '0'}px;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'}px;
    border-bottom-width: 3px;
    border-bottom-style: groove;
    border-bottom-color: ${props => props.color};
`

export default Separator
import React, { Fragment, useState } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'
import {
    CREATOR_NAME,
    CONTINUUM_NAME,
    PRIME_NAME,
    TUNE_NAME,
    CUT_NAME
} from '../../constants'

const StoryContainer = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: ${p => p.textColor};
`

const MenuPanel = styled.div`
    width: 25%;
    height: 100%;
    border-right: 3px groove ${props => props.borderColor};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const ContentPanel = styled.div`
    position: relative;
    width: 75%;
    height: 100%;
`

const LinkTo = styled.div`
    width: 100%;
    height: 50px;
    font: 24px verdana, sans-serif;
    text-indent: 40px;
    margin-top: ${p => p.marginTop}px;
`

const MoreInfo = styled.div`
    width: 80%;
    height: 100px;
    font: 20px verdana, sans-serif;
    margin-left: 40px;
    margin-top: ${p => p.marginTop}px;
`

const Narrative = styled.div`
    width: ${p => p.percentWidth ? p.percentWidth : '100'}%;
    height: auto;
    font: 24px verdana, sans-serif;
    text-align: ${p => p.textAlign ? p.textAlign : 'center'};
    margin-top: ${p => p.marginTop}px;
    margin-left: ${p => p.marginLeft}px;

    & h1 { 
        font: 32px verdana, sans-serif;
        text-decoration: underline; 
    }
`

const Dot = styled.img`
    width: ${p => p.width}px;
    height: auto;
    border: none;
    outline: none;
`

const ProductGrid = styled.div`
    width: 85%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 80px;
`

const ProductCell = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`

const ProductImage = styled.img`
    width: ${p => p.width}px;
    height: auto;
    border: none;
    outline: none;
`

const ProductDescription = styled.div`
    width: 180px;
    height: auto;
    font: 20px verdana, sans-serif;
    text-align: left;
    margin-top: 25px;
    margin-left: 20px;

    & h2 { 
        font: 28px verdana, sans-serif;
        margin-top: 0;
        margin-bottom: 0; 
    }
`

const Copyright = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 80%;
    height: auto;
    font: 16px verdana, sans-serif;
    text-align: right;
    margin-top: ${p => p.marginTop}px;
`

const Story = ({ width, height, vertical }) => {
    // State
    const [ arrayNarrative, setArrayNarrative ] = useState({
        arrayStory: true, 
        arrayCreator: false,
        arrayContinuum: false,
        oneArray: false
    })

    // Other hooks
    const theme = useTheme()

    // Destructure the theme
    const { white, dark, rgbDark, rgbaWhiteBg, rgbaDarkBg } = theme
    const [ iconColor, bgColor, borderColor, textColor ] = theme.isDarkBackground() 
        ? 
        [ white, rgbaWhiteBg, rgbaWhiteBg, white ] 
        : 
        [ dark, rgbaDarkBg, rgbaDarkBg, rgbDark ];

        // Handlers for Array narrative
        const setArrayStory = () => setArrayNarrative({
            arrayStory: true, 
            arrayCreator: false,
            arrayContinuum: false,
            oneArray: false
        })
        const setArrayCreator = () => setArrayNarrative({
            arrayStory: false, 
            arrayCreator: true,
            arrayContinuum: false,
            oneArray: false
        })
        const setArrayContinuum = () => setArrayNarrative({
            arrayStory: false, 
            arrayCreator: false,
            arrayContinuum: true,
            oneArray: false
        })
        const setOneArray = () => setArrayNarrative({
            arrayStory: false, 
            arrayCreator: false,
            arrayContinuum: false,
            oneArray: true
        })

    return (
        <StoryContainer width={width} height={height} textColor={textColor}>
            <MenuPanel borderColor={bgColor}>
                <LinkTo onClick={setArrayStory} marginTop={80}>ARRAY STORY</LinkTo>
                <LinkTo onClick={setArrayCreator}>{`${CREATOR_NAME}`}</LinkTo>
                <LinkTo onClick={setArrayContinuum}>{`${CONTINUUM_NAME}`}</LinkTo>
                <LinkTo onClick={setOneArray}>1ARRAY</LinkTo>
                <MoreInfo marginTop={80}>
                    For more information, please contact us at <a href='https://info@1array.com'>info</a>
                </MoreInfo>
            </MenuPanel>
            <ContentPanel>
            {
                arrayNarrative.arrayStory
                &&
                <Fragment>
                    <Narrative>
                        <h1>ARRAY&trade; STORY</h1>
                        <Narrative marginTop={60}>
                            <p>A science behind the service.</p>
                            <Dot width={8} src={`${process.env.PUBLIC_URL}/images/dot-${iconColor}.png`} />
                            <p>A service that became a product.</p>
                            <Dot width={8} src={`${process.env.PUBLIC_URL}/images/dot-${iconColor}.png`} />
                            <p>A product with a unique version.</p>
                        </Narrative>
                    </Narrative>
                    <Copyright>{"Omni Creator, Inc. New York, New York USA"}</Copyright>
                </Fragment>
            }
            {
                arrayNarrative.arrayCreator
                &&
                <Fragment>
                    <Narrative>
                        <h1>{`${CREATOR_NAME}`}&trade;</h1>
                        <Narrative marginLeft={80} percentWidth={85} textAlign='left'>
                            <p>Beauty services and products re-invented: All Array cut services are
                            done with instruments and applied techniques. Patent-pending science
                            is the foundation of unique synthesized molecules that activate methods
                            and systems and produce an Original Artistic Service (OAS).</p>
                            <p>Individualized products are born of this process. The service industry
                            moves on-line and in digital formats; both the art and enterprise of the
                            professional services industry are forever changed.</p>
                        </Narrative>
                    </Narrative>
                    <Copyright>{"Omni Creator, Inc. New York, New York USA"}</Copyright>
                </Fragment>
            }
            {
                arrayNarrative.arrayContinuum
                &&
                <Fragment>
                    <Narrative>
                        <h1>{`${CONTINUUM_NAME}`}&trade;</h1>
                        <Narrative marginLeft={80} percentWidth={85} textAlign='left'>
                            <p>Original Artistic Services (OAS) establish the requirements for client
                            home use products. The client’s products optimize their versions of new
                            categories branded to their unique Array, the stylist who created it and
                            the salon that delivered the experience.</p>
                        </Narrative>
                        <ProductGrid>
                            <ProductCell>
                                <ProductImage width={60} src={`${process.env.PUBLIC_URL}/images/shampoo-bottle.png`} />
                                <ProductDescription>
                                    <h2>{PRIME_NAME}&trade;</h2>
                                    Cleansing science designed to reinforce your cut.
                                </ProductDescription>
                            </ProductCell>
                            <ProductCell>
                                <ProductImage width={60} src={`${process.env.PUBLIC_URL}/images/conditioner-bottle.png`} />
                                <ProductDescription>
                                    <h2>{TUNE_NAME}&trade;</h2>
                                    Conditioning to nurture your hair.
                                </ProductDescription>
                            </ProductCell>
                            <ProductCell>
                                <ProductImage width={65} src={`${process.env.PUBLIC_URL}/images/cut-bottle.png`} />
                                <ProductDescription>
                                    <h2>{CUT_NAME}&trade;</h2>
                                    Styling science to enhance your stylist’s artistic direction.
                                </ProductDescription>
                            </ProductCell>
                        </ProductGrid>
                    </Narrative>
                    <Copyright>{"Omni Creator, Inc. New York, New York USA"}</Copyright>
                </Fragment>
            }
            {
                arrayNarrative.oneArray
                &&
                <Fragment>
                    <Narrative>
                        <h1>1ARRAY&trade;</h1>
                        <Narrative marginLeft={80} percentWidth={85} textAlign='left'> 
                            <p>Array signals the end of retail and digital shopping for mass-branded
                            products. As the merchant for services and products, we engineer
                            performance-based platforms to move commerce out of the anonymous
                            selection process and into OAS digital systems.</p>
                            <p>Array as merchant to OAS transactions feeds a revolution in planning,
                            purchasing, fill assembly and logistics. Service and product supply
                            chains fully integrated with manufacturing, distribution and commerce.</p>
                        </Narrative>
                    </Narrative>
                    <Copyright>{"Omni Creator, Inc. New York, New York USA"}</Copyright>
                </Fragment>
            }
            </ContentPanel>
        </StoryContainer>
    )
}

export default Story
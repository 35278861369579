import React, { Fragment } from 'react'
import styled from 'styled-components'

import { useLandscape } from '../../hooks/useMedia'
import { useTheme } from '../contexts/Theme'
import { classicDark, classicLight } from '../../overlayColors'

// -webkit-filter: brightness(100%) grayscale(100%) opacity(15%);
// filter: brightness(100%) grayscale(100%) opacity(15%);
const Image = styled.img`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 50%;
    z-index: -1;
`

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom right, ${({ topColor }) => topColor}, ${({ bottomColor }) => bottomColor});
    background-position: center;
    background-size: cover;
    z-index: -2;
`

const Wallpaper = ({ src, lightOverlay, darkOverlay }) => {
    const theme = useTheme()
    const isLandscape = useLandscape() 
    const { topColor, bottomColor } = // Defaults unless overlay colors are propped
        theme.isDarkBackground() ? 
        (darkOverlay ? darkOverlay : classicDark) : 
        (lightOverlay ? lightOverlay : classicLight);
    const chiffon = theme.isDarkBackground() ? 'dark' : 'blue';

    return (
        <Fragment>
            { 
                src 
                ? 
                <Image src={`../../../images/wallpaper-${src}.jpg`} />
                :
                <Image src={`../../../images/wallpaper-${chiffon}-chiffon${isLandscape ? '-landscape' : ''}.jpg`} />
            }
            <Overlay topColor={topColor} bottomColor={bottomColor} />
        </Fragment>
    )
} 

export default Wallpaper

import React from 'react'

import MembersHeader from './parts/Header'
import MemberCount from './parts/MemberCount'
import MemberSearch from './parts/MemberSearch'
import { MembersProvider } from './MembersContext'
import useMembers from './useMembers'
import useActiveMember from '../../../../hooks/useActiveMember'
import useCurrentMember from '../../../../hooks/useCurrentMember'
import useRecoverObjects from '../../../../hooks/useRecoverObjects'
import useLogoff from '../../../../hooks/useLogoff'

import Null from '../../../controls/Null'

// The proxy calls the hooks for this page and sets up its context
//
const MembersProxy = ({ children }) => {
    const { activeMember } = useActiveMember()
    const { ...rest } = useMembers()
    const { setCurrentMember } = useCurrentMember()
    const { loggingOff, logoff } = useLogoff({ activeMember })

    // Check for and recover from page refresh
    if (useRecoverObjects({ activeMember })) return <Null />

    // Check for logoff
    if (loggingOff) return <Null />

    return (
        <MembersProvider value={{ activeMember, logoff, setCurrentMember, ...rest }}>{children}</MembersProvider>
    )
}

// Component parts of the page, can be laid out any way we want
//
MembersProxy.Header = MembersHeader
MembersProxy.MemberCount = MemberCount
MembersProxy.MemberSearch = MemberSearch

export default MembersProxy
import React, { useState, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'

import { monthMap } from './Calendar'
import { useTheme } from '../contexts/Theme'
import { useLandscape, usePhone } from '../../hooks/useMedia'
import Spinner from './Spinner'
import { classicLight, classicDark } from '../../overlayColors'

const Container = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    z-index: 10;

    &.scheduler-enter {
        opacity: 0;
        transform: scale(0.5);
    }
    &.scheduler-enter-active {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%);
        transition: opacity 500ms, transform 500ms;
    }
    &.scheduler-exit {
        opacity: 1;
    }
    &.scheduler-exit-active {
        opacity: 0;
        transform: scale(0.5) translateY(0);
        transition: opacity 500ms, transform 500ms;
    }
`

const Box = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid ${p => p.borderColor};
    border-radius: 15px;
    background-image: linear-gradient(to bottom right, ${({ topColor }) => topColor}, ${({ bottomColor }) => bottomColor});
    color: ${props => props.color};
    box-shadow: 10px 10px 2px ${props => props.shadowColor};
    overflow: hidden;
    z-index: 4;
`

const Header = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
`

const StatusBar = styled.div`
    height: 40px;
    width: 250px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const Bang = styled.img`
    height: 40px;
    width: auto;
`

const Title = styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-text: center;
    font: 20px verdana, sans-serif;
`

const Kill = styled.div`
    height: 40px;
    width: 40px;
    margin: 4px 4px 0 0;
    background-image: url('${process.env.PUBLIC_URL}/images/kill-${props => props.iconColor}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const ScheduleContainer = styled.div`
    position: relative;
    width: 300px;
    height: 652px;
    margin-top: 10px;
`

const ScheduleGrid = styled.div`
    position: absolute;
    top: 0;
    left: 90px;
    width: 212px;
    height: 652px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-color: ${p => p.borderColor};
`

const ScheduleGridRow = styled.div`
    width: 100%;
    height: 49px;
    border-bottom: 1px solid ${p => p.borderColor};
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
`

const Notch = styled.div`
    width: 10px;
    height: 49px;
    border-right: 1px solid ${p => p.borderColor};
    flex-shrink: 0;
`

const Quarter = styled.div`
    width: 49px;
    height: 10px;
    border-right: 1px solid ${p => p.borderColor};
`

const Half = styled.div`
    width: 49px;
    height: 20px;
    border-right: 1px solid ${p => p.borderColor};
`

const Labels = styled.div`
    position: absolute;
    top: 1px;
    left: 0;
    width: 90px;
    height: 650px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font: 20px verdana, sans-serif;
`

const Label = styled.div`
    width: 90px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Schedule = styled.div`
    position: absolute;
    top: 1px;
    left: 101px;
    width: 200px;
    height: 650px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
`

const Segment = styled.div`
    position: relative;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
`

const ScheduleIndicator = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 51px;
    height: 51px;
    background-image: url('${process.env.PUBLIC_URL}/images/scheduled-${p => p.imageIndex}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const ConfirmBubble = styled.div`
    position: absolute;
    left: 0;
    top: ${p => p.top}px;
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: ${p => p.bgColor};
    border-radius: 20px;
    font: 24px verdana, sans-serif;
    color: ${p => p.color};
    z-index: 5;
`

const Stem = styled.div`
    position: absolute;
    bottom: -49px;
    left: ${p => p.left}px;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 10px 0 10px;
    border-color: ${p => p.bgColor} rgba(0, 0, 0, 0);
`

const InvertedStem = styled.div`
    position: absolute;
    top: -49px;
    left: ${p => p.left}px;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 50px 10px;
    border-color: ${p => p.bgColor} rgba(0, 0, 0, 0);
`

const Confirm = styled.div`
    width: 270px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 100px;

    &.pressed { box-shadow: 2px 2px 2px 2px ${props => props.borderColor} inset; }
`

// scheduleMappings[width][offset] where width between 3 .. 7 and offset 0 .. 3 gives you the array of indices
//
const scheduleMappings = [ [], [], [],
    [ [ 1, 2, 3 ], [ 1, 2, 3 ], [ 1, 2, 3 ], [ 1, 2, 3 ] ],
    [ [ 1, 2, 2, 3 ], [ 1, 2, 2, 3 ], [ 1, 2, 2, 3 ], [ 1, 2, 2, 3 ] ],
    [ [ 17, 2, 2, 3, 10 ], [ 17, 2, 3, 1, 18 ], [ 17, 3, 1, 2, 18 ], [ 11, 1, 2, 2, 18 ] ],
    [ [ 4, 5, 2, 3, 7, 9 ], [ 4, 5, 3, 1, 8, 9 ], [ 4, 6, 1, 2, 8, 9 ], [ 11, 17, 2, 2, 18, 10 ] ],
    [ [ 4, 13, 5, 3, 7, 12, 9 ], [ 4, 13, 6, 1, 8, 12, 9 ], [ 4, 6, 17, 2, 8, 9, 10 ], [ 11, 4, 5, 2, 18, 7, 9 ] ]
]

const hours = [ '9', '10', '11', '12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10' ]
const minutesStart = [ '00', '15', '30', '45' ], minutesEnd = [ '15', '30', '45', '00' ];

const Scheduler = ({ 
    onTap, spinner, year, month, day, 
    lightBackground = classicLight, darkBackground = classicDark     
}) => {
    // State
    const [ show, setShow ] = useState(false)
    const [ kill, setKill ] = useState(false)
    const [ error, setError ] = useState(false)
    const [ statusMessage, setStatusMessage ] = useState('')
    const [ confirm, setConfirm ] = useState(false)
    const [ segments, setSegments ] = useState()
    
    // Other hooks
    const selection = useRef({ startIndex: -1})
    const bubbleCoords = useRef()
    const invert = useRef(false)
    const theme = useTheme()
    const isLandscape = useLandscape()
    const isPhone = usePhone()

    useEffect(() => {
        onTap?.(setShow)
    }, [])

    // New date effect
    useEffect(() => {
        // Clear status bar and reset everything
        setError(false)
        setStatusMessage('')
        setConfirm(false)
        selection.current = { startIndex: -1 }

        // Initialize segments for new date
        const s = []
        let q = 'AM'
        for (let i = 0, h = -1, top = 25, left = 25 ; i < 52; i++ ) {
            if (i % 4 === 0) { if (++h === 3) q = 'PM'; }
            s.push({ 
                index: i, class: "", top, left, 
                startsAt: `${hours[h]}:${minutesStart[i % 4]} ${q}`,
                endsAt: `${hours[i % 4 === 3 ? h + 1 : h]}:${minutesEnd[i % 4]} ${i === 11 ? 'PM' : q}`
            })
            if (i % 4 === 3) { top += 50; left = 25; } else { left += 50; }
        }
        setSegments(s)
    }, [year, month, day])

    useEffect(() => {
        setKill(false)
        setShow(false)
    }, [ kill ])

    const { white, dark, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const [ color, iconColor, shadowColor, topColor, bottomColor, borderColor, bubbleColor, bubbleIcon ] = 
        theme.isLightBackground() ?  
        [ rgbDark, dark, rgbaDarkBg, lightBackground.topColor, lightBackground.bottomColor, rgbaDarkBg, white, white ] :
        [ white, white, rgbaWhiteBg, darkBackground.topColor, darkBackground.bottomColor, rgbaWhiteBg, rgbDark, dark ];

    // Calculate the position 
    const width=320, height=750;

    const onKill = e => setKill(true)

    // Confirm selection handler
    //
    const onConfirmSelection = () => {
        selection.current.startIndex = -1
        setConfirm(false)
    }

    // Cancel selection handler
    //
    const onCancelSelection = () => {
        setSegments([ ...segments.map((seg, i) => 
            seg.index >= selection.current.startIndex && seg.index <= selection.current.finishIndex ? {
                ...seg, 
                imageIndex: null , 
                class: '' 
            } : seg 
        )])
        selection.current.startIndex = -1
        setConfirm(false)
        setStatusMessage('')
    }

    // 15 min. segment onclick handler
    //
    const onClick = s => {
        // If scheduled segment, show client ...
        for (let i = 0; i < segments.length; i++)
            if (segments[i].class === 'scheduled' && s.index === i) {
                return;
            }

        if (selection.current.startIndex > -1) { // Not first click
            if ( s.index < selection.current.startIndex + 2 || s.index > selection.current.startIndex + 6 ) { // Not a numerically valid interval
                selection.current.startIndex = -1
                setError(true)
                setStatusMessage('Invalid interval')
                return;
            }

            // Must not overlap a scheduled segment
            for (let i = 0; i < segments.length; i++)
                if (segments[i].class === 'scheduled' && i > selection.current.startIndex && i < s.index) { // Illegal overlap
                    selection.current.startIndex = -1
                    setError(true)
                    setStatusMessage('Invalid interval')
                    return;
                }

            // Legit scheduling ...

            // Reset error
            setError(false)
            setStatusMessage(m => `${m} ${s.endsAt}`)

            // Get the "center of gravity" of this selection for the bubble stem
            const selLength = s.index - selection.current.startIndex + 1
            let top = 0, left = 0;
            for (let j = selection.current.startIndex; j <= s.index; j++) {
                top += segments[j].top;
                left += segments[j].left;
            }
            top = Math.floor( top / selLength );
            left = Math.floor( left / selLength );
            bubbleCoords.current = { top, left }
            setConfirm(true)
            invert.current = top < 100;

            // Get the image map for this selection
            const map = scheduleMappings[selLength][selection.current.startIndex % 4]
            let mapIndex = 0

            setSegments([ ...segments.map((seg, i) => 
                seg.index >= selection.current.startIndex && seg.index <= s.index ? {
                    ...seg, 
                    imageIndex: map[mapIndex++] , 
                    class: 'scheduled' 
                } : seg 
            )])
            selection.current.finishIndex = s.index
        } else { // First click
            selection.current.startIndex = s.index
            setError(false)
            setStatusMessage(`${s.startsAt} ..`)
        }
    }
    
    return (
        <CSSTransition 
            in={show && !kill} 
            classNames="scheduler" 
            timeout={1000}
            unmountOnExit
        >
            <Container width={width} height={height}>
                <Box 
                     color={color} topColor={topColor} bottomColor={bottomColor} 
                     shadowColor={shadowColor} borderColor={borderColor}
                >
                { spinner && <Spinner color={color} diameter={width/3} />}
                    <Header>
                        <StatusBar>
                        { error && <Bang src={`${process.env.PUBLIC_URL}/images/bang-${iconColor}.png`} /> }
                        { statusMessage }
                        </StatusBar>
                        <Kill iconColor={iconColor} onClick={onKill} />
                    </Header>                    
                    <Title>{`${monthMap[month]} ${day}, ${year}`}</Title>
                    <ScheduleContainer>
                        {
                            confirm &&
                            <ConfirmBubble 
                                top={ bubbleCoords.current.top + (invert.current ? 50 : -210) } 
                                bgColor={color} color={bubbleColor}
                            >
                                <Confirm onClick={onConfirmSelection} width={250} height={50}>
                                    <img height={50} width={50} src={`${process.env.PUBLIC_URL}/images/confirm-${bubbleIcon}.png`} alt='' />
                                    Confirm selection 
                                </Confirm>
                                <Confirm onClick={onCancelSelection} width={250} height={50}>
                                    <img height={50} width={50} src={`${process.env.PUBLIC_URL}/images/cancel-${bubbleIcon}.png`} alt='' />
                                    Cancel selection
                                </Confirm>
                                {
                                    invert.current ?
                                    <InvertedStem left={100 + bubbleCoords.current.left} bgColor={color} /> :
                                    <Stem left={100 + bubbleCoords.current.left} bgColor={color} />
                                }
                                
                            </ConfirmBubble>
                        }
                        <Labels>
                            <Label />
                            <Label>10:00</Label>
                            <Label />
                            <Label>12:00</Label>
                            <Label />
                            <Label>2:00</Label>
                            <Label />
                            <Label>4:00</Label>
                            <Label />
                            <Label>6:00</Label>
                            <Label />
                            <Label>8:00</Label>
                        </Labels>
                        <ScheduleGrid borderColor={borderColor}>
                            <ScheduleGridRow borderColor={borderColor}>
                                <Notch borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} /> 
                                <Half borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} /> 
                            </ScheduleGridRow>
                            <ScheduleGridRow borderColor={borderColor}>
                                <Notch borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} /> 
                                <Half borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} />
                            </ScheduleGridRow>
                            <ScheduleGridRow borderColor={borderColor}>
                                <Notch borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} /> 
                                <Half borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} />
                            </ScheduleGridRow>
                            <ScheduleGridRow borderColor={borderColor}>
                                <Notch borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} /> 
                                <Half borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} />
                            </ScheduleGridRow>
                            <ScheduleGridRow borderColor={borderColor}>
                                <Notch borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} /> 
                                <Half borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} />
                            </ScheduleGridRow>
                            <ScheduleGridRow borderColor={borderColor}>
                                <Notch borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} /> 
                                <Half borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} />
                            </ScheduleGridRow>
                            <ScheduleGridRow borderColor={borderColor}>
                                <Notch borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} /> 
                                <Half borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} />
                            </ScheduleGridRow>
                            <ScheduleGridRow borderColor={borderColor}>
                                <Notch borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} /> 
                                <Half borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} />
                            </ScheduleGridRow>
                            <ScheduleGridRow borderColor={borderColor}>
                                <Notch borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} /> 
                                <Half borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} />
                            </ScheduleGridRow>
                            <ScheduleGridRow borderColor={borderColor}>
                                <Notch borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} /> 
                                <Half borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} />
                            </ScheduleGridRow>
                            <ScheduleGridRow borderColor={borderColor}>
                                <Notch borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} /> 
                                <Half borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} />
                            </ScheduleGridRow>
                            <ScheduleGridRow borderColor={borderColor}>
                                <Notch borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} /> 
                                <Half borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} />
                            </ScheduleGridRow>
                            <ScheduleGridRow borderColor={borderColor}>
                                <Notch borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} /> 
                                <Half borderColor={borderColor} /> 
                                <Quarter borderColor={borderColor} />
                            </ScheduleGridRow>
                        </ScheduleGrid>
                        <Schedule>
                        {
                            segments &&
                            segments.map((s, i) => {
                                return (
                                    <Segment key={i} 
                                        onClick={() => onClick(s)}
                                        className={s.class} 
                                    >
                                    {
                                        s.class === 'scheduled' &&
                                        <ScheduleIndicator imageIndex={s.imageIndex} />
                                    }
                                    </Segment>
                                )
                            })
                        }
                        </Schedule>
                    </ScheduleContainer>
                </Box>
            </Container>
        </CSSTransition> 
    )
}

export default Scheduler
import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import useClientsContext from '../ClientsContext'
import { useTheme } from '../../../../contexts/Theme'

const Title = styled.div`
    position: relative;
    width: ${p => p.pctWidth ? p.pctWidth : '90'}%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font: 24px verdana, sans-serif;
    color: ${props => props.color};
    margin-top: 40px;
`

const Count = styled.div`
    height: 100%;
    width: auto;
    font: 42px verdana, sans-serif;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const AddClient = styled.div`
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-image: url('${process.env.PUBLIC_URL}/images/add-client.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.pressed { box-shadow: 2px 2px 2px 2px ${props => props.shadowColor} inset; }
`

const ClientCount = ({ pctWidth }) => {
    // State
    const [ addClientPressed, setAddClientPressed ] = useState(false)
    const { activeMember, currentMember, isCurrentMember } = useClientsContext()
    
    // Other hooks
    const addClient = useRef(null)
    const theme = useTheme()

    // Define the member being referenced
    const theMember = isCurrentMember() ? currentMember : activeMember;

    // Theme
    const { white, rgbDark, rgbaDarkBg } = theme
    const [ textColor, shadowColor ] = theme.isDarkBackground() 
        ? [ white, rgbaDarkBg ] : [ rgbDark, rgbaDarkBg ];

    return (
        <Title color={textColor} pctWidth={pctWidth}>
            <Count>{`${theMember.clients.length} client${theMember.clients.length === 1 ? '' : 's'}`}</Count>
            {
                isCurrentMember() || 
                <AddClient
                    shadowColor={shadowColor} 
                    onClick={() => addClient.current.click()}
                    onTouchStart={() => setAddClientPressed(true)}
                    onTouchEnd={() => setAddClientPressed(false)}
                    className={ addClientPressed ? 'pressed' : '' }
                >
                    <Link ref={addClient} to="/client-signup" style={{display: "none"}} />
                </AddClient>
            }
        </Title>
    )
}

export default ClientCount
import React, { Fragment, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import SwipeGroup from '../../../../controls/SwipeGroup'
import { useTheme } from '../../../../contexts/Theme'
import useClientContext from '../ClientContext'

const ActivityContainer = styled.div`
    width: ${p => p.pctWidth ? p.pctWidth : '90'}%;
    height: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
`

const CurrentHaircutContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const Title = styled.div`
    width: 100%;
    height: 40px;
    text-align: center;
    font: 20px verdana, sans-serif;
    color: ${props => props.color};
    margin-top: ${props => props.marginTop ? props.marginTop: '0'}px;
`

const CurrentHaircutFrame = styled.div`
    position: relative;
    width: 190px;
    height: 190px;
    overflow: hidden;
`

const NoPhoto = styled.div`
    width: 100%;
    height: 100%;
    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: 18px verdana, sans-serif;
    color: rgba(255, 255, 255, 0.6);
`

const MenuContainer = styled.div`
    width: 100px;
    height: 190px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
`

const MenuItem = styled.div`
    width: 100%;
    height: 60px;
    margin-top: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
`

const LinkTo = styled.img`
    width: ${props => props.width}px;
    height: auto;
    &.disabled { opacity: 0.5; }
`

const Go = styled.img`
    width: 30px;
    height: 30px;
    &.disabled { opacity: 0.5; }
`

const CurrentHaircut = ({ pctWidth }) => {
    // Hooks
    const toHaircut = useRef(null)
    const toOrder = useRef(null)
    const { currentClient } = useClientContext()
    const theme = useTheme()

    // Destructure the theme
    const { white, dark, rgbDark } = theme
    const [ color, iconColor ] = theme.isDarkBackground() ? [ white, white ] : [ rgbDark, dark ];

    // Destructure the client
    const { haircuts } = currentClient
    const [ haircut ] = haircuts

    return (
        <Fragment>
            <Link ref={toHaircut} to="/client/add-haircut" style={{display: 'none'}} />
            <Link ref={toOrder} to="/client/order" style={{display: 'none'}} />
            <ActivityContainer pctWidth={pctWidth}>
                <CurrentHaircutContainer>
                    <Title color={color}>{'Current Haircut'}</Title>
                    <CurrentHaircutFrame>
                    {
                        haircuts.length > 0 
                        ? 
                        (
                            haircuts[0].photos.length > 0 
                            ?
                            <SwipeGroup size={190} photos={haircuts[0].photos} /> 
                            :
                            <NoPhoto>{'No photo available'}</NoPhoto>
                        ) 
                        :
                        <NoPhoto>{'No photo available'}</NoPhoto> 
                    }
                    </CurrentHaircutFrame>
                </CurrentHaircutContainer>
                <MenuContainer>
                    <MenuItem>
                        <LinkTo
                            width={50} onClick={() => toHaircut.current.click()} 
                            src={`${process.env.PUBLIC_URL}/images/scissors-even-${iconColor}.png`} 
                        />
                        <Go 
                            onClick={() => toHaircut.current.click()}
                            src={`${process.env.PUBLIC_URL}/images/go-${iconColor}.png`} 
                        />
                    </MenuItem>
                    <MenuItem>
                        <LinkTo 
                            width={50} onClick={haircut ? () => toOrder.current.click() : null}
                            src={`${process.env.PUBLIC_URL}/images/shopping-cart-${iconColor}.png`} 
                            className={haircut ? '' : 'disabled'}   
                        />
                        <Go 
                            onClick={haircut ? () => toOrder.current.click() : null}
                            src={`${process.env.PUBLIC_URL}/images/go-${iconColor}.png`} 
                            className={haircut ? '' : 'disabled'}   
                        />
                    </MenuItem>
                </MenuContainer>
            </ActivityContainer>
        </Fragment>
    )
}

export default CurrentHaircut
import React, { useCallback } from 'react'
import { CSSTransition } from 'react-transition-group'

import MessageBox from '../../../../controls/MessageBox'
import useOrderContext from '../OrderContext'
import { lightBlue, darkBlue } from '../../../../../overlayColors'

const PayStatus = () => {
    // Global state
    const { payStatus, setPayStatus, spinner, statusMessage } = useOrderContext()

    const handleMessageBoxOk = useCallback(
        () => {
            setPayStatus(false)
        },
        [ setPayStatus ]
    )

    return (
        <CSSTransition 
            in={payStatus} 
            classNames="message" 
            timeout={1000}
            unmountOnExit
        >
            <MessageBox 
                spinner={spinner} message={statusMessage}
                lightBackground={lightBlue} darkBackground={darkBlue} 
                onOk={handleMessageBoxOk} label='Ok'
                width='320' height='240' 
            />
        </CSSTransition>
    )
}

export default PayStatus
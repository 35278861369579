import React, { Fragment } from 'react'

import Header from '../../../../controls/Header'
import HeaderDesktop from '../../../../media/HeaderDesktop'
import useAddHaircutContext from '../AddHaircutContext'
import { usePhone } from '../../../../../hooks/useMedia'
import { useTheme } from '../../../../contexts/Theme'
import { useFromClients, firstName } from '../../../../../utils'

const AddHaircutHeader = () => {
    // Other hooks
    const { runResetFunctions, name } = useAddHaircutContext()
    const fromClients = useFromClients()
    const isPhone = usePhone()
    const theme = useTheme()

    // Destructure theme
    const backColor = theme.isDarkBackground() ? "yellow" : "blue";

    return (
        <Fragment>
        {
            isPhone
            ?
            <Header arrow={{
                color: backColor,
                to: `/${fromClients ? 'clients' : 'client'}`,
                destination: `${fromClients ? 'Clients' : firstName(name)}`
            }} title='Add Haircut' onBack={() => {runResetFunctions()}} separator />
            :
            <HeaderDesktop height={100} leftContent={<Header arrow={{
                color: backColor,
                to: `/${fromClients ? 'clients' : 'client'}`,
                destination: `${fromClients ? 'Clients' : firstName(name)}`
            }} title='Add Haircut' onBack={() => {runResetFunctions()}} />} />
        } 
        </Fragment>
    )
}

export default AddHaircutHeader
import React, { createContext, useContext } from 'react'

const ClientSignupForm = createContext(undefined)

export const ClientSignupFormProvider = ({ children, value }) => {
    return (
        <ClientSignupForm.Provider value={value}>{children}</ClientSignupForm.Provider>
    )
}

const useClientSignupFormContext = () => {
    const context = useContext(ClientSignupForm)

    if (context === undefined) throw new Error('useClientSignupFormContext must be used within a ClientSignupFormProvider')

    return context;
}

export default useClientSignupFormContext
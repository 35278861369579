import { useState, useRef, useEffect } from 'react'
import axios from 'axios'

import {
    PRIME12, TUNE12, CUT12
} from '../../../controls/Ribbon'
import { useLandscape } from '../../../../hooks/useMedia'
import {
    PRIME_NAME,
    TUNE_NAME,
    CUT_NAME
} from '../../../../constants'
import { timeoutConfig } from '../../../../utils'

let lastSelection = {
    primeSelected: false, tuneSelected: false, cutSelected: false
}
let lastInvoiceItems = [
    { name: `${PRIME_NAME} 8oz.`, quantity: 1, wasTapped: false },
    { name: `${TUNE_NAME} 8oz.`, quantity: 1, wasTapped: false },
    { name: `${CUT_NAME} 6oz.`, quantity: 1, wasTapped: false }
]
let lastReflectedItems = []
let lastSubtotal = 0
let lastDiscount = 0
let lastTax = 0
let lastShippingAndHandling = 0
let lastTotal = 0

let lastEnteringShippingAddress = false
let lastShippingAddressEntered = false

let initialMount = true

const useOrder = () => {
    // State
    const [ enteringShippingAddress, setEnteringShippingAddress ] = useState(false)
    const [ shippingAddressEntered, setShippingAddressEntered ] = useState(false)
    const [ selection, setSelection ] = useState({
        primeSelected: false, tuneSelected: false, cutSelected: false 
    })
    const [ payStatus, setPayStatus ] = useState(false)
    const [ spinner, setSpinner ] = useState(false)
    const [ statusMessage, setStatusMessage ] = useState(null)
    const [ discount, setDiscount ] = useState(null)
    const [ total, setTotal ] = useState(0)

    // Other hooks
    const isLandscape = useLandscape()
    const invoiceItems = useRef([
        { name: `${PRIME_NAME} 8oz.`, quantity: 1, wasTapped: false },
        { name: `${TUNE_NAME} 8oz.`, quantity: 1, wasTapped: false },
        { name: `${CUT_NAME} 6oz.`, quantity: 1, wasTapped: false }
    ])

    // Keeps track of items updated in the invoice:
    const reflectedItems = useRef([])

    const subtotal = useRef(0)
    const tax = useRef(0)
    const shippingAndHandling = useRef(0)

    // Initial page mount effect, prices load
    //
    useEffect(() => {
        async function getPrices() {
            try {
                setSpinner(true)
                const rsp = await axios.get(`/api/prices`, timeoutConfig({}))
                setSpinner(false)
                invoiceItems.current.forEach((item, i) => {
                    lastInvoiceItems[i].price = item.price = rsp.data.filter(datum => datum.name === item.name)[0].price
                })
                initialMount = false
            } catch(err) {
                setPayStatus(true)
                if (err.response) {
                    switch (err.response.status) {
                        case 500: // Server error
                            setStatusMessage('Server error, product prices not available.')
                            console.log('Error! ', 'Server error')
                            break;
                        default: // 400, session expired
                            console.log('Error! ', err.response.data)
                            setStatusMessage(`${err.response.data.message}, product prices not available.`)
                    }
                } else if (err?.code === 'ECONNABORTED') { // Timeout error
                    setStatusMessage(`Server took too long to respond, product prices not available, try again later.`)
                } else { // Not likely
                    console.log('ERR: ', err?.code)
                    console.log('ERR: ', err?.message) 
                    setStatusMessage(`Error: ${err?.message}, product prices not available.`)
                }

                setSpinner(false)
            }
        }

        if (!initialMount) return;
        getPrices()
    }, [])

    // Recover from landscape <-> portrait change
    //
    useEffect(() => {
        if (initialMount) return;
        
        setSelection(lastSelection)
        invoiceItems.current = lastInvoiceItems
        reflectedItems.current = lastReflectedItems
        subtotal.current = lastSubtotal
        setDiscount(lastDiscount)
        tax.current = lastTax
        shippingAndHandling.current = lastShippingAndHandling
        setTotal(lastTotal)
        setEnteringShippingAddress(lastEnteringShippingAddress)
        setShippingAddressEntered(lastShippingAddressEntered)
    }, [ isLandscape ])
    
    // Ribbon selection handlers

    const onSelected = sel => {
        switch (sel?.code) {
            case PRIME12:
                if (selection.primeSelected) break;
                invoiceItems.current[0].wasTapped = true
                invoiceItems.current[0].image = sel.image
                lastInvoiceItems[0].wasTapped = true
                lastInvoiceItems[0].image = sel.image
                setSelection({ ...selection, primeSelected: true })
                lastSelection = { ...lastSelection, primeSelected: true }
                break;
            case TUNE12:
                if (selection.tuneSelected) break;
                invoiceItems.current[1].wasTapped = true
                invoiceItems.current[1].image = sel.image
                lastInvoiceItems[1].wasTapped = true
                lastInvoiceItems[1].image = sel.image
                setSelection({ ...selection, tuneSelected: true })
                lastSelection = { ...lastSelection, tuneSelected: true }
                break;
            case CUT12:
                if (selection.cutSelected) break;
                invoiceItems.current[2].wasTapped = true
                invoiceItems.current[2].image = sel.image
                lastInvoiceItems[2].wasTapped = true
                lastInvoiceItems[2].image = sel.image
                setSelection({ ...selection, cutSelected: true })
                lastSelection = { ...lastSelection, cutSelected: true }
                break;
            default: break;
        }
                      
        invoiceItems.current = [ ...invoiceItems.current ]
    }

    // Reset all locally backed-up state for when we exit the page
    //
    const resetBackup = () => {
        lastSelection = {
            primeSelected: false, tuneSelected: false, cutSelected: false
        }
        lastInvoiceItems = [
            { name: `${PRIME_NAME} 8oz.`, price: 19.30, quantity: 1, wasTapped: false },
            { name: `${TUNE_NAME} 8oz.`, price: 19.30, quantity: 1, wasTapped: false },
            { name: `${CUT_NAME} 6oz.`, price: 19.30, quantity: 1, wasTapped: false }
        ]
        lastReflectedItems = []
        lastSubtotal = 0
        lastDiscount = null
        lastTax = 0
        lastShippingAndHandling = 0
        lastTotal = 0
        setTotal(0)
        lastEnteringShippingAddress = false
        setEnteringShippingAddress(false)
        lastShippingAddressEntered = false
        setShippingAddressEntered(false)

        initialMount = true
    }

    const updateReflectedItems = changedItems => {
        reflectedItems.current = [ ...changedItems ]
        lastReflectedItems = [ ...changedItems ]
    }

    const updateSubtotal = val => {
        subtotal.current = val
        lastSubtotal = val
    }

    const updateDiscount = val => {
        setDiscount(val)
        lastDiscount = val
    }

    const updateTax = val => {
        tax.current = val
        lastTax = val
    }

    const updateShippingAndHandling = val => {
        shippingAndHandling.current = val
        lastShippingAndHandling = val
    }

    const updateTotal = val => {
        setTotal(val)
        lastTotal = val
    }

    const updateEnteringShippingAddress = val => {
        setEnteringShippingAddress(val)
        lastEnteringShippingAddress = val
    }

    const updateShippingAddressEntered = val => {
        setShippingAddressEntered(val)
        lastShippingAddressEntered = val
    }

    const updateInvoiceItems = changedItems => {
        changedItems.forEach(changedItem => {
            invoiceItems.current.forEach((invoiceItem, i) => {
                if (changedItem.name === invoiceItem.name) {
                    invoiceItem.quantity = changedItem.quantity
                    lastInvoiceItems[i].quantity = changedItem.quantity
                }
            })
        })
    }

    return {
        enteringShippingAddress, updateEnteringShippingAddress, shippingAddressEntered, updateShippingAddressEntered,
        onSelected, selection,
        payStatus, setPayStatus, spinner, setSpinner, statusMessage, setStatusMessage,
        invoiceItems, reflectedItems, updateInvoiceItems, updateReflectedItems, updateSubtotal, updateTax, 
        updateShippingAndHandling, updateTotal, updateDiscount, subtotal, tax, shippingAndHandling, total, discount,
        resetBackup
    }
}

export default useOrder
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import {
    CLEAR_ACTIVE_MEMBER,
    CLEAR_ACTIVE_CLIENT,
    CLEAR_ACTIVE_ADMIN,
    CLEAR_HOST_MEMBER,
    CLEAR_TOKEN
} from '../redux/actions/types'

const useLogoff = ({ activeMember, activeClient, hostMember, activeAdmin }) => {
    // State
    const [ loggingOff, setLoggingOff ] = useState(false)

    // Other hooks
    const dispatch = useDispatch()

    if (activeMember) { 
        return {
            loggingOff,
            logoff: () => { // Active (logged-in) member is logging off
                setLoggingOff(true)
                dispatch({ type: CLEAR_ACTIVE_MEMBER })
                dispatch({ type: CLEAR_TOKEN })
            }
        }
    }

    if (activeClient) { 
        return {
            loggingOff,
            logoff: () => { // Active (logged-in) client is logging off
                setLoggingOff(true)
                dispatch({ type: CLEAR_ACTIVE_CLIENT })
                dispatch({ type: CLEAR_HOST_MEMBER })
                dispatch({ type: CLEAR_TOKEN })
            }
        }
    }
}

export default useLogoff
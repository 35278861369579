import React from 'react'
import styled from 'styled-components'

import { useHelp } from '../contexts/Help'

const Item = styled.div`
    position: relative;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    background-image: url('${process.env.PUBLIC_URL}/images/help.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`

const HelpMenuItem = ({ size, bgColor }) => {
    const help = useHelp()

    //const handleItemClick = () => theme.setLightUnderline()
    const handleItemClick = () => {
        help.setHelpRequested(!help.helpWasRequested())
        help.refreshPage && help.refreshPage() // To refresh the current page
    }

    return <Item size={size} bgColor={bgColor} onClick={handleItemClick} />
}

export default HelpMenuItem
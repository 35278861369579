import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Form } from 'reactstrap'

import Wallpaper from '../controls/Wallpaper'
import Portrait from '../controls/Portrait'
import Landscape from '../controls/Landscape'
import Logo from '../controls/Logo'
import Separator from '../controls/Separator'
import Column from '../controls/Column'
import Spinner from '../controls/Spinner'
import IconInput from '../controls/IconInput'
import Input from '../controls/Input'
import Submit from '../controls/Submit'
import Button from '../controls/Button'
import Switch from '../controls/Switch'
import ErrorMessage from '../controls/ErrorMessage'
import ShareButtons from '../controls/ShareButtons'
import SideMenu from '../controls/SideMenu'
import useSwitchTheme from '../../hooks/useSwitchTheme'
import useActivateHelp from '../../hooks/useActivateHelp'
import { useTheme } from '../contexts/Theme'
import { useLandscape } from '../../hooks/useMedia'
import { 
    USER_LOADING, 
    secretQuestions,
    CREATOR_NAME,
    CONTINUUM_NAME,
    PRIME_NAME,
    TUNE_NAME,
    CUT_NAME 
} from '../../constants'

const Half = styled.div`
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`

const Narrative = styled.div`
    width: 100%;
    height: auto;
    font: 20px verdana, sans-serif;
    color: ${p => p.color};
    margin-left: ${p => p.marginLeft}px;
    margin-right: ${p => p.marginRight}px;
`

const Copyright = styled.div`
    width: 100%;
    height: auto;
    font: 10px verdana, sans-serif;
    color: ${p => p.color};
    text-align: right;
    margin-top: ${p => p.marginTop}px;
`

const ProductGrid = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: ${p => p.marginTop}px;
    margin-bottom: ${p => p.marginBottom}px;
`

const ProductCell = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`

const ProductImage = styled.img`
    width: ${p => p.width}px;
    height: auto;
    border: none;
    outline: none;
`

const ProductDescription = styled.div`
    width: 90px;
    height: auto;
    font: 12px verdana, sans-serif;
    color: ${p => p.color};
    margin-left: 2px;

    & h2 { 
        font: 20px verdana, sans-serif;
        margin-top: -5px;
        margin-bottom: -2px; 
    }
`

const ForgotPassword = styled.div`
    width: 300px;
    height: auto;
    font: 18px verdana, sans-serif;
    text-align: right;
    margin-top: 15px;
    color: ${p => p.color};
`

const Conversation = styled.div`
    width: 300px;
    height: auto;
    font: 18px verdana, sans-serif;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    color: ${p => p.color};
`

const LoginPhone = ({ 
    onPasswordChange, onUseridChange, onAnswerChange, onSubmit, onSubmitAnswer, 
    onOkReply, onForgotPassword, user, answerReply, onKeepMe, loadStatus, loginError 
}) => {
    // Local state
    const [ keepMe, setKeepMe ] = useState(0)
    const [ arrayNarrative, setArrayNarrative ] = useState({
        arrayStory: false, 
        arrayCreator: false,
        arrayContinuum: false,
        oneArray: false,
        loggingOn: true
    })
    
    // Other hooks
    const theme = useTheme()
    const isLandscape = useLandscape()
    useSwitchTheme()
    useActivateHelp()
    
    const onKeepMeChange = e => {
        setKeepMe(e.target.value)
        onKeepMe(e)
    }

    // Destructure the theme
    const { rgbaWhiteBg, rgbaDarkBg, white, rgbDark } = theme
    const [ sepColor, color, wallpaper ] = 
        theme.isDarkBackground() ? 
        [ rgbaWhiteBg, white, "dark-chiffon" ] : 
        [ rgbaDarkBg, rgbDark, "blue-chiffon" ];

    // Handlers for Array narrative
    const setArrayStory = () => setArrayNarrative({
        arrayStory: true, 
        arrayCreator: false,
        arrayContinuum: false,
        oneArray: false,
        loggingOn: false
    })
    const setArrayCreator = () => setArrayNarrative({
        arrayStory: false, 
        arrayCreator: true,
        arrayContinuum: false,
        oneArray: false,
        loggingOn: false
    })
    const setArrayContinuum = () => setArrayNarrative({
        arrayStory: false, 
        arrayCreator: false,
        arrayContinuum: true,
        oneArray: false,
        loggingOn: false
    })
    const setOneArray = () => setArrayNarrative({
        arrayStory: false, 
        arrayCreator: false,
        arrayContinuum: false,
        oneArray: true,
        loggingOn: false
    })
    const setLoggingOn = () => setArrayNarrative({
        arrayStory: false, 
        arrayCreator: false,
        arrayContinuum: false,
        oneArray: false,
        loggingOn: true
    })

    return (
        <Fragment>
        {
            isLandscape
            ?
            <Landscape>
                <SideMenu top={50} />
                <Wallpaper />
                <Half>
                    <Logo onClick={setArrayStory} />
                    <ShareButtons iconSize={40} direction='row' marginTop={80} />
                </Half>  
                <Half>
                {
                    arrayNarrative.loggingOn 
                    && 
                    <Fragment>       
                        {loadStatus === USER_LOADING && <Spinner diameter={50} />}
                        {
                            user
                            ?
                            <Fragment>
                                <Conversation color={color}>{secretQuestions[user.secretQuestion]}</Conversation>
                                <Input 
                                    type='text' 
                                    name='secretAnswer' 
                                    placeholder='Answer'
                                    marginTop={20}
                                    width={260}  
                                    onChange={onAnswerChange}
                                    required
                                />
                                <Button 
                                    label="Submit answer" onClick={onSubmitAnswer} 
                                    width="200" height="50" marginTop={30} 
                                /> 
                            </Fragment>
                            :
                            answerReply
                            ?
                            <Fragment>                        
                                <Conversation color={color}>{answerReply}</Conversation>
                                <Button 
                                    label="Ok" onClick={onOkReply} 
                                    width="100" height="50" marginTop={30} 
                                />
                            </Fragment>
                            :
                            <Fragment>
                                { loginError && (<ErrorMessage>{loginError}</ErrorMessage>) }
                                <Form onSubmit={onSubmit}> 
                                    <Column>
                                        <IconInput 
                                            type='text' 
                                            iconUrl='email' 
                                            name='userid' 
                                            placeholder='Email Address'
                                            marginTop={20} 
                                            onChange={onUseridChange}
                                            required
                                        />
                                        <IconInput 
                                            type='password' 
                                            iconUrl='password' 
                                            name='password' 
                                            placeholder='Password or Access Code'
                                            marginTop={30} 
                                            onChange={onPasswordChange} 
                                            required
                                        />
                                        <ForgotPassword onClick={onForgotPassword} color={color}>forgot password?</ForgotPassword>
                                        <Submit 
                                            width={100} 
                                            height={50} 
                                            marginTop={40} 
                                            text='Enter' 
                                        />
                                    </Column>
                                </Form>
                            </Fragment>
                        }
                        <Switch 
                            width={60} height={30} label='Keep me logged in' onChange={onKeepMeChange} marginTop={20} 
                            helpCaption='Switch on to make your session last 24 hours instead of 2 hours.' value={keepMe}
                        />
                    </Fragment>
                }
                {
                    arrayNarrative.arrayStory
                    &&
                    <Fragment>
                        <Narrative color={color} marginLeft={40}>
                            <p>A science behind the service.</p>
                            <p>A service that became a product.</p>
                            <p>A product with a unique version.</p>
                        </Narrative>
                        <Button label='createARRAY >>' onClick={setArrayCreator} width={250} borderless />
                        <Copyright color={color}>{"Omni Creator, Inc. New York, New York USA"}</Copyright>
                    </Fragment>
                }
                {
                    arrayNarrative.arrayCreator
                    &&
                    <Fragment>
                        <Narrative color={color} marginLeft={20} marginRight={20}>
                            <p>Beauty services and products re-invented: All Array cut services are
                            done with instruments and applied techniques. Patent-pending science
                            is the foundation of unique synthesized molecules that activate methods
                            and systems and produce an Original Artistic Service (OAS).</p>
                            <p>Individualized products are born of this process. The service industry
                            moves on-line and in digital formats; both the art and enterprise of the
                            professional services industry are forever changed.</p>
                        </Narrative>
                        <Button onClick={setArrayContinuum} label={`${CONTINUUM_NAME} >>`} width={300} borderless />
                        <Copyright color={color}>{"Omni Creator, Inc. New York, New York USA"}</Copyright>
                    </Fragment>
                }
                {
                    arrayNarrative.arrayContinuum
                    &&
                    <Fragment>
                        <Narrative color={color}>
                            <p>Original Artistic Services (OAS) establish the requirements for client
                            home use products. The client’s products optimize their versions of new
                            categories branded to their unique Array, the stylist who created it and
                            the salon that delivered the experience.</p>
                        </Narrative>
                        <ProductGrid marginTop={0} marginBottom={0}>
                            <ProductCell>
                                <ProductImage width={25} src={`${process.env.PUBLIC_URL}/images/shampoo-bottle.png`} />
                                <ProductDescription color={color}>
                                    <h2>PRIME&trade;</h2>
                                    Cleansing science designed to reinforce your cut.
                                </ProductDescription>
                            </ProductCell>
                            <ProductCell>
                                <ProductImage width={25} src={`${process.env.PUBLIC_URL}/images/conditioner-bottle.png`} />
                                <ProductDescription color={color}>
                                    <h2>TUNE&trade;</h2>
                                    Conditioning to nurture your hair.
                                </ProductDescription>
                            </ProductCell>
                            <ProductCell>
                                <ProductImage width={27} src={`${process.env.PUBLIC_URL}/images/cut-bottle.png`} />
                                <ProductDescription color={color}>
                                    <h2>WEAR&trade;</h2>
                                    Styling science to enhance your stylist’s artistic direction.
                                </ProductDescription>
                            </ProductCell>
                        </ProductGrid>
                        <Button onClick={setOneArray} label='1Array >>' width={300} borderless />
                        <Copyright color={color}>{"Omni Creator, Inc. New York, New York USA"}</Copyright>
                    </Fragment>
                }
                {
                    arrayNarrative.oneArray
                    &&
                    <Fragment>
                        <Narrative color={color} marginLeft={20} marginRight={20}>
                            <p>Array signals the end of retail and digital shopping for mass-branded products. 
                            As the merchant for services and products, we engineer
                            performance-based platforms to move commerce out of the anonymous
                            selection process and into OAS digital systems.</p>
                            <p>Array as merchant to OAS transactions feeds a revolution in planning,
                            purchasing, fill assembly and logistics. Service and product supply
                            chains fully integrated with manufacturing, distribution and commerce.</p>
                            <p>For more information,
                            please contact us at <a href='https://info@1array.com'>info</a></p>
                        </Narrative>
                        <Button onClick={setLoggingOn} label='Logon >>' width={300} borderless />
                        <Copyright color={color}>{"Omni Creator, Inc. New York, New York USA"}</Copyright>
                    </Fragment>
                }
                </Half>                  
            </Landscape>
            :
            <Portrait>
                <SideMenu />
                <Wallpaper />
                <Logo onClick={setArrayStory} />
                <Separator color={sepColor} marginBottom={40} />
                {
                    arrayNarrative.loggingOn
                    &&
                    <Fragment>
                        {loadStatus === USER_LOADING && <Spinner diameter={50} />}
                        {
                            user
                            ?
                            <Fragment>
                                <Conversation color={color}>{secretQuestions[user.secretQuestion]}</Conversation>
                                <Input 
                                    type='text' 
                                    name='secretAnswer' 
                                    placeholder='Answer'
                                    marginTop={20}
                                    width={260} 
                                    onChange={onAnswerChange}
                                    required
                                />
                                <Button 
                                    label="Submit answer" onClick={onSubmitAnswer} 
                                    width="200" height="50" marginTop={30} 
                                /> 
                            </Fragment>
                            :
                            answerReply
                            ?
                            <Fragment>                        
                                <Conversation color={color}>{answerReply}</Conversation>
                                <Button 
                                    label="Ok" onClick={onOkReply} 
                                    width="100" height="50" marginTop={30} 
                                />
                            </Fragment>
                            :
                            <Fragment>
                                { loginError && (<ErrorMessage>{loginError}</ErrorMessage>) }
                                <Form onSubmit={onSubmit}>
                                    <Column>
                                        <IconInput 
                                            type='text' 
                                            iconUrl='email' 
                                            name='userid' 
                                            placeholder='Email Address'
                                            marginTop={20} 
                                            onChange={onUseridChange}
                                            required
                                        />
                                        <IconInput 
                                            type='password' 
                                            iconUrl='password' 
                                            name='password' 
                                            placeholder='Password or Access Code'
                                            marginTop={30} 
                                            onChange={onPasswordChange} 
                                            required
                                        />
                                        <ForgotPassword onClick={onForgotPassword} color={color}>forgot password?</ForgotPassword>
                                        <Submit 
                                            width={100} 
                                            height={50} 
                                            marginTop={40} 
                                            text='Enter' 
                                        />
                                    </Column>                    
                                </Form>
                            </Fragment>
                        }
                        <Switch 
                            width={60} height={30} label='Keep me logged in' onChange={onKeepMeChange} marginTop={80} 
                            helpCaption='Switch on to make your session last 24 hours instead of 2 hours.' value={keepMe}
                        />
                    </Fragment>
                }
                {
                    arrayNarrative.arrayStory
                    &&
                    <Fragment>
                        <Narrative color={color} marginLeft={40}>
                            <p>A science behind the service.</p>
                            <p>A service that became a product.</p>
                            <p>A product with a unique version.</p>
                        </Narrative>
                        <Button onClick={setArrayCreator} label={`${CREATOR_NAME} >>`} width={250} borderless />
                        <Copyright color={color} marginTop={50}>{"Omni Creator, Inc. New York, New York USA"}</Copyright>
                    </Fragment>
                }
                {
                    arrayNarrative.arrayCreator
                    &&
                    <Fragment>
                        <Narrative color={color} marginLeft={20} marginRight={20}>
                            <p>Beauty services and products re-invented: All Array cut services are
                            done with instruments and applied techniques. Patent-pending science
                            is the foundation of unique synthesized molecules that activate methods
                            and systems and produce an Original Artistic Service (OAS).</p>
                            <p>Individualized products are born of this process. The service industry
                            moves on-line and in digital formats; both the art and enterprise of the
                            professional services industry are forever changed.</p>
                        </Narrative>
                        <Button onClick={setArrayContinuum} label={`${CONTINUUM_NAME} >>`} width={300} borderless />
                        <Copyright color={color}>{"Omni Creator, Inc. New York, New York USA"}</Copyright>
                    </Fragment>
                }
                {
                    arrayNarrative.arrayContinuum
                    &&
                    <Fragment>
                        <Narrative color={color}>
                            <p>Original Artistic Services (OAS) establish the requirements for client
                            home use products. The client’s products optimize their versions of new
                            categories branded to their unique Array, the stylist who created it and
                            the salon that delivered the experience.</p>
                        </Narrative>
                        <ProductGrid marginTop={30} marginBottom={20}>
                            <ProductCell>
                                <ProductImage width={25} src={`${process.env.PUBLIC_URL}/images/shampoo-bottle.png`} />
                                <ProductDescription color={color}>
                                    <h2>{PRIME_NAME}&trade;</h2>
                                    Cleansing science designed to reinforce your cut.
                                </ProductDescription>
                            </ProductCell>
                            <ProductCell>
                                <ProductImage width={25} src={`${process.env.PUBLIC_URL}/images/conditioner-bottle.png`} />
                                <ProductDescription color={color}>
                                    <h2>{TUNE_NAME}&trade;</h2>
                                    Conditioning to nurture your hair.
                                </ProductDescription>
                            </ProductCell>
                            <ProductCell>
                                <ProductImage width={27} src={`${process.env.PUBLIC_URL}/images/cut-bottle.png`} />
                                <ProductDescription color={color}>
                                    <h2>{CUT_NAME}&trade;</h2>
                                    Styling science to enhance your stylist’s artistic direction.
                                </ProductDescription>
                            </ProductCell>
                        </ProductGrid>
                        <Button onClick={setOneArray} label='1Array >>' width={300} borderless />
                        <Copyright color={color}>{"Omni Creator, Inc. New York, New York USA"}</Copyright>
                    </Fragment>
                }
                {
                    arrayNarrative.oneArray
                    &&
                    <Fragment>
                        <Narrative color={color} marginLeft={20} marginRight={20}>
                            <p>Array signals the end of retail and digital shopping for mass-branded products. 
                            As the merchant for services and products, we engineer
                            performance-based platforms to move commerce out of the anonymous
                            selection process and into OAS digital systems.</p>
                            <p>Array as merchant to OAS transactions feeds a revolution in planning,
                            purchasing, fill assembly and logistics. Service and product supply
                            chains fully integrated with manufacturing, distribution and commerce.</p>
                            <p>For more information,
                            please contact us at <a href='https://info@1array.com'>info</a></p>
                        </Narrative>
                        <Button onClick={setLoggingOn} label='Logon >>' width={300} borderless />
                        <Copyright color={color}>{"Omni Creator, Inc. New York, New York USA"}</Copyright>
                    </Fragment>
                }
                <ShareButtons iconSize={40} direction='row' marginTop={80} />
            </Portrait>
        }
        </Fragment>
    )
}

export default LoginPhone

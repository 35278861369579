import React, { useContext, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'
import Caption from './Caption'

const UnderlineContainer = styled.div`
    position: relative;
    width: 320px;
    height: 40px;
    margin-top: ${props => props.marginTop}px;
`

const UnderlineIcon = styled.div`
    position: absolute;
    top: -10px;
    left: 0px;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0);
    background-image: url('../../../images/${props => props.iconUrl}-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const UnderlineInput = styled.div`
    position: absolute;
    top: -10px;
    left: 65px;
    width: 250px;
    height: 40px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
`

const Underline = styled.div`
    position: absolute;
    bottom: 0px;
    left: 60px;
    right: 0px;
    border-style: solid;
    border-width: 1px;
    border-color: ${props => props.color};
    background-color: ${props => props.color};
`

const OvalContainer = styled.div`
    width: 100%;
    height: 60px;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${props => props.marginTop}px;
`

const OvalIcon = styled.div`
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: ${props => props.bgColor};
    background-image: url('../../../images/${props => props.iconUrl}-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px 50px;
`

const OvalInput = styled.div`
    position: relative;
    width: 250px;
    height: 60px;
    margin-left: 10px;
    border-radius: 100px;
    border: none;
    background-color: ${props => props.bgColor};
`

const DropdownIcon = styled.div`
    position: absolute;
    top: ${props => props.top};
    right: ${props => props.right};
    width: 40px;
    height: 50px;
    background-image: url('../../../images/dropdown-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Select = styled.input`
    position: absolute;
    top: 10px;
    left: 0;
    width: 200px;
    height: 40px;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    font: 18px verdana, sans-serif;
    color: ${props => props.color};
    text-indent: 30px;
    
    &::placeholder {
        color: ${props => props.color};
        opacity: 0.6;
    }
`

const Options = styled.div`
    position: absolute;
    top: 50px;
    left: 0;
    width: 250px;
    height: auto;
    background-color: rgb(0, 0, 124);
    font: 18px verdana, sans-serif;
    color: ${props => props.color};
    overflow: scroll;
    z-index: 2;

    &.options-enter {
        opacity: 0;
        transform: scale(0.8) translateY(-50px);
    }
    &.options-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 500ms, transform 500ms;
    }
    &.options-exit {
        opacity: 1;
    }
    &.options-exit-active {
        opacity: 0;
        transform: scale(0.8) translateY(-50px);
        transition: opacity 500ms, transform 500ms;
    }
`

const Option = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    text-indent: 30px;

    &.alternate { background-color: rgb(38, 38, 255) }
    &.selected { background-color: rgb(124, 0, 124) }
`

const Dropdown = ({ 
    options, onChange, caption, name, marginTop, preselected, 
    getSelectedIndex, iconUrl, placeholder, alternating 
}) => {
    const [ captionVisible, setCaptionVisible ] = useState(false)
    const [ resultsVisible, setResultsVisible ] = useState(false)
    const [ optionSelected, setOptionSelected ] = useState(-1)

    const theme = useTheme()
    const { white, dark, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const bgColor = theme.isDarkBackground() ? rgbaWhiteBg : rgbaDarkBg;
    const iconColor = theme.isDarkBackground() ? white : dark;
    const textColor = theme.isDarkBackground() ? white : rgbDark;

    const onTouchStartIcon = e => setCaptionVisible(!captionVisible)
    const onClickDropdown = e => setResultsVisible(!resultsVisible)

    const onOptionSelected = key => {
        onChange(key)
        setOptionSelected(key)

        // Signal selected index if desired by caller
        getSelectedIndex?.(key)
    }
    const onOptionSelectedEnd = () => setResultsVisible(false)
    const onOptionClicked = key => {
        onChange(key)
        setOptionSelected(key)
        setResultsVisible(false)
    }
    
    const Container = theme.isInputOval() ? OvalContainer : UnderlineContainer;
    const Icon = theme.isInputOval() ? OvalIcon : UnderlineIcon;
    const Input = theme.isInputOval() ? OvalInput : UnderlineInput;
    const stemOffset = theme.isInputOval() ? '30' : '25';
    const ddTop = theme.isInputOval() ? '5px' : '-5px';
    const ddRight = theme.isInputOval() ? '20px' : '10px';

    return (
        <Container marginTop={marginTop}>
            {
                caption
                ?
                <Icon bgColor={bgColor} iconUrl={iconUrl} color={iconColor} onClick={onTouchStartIcon}>
                {
                    captionVisible && 
                    <Caption 
                        text={caption}
                        width='200' bottom='60' left='0' stemOffset={stemOffset} 
                    />
                }
                </Icon>
                :
                <Icon bgColor={bgColor} iconUrl={iconUrl} color={iconColor} />
            }
            <Input bgColor={bgColor}>
                {
                    optionSelected >= 0 
                    ?
                    <Select type='text' name={name} color={textColor} value={options[optionSelected]} 
                        placeholder={placeholder} />
                    :
                    (
                        preselected >= 0
                        ?
                        <Select type='text' name={name} color={textColor} value={options[preselected]} 
                            placeholder={placeholder} />
                        :
                        <Select type='text' name={name} color={textColor} placeholder={placeholder} />
                    )
                } 
                <CSSTransition 
                    in={resultsVisible} 
                    classNames="options" 
                    timeout={500}
                    unmountOnExit
                >
                    <Options color={textColor}>
                        {
                            options.map((option, i) => (
                                <Option key={i}
                                    onClick={e => onOptionClicked(i)} 
                                    onTouchStart={e => onOptionSelected(i)}
                                    onTouchEnd={e => onOptionSelectedEnd()}
                                    className={
                                        `${i % 2 === 0 && alternating && 'alternate'} ${(i === optionSelected || i === preselected) && 'selected'}`
                                    }
                                >{option}</Option>
                            ))
                        }
                    </Options>
                </CSSTransition>
                <DropdownIcon onClick={onClickDropdown} color={iconColor} top={ddTop} right={ddRight} />
            </Input>
            {theme.isInputUnderline() && <Underline color={textColor} />} 
        </Container>
    )
}

export default Dropdown
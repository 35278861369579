import React from 'react'
import { CSSTransition } from 'react-transition-group'

import Ribbon, { SERUM_RADIUS, SERUM_BLUR, SERUM_PLUMB, SERUM_STRATA, SERUM_TILT } from '../../../../controls/Ribbon'
import useSalonOrderContext from '../SalonOrderContext'

const items = [
    SERUM_RADIUS,
    SERUM_BLUR,
    SERUM_PLUMB,
    SERUM_STRATA,
    SERUM_TILT
]

const SerumsRibbon = ({ marginTop }) => {
    const { menuSelection, onSerumSelected } = useSalonOrderContext()

    return (
        
        <CSSTransition 
            in={menuSelection.serumsSelected} 
            classNames="ribbon" 
            timeout={1000}
            unmountOnExit
        >
            <Ribbon items={items} onChange={onSerumSelected} marginTop={marginTop ? marginTop : 10} />
        </CSSTransition>
    )
}

export default SerumsRibbon
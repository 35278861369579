import React from 'react'

import Password from './Password'
import RepeatPassword from './RepeatPassword'
import Name from './Name'
import Phone from './Phone'
import Email from './Email'
import ProfilePhoto from './ProfilePhoto'
import Salon from './Salon'
import Security from './Security'
import Birthday from './Birthday'
import About from './About'
import Submit from './Submit'
import Done from './Done'
import { SignupFormProvider } from '../SignupFormContext'
import useSignupForm from '../useSignupForm'

const SignupForm = ({ children }) => {
    // Hooks
    const { onSubmit, ...rest } = useSignupForm()

    return (
        <SignupFormProvider value={{ ...rest }}>            
            <form onSubmit={onSubmit}>
                {children}                
            </form>
        </SignupFormProvider>
    )
}

// Signup form components, lay these out any way you want ... 

SignupForm.Password = Password
SignupForm.RepeatPassword = RepeatPassword
SignupForm.Name = Name
SignupForm.Phone = Phone
SignupForm.Email = Email
SignupForm.ProfilePhoto = ProfilePhoto
SignupForm.Salon = Salon
SignupForm.Security = Security
SignupForm.Birthday = Birthday
SignupForm.About = About
SignupForm.Submit = Submit
SignupForm.Done = Done

export default SignupForm
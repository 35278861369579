import React, { Fragment, useState, useRef, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import axios from 'axios'
import { useStripe } from '@stripe/react-stripe-js'

import CardInput, { StripeError } from '../../../../controls/CardInput'
import Input from '../../../../controls/Input'
import Button from '../../../../controls/Button'
import AssetList from '../../../../controls/AssetList'
import { tokenConfig, timeoutConfig } from '../../../../../utils'
import { useTheme } from '../../../../contexts/Theme'
import { useError } from '../../../../contexts/Error'
import useProfileContext from '../ProfileContext'

const Cell = styled.div`
    display: table-cell;
`

const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: ${p => p.color};

    &.step-enter { opacity: 0; }
    &.step-enter-active { 
        opacity: 1;
        transition: opacity 1000ms; 
    }
    &.step-exit { opacity: 1; }
    &.step-exit-active {
        opacity: 0;
        transition: opacity 1000ms;
    }
`

const MenuBar = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-${p => p.align};
`

const Kill = styled.img`
    margin-top: 10px;
    margin-right: 10px;
    width: 30px;
    height: 30px;
`

const Instruction = styled.div`
    width: 80%;
    height: auto;
    font: 18px verdana, sans-serif;
    color: ${props => props.textColor};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    margin-top: 20px;
`

const ZipContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`

const AddCard = styled.div`
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2px groove ${props => props.borderColor};   
    background-image: url('${process.env.PUBLIC_URL}/images/add-card-${props => props.iconColor}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.touch { box-shadow: -2px 2px 10px 5px ${props => props.shadowColor}; }
    &.disabled {
        box-shadow: none; 
        opacity: 0.5; 
    }
`

let lastSetupIntent = null
let lastAddingCard = false

const CardManagerProxy = ({ onKill }) => {
    // State
    const [ addCardTouch, setAddCardTouch ] = useState(false)
    const [ addingCard, setAddingCard ] = useState(false)
    const [ billingEqualsShipping, setBillingEqualsShipping ] = useState(true)
    const [ zip, setZip ] = useState('')

    // Other hooks
    const stripe = useStripe()
    const { 
        activeClient, updateActiveClient, authToken, 
        setStatus, setSpinner, setStatusMessage, setErrorMessage, 
        setOnStatusOk, setOnStatusYes, setOnStatusNo,
        addResetFunction
    } = useProfileContext()
    const theme = useTheme()
    const setupIntent = useRef()
    const paymentMethod = useRef()

    useEffect(() => {
        addResetFunction(resetBackup)
        if (!lastSetupIntent) return;
        setupIntent.current = lastSetupIntent
        setAddingCard(lastAddingCard)
    }, [])

    // Query the active client
    const { shippingAddresses } = activeClient
    const disabled = shippingAddresses?.reduce((acc, addr) => acc = addr.isDefault ? false : acc, true)
    const defaultShippingAddress = disabled ? null : shippingAddresses.filter(addr => addr.isDefault)[0];

    // Destructure theme
    const { white, dark, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const [ textColor, shadowColor, borderColor, iconColor ] = theme.isDarkBackground() ? 
        [ white, white, rgbaWhiteBg, white ] : 
        [ rgbDark, rgbDark, rgbaDarkBg, dark ];

    const formatPaymentMethod = paymentMethod => {
        const { paymentType: type, cardName, lastFour } = paymentMethod
        return type === 'card' ? `${cardName} *-${lastFour}` : '';
    }

    const resetBackup = () => {
        lastAddingCard = false
        lastSetupIntent = null
    }

    // New credit card ready handler
    //
    const onCardReady = async (element, disable) => {

        // Disable card submission
        disable(true)

        try { 

            setOnStatusOk(() => () => {
                setStatus(false)
                setAddingCard(false)
                lastAddingCard = false
            })
            setStatus(true)
            setSpinner(true)
            
            // Confirm the setup intent
            let result = await stripe.confirmCardSetup(
                setupIntent.current.client_secret,
                {
                    payment_method: {
                        card: element,
                        billing_details: {
                            name: activeClient.name
                        }
                    } 
                }
            )

            if (result.error) throw new StripeError( { data: { message: result.error.message } } ) 

            // No error, payment method created by stripe, save the info ...

            // Retrieve the payment method object created by stripe            
            result = await axios.get(`/pay/payment-methods/${result.setupIntent.payment_method}`)

            const { id, card } = paymentMethod.current = result.data

            const pm = {
                paymentType: 'card',
                cardName: card.brand,
                lastFour: card.last4,
                stripePaymentMethodId: id,
                isEnabled: true
            }

            const paymentMethods = [ pm, ...activeClient.paymentMethods ]
            
            // Update the client DB            
            await axios.patch(`/api/clients/${activeClient._id}`, { paymentMethods }, timeoutConfig(tokenConfig(authToken)))

            // DB successfully updated, update the active client
            updateActiveClient("paymentMethods", paymentMethods)

            setSpinner(false)
            setStatusMessage(`${card.brand} ****-${card.last4} successfully saved.`)

        } catch(err) {

            if (err.response) { // status 400 (incl. authentication), 404 (not found), 500, stripe error
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, payment methods not updated.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, payment methods not updated, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, payment methods not updated`)
            }
            
            setSpinner(false)
        }
    }

    const onCardError = message => setErrorMessage(message)

    const onZipReady = e => {
        setBillingEqualsShipping(false)
    }

    // Credit card delete handler
    //
    const onCardDelete = async index => {

        try {

            setOnStatusOk(() => () => setStatus(false))
            setStatus(true)
            setSpinner(true)

            // Filter the payment methods
            const filtered = activeClient.paymentMethods.filter(pm => pm.isEnabled)

            // Stripe detach the payment method from the customer
            const pm = filtered[index]           
            await axios.delete(`/pay/payment-methods/${pm.stripePaymentMethodId}`, timeoutConfig(tokenConfig(authToken)))

            // Disable the deleted payment method
            pm.isEnabled = false
            pm.isDefault = false
            const paymentMethods = [ ...activeClient.paymentMethods ]
            
            // Update the client DB            
            await axios.patch(`/api/clients/${activeClient._id}`, { paymentMethods }, timeoutConfig(tokenConfig(authToken)))

            // DB successfully updated, update the active client
            updateActiveClient("paymentMethods", paymentMethods)

            setSpinner(false)
            setStatusMessage(`${pm.cardName} ****-${pm.lastFour} removed.`)

        } catch(err) {

            if (err.response) { // status 400 (incl. authentication), 404 (not found), 500
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, payment methods not updated.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, payment methods not updated, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, payment methods not updated`)
            }
            
            setSpinner(false)
        }
    }

    // Card set as default handler
    //
    const onCardSetDefault = async index => {

        try {

            setOnStatusOk(() => () => setStatus(false))
            setStatus(true)
            setSpinner(true)

            // Get the filtered list
            const filtered = activeClient.paymentMethods.filter(pm => pm.isEnabled)
            filtered.forEach((pm, i) => {
                pm.isDefault = i === index ? !pm.isDefault : false
            })

            // Get the patched list
            const paymentMethods = [ ...activeClient.paymentMethods ]
            
            // Update the client DB            
            await axios.patch(`/api/clients/${activeClient._id}`, { paymentMethods }, timeoutConfig(tokenConfig(authToken)))

            // DB successfully updated, update the active client
            updateActiveClient("paymentMethods", paymentMethods)

            setSpinner(false)
            setStatusMessage(`Default payment method ${filtered[index].isDefault ? 'set' : 'unset'}.`)

        } catch(err) {

            if (err.response) { // status 400 (incl. authentication), 404 (not found), 500
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, payment methods not updated.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, payment methods not updated, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, payment methods not updated`)
            }
            
            setSpinner(false)
        }
    }

    const onDisclaimerAccept = async e => {
        
        setAddingCard(true)
        lastAddingCard = true

        // Creating a new setup intent, so reset any old stuff
        setOnStatusOk(null)
        setupIntent.current = null
        lastSetupIntent = null

        try {
            setSpinner(true)
            
            // Create the stripe setup intent
            let rsp = await axios.post(
                '/pay/setup-intents/', { customer: activeClient.stripeCustomerId }, timeoutConfig(tokenConfig(authToken)) 
            )
            
            // Setup intent successfully created
            setSpinner(false)
            setStatus(false)
            setupIntent.current = rsp.data
            lastSetupIntent = rsp.data
    
        } catch(err) {

            if (err.response) { // status 400 (incl. authentication), 404 (not found), 500
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, payment methods not updated.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, payment methods not updated, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message)
                setStatusMessage(`Error: ${err?.message}, payment methods not updated`)
            }
            
            setOnStatusOk(() => onDisclaimerErrorExit)
            setAddingCard(false)
            lastAddingCard = false
            setSpinner(false)
        }
    }
    const onDisclaimerReject = e => {
        setStatus(false)
    }
    const onDisclaimerErrorExit = e => {
        setStatus(false)
    }

    // Add card handlers
    //
    const onAddCardTouch = e => setAddCardTouch(true)
    const onAddCardRelease = e => setAddCardTouch(false)
    const onAddCardClick = e => {
        setStatus(true)
        setOnStatusOk(null)
        setOnStatusYes(() => onDisclaimerAccept)
        setOnStatusNo(() => onDisclaimerReject)
        setStatusMessage(
            'I authorize Omni Creator Inc. to send instructions to the financial institution that issued my card ' + 
            'to take payments from my card account in accordance with the terms of my agreement with you.'
        )
    }
    
    const handleKill = () => {
        setAddingCard(false)
        lastAddingCard = false
        onKill()
    }

    return (
        <Cell>
            <Container color={textColor}>
                <MenuBar align='end'>
                    <Kill onClick={handleKill} src={`${process.env.PUBLIC_URL}/images/kill-${iconColor}.png`} />
                </MenuBar>
                <MenuBar align='start'>
                    <AddCard 
                        shadowColor={shadowColor} borderColor={borderColor} iconColor={iconColor}
                        className={`${addCardTouch ? 'touch' : ''} ${addingCard || disabled ? 'disabled' : ''}`}
                        onTouchStart={onAddCardTouch} onTouchEnd={onAddCardRelease} 
                        onClick={addingCard || disabled ? null : onAddCardClick}
                    />
                </MenuBar>
                {
                    disabled
                    &&
                    <Instruction>You must have a default shipping address before entering card details.</Instruction>
                }
                { 
                    addingCard 
                    && 
                    <Fragment>
                        <Instruction>Enter card details below for use in future purchases.</Instruction>
                        <CardInput 
                            marginTop={30} onReady={onCardReady} onError={onCardError} 
                            zip={billingEqualsShipping ? defaultShippingAddress?.zip : zip} 
                        />
                        <Instruction>Enter the billing zip code if different:</Instruction> 
                        <ZipContainer>
                            <Input 
                                type='text' name='zip' value={zip} width={120} 
                                placeholder='Zip' marginTop={20} onChange={e => setZip(e.target.value)}
                            />
                            <Button onClick={onZipReady} width={80} height={40} marginTop={20} label='Done' />
                        </ZipContainer>
                    </Fragment>
                }
                <AssetList 
                    assets={activeClient.paymentMethods?.filter(pm => pm.isEnabled)} 
                    title='Payment Methods (Select Default)' assetName='payment methods'
                    caption='Tap the circles to select the default payment method.' onTrash={onCardDelete}
                    marginTop={20} marginBottom={100} formatLabel={formatPaymentMethod} 
                    onDefaultChanged={onCardSetDefault}
                />
            </Container>
        </Cell>
    )
}

export const CardManager = () => {
    const { managingCards, setManagingCards } = useProfileContext()

    const onKill = () => setManagingCards(false)

    return (
        <CSSTransition 
            in={managingCards} 
            classNames="step" 
            timeout={500}
            unmountOnExit
        >
            <CardManagerProxy onKill={onKill} />
        </CSSTransition>
    )
}

const ManageCards = () => {
    const { managingAddresses, managingCards, setManagingCards } = useProfileContext()
    const { scrollTop } = useError()

    const onManageCards = () => {
        setManagingCards(true)
        scrollTop(0)
    }

    return (
        <Fragment>
        {
            (!managingCards && !managingAddresses)
            &&
            <Button 
                onClick={onManageCards} label='Manage Credit Cards' 
                width={180} height={70} marginTop={20}
            />
        }
        </Fragment>
    )
}

export default ManageCards
import React, { useState } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'

const Viewport = styled.div`
    width: 100%;
    height: 100px;
    overflow: scroll;
    margin-top: ${p => p.marginTop}px;
`

const TabGroup = styled.div`
    width: auto;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const Tab = styled.div`
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    width: ${props => props.width}px;
    height: 60px;
    flex-shrink: 0;

    &.selected {
        -webkit-filter: drop-shadow(0 0 10px ${props => props.shadowColor});
        -moz-filter: drop-shadow(0 0 10px ${props => props.shadowColor});
        -ms-filter: drop-shadow(0 0 10px ${props => props.shadowColor});
        -o-filter: drop-shadow(0 0 10px ${props => props.shadowColor});
        filter: drop-shadow(0 0 10px ${props => props.shadowColor});
    }
`

const TabContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    background-color: ${p => p.bgColor};
    color: ${p => p.color};
    font: 20px verdana, sans-serif;
`

const Stem = styled.div`
    position: absolute;
    bottom: 1px;
    left: 50%;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 5px 0 5px;
    border-color: ${p => p.bgColor} rgba(0, 0, 0, 0);
`

// tabs: [
//      { label, onClick }
// ]
const Tabs = ({ tabs, marginTop }) => {
    // State
    const [ selectedTab, setSelectedTab ] = useState(null)

    const theme = useTheme()

    // Destructure the theme
    const { white, rgbDark } = theme
    const [ color, bgColor, shadowColor ] = theme.isDarkBackground() ?
        [ rgbDark, white, 'rgba(255, 255, 255, 0.8)' ] :
        [ white, rgbDark, 'rgba(0, 255, 255, 0.8)' ];

    const onSelectTab = i => {
        setSelectedTab(i)
    }

    return (
        <Viewport marginTop={marginTop}>
            <TabGroup>
            {
                tabs.map((tab, i) => 
                    <Tab onClick={() => { onSelectTab(i); tab.onClick() }} key={i} width={110} 
                        className={ selectedTab === i ? "selected" : "" }
                        shadowColor={shadowColor} 
                    >
                        <TabContent bgColor={bgColor} color={color} >{tab.label}</TabContent>
                        <Stem bgColor={bgColor} />
                    </Tab>
                )
            }
            </TabGroup>
        </Viewport>
    )
}

export default Tabs;
import React, { Fragment } from 'react'

import Submit from '../../../../controls/Submit'
import useProfileContext from '../ProfileContext'

const ProfileSubmit = () => {
    // Hooks
    const { managingAddresses, managingCards } = useProfileContext()

    return (
        <Fragment>
        {
            (!managingAddresses && !managingCards)
            &&
            <Submit width={'100'} marginTop={'40'} text='Submit' />
        }
        </Fragment>
    )
}

export default ProfileSubmit
import React, { Fragment, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import HelpBubble from '../../../../controls/HelpBubble'
import { useHelp } from '../../../../contexts/Help'
import { useTheme } from '../../../../contexts/Theme'

const Container = styled.div`
    width: 60%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: ${props => props.marginTop}px;
`

const Action = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const EditProfile = styled.div`
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2px groove ${props => props.borderColor};   
    background-image: url('${process.env.PUBLIC_URL}/images/edit-profile-${props => props.iconColor}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.touch { box-shadow: -2px 2px 10px 5px ${props => props.shadowColor}; }
`

const Actions = () => {
    // State
    const [ editProfileTouch, setEditProfileTouch ] = useState(false)

    // Hooks
    const help = useHelp()
    const theme = useTheme()
    const toProfile = useRef()

    // Help
    const helpRequested = help.helpWasRequested()

    // Theme
    const { white, dark, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const [ shadowColor, borderColor, iconColor ] = theme.isDarkBackground() ? 
        [ white, rgbaWhiteBg, white ] : 
        [ rgbDark, rgbaDarkBg, dark ];

        // Edit profile handlers
        //
        const onEditProfileTouch = e => setEditProfileTouch(true)
        const onEditProfileRelease = e => {
            setEditProfileTouch(false)
            toProfile.current.click()
        } 

    return (
        <Fragment>
            <Link ref={toProfile} to="/client-profile" style={{display: "none"}} />
            <Container marginTop={20}>
                <Action>
                    { helpRequested && <HelpBubble caption='Edit your user profile.' marginBottom={5} />}
                    <EditProfile 
                        shadowColor={shadowColor} borderColor={borderColor}
                        iconColor={iconColor} className={`${editProfileTouch ? 'touch' : ''}`}
                        onTouchStart={onEditProfileTouch} onTouchEnd={onEditProfileRelease}
                        onMouseDown={onEditProfileTouch} onMouseUp={onEditProfileRelease}                                 
                    />
                </Action>
            </Container>
        </Fragment>
    )
}

export default Actions
import React, { Fragment } from 'react'
import { CSSTransition } from 'react-transition-group'

import ErrorMessage from '../../../../controls/ErrorMessage'
import MessageBox from '../../../../controls/MessageBox'
import Null from '../../../../controls/Null'
import useClientSignupContext from '../ClientSignupContext'
import { classicLight, classicDark } from '../../../../../overlayColors'

const lightOverlay = classicLight
const darkOverlay = classicDark

const Status = () => {
    // Hooks
    const { errorMessage, statusMessage, status, spinner, onStatusOk } = useClientSignupContext()
    
    const ErrorMessageOrNot = errorMessage ? ErrorMessage : Null;

    return (
        <Fragment>
            <ErrorMessageOrNot>{errorMessage}</ErrorMessageOrNot>
            <CSSTransition 
                in={status} 
                classNames="message" 
                timeout={1000}
                unmountOnExit
            >
                <MessageBox 
                    spinner={spinner} message={statusMessage}
                    lightBackground={lightOverlay} darkBackground={darkOverlay} 
                    onOk={ onStatusOk ? onStatusOk : () => null } label='Ok'
                    width='320' height='240' 
                />
            </CSSTransition>
        </Fragment>
    )
}

export default Status
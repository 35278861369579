import React, { useState, useEffect } from 'react'

import IconInput from '../../../../controls/IconInput'
import useClientSignupContext from '../ClientSignupContext'
import useClientSignupFormContext from '../ClientSignupFormContext'

let lastPhone = ''

const Phone = () => {
    // Local state
    const [ phone, setPhone ] = useState('')

    // Hooks
    const { addResetFunction } = useClientSignupContext()
    const { onPhoneChange } = useClientSignupFormContext()

    // Page mounted/portrait-to-landscape effect
    //
    useEffect(() => {
        addResetFunction(resetBackup)
        lastPhone && setPhone(lastPhone)
    }, [])

    const resetBackup = () => {
        lastPhone = ''
    }
    
    const onChange = e => {
        onPhoneChange(e)
        setPhone(e.target.value)
        lastPhone = e.target.value
    }

    return (
        <IconInput
            type='text' 
            iconUrl='phone' 
            name='phone'
            value={phone} 
            placeholder="Client's cell (required)"
            marginTop={30} 
            onChange={onChange}
            caption="Enter client's mobile phone number."
            required
        />
    )
}

export default Phone
import React, { Fragment } from 'react'
import styled from 'styled-components'

import Separator from '../../../../controls/Separator'
import { useTheme } from '../../../../contexts/Theme'
import useHomeContext from '../HomeContext'
import { firstName } from '../../../../../utils'

const Welcome = styled.div`
    width: 80%;
    height: auto;
    font: 20px verdana, sans-serif;
    color: ${props => props.textColor};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
`

const Greeting = () => {
    // Hooks
    const { activeClient } = useHomeContext()
    const theme = useTheme()

    const { white, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const [ textColor, borderColor ] = theme.isDarkBackground() ? [ white, rgbaWhiteBg ] : [ rgbDark, rgbaDarkBg ];

    return (
        <Fragment>
            <Welcome textColor={textColor}>
                Welcome to your Array &reg; home page, {firstName(activeClient.name)}!
            </Welcome>
            <Separator color={borderColor} marginTop={20} />
        </Fragment>
    )
}

export default Greeting
import React, { Fragment, useState, useRef } from 'react'
import styled from 'styled-components'
import { Navigate, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Portrait from '../controls/Portrait'
import SideMenu from '../controls/SideMenu'
import Header from '../controls/Header'
import Wallpaper from '../controls/Wallpaper'
import HierarchicalMenu from '../controls/HierarchicalMenu'
import HeaderDesktop from '../media/HeaderDesktop'
import Null from '../controls/Null'
import useSwitchTheme from '../../hooks/useSwitchTheme'
import { useTheme } from '../contexts/Theme'
import { usePhone } from '../../hooks/useMedia'
import {
    CLEAR_ACTIVE_ADMIN,
    CLEAR_TOKEN,
    RESTORE_ACTIVE_ADMIN,
    RESTORE_TOKEN
} from '../../redux/actions/types'
import { isEmpty } from '../../utils'

const Message = styled.div`
    width: 200px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${props => props.color};
    font: 28px verdana, sans-serif;
`

const EditProfile = styled.div`
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2px groove ${props => props.borderColor};   
    background-image: url('${process.env.PUBLIC_URL}/images/edit-profile-${props => props.iconColor}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: ${props => props.marginTop ? props.marginTop : 0}px;

    &.touch { box-shadow: -2px 2px 10px 5px ${props => props.shadowColor}; }
`

const AdminHome = () => {
    // State
    const [ editProfileTouch, setEditProfileTouch ] = useState(false)
    const [ loggingOff, setLoggingOff ] = useState(false)

    // Other hooks
    const theme = useTheme()
    const dispatch = useDispatch()
    const activeAdmin = useSelector(state => state.activeAdmin)
    const toProfile = useRef()
    const isPhone = usePhone()
    const mainMenu = useRef([
        { 
            label: 'Salons',
            menu: [
                { label: 'Add', to: '/add-salon' }
            ]
        },
        { 
            label: 'Members',
            menu: [
                { label: 'Add', to: '/add-member' }
            ] 
        },
        { 
            label: 'Admins',
            menu: [
                { label: 'Add', to: '/admin-signup' }
            ] 
        },
        { 
            label: 'Orders',
            to: '/orders'
        }
    ])

    useSwitchTheme()

    // Logoff doesn't need to re-render 
    loggingOff && <Navigate to='/' replace />

    // Check for and recover from page refresh
    if (isEmpty(activeAdmin)) {
        // Restore active admin and token from local storage post-refresh
        dispatch({ type: RESTORE_ACTIVE_ADMIN })
        dispatch({ type: RESTORE_TOKEN })

        // Don't want to render with missing data
        return <Null />
    }      

    const logoff = () => {
        setLoggingOff(true)
        dispatch({ type: CLEAR_ACTIVE_ADMIN })
        dispatch({ type: CLEAR_TOKEN })
    }

    // Edit profile handlers
    //
    const onEditProfileTouch = e => setEditProfileTouch(true)
    const onEditProfileRelease = e => {
        setEditProfileTouch(false)
        toProfile.current.click()
    }        
    
    // Destructure the theme
    const { white, dark, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const [ textColor, iconColor, borderColor, shadowColor ] = theme.isDarkBackground() 
        ? [ white, white, rgbaWhiteBg, white ] 
        : [ rgbDark, dark, rgbaDarkBg, rgbDark ];

    return (
        <Fragment>
            <Portrait>
                <Wallpaper />
                {
                    isPhone
                    ?
                    <Fragment>
                        <SideMenu />
                        <Header arrow={{
                            color: 'red',
                            to: '/',
                            destination: 'Logout'
                        }} onBack={logoff} separator />
                    </Fragment>
                    :
                    <HeaderDesktop height={100} leftContent={<Header arrow={{
                        color: 'red',
                        to: '/',
                        destination: 'Logout'
                    }} onBack={logoff} />} />
                }
                <HierarchicalMenu menu={mainMenu.current} />
                <Link ref={toProfile} to="/admin-profile" style={{display: "none"}} />
                <EditProfile 
                    shadowColor={shadowColor} borderColor={borderColor} marginTop={40}
                    iconColor={iconColor} className={`${editProfileTouch ? 'touch' : ''}`}
                    onTouchStart={onEditProfileTouch} onTouchEnd={onEditProfileRelease}                                 
                />
            </Portrait>
        </Fragment>
    )
}

export default AdminHome
import React, { Fragment } from 'react'

import Portrait from '../../../controls/Portrait'
import Wallpaper from '../../../controls/Wallpaper'
import Landscape from '../../../controls/Landscape'
import LandscapeSegment from '../../../controls/LandscapeSegment'
import SideMenu from '../../../controls/SideMenu'
import AddHaircutProxy from './AddHaircutProxy'
import { usePhone, useLandscape } from '../../../../hooks/useMedia'
import useSwitchTheme from '../../../../hooks/useSwitchTheme'

const Client = () => {
    // Local state

    // Other hooks
    useSwitchTheme()
    const isPhone = usePhone()
    const isLandscape = useLandscape()

    return (
        <Fragment>
            <Wallpaper />
            {
                isPhone 
                ?
                (
                    isLandscape 
                    ?
                    <Landscape align='flex-start'>
                        <AddHaircutProxy> 
                            <SideMenu />
                            <AddHaircutProxy.Footer leftPct={50} />
                            <LandscapeSegment pctWidth={45} pctHeight={100}>
                                <AddHaircutProxy.Header />
                                <AddHaircutProxy.Status />
                                <AddHaircutProxy.Instruction />
                            </LandscapeSegment>
                            <LandscapeSegment pctWidth={55}>
                                <AddHaircutProxy.Introduction marginTop={120} />
                                <AddHaircutProxy.Technicals marginBottom={50} />
                                <AddHaircutProxy.Templates marginBottom={50} />
                                <AddHaircutProxy.Topographies marginBottom={50} />
                                <AddHaircutProxy.Mix marginTop={120} />
                                <AddHaircutProxy.Photo marginTop={30} />
                                <AddHaircutProxy.NameAndSave marginTop={120} />
                            </LandscapeSegment>
                        </AddHaircutProxy>
                    </Landscape>
                    :
                    <Portrait>
                        <AddHaircutProxy> 
                            <SideMenu />
                            <AddHaircutProxy.Footer />
                            <AddHaircutProxy.Header />
                            <AddHaircutProxy.Status />
                            <AddHaircutProxy.Instruction />
                            <AddHaircutProxy.Introduction marginTop={30} />
                            <AddHaircutProxy.Technicals marginBottom={50} />
                            <AddHaircutProxy.Templates marginTop={30} marginBottom={50} />
                            <AddHaircutProxy.Topographies marginTop={30} />
                            <AddHaircutProxy.Mix marginTop={30} />
                            <AddHaircutProxy.Photo marginTop={30} />
                            <AddHaircutProxy.NameAndSave marginTop={30} />
                        </AddHaircutProxy>
                    </Portrait>
                )
                :
                (
                    isLandscape 
                    ?
                    <Portrait>
                        <AddHaircutProxy>
                            <AddHaircutProxy.Header />
                            <AddHaircutProxy.Status />
                            <Landscape align='flex-start'>
                                <AddHaircutProxy.Footer leftPct={50} />
                                <LandscapeSegment pctWidth={45} pctHeight={100}>
                                    <AddHaircutProxy.Instruction marginTop={100} />
                                </LandscapeSegment>
                                <LandscapeSegment pctWidth={55}>
                                    <AddHaircutProxy.Introduction marginTop={120} />
                                    <AddHaircutProxy.Technicals marginTop={50} marginBottom={100} />
                                    <AddHaircutProxy.Templates marginBottom={50} />
                                    <AddHaircutProxy.Topographies />
                                    <AddHaircutProxy.Mix marginTop={120} />
                                    <AddHaircutProxy.Photo marginTop={50} />
                                    <AddHaircutProxy.NameAndSave marginTop={80} />
                                </LandscapeSegment>
                            </Landscape>
                        </AddHaircutProxy>
                    </Portrait>
                    :
                    <Portrait>
                        <AddHaircutProxy>
                            <AddHaircutProxy.Footer />
                            <AddHaircutProxy.Header />
                            <AddHaircutProxy.Status />
                            <AddHaircutProxy.Instruction marginTop={40} />
                            <AddHaircutProxy.Introduction marginTop={50} />
                            <AddHaircutProxy.Technicals marginTop={50} />
                            <AddHaircutProxy.Templates marginBottom={50} />
                            <AddHaircutProxy.Topographies />
                            <AddHaircutProxy.Mix />
                            <AddHaircutProxy.Photo marginTop={50} />
                            <AddHaircutProxy.NameAndSave marginTop={80} />
                        </AddHaircutProxy>
                    </Portrait>
                )
            }
        </Fragment>
    )
}

export default Client
import React, { useState, useCallback, useRef, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { CardElement, useElements } from '@stripe/react-stripe-js'

import { useTheme } from '../contexts/Theme'
import Caption from './Caption'
import Null from './Null'

const UnderlineContainer = styled.div`
    position: relative;
    width: 320px;
    height: 50px;
    margin-top: ${props => props.marginTop}px;
    margin-bottom: ${props => props.marginBottom}px;
    overflow: hidden;
`

const UnderlineIcon = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    background-image: url('../../../images/credit-card-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const UnderlineInput = styled.div`
    position: absolute;
    right: 0;
    bottom: -10px;
    width: 270px;
    height: 40px;
`

const UnderlineViewport = styled.div`
    position: absolute;
    bottom: 0;
    right: 50px;
    width: 210px;
    height: 40px;
    overflow: hidden;
`

const StripeCardUnderlineInput = createGlobalStyle`
    .StripeElement {
        position: absolute;
        top: 0;
        left: 0;
        width: 320px;
        height: 40px;
        color: ${props => props.color};
        background-color: rgba(0, 0, 0, 0);
        border: 0;
        outline: none;
    }

    .StripeElement--invalid {
        border: 1px solid #fa755a;
    }
`

const Done = styled.img`
    position: absolute;
    right: 0;
    bottom: 50%;
    width: 50px;
    height: 20px;
    margin-bottom: -10px;
    border: none;
    outline: none;

    &.touch { box-shadow: -2px 2px 10px 5px ${props => props.bgColor}; }
    &.disabled { opacity: 0.5 }
`

const Underline = styled.div`
    position: absolute;
    bottom: 0px;
    left: 60px;
    right: 0px;
    border-style: solid;
    border-width: 1px;
    border-color: ${props => props.color};
    background-color: ${props => props.color};
`

const OvalContainer = styled.div`
    width: 320px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${props => props.marginTop}px;
`

const OvalIcon = styled.div`
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: ${props => props.bgColor};
    background-image: url('../../../images/credit-card-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px 50px;
`

const OvalInput = styled.div`
    position: relative;
    width: 250px;
    height: 60px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: none;
    background-color: ${props => props.bgColor};
    overflow: hidden;
`

const OvalViewport = styled.div`
    position: absolute;
    right: 65px;
    bottom: 0;
    width: 170px;
    height: 40px;
    overflow: hidden;
`

// position: absolute;
// left: 0;
// bottom: 0;
        // width: 360px;
        // height: 40px;
const StripeCardOvalInput = createGlobalStyle`
    .StripeElement {
        background-color: rgba(0, 0, 0, 0);
        color: ${props => props.color};
        border: 0;
        outline: none;
    }

    .StripeElement--invalid {
        border: 1px solid #fa755a;
    }
`

export class StripeError extends Error {
    constructor(errObj) {
        super()
        this.response = errObj
    }
}

const CardInput = ({ onReady, onError, zip, marginTop, marginBottom }) => {
    const [ done, setDone ] = useState(false)
    const [ doneDisabled, setDoneDisabled ] = useState(true)
    const [ captionVisible, setCaptionVisible ] = useState(false)

    // Other hooks
    const caption = useRef(
        'Enter indicated credit card fields, or scan your credit card on supporting devices, tap DONE to complete.')
    const cardElement = useRef(null)
    const elements = useElements()

    // Theme
    const theme = useTheme()
    const { white, dark, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const textColor = theme.isDarkBackground() ? white : rgbDark;
    const bgColor = theme.isDarkBackground() ? rgbaWhiteBg : rgbaDarkBg ;
    const iconColor = theme.isDarkBackground() ? white : dark;
    const stemOffset = theme.isInputOval() ? '30' : '25';
    const doneBottom = theme.isInputOval() ? '5' : '15';

    useEffect(() => {
        cardElement.current = elements.getElement(CardElement)
        try {
            cardElement.current.on('change', e => {
                if (e.complete) {
                    setDoneDisabled(false)
                    onError && onError(null)
                } else {
                    setDoneDisabled(true) 
                    if (e.error && onError) onError(e.error.message)
                }
            })
        } catch (err) {
            /* Log error */
        }
    }, [elements, onError])

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                fontFamily: 'verdana, sans-serif',
                fontSize: '18px',
                color: textColor,
                '::placeholder': { color: bgColor }
            },
            complete: { color: textColor }
        },
        value: { postalCode: zip }
        //, hidePostalCode: true
    }

    const onTouchIcon = useCallback(() => setCaptionVisible(!captionVisible), [captionVisible])
    const onTouchStartDone = () => setDone(true)
    const onTouchEndDone = () => {
        setDone(false)
        onReady(cardElement.current, val => setDoneDisabled(val))
    }

    const [ Container, StripeCardInput, Icon, Input, Viewport, UnderlineOrNot ] =
        theme.isInputOval() ?
        [ OvalContainer, StripeCardOvalInput, OvalIcon, OvalInput, OvalViewport, Null ] :
        [ UnderlineContainer, StripeCardUnderlineInput, UnderlineIcon, UnderlineInput, UnderlineViewport, Underline ];

    return (
        <Container marginTop={marginTop} marginBottom={marginBottom}>
            <StripeCardInput color={textColor} />
            <Icon bgColor={bgColor} color={iconColor} onTouchStart={onTouchIcon}>
                { captionVisible && <Caption text={caption.current} width='200' bottom='60' left='0' stemOffset={stemOffset}/> }
            </Icon>
            <Input bgColor={bgColor}>
                <Viewport>
                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                </Viewport> 
                <Done 
                    onTouchStart={doneDisabled ? null : onTouchStartDone} onTouchEnd={doneDisabled ? null : onTouchEndDone} 
                    bottom={doneBottom} bgColor={bgColor} src={`${process.env.PUBLIC_URL}/images/done-${iconColor}.png`} 
                    className={`${done ? 'touch' : ''} ${doneDisabled ? 'disabled' : ''}`}
                />
            </Input>
            <UnderlineOrNot color={textColor} />
        </Container>
    )
}

export default CardInput
import styled from 'styled-components'

export const ItemWrap = styled.div`
    width: ${p => p.width}px;
    height: ${p => p.height}px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`

const ItemContainer = styled.div`
    width: ${p => p.width}px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
`

export default ItemContainer
import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'

import { ADD_CLIENT_TO_MEMBER, SET_MEMBER_PROPERTY } from '../redux/actions/types'
import { isEmpty } from '../utils'

const useActiveMember = () => {
    // Local state
    const [ salon, setSalon ] = useState(null)

    // Get the active member
    const activeMember = useSelector(state => state.activeMember)
    const authToken = useSelector(state => state.token)
    const dispatch = useDispatch()

    // Get the salon for the active member
    //
    useEffect(() => {
        async function getSalon() {
            if (!isEmpty(activeMember)) {
                const rsp = await axios.get(`/api/salons/${activeMember.salon.salonId}/id`)
                setSalon(rsp.data)
            }
        }
        activeMember?.salon && getSalon()
    }, [ activeMember ])

    const updateActiveMember = useCallback((key, value) => {
        dispatch({
            type: SET_MEMBER_PROPERTY,
            payload: {
                key: key,
                value: value
            }
        })
    }, [ dispatch ])

    // Adds client summary to the host member: { name, id, sample photo}
    //
    const addClientToMember = useCallback(summary => {
        dispatch({ 
            type: ADD_CLIENT_TO_MEMBER,
            payload: summary 
        })
    }, [ dispatch ])

    return {
        activeMember,
        authToken,
        salon,
        updateActiveMember,
        addClientToMember
    }
}

export default useActiveMember
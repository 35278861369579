import React, { Fragment, useState } from 'react'
import styled from 'styled-components'

const Viewport = styled.div`
    width: ${p => p.pctWidth}%;
    height: ${p => p.height}px;
    margin-top: ${p => p.marginTop}px;
    overflow-x: scroll;
`

const Container = styled.div`
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`

const Item = styled.div`
    width: ${p => p.width - 8}px;
    height: ${p => p.height - 8}px;
    font: ${p => Math.floor(p.width/5)}px verdana, sans-serif;
    color: ${p => p.color};
    background-color: ${p => p.bgColor};
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    border: 4px outset rgb(40, 40, 40);

    &.pressed {
        border-style: inset;
    }
`

//  items: [{
//      icon: string (optional),
//      label: string (optional),
//      onClick: handler,
//      color: string,
//      bgColor: string
//  }, ...]
//
const GridMenu = ({ gridWidthPct, cellSize, items, marginTop }) => {
    const [ pressed, setPressed ] = useState(-1)

    return (
        <Fragment>
            <Viewport pctWidth={gridWidthPct} height={cellSize} marginTop={marginTop}>
                <Container>
                {
                    items.map((item, i) => {
                        const { color, bgColor, onClick, label } = item 
                        return (
                        <Item 
                            key={i} width={cellSize} height={cellSize} className={pressed === i ? 'pressed' : ''}
                            onTouchStart={() => setPressed(i)} onTouchEnd={() => setPressed(-1)} 
                            color={color} bgColor={bgColor} onClick={onClick}>
                            {label}
                        </Item>)
                    })
                }
                </Container>
            </Viewport>
        </Fragment>
    )
}

export default GridMenu
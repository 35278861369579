import React, { useCallback, Fragment } from 'react'
import { CSSTransition } from 'react-transition-group'

import MessageBox from '../../../../controls/MessageBox'
import Spinner from '../../../../controls/Spinner'
import useSalonOrderContext from '../SalonOrderContext'
import { lightBlue, darkBlue } from '../../../../../overlayColors'

const PayStatus = () => {
    // Global state
    const { payStatus, setPayStatus, spinner, statusMessage } = useSalonOrderContext()

    const handleMessageBoxOk = useCallback(
        () => setPayStatus(false),
        [ setPayStatus ],
    )

    return (
        <Fragment>
            <CSSTransition 
                in={payStatus} 
                classNames="message" 
                timeout={1000}
                unmountOnExit
            >
                <MessageBox 
                    spinner={spinner} message={statusMessage}
                    lightBackground={lightBlue} darkBackground={darkBlue} 
                    onOk={handleMessageBoxOk} label='Ok'
                    width='320' height='240' 
                />
            </CSSTransition>
            {
                (spinner && !payStatus)
                &&
                <Spinner diameter={100} />
            }
        </Fragment>
    )
}

export default PayStatus
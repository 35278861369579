import React, { Fragment, useEffect } from 'react'

import IconInput from '../../../../controls/IconInput'
import useProfileContext from '../ProfileContext'
import useProfileFormContext from '../ProfileFormContext'

let lastRepeatPassword = ''

const RepeatPassword = () => {
    // Hooks
    const { addResetFunction, activeClient, managingAddresses, managingCards } = useProfileContext()
    const { repeatPassword, setRepeatPassword } = useProfileFormContext()

    useEffect(() => {
        addResetFunction(resetBackup)
        lastRepeatPassword && setRepeatPassword(lastRepeatPassword)
    }, [])

    const resetBackup = () => {
        lastRepeatPassword = ''
    }

    const onChange = e => {
        setRepeatPassword(e.target.value)
        lastRepeatPassword = e.target.value
    }

    return (
        <Fragment>
        {
            (!managingAddresses && !managingCards)
            &&
            <IconInput 
                type='password' 
                iconUrl='password' 
                name='repeatPassword'
                placeholder='Repeat new password'
                marginTop={30} 
                value={repeatPassword}
                onChange={onChange}
                required={activeClient.firstVisit}
            />
        }
        </Fragment>
    )
}

export default RepeatPassword
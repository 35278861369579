import React, { Fragment, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import Spinner from './Spinner'
import Scheduler from './Scheduler'
import { useTheme } from '../contexts/Theme'
import { timeoutConfig } from '../../utils'

const lastDayOfMonth = (month, year) => {
    const isLeapYear = year => (year % 100 === 0) ? (year % 400 === 0) : (year % 4 === 0);

    const lastDay = [
        31, // January
        28, // February
        31, // March
        30, // April
        31, // May
        30, // June
        31, // July
        31, // August
        30, // September
        31, // October
        30, // November
        31  // December 
    ]

    if (month === -1) month = 11
    return lastDay[month] + (month === 1 && isLeapYear(year) ? 1 : 0)
}

export const monthMap = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
]

// Build and return the following data structure for the given date's month's calendar:
//      {
//          year: year,
//          month: 0 .. 11,
//          dayInLastMonth: { year, month, day },
//          days: [ sundays, mondays, .. saturdays ],
//          dayInNextMonth: { year, month, day }
//      }
//
const getMonth = ( date, dayOfTheWeek /* 0 .. 6 */ ) => {
    let lastMonthWeekDay, nextMonthWeekday;
    const monthRequested = [
        [], // Sundays
        [], // Mondays
        [], // Tuesdays
        [], // Wednesdays
        [], // Thursdays
        [], // Fridays
        [], // Saturdays
    ]

    // Get today's date for future reference
    const today = new Date()
    const todayYear = today.getFullYear()
    const todayMonth = today.getMonth() // 0 - 11
    const todayDay = today.getDate() // 1 - 31

    // Break down the given date
    const year = date.getFullYear()
    const month = date.getMonth() // 0 - 11
    const dayOfTheMonth = date.getDate() // 1 - 31

    // Check against today so far
    let isToday = todayYear === year && todayMonth === month;
    let tag = 
        todayYear > year ? "past" : 
        todayYear < year ? "future" :
        todayMonth > month ? "past" :
        todayMonth < month ? "future" :
        "";

    // Work backwards
    let day = dayOfTheMonth, weekDay = dayOfTheWeek;
    while (day > 0) { 
        monthRequested[weekDay].unshift({ 
            day: day,
            class: isToday ? ( day === todayDay ? "today" : day < todayDay ? "past" : "future" ) : tag
        })
        day--;
        if (--weekDay === -1) weekDay = 6;
    }
    // Fill in last month
    if (day === 0 && weekDay === 6) { // No last month fill-ins
        lastMonthWeekDay = 6
    } else {
        lastMonthWeekDay = weekDay
        day = lastDayOfMonth(month - 1, year)
        while (weekDay > -1) {
            monthRequested[weekDay--].unshift({ day: day--, class: "other" })
        }
    }

    // Work forwards
    day = dayOfTheMonth
    weekDay = dayOfTheWeek
    while (day < lastDayOfMonth(month, year)) {
        if (++weekDay === 7) weekDay = 0
        monthRequested[weekDay].push({ 
            day: ++day,
            class: isToday ? ( day === todayDay ? "today" : day < todayDay ? "past" : "future" ) : tag 
        })
    }
    // Fill in next month
    if (weekDay === 6) { // Done
        nextMonthWeekday = 0
    } else {
        day = 1
        weekDay++
        nextMonthWeekday = weekDay
        while (weekDay < 7) {
            monthRequested[weekDay++].push({ day: day++, class: "other" })
        }
    }

    return {
        year: year,
        month: month,
        dayInLastMonth: {
            year: month === 0 ? year - 1 : year,
            month: month === 0 ? 11 : month - 1,
            day: lastDayOfMonth(month - 1, year),
            weekDay: lastMonthWeekDay
        },
        days: monthRequested,
        dayInNextMonth: { 
            year: month === 11 ? year + 1 : year,
            month: (month + 1) % 12,
            day: 1,
            weekDay: nextMonthWeekday
        }
    }
}

const BOX_SIZE = 35;

const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    justify-content: space-between;
    font: 20px verdana, sans-serif;
    transition: top 1s;
`

const Next = styled.img`
    width: 50px;
    height: 50px;
`

const Previous = styled.img`
    width: 50px;
    height: 50px;
`

const Month = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const Title = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${p => p.color};
`

const Headings = styled.div`
    width: auto;
    height: 32px;
    display: flex;
    justify-content: flex-start;
    background-color: ${props => props.bgColor};
    color: ${props => props.color};
`

const DayHeading = styled.div`
    width: ${BOX_SIZE}px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-shrink: 0;
    border: 1px solid ${p => p.borderColor};
`

const DaysOfMonth = styled.div`
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: ${props => props.color};
`

const DaysOfWeek = styled.div`
    width: ${BOX_SIZE}px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-style: solid;
    border-width: 0 1px 0 1px;
    border-color: ${p => p.borderColor};
`

const Day = styled.div`
    width: ${BOX_SIZE}px;
    height: ${BOX_SIZE}px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    border-style: solid;
    border-width: 1px 0 1px 0;
    border-color: ${p => p.borderColor};

    &.other { background-color: ${p => p.otherBgColor}; }
    &.today { color: ${p => p.todayBgColor}; }
`

const ErrorMessage = styled.div`
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font: 20px verdana, sans-serif;
    color: red;
`

// This takes the structure calendarMonth: { year, month, days }
//
const CalendarMonth = ({ calendarMonth, appointments }) => {
    // State
    const [ date, setDate ] = useState({})

    // Other hooks
    const theme = useTheme()
    const schedulerTap = useRef()

    useEffect(() => {
        console.log('MONTH MOUNTING...', appointments)
    }, [calendarMonth, appointments])

    const onDayClick = (y, m, d) => {
        setDate({ year: y, month: m, day: d })
        schedulerTap.current()
    }

    // Destructure the theme
    const { white, rgbDark, rgbaWhiteBg, rgbaDarkBg } = theme
    const [ color, otherBgColor, headerBgColor, borderColor, todayBgColor ] = theme.isDarkBackground() ?
        [ white, rgbaWhiteBg, "rgb(112, 146, 190)", white, "rgb(0, 128, 255)" ] :
        [ rgbDark, rgbaDarkBg, "rgb(112, 146, 190)", rgbDark, "rgb(0, 128, 255)" ]
    
    return (
        <Fragment> 
            <Scheduler 
                onTap={set => schedulerTap.current = () => set(true)}
                day={date.day} month={date.month} year={date.year} 
            />
            <Month>
                <Title color={color}>{`${monthMap[calendarMonth.month]} ${calendarMonth.year}`}</Title>
                <Headings bgColor={headerBgColor} color={color} borderColor={borderColor}>
                    <DayHeading borderColor={borderColor}>Su</DayHeading>
                    <DayHeading borderColor={borderColor}>Mo</DayHeading>
                    <DayHeading borderColor={borderColor}>Tu</DayHeading>
                    <DayHeading borderColor={borderColor}>We</DayHeading>
                    <DayHeading borderColor={borderColor}>Th</DayHeading>
                    <DayHeading borderColor={borderColor}>Fr</DayHeading>
                    <DayHeading borderColor={borderColor}>Sa</DayHeading>
                </Headings>
                <DaysOfMonth color={color}>
                {
                    /* Iterate over the week days 0 .. 6 */
                    calendarMonth.days.map((weekDay, i) =>
                        <DaysOfWeek key={i} borderColor={borderColor}>
                        {
                            /* Iterate within individual week days */
                            weekDay.map((wd, j) => {
                                const m = calendarMonth.month, d = wd.day, y = calendarMonth.year;
                                return (
                                    <Day 
                                        onClick={
                                            (wd.class === "today" || wd.class === "future") ?
                                            () => onDayClick(y, m, d) :
                                            () => null
                                        }
                                        key={j} borderColor={borderColor} className={wd.class} 
                                        otherBgColor={otherBgColor} todayBgColor={todayBgColor}
                                    >
                                        {wd.day}
                                    </Day>
                                )
                            })
                        }
                        </DaysOfWeek> 
                    )
                }
                </DaysOfMonth>
            </Month>
        </Fragment>
    )
}

// New calendar always starts with today's month
//
const Calendar = ( member ) => {
    // State
    const [ calendar, setCalendar ] = useState(getMonth(new Date(), new Date().getDay()))
    const [ spinner, setSpinner ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState('')

    useEffect(() => {
        // Get all the member's appointments
        async function getAppointments(id) {
            try {
                setSpinner(true)
                const rsp = await axios.get(`/api/appointments/${id}`, timeoutConfig({}))
                appointments.current = rsp.data
                setSpinner(false)
            } catch (err) {
                if (err.response) {
                    switch (err.response.status) {
                        case 500: // Server error
                            setErrorMessage('Server error, appointments not available.')
                            console.log('Error! ', 'Server error')
                            break;
                        default: // 400, session expired
                            console.log('Error! ', err.response.data)
                            setErrorMessage(`${err.response.data.message}, appointments not available.`)
                    }
                } else if (err?.code === 'ECONNABORTED') { // Timeout error
                    setErrorMessage(`Server took too long to respond, appointments not available, try again later.`)
                } else { // Not likely
                    console.log('ERR: ', err?.code)
                    console.log('ERR: ', err?.message) 
                    setErrorMessage(`Error: ${err?.message}, appointments not available.`)
                }

                setSpinner(false)
            }
        }
        getAppointments(member.member._id)
    }, [ member ])

    // Other hooks
    const theme = useTheme()
    const appointments = useRef()
    
    // Destructure the theme
    const { white, dark } = theme
    const [ iconColor ] = theme.isDarkBackground() ?
        [ white ] : [ dark ]

    const onPrevious = () => {
        setCalendar(getMonth(new Date(
            calendar.dayInLastMonth.year,
            calendar.dayInLastMonth.month,
            calendar.dayInLastMonth.day
        ), calendar.dayInLastMonth.weekDay))
    }

    const onNext = () => {
        setCalendar(getMonth(new Date(
            calendar.dayInNextMonth.year,
            calendar.dayInNextMonth.month,
            calendar.dayInNextMonth.day
        ), calendar.dayInNextMonth.weekDay))
    }
    
    return (
        <Fragment>
        {
            spinner
            ?
            <div style={{ position: "relative", height: "100px", width: "100px" }}>
                <Spinner diameter={100} />
            </div>
            :
            errorMessage
            ?
            <ErrorMessage>{errorMessage}</ErrorMessage>
            :
            <Fragment>
            {
                calendar 
                &&
                <Container> 
                    <Previous
                        onClick={onPrevious} 
                        src={`${process.env.PUBLIC_URL}/images/previous-${iconColor}.png`} 
                    />
                    <CalendarMonth 
                        calendarMonth={{ year: calendar.year, month: calendar.month, days: calendar.days }}
                        appointments={appointments.current} 
                    />
                    <Next
                        onClick={onNext}
                        src={`${process.env.PUBLIC_URL}/images/next-${iconColor}.png`} 
                    />
                </Container>
            }
            </Fragment>
        }
        </Fragment>
    )
}

export default Calendar
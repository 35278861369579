import React, { Fragment } from 'react'

import Ribbon, { PRIME12, TUNE12, CUT12 } from '../../../../controls/Ribbon'
import useOrderContext from '../OrderContext'

const items = [
    PRIME12,
    TUNE12,
    CUT12
]

const ClientOrderRibbon = ({ marginTop }) => {
    const { defaultPaymentMethodSet, defaultShippingAddressSet, onSelected } = useOrderContext()

    return (
        <Fragment>
        {
            defaultPaymentMethodSet && defaultShippingAddressSet &&
            <Ribbon items={items} onChange={onSelected} marginTop={ marginTop ? marginTop : 10 } />
        }
        </Fragment>
    )
}

export default ClientOrderRibbon
import React from 'react'
import { CSSTransition } from 'react-transition-group'

import Ribbon, { CREAM_SMOOTH, CREAM_SOFT, CREAM_TEXTURED } from '../../../../controls/Ribbon'
import useSalonOrderContext from '../SalonOrderContext'

const items = [
    CREAM_SMOOTH,
    CREAM_SOFT,
    CREAM_TEXTURED
]

const CreamsRibbon = ({ marginTop }) => {
    const { menuSelection, onCreamSelected } = useSalonOrderContext()

    return (
        
        <CSSTransition 
            in={menuSelection.creamsSelected} 
            classNames="ribbon" 
            timeout={1000}
            unmountOnExit
        >
            <Ribbon items={items} onChange={onCreamSelected} marginTop={ marginTop ? marginTop : 10 } />
        </CSSTransition>
    )
}

export default CreamsRibbon
import React, { useState } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'
import Caption from './Caption'
import Null from './Null'

const Container = styled.div`
    width: 320px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: ${props => props.marginTop}px;
`

const UnderlineIcon = styled.div`
    position: relative;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0);
    background-image: url('../../../images/about-me-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const UnderlineContent = styled.textarea`
    width: 250px;
    height: 150px;
    outline: none;
    border: 2px solid ${props => props.color};
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    color: ${props => props.color};
    font: 18px verdana, sans-serif;

    &::placeholder { opacity: 0.6; }
`

const OvalIcon = styled.div`
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: ${props => props.bgColor};
    background-image: url('../../../images/about-me-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px 50px;
`

const OvalContent = styled.textarea`
    width: 210px;
    height: 150px;
    outline: none;
    border: none;
    border-radius: 30px;
    padding: 20px;
    background-color: ${props => props.bgColor};
    color: ${props => props.color};
    font: 18px verdana, sans-serif;

    &::placeholder { 
        color: ${props => props.color};
        opacity: 0.6; 
    }
`

const AboutMe = ({ value, onChange, placeholder, caption, marginTop }) => {
    // State
    const [ captionVisible, setCaptionVisible ] = useState(false)

    // Theme
    const theme = useTheme()
    const { white, dark, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const bgColor = theme.isDarkBackground() ? rgbaWhiteBg : rgbaDarkBg;
    const iconColor = theme.isDarkBackground() ? white : dark;
    const textColor = theme.isDarkBackground() ? white : rgbDark;
    const stemOffset = theme.isInputOval() ? '30' : '25';

    // Handlers
    const onTouchIcon = () => setCaptionVisible(!captionVisible)

    // Variable components
    const Icon = theme.isInputOval() ? OvalIcon : UnderlineIcon;
    const Content = theme.isInputOval() ? OvalContent : UnderlineContent;
    const CaptionOrNot = caption && captionVisible ? Caption : Null;

    return (               
        <Container marginTop={marginTop}>
            <Icon bgColor={bgColor} color={iconColor} onClick={onTouchIcon}>
                <CaptionOrNot text={caption} width='200' bottom='60' left='0' stemOffset={stemOffset} />
            </Icon>
            <Content value={value} bgColor={bgColor} color={textColor} onChange={onChange} placeholder={placeholder} />
        </Container>
    )
}

export default AboutMe
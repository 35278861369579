import { useRef, useEffect } from 'react'

let lastResetFunctions = []

const useClients = () => {
    
    const resetFunctions = useRef([])

    useEffect(() => {
        resetFunctions.current = lastResetFunctions
    }, [])

    const addResetFunction = func => {
        resetFunctions.current.push(func)
        lastResetFunctions.push(func)
    }

    const runResetFunctions = () => resetFunctions.current.forEach(func => func?.())

    return {
        addResetFunction, runResetFunctions
    }
}

export default useClients
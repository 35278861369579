import React, { Fragment, useState, useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import axios from 'axios'
import { Form } from 'reactstrap'

import Header from '../controls/Header'
import Column from '../controls/Column'
import HeaderDesktop from '../media/HeaderDesktop'
import SideMenu from '../controls/SideMenu'
import Wallpaper from '../controls/Wallpaper'
import Null from '../controls/Null'
import MessageBox from '../controls/MessageBox'
import ErrorMessage from '../controls/ErrorMessage'
import IconInput from '../controls/IconInput'
import Submit from '../controls/Submit'
import { usePhone, useLandscape } from '../../hooks/useMedia'
import useSwitchTheme from '../../hooks/useSwitchTheme'
import {
    RESTORE_ACTIVE_ADMIN, 
    RESTORE_TOKEN
} from '../../redux/actions/types'
import { isEmpty, tokenConfig, timeoutConfig } from '../../utils'
import { classicLight, classicDark } from '../../overlayColors'

const Halves = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`

const Half = styled.div`
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const lightOverlay = classicLight
const darkOverlay = classicDark

const handleInputChange = (e, key, dispatch) => {
    dispatch({
        type: SET_NEW_PROPERTY,
        payload: {
            key: key,
            value: e.target.value
        }
    })
}

const SET_NEW_PROPERTY = "SET_NEW_PROPERTY"
const adminReducer = (admin, action) => {
    switch (action.type) {
        case SET_NEW_PROPERTY: 
            admin = { ...admin, [action.payload.key]: action.payload.value }
            return admin
        default: 
            return admin
    }
}

const AdminSignup = () => {
    // Local state
    const [ newAdmin, adminDispatch ] = useReducer(adminReducer, {})
    const [ messageBoxVisible, setMessageBoxVisible ] = useState(false)
    const [ spinnerVisible, setSpinnerVisible ] = useState(false)
    const [ statusMessage, setStatusMessage ] = useState('')
    const [ errorMessage, setErrorMessage ] = useState(null)

    // Other hooks
    const dispatch = useDispatch()
    const activeAdmin = useSelector(state => state.activeAdmin)
    const authToken = useSelector(state => state.token)
    const isPhone = usePhone()
    const isLandscape = useLandscape()
    useSwitchTheme()
    
    // Check for and recover from page refresh
    if (isEmpty(activeAdmin)) {
        // Restore active admin and token from local storage post-refresh
        dispatch({ type: RESTORE_ACTIVE_ADMIN })
        dispatch({ type: RESTORE_TOKEN })

        // Don't want to render with missing data
        return <Null />
    }

    // Handlers
    const onNameChange = e => handleInputChange(e, 'name', adminDispatch)
    const onUseridChange = e => {
        setErrorMessage(null)
        handleInputChange(e, 'userid', adminDispatch)
    }
    const onEmailChange = e => handleInputChange(e, 'email', adminDispatch)
    const onPhoneChange = e => handleInputChange(e, 'phone', adminDispatch)

    const onSubmit = async e => {
        e.preventDefault()

        try {
            
            setMessageBoxVisible(true)
            setSpinnerVisible(true)

            // Check userid availability
            await axios.get(`/api/login/${newAdmin.userid}`, timeoutConfig({}))
            
            // Save the new admin to the DB
            newAdmin.firstVisit = true
            const rsp = await axios.post('/api/admins', newAdmin, timeoutConfig(tokenConfig(authToken)))

            console.log(rsp)

            setSpinnerVisible(false)
            setStatusMessage(`Admin successfully added.`)

        } catch (err) {
            if (err.response) {
                switch (err.response.status) {
                    case 403: // Userid in use
                        setErrorMessage(err.response.data.error)
                        setStatusMessage(`${err.response.data.error} and is invalid, see above.`)
                        break;
                    case 500: // Server error
                        setStatusMessage('Server error, admin not registered.')
                        console.log('Error! ', 'Server error')
                        break;
                    case 412: // Twilio error
                        setStatusMessage('Message send error, see webmaster for access code.')
                        break;
                    default: // 400, session expired
                        console.log('Error! ', err.response.data)
                        setStatusMessage(`${err.response.data.message}, admin not registered.`)
                }
            } else if (err?.code === 'ECONNABORTED') { // Timeout error
                setStatusMessage(`Server took too long to respond, admin not registered, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, admin not created`)
            }           

            setSpinnerVisible(false)
        }
    }

    const ErrorMessageOrNot = errorMessage ? ErrorMessage : Null;

    return (
        <Fragment>
            <Wallpaper />
            <CSSTransition 
                in={messageBoxVisible} 
                classNames="message" 
                timeout={1000}
                unmountOnExit
            >
                <MessageBox  
                    spinner={spinnerVisible} message={statusMessage}
                    lightBackground={lightOverlay} darkBackground={darkOverlay}
                    onOk={()=> setMessageBoxVisible(false)} label='Ok'
                    width='320' height='240' 
                />
            </CSSTransition>
            { /* Header section */
                isPhone
                ?
                <Fragment>
                    <SideMenu top={isLandscape ? 50 : 0} />
                    <Header arrow={{
                        color: 'yellow',
                        to: '/admin-home',
                        destination: 'Home'
                    }} title='Add Admin' separator />
                </Fragment>
                :
                <HeaderDesktop height={100} leftContent={<Header arrow={{
                    color: 'yellow',
                    to: 'admin-home',
                    destination: 'Home'
                }} title='Add Admin' />} />
            }
            <ErrorMessageOrNot>{errorMessage}</ErrorMessageOrNot>
            <Form onSubmit={onSubmit}>
            {
                isLandscape
                ?
                <Column>
                    <Halves>
                        <Half>           
                            <IconInput 
                                type='text' 
                                iconUrl='name' 
                                name='name'
                                value={newAdmin.name} 
                                placeholder='Admin name (required)'
                                marginTop={30} 
                                onChange={onNameChange}
                                required
                            />
                            <IconInput 
                                type='text' 
                                iconUrl='userid' 
                                name='userid'
                                value={newAdmin.userid} 
                                placeholder='Admin userid (required)'
                                marginTop={30} 
                                onChange={onUseridChange}
                                required
                            />
                        </Half>
                        <Half>
                            <IconInput 
                                type='text' 
                                iconUrl='email' 
                                name='email'
                                value={newAdmin.email} 
                                placeholder='Admin email (required)'
                                marginTop={30} 
                                onChange={onEmailChange}
                                required
                            />
                            <IconInput 
                                type='text' 
                                iconUrl='phone' 
                                name='phone'
                                value={newAdmin.phone} 
                                placeholder='Admin phone (required)'
                                marginTop={30} 
                                onChange={onPhoneChange}
                                required
                            />
                        </Half>
                    </Halves>
                    <Submit width={'100'} marginTop={'40'} />
                </Column>
                :
                <Column>           
                    <IconInput 
                        type='text' 
                        iconUrl='name' 
                        name='name'
                        value={newAdmin.name} 
                        placeholder='Admin name (required)'
                        marginTop={30} 
                        onChange={onNameChange}
                        required
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='userid' 
                        name='userid'
                        value={newAdmin.userid} 
                        placeholder='Admin userid (required)'
                        marginTop={30} 
                        onChange={onUseridChange}
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='email' 
                        name='email'
                        value={newAdmin.email} 
                        placeholder='Admin email (required)'
                        marginTop={30} 
                        onChange={onEmailChange}
                        required
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='phone' 
                        name='phone'
                        value={newAdmin.phone} 
                        placeholder='Admin phone (required)'
                        marginTop={30} 
                        onChange={onPhoneChange}
                    />
                    <Submit width={'100'} marginTop={'40'} />
                </Column> 
            }
            </Form>
        </Fragment>
    )
}

export default AdminSignup
import React, { useContext, createContext } from 'react'

const ErrorContext = createContext()

export function ErrorProvider(props) {
    const value = {
        passwordError: (password, repeatPassword) => {
            if ( !password || !repeatPassword ) return {
                error: true,
                message: 'password and repeat password must be entered'
            }
            if ( password.length < 8 ) return {
                error: true,
                message: 'The password must be eight characters or greater'
            }
            if ( password !== repeatPassword ) return {
                error: true,
                message: 'Password and repeat password must be the same'
            }
    
            return { error: false }
        },
        passwordsEqualError: (password, repeatPassword) => {
            if ( !password && !repeatPassword ) return {
                error: false
            }
            if ( password.length < 8 ) return {
                error: true,
                message: 'The password must be eight characters or greater'
            }
            if ( password !== repeatPassword ) return {
                error: true,
                message: 'Password and repeat password must be the same'
            }
    
            return { error: false }
        },
        questionAndAnswerError: (question, answer) => {
            if ( question === undefined && answer ) return {
                error: true,
                message: 'If a secret question answer is entered a question must be selected'
            }
            if ( question !== undefined && answer === undefined ) return {
                error: true,
                message: 'If a secret question is selected an answer must be entered'
            }
    
            return { error: false }
        },
        questionAndAnswerRequiredError: (question, answer) => {
            if ( question === undefined || answer === undefined ) return {
                error: true,
                message: 'A secret question must be selected and an answer must be entered'
            }
    
            return { error: false }
        },
        salonError: salon => {
            if ( !salon ) return {
                error: true,
                message: 'Salon must be selected'
            }
    
            return { error: false }
        },
        addressError: address => {
            const { addr1, city, state, zip } = address 
            if ( addr1.length === 0 ) return {
                error: true,
                message: 'First address line is required'
            } 
            if ( city.length === 0 ) return {
                error: true,
                message: 'City or municipality is required'
            } 
            if ( state.length === 0 ) return {
                error: true,
                message: 'State or province is required'
            } 
            if ( zip.length === 0 ) return {
                error: true,
                message: 'Zip code or postal code is required'
            }
    
            return { error: false }
        },
        rangeError: (value, range, message) => {
            if (value > range.high || value < range.low) return {
                error: true,
                message
            }
    
            return { error: false }
        },
        scrollTop: top => {
            document.documentElement.scrollTop = document.body.scrollTop = top
        }
    }

    return <ErrorContext.Provider value={value} {...props} />
}

export function useError() {
    const errorContext = useContext(ErrorContext)
    if (!errorContext) throw new Error('Error context not available.')
    
    return errorContext
}
import React, { Fragment, useCallback } from 'react'

import { START } from '../useAddHaircut'
import Button from '../../../../controls/Button'
import useAddHaircutContext from '../AddHaircutContext'

const Introduction = ({ marginTop }) => {
    const { stage, onStageChange } = useAddHaircutContext()

    const onStart = useCallback(() => onStageChange(START), [])

    return (
        <Fragment>
        {
            (stage === START) &&
            <Button onClick={onStart} label='Start Haircut' width={200} height={75} marginTop={marginTop} fontSize={24} />
        }
        </Fragment>
    )
}

export default Introduction
import React from 'react'
import { CSSTransition } from 'react-transition-group'

import Ribbon, { PRIME1_16, PRIME2_16, PRIME3_16 } from '../../../../controls/Ribbon'
import useSalonOrderContext from '../SalonOrderContext'

const items = [
    PRIME1_16,
    PRIME2_16,
    PRIME3_16
]

const PrimeRibbon = ({ marginTop }) => {
    const { menuSelection, onPrimeSelected } = useSalonOrderContext()

    return (
        
        <CSSTransition 
            in={menuSelection.primeSelected} 
            classNames="ribbon" 
            timeout={1000}
            unmountOnExit
        >
            <Ribbon items={items} onChange={onPrimeSelected} marginTop={ marginTop ? marginTop : 10 } />
        </CSSTransition>
    )
}

export default PrimeRibbon
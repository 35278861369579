import React, { createContext, useContext } from 'react'

const Client = createContext(undefined)

export const ClientProvider = ({ children, value }) => {
    return (
        <Client.Provider value={value}>{children}</Client.Provider>
    )
}

const useClientContext = () => {
    const context = useContext(Client)

    if (context === undefined) throw new Error('useClientContext must be used within a ClientProvider')

    return context;
}

export default useClientContext
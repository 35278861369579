import React from 'react'
import styled from 'styled-components'

import Separator from './Separator'
import { useTheme } from '../contexts/Theme'

const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const Strip = styled.div`
    width: 100%; 
    height: auto; 
    display: flex;
    flex-direction: row; 
    align-items: flex-start;
    justify-content: space-around;
`

const PowerStrip = ({ marginTop, children }) => {
    const theme = useTheme()
    
    // Destructure the theme
    const { rgbaWhiteBg, rgbaDarkBg } = theme
    const color = theme.isDarkBackground() ? rgbaWhiteBg : rgbaDarkBg;

    return (
        <Container>
            <Separator marginTop={marginTop} color={color} />
            <Strip>{children}</Strip>
        </Container>
    )
}

export default PowerStrip
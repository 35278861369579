import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Container = styled.div`
    position: fixed;
    left: ${p => p.left ? p.left + '%' : '0'};
    bottom: 0;
    width:  ${p => p.left ? 100 - p.left + '%' : '100%'};
    height: 40px;
    background: rgb(64, 0, 128);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    z-index: 10;
`

const MenuItem = styled.div`
    width: ${props => 100 / props.num - 1}%;
    height: 30px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 2px solid yellow;
    font: 20px Verdana, sans-serif;
    color: yellow;
    text-decoration: none;

    &:last-child {
        border-right: 0;
    }
`

const FooterLink = styled(Link)`
    color: yellow;
    text-decoration: none;

    &.selected {
        outline: none;
        text-decoration: underline;
    }
` 
// leftPct: Distance in percent width of the screen from bottom left edge
//
const FooterMenu = ({ items, leftPct }) => {
    const [selectedKey, setSelectedKey] = useState(-1)

    const onClick = key => {
        setSelectedKey(key)
    }

    return (
        <div>
            <Container left={leftPct}>
                {items.map((item, i) => {
                    if (selectedKey === i || (selectedKey < 0 && item.selected)) {
                        return <MenuItem key={i} pct={leftPct} num={items.length} onClick={e => onClick(i)}>
                            <FooterLink to={item.to} className='selected'>{item.label}</FooterLink>
                        </MenuItem>
                    }
                    return <MenuItem key={i} pct={leftPct} num={items.length} onClick={e => onClick(i)}>
                        <FooterLink to={item.to}>{item.label}</FooterLink>
                    </MenuItem>
                })}
            </Container>
        </div>
    )
}

export default FooterMenu

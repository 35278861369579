import React from 'react'
import styled from 'styled-components'

import Modal from './Modal'
import Address from './Address'
import { useTheme } from '../contexts/Theme'

const Container = styled.div`
    position: relative;
    width: 220px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const Kill = styled.img`
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
`

const Image = styled.img`
    width: 80px;
    height: 80px; 
`

const Fields = styled.div`
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const InputRow = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: ${p => p.marginTop}px;
`

const AddressInputModal = ({ onKill, onSubmit }) => {  
    const theme = useTheme()
    // Theme
    const { dark, white } = theme
    const [ iconColor ] = theme.isDarkBackground() ? [ white ] : [ dark ];
    
    return (
        <Modal>
            <Container>
                <Kill onClick={onKill} src={`${process.env.PUBLIC_URL}/images/kill-${iconColor}.png`} />
                <Image src={`${process.env.PUBLIC_URL}/images/address-${iconColor}.png`} />
                <Fields>
                <Address onDone={onSubmit}>
                    <Address.Addr1 marginTop={15} />
                    <Address.Addr2 marginTop={15} />
                    <InputRow marginTop={15}>
                        <Address.City percentWidth={50} />
                        <Address.State percentWidth={30} />
                    </InputRow>
                    <InputRow marginTop={15}>
                        <Address.Zip percentWidth={50} />
                        <Address.Country percentWidth={40} />
                    </InputRow>
                    <Address.Done width={176} height={50} marginTop={20} marginBottom={20} text='Done' />
                </Address>
                </Fields>
            </Container>
        </Modal>
    )
}

export default AddressInputModal
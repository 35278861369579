import React, { Fragment } from 'react'
import { Navigate } from 'react-router-dom'

import useSignupContext from '../SignupContext'
import useSignupFormContext from '../SignupFormContext'

const Done = () => {
    const { activeMember, dataSubmitted } = useSignupFormContext()
    const { runResetFunctions } = useSignupContext()

    dataSubmitted && runResetFunctions()
    
    const { isAdmin } = activeMember ?? {};

    const to = isAdmin ? "/members" : "/clients";

    return (
        <Fragment>
        {
            dataSubmitted 
            &&
            <Navigate to={to} replace />
        }
        </Fragment>
    )
}

export default Done
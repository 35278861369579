import React, { Fragment, useState, useEffect } from 'react'

import IconInput from '../../../../controls/IconInput'
import { isEmpty } from '../../../../../utils'
import useProfileContext from '../ProfileContext'
import useProfileFormContext from '../ProfileFormContext'

let lastEmail = ''
let emailEdited = false

const Email = () => {
    const [ email, setEmail ] = useState('')

    // Hooks
    const { activeClient, addResetFunction, managingAddresses, managingCards } = useProfileContext()
    const { onEmailChange } = useProfileFormContext()

    // Page mounted/portrait-to-landscape effect
    //
    useEffect(() => {
        addResetFunction(resetBackup)
        lastEmail && setEmail(lastEmail)
    }, [])

    // Active member loaded effect
    useEffect(() => {
        if (isEmpty(activeClient)) return;
        if (!emailEdited) {
            setEmail(activeClient.email)
            lastEmail = activeClient.email
        }
    }, [ activeClient ])

    const resetBackup = () => {
        lastEmail = ''
        emailEdited = false
    }
    
    const onChange = e => {
        onEmailChange(e)
        setEmail(e.target.value)
        lastEmail = e.target.value
        emailEdited = true
    }

    return (
        <Fragment>
        {
            (!managingAddresses && !managingCards)
            &&
            <IconInput
                type='text' 
                iconUrl='email' 
                name='email'
                value={email} 
                placeholder='Your e-mail'
                marginTop={30} 
                onChange={onChange}
                caption='Your e-mail for info and updates. (not required)'
            />
        }
        </Fragment>
    )
}

export default Email
import React from 'react'

import Password from './Password'
import RepeatPassword from './RepeatPassword'
import Name from './Name'
import Phone from './Phone'
import Email from './Email'
import Security from './Security'
import Birthday from './Birthday'
import Submit from './Submit'
import { ProfileFormProvider } from '../ProfileFormContext'
import useProfileForm from '../useProfileForm'

const ProfileForm = ({ children }) => {
    // Hooks
    const { onSubmit, ...rest } = useProfileForm()

    return (
        <ProfileFormProvider value={{ ...rest }}>
            <form onSubmit={onSubmit}>
                {children}                
            </form>
        </ProfileFormProvider>
    )
}

// Signup form components, lay these out any way you want ... 

ProfileForm.Password = Password
ProfileForm.RepeatPassword = RepeatPassword
ProfileForm.Name = Name
ProfileForm.Phone = Phone
ProfileForm.Email = Email
ProfileForm.Security = Security
ProfileForm.Birthday = Birthday
ProfileForm.Submit = Submit

export default ProfileForm
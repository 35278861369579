import React, { Fragment, useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import SearchBarService from '../../../../controls/SearchBarService'
import Photo from '../../../../controls/Photo'
import { standardDate } from '../../../../controls/DatePicker'
import { useTheme } from '../../../../contexts/Theme'
import useClientsContext from '../ClientsContext'

const ClientsViewport = styled.div`
    width: ${p => p.pctWidth ? p.pctWidth : '100'}%;
    height: 350px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 50px;
`

const ClientList = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const ClientSummaryContainer = styled.div`
    width: ${p => p.pctWidth ? p.pctWidth : '95'}%;
    height: 90px;
    border-width: 0 0 2px 0;
    border-color: ${props => props.borderColor};
    border-style: groove;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
`

const ClientSummaryText = styled.div`
    width: 160px;
    height: 70px;
    overflow-x: scroll;
    color: ${props => props.color};
    margin-top: 10px;
`

const PhotoAndText = styled.div`
    width: 260px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const ClientName = styled.div`
    width: 100%;
    height: 50%;
    font: 22px verdana, sans-serif;
    white-space: nowrap;
`

const LastHaircut = styled.div`
    width: 100%;
    height: 50%;
    font: 14px verdana, sans-serif;
    white-space: nowrap;
`

const Scissors = styled.img`
    height: 50px;
    width: auto;
`

let lastClientSummaries = []

const ClientSearch = ({ viewportWidthPct, summaryWidthPct }) => {
    // State
    const [ clientSummaries, setClientSummaries ] = useState([])
    const { 
        activeMember, currentMember, isCurrentMember, setCurrentClient, addResetFunction 
    } = useClientsContext()

    // Other hooks
    const toClient = useRef()
    const toHaircut = useRef()
    const theme = useTheme()

    useEffect(() => {
        setClientSummaries(lastClientSummaries)
    }, [])

    // Add our reset function
    //
    useEffect(() => {
        addResetFunction && addResetFunction(resetBackup)
    }, [ addResetFunction ])

    // Define the member being referenced
    const theMember = isCurrentMember() ? currentMember : activeMember;

    // Theme
    const { white, dark, rgbDark } = theme
    const [ textColor, iconColor ] = theme.isDarkBackground() ? [ white, white ] : [ rgbDark, dark ];

    const resetBackup = () => {
        lastClientSummaries = []
    }

    const onSearch = searchTerm => {
        lastClientSummaries = 
            searchTerm 
            ?
            theMember.clients.filter(cl => cl.name.toLowerCase().includes(searchTerm.toLowerCase()))
            :
            theMember.clients;
        setClientSummaries(lastClientSummaries)
    }

    return (
        <Fragment>
            <Link ref={toClient} to="/client" style={{display: "none"}} />
            <Link ref={toHaircut} to="/clients/add-haircut" style={{display: "none"}} />
            <SearchBarService 
                iconUrl='person' 
                name='clients'
                marginTop='50'
                placeholder='Your clients'
                caption="Tap spy to show all your clients, or first enter search term to narrow the list."
                search={onSearch}
            />                   
            <ClientsViewport pctWidth={viewportWidthPct}> 
                <ClientList>
                { 
                    clientSummaries.length > 0
                    &&
                    (
                        clientSummaries.map((client, i) => 
                            <ClientSummaryContainer key={i} pctWidth={summaryWidthPct}>
                                <PhotoAndText onClick={async () => {
                                        if (isCurrentMember()) return;
                                        await setCurrentClient(client)
                                        toClient.current.click()
                                    }
                                }>
                                    <Photo photo={client.samplePhoto} width={80} />
                                    <ClientSummaryText color={textColor}>
                                        <ClientName textColor={textColor}>{client.name}</ClientName>
                                        <LastHaircut textColor={textColor}>
                                        {
                                            client.lastHaircutDate ? 
                                            `Last haircut: ${standardDate(client.lastHaircutDate)}` :
                                            'No haircut yet'
                                        }
                                        </LastHaircut>
                                    </ClientSummaryText>
                                </PhotoAndText>
                                <Scissors 
                                    src={`${process.env.PUBLIC_URL}/images/scissors-${iconColor}.png`}
                                    onClick={async () => {
                                        await setCurrentClient(client)
                                        toHaircut.current.click()
                                    }
                                }/>
                            </ClientSummaryContainer>
                        )
                    ) 
                }
                </ClientList>
            </ClientsViewport>
        </Fragment>
    )
}

export default ClientSearch
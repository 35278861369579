import React, { Fragment, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { useTheme } from '../contexts/Theme'

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const Back = styled.div`
    width: 120px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const Arrow = styled.div`
    width: 48px; 
    height: 100%;
    background-image: url(${props => props.img});
    background-color: rgba(0, 0, 0, 0); 
    background-repeat: no-repeat;
    background-position: center;
`

const Destination = styled.div`
    width: auto;
    height: 100%;
    margin-left: -15px;
    background-color: rgba(0, 0, 0, 0);
    color: ${props => props.color};
    font: 18px verdana, sans-serif;
    display: flex;
    justify-content: left;
    align-items: center;
`

const BackLink = styled(Link)`
    outline: none;
    color: ${props => props.color};
    text-decoration: none;
`

const Title = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -75px;
    width: 150px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    color: ${props => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    font: 22px verdana, sans-serif;
`

const Ok = styled.div`
    width: 50px;
    height: 100%;
    margin-right: 10px;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font: 24px verdana, sans-serif;
`

const OkLink = styled(Link)`
    color: ${props => props.color};
    text-decoration: none;
`

const Separator = styled.div`
    width: 100%;
    height: 2px;
    margin: 8px 0 8px 0;
    border-bottom-width: 2px;
    border-bottom-style: groove;
    border-bottom-color: ${props => props.color};
`

const Header = ({ arrow, onBack, title, ok, separator }) => {

    const theme = useTheme()
    const back = useRef()

    const { white, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const textColor = theme.isDarkBackground() ? white : rgbDark;
    const sepColor = theme.isDarkBackground() ? rgbaWhiteBg : rgbaDarkBg;

    return (
        <Fragment>
            <Container>
                <Back> 
                    {
                        onBack ? (
                            <Fragment>
                                <Arrow img={completeImagePath(arrow.color)} onClick={e => { onBack(); back.current.click() }} />
                                <Destination color={arrow.color} onClick={e => { onBack() }}>
                                    <BackLink ref={back} color={arrow.color} to={arrow.to}>{arrow.destination}</BackLink>
                                </Destination>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Arrow img={completeImagePath(arrow.color)} onClick={() => back.current.click()} />
                                <Destination>
                                    <BackLink ref={back} color={arrow.color} to={arrow.to}>{arrow.destination}</BackLink>
                                </Destination>
                            </Fragment>
                        )
                    }
                </Back>
                {title && <Title color={textColor}>{title}</Title>}
                {ok && <Ok><OkLink to={ok.to} color={textColor}>{ok.label}</OkLink></Ok>}
            </Container>
            { separator && <Separator color={sepColor} />}
        </Fragment>
    )
}

const completeImagePath = color => `${process.env.PUBLIC_URL}/images/back-${color}.png`

export default Header

import React, { createContext, useContext } from 'react'

const ProfileForm = createContext(undefined)

export const ProfileFormProvider = ({ children, value }) => {
    return (
        <ProfileForm.Provider value={value}>{children}</ProfileForm.Provider>
    )
}

const useProfileFormContext = () => {
    const context = useContext(ProfileForm)

    if (context === undefined) throw new Error('useProfileFormContext must be used within a ProfileFormProvider')

    return context;
}

export default useProfileFormContext
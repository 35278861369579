import React, { Fragment, useEffect } from 'react'

import IconInput from '../../../../controls/IconInput'
import useProfileContext from '../ProfileContext'
import useProfileFormContext from '../ProfileFormContext'

let lastPassword = ''

const Password = () => {
    // Hooks
    const { addResetFunction, activeClient, managingAddresses, managingCards } = useProfileContext()
    const { password, setPassword } = useProfileFormContext()

    useEffect(() => {
        addResetFunction(resetBackup)
        lastPassword && setPassword(lastPassword)
    }, [])

    const resetBackup = () => {
        lastPassword = ''
    }

    const onChange = e => {
        setPassword(e.target.value)
        lastPassword = e.target.value
    }

    return (
        <Fragment>
        {
            (!managingAddresses && !managingCards)
            &&
            <IconInput 
                type='password' 
                iconUrl='password' 
                name='password'
                placeholder={`New password (${activeClient.firstVisit ? '' : 'not '}req.)`}
                marginTop={30} 
                value={password}
                onChange={onChange}
                caption={`Your password (${activeClient.firstVisit ? '' : 'not '}required, must be at least eight characters).`}
                required={activeClient.firstVisit}
            />
        }
        </Fragment>
    )
}

export default Password
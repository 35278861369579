import { 
    SET_ACTIVE_MEMBER, 
    SET_MEMBER_PROPERTY, 
    CLEAR_ACTIVE_MEMBER, 
    BACKUP_ACTIVE_MEMBER,
    RESTORE_ACTIVE_MEMBER,
    ADD_CLIENT_TO_MEMBER 
} from '../actions/types'

const initialState = {}

const activeMember = (state = initialState, action) => {

    switch (action.type) {
        case SET_ACTIVE_MEMBER:
        {
            const member = { ...action.payload }
            localStorage.setItem('activeMember', JSON.stringify(member))
            return member
        }
        case BACKUP_ACTIVE_MEMBER:
        {
            const member = { ...action.payload }
            localStorage.setItem('activeMember', JSON.stringify(member))
            return member
        }
        case SET_MEMBER_PROPERTY: {
            const member = { ...state }
            member[action.payload.key] = action.payload.value
            localStorage.setItem('activeMember', JSON.stringify(member))
            return member
        }
        case CLEAR_ACTIVE_MEMBER:
            localStorage.removeItem('activeMember')
            return {}
        case RESTORE_ACTIVE_MEMBER: {
            const member = localStorage.getItem('activeMember')
            return JSON.parse(member)
        }
        case ADD_CLIENT_TO_MEMBER: {
            const member = { ...state }
            member.clients = [ action.payload, ...member.clients ]
            localStorage.setItem('activeMember', JSON.stringify(member))
            return member
        }
        default:
            return state
    }
}

export default activeMember
import React, { Fragment, useRef } from 'react'
import styled from 'styled-components'
import { 
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
    TwitterShareButton,
    TwitterIcon
} from 'react-share'

// width: 240px;
// height: 40px;
// margin-top: 80px;
const Shares = styled.div`
    width: ${props => props.direction === 'row' ? 5 * props.size + 20 : props.size + 4}px;
    height: ${props => props.direction === 'column' ? 5 * props.size + 20 : props.size + 4}px;
    margin-top: ${props => props.marginTop}px;
    display: flex;
    flex-direction: ${props => props.direction};
    justify-content: space-between;
`

const ShareButtons = ({ iconSize, direction /* 'row' or 'column' */, marginTop }) => {
    const borderRadius = useRef(6)

    return (
        <Fragment>            
            <Shares size={iconSize} direction={direction} marginTop={marginTop}>
                <RedditShareButton url='https://array-beauty.com' title='Array beauty products'>
                    <RedditIcon size={iconSize} borderRadius={borderRadius.current} />
                </RedditShareButton>
                <FacebookShareButton url='https://array-beauty.com' quote='Array beauty products'>
                    <FacebookIcon size={iconSize} borderRadius={borderRadius.current} />
                </FacebookShareButton>
                <LinkedinShareButton url='https://array-beauty.com' title='Array beauty products'>
                    <LinkedinIcon size={iconSize} borderRadius={borderRadius.current} /> 
                </LinkedinShareButton>
                <PinterestShareButton 
                    url='https://array-beauty.com' description='Array beauty products'
                    media='https://array-beauty.com/images/conditioner-bottle.png'
                >
                    <PinterestIcon size={iconSize} borderRadius={borderRadius.current} /> 
                </PinterestShareButton>
                <TwitterShareButton url='https://array-beauty.com' title='Array beauty products'>
                    <TwitterIcon size={iconSize} borderRadius={borderRadius.current} />
                </TwitterShareButton>
            </Shares>
        </Fragment>
    )
}

export default ShareButtons
import React, { Fragment } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'

const Container = styled.div`
    position: absolute;
    bottom: ${props => props.bottom ? props.bottom - 2 + 'px' : props.bottomPct + '%'};
    left: ${props => props.left ? props.left + 'px' : props.leftPct + '%'};
    margin-left: ${props => props.marginLeft ? props.marginLeft : 0}px;
    width: ${props => props.width}px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 5;
`

const Content = styled.div`
    width: 100%;
    height: auto;
    word-wrap: normal;
    background-color: white;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 5px;
    font: 20px verdana, sans-serif;
    color: ${props => props.textColor}; 
`

const StemContainer = styled.div`
    position: relative;
    width: 100%;
    height: 15px;
    background-color: rgba(0, 0, 0, 0);
`

const Stem = styled.div`
    position: absolute;
    bottom: 0;
    left: ${props => props.left - 5}px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 5px 0 5px;
    border-color: white rgba(0, 0, 0, 0);
`

const Caption = ({ text, width, bottom, left, bottomPct, leftPct, marginLeft, stemOffset }) => {
    const theme = useTheme()
    const { rgbDark } = theme

    return (
        <Fragment>
        {
            bottom 
            ?
            <Container width={width} bottom={bottom} left={left} marginLeft={marginLeft}>
                <Content textColor={rgbDark}>{text}</Content>
                <StemContainer>
                    <Stem left={stemOffset} />
                </StemContainer>
            </Container>
            :
            <Container width={width} bottomPct={bottomPct} leftPct={leftPct} marginLeft={marginLeft}>
                <Content textColor={rgbDark}>{text}</Content>
                <StemContainer>
                    <Stem left={stemOffset} />
                </StemContainer>
            </Container>
        }
        </Fragment>
    )
}

export default Caption
import React, { Fragment, useState, useEffect } from 'react'

import IconInput from '../../../../controls/IconInput'
import Dropdown from '../../../../controls/Dropdown'
import { secretQuestions } from '../../../../../constants'
import useSignupFormContext from '../SignupFormContext'
import useSignupContext from '../SignupContext'

let lastIndex = -1
let lastAnswer = ''

const Security = () => {
    // Local state
    const [ selected, setSelected ] = useState(-1)
    const [ answer, setAnswer ] = useState('')

    // Other hooks
    const { onQuestionChange, onAnswerChange } = useSignupFormContext()
    const { addResetFunction } = useSignupContext()

    useEffect(() => {
        addResetFunction(resetBackup)
        setSelected(lastIndex)
        lastAnswer && setAnswer(lastAnswer)
    }, [])

    const getIndex = index => {
        setSelected(index)
        lastIndex = index
    }

    const onAnswer = e => {
        onAnswerChange(e)
        setAnswer(e.target.value)
        lastAnswer = e.target.value
    }

    const resetBackup = () => {
        lastIndex = -1
        lastAnswer = ''
    }

    return (
        <Fragment>
            <Dropdown
                options={secretQuestions}
                caption='Pick a security reset question from the list.'
                name='secretQuestion'
                iconUrl='security-questions'
                placeholder='Security questions'
                onChange={onQuestionChange}
                marginTop={30}
                preselected={selected}
                getSelectedIndex={getIndex}
                alternating
            />
            <IconInput 
                type='text' 
                iconUrl='security-questions' 
                name='secretQuestionAnswer'
                placeholder='Security question answer'
                value={answer}
                marginTop={30} 
                onChange={onAnswer}
                caption='Answer the security reset question.'
                required
            />
        </Fragment>
    )
}

export default Security
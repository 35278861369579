import React, { useState } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'

const ListContainer = styled.div`
    width: 90%;
    height: auto;
    margin-top: ${props => props.marginTop}px;
    margin-bottom: ${props => props.marginBottom}px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: ${props => props.textColor};
`

const Title = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    font: 24px verdana, sans-serif;
    text-align: center;
    flex-shrink: 0;
`

const List = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 10px;
    margin-bottom: 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

const Item = styled.div`
    position: relative;
    right: 50px;
    width: 60px;
    height: 70px;
    border-color: ${props => props.borderColor};
    border-style: solid;
    border-width: 0 2px 2px 0;
    font: 20px verdana, sans-serif;
`

const Joint = styled.div`
    position: absolute;
    right: -10px;
    bottom: -10px;
    height: 20px;
    width: 20px;
    background-color: rgb(255, 128, 64);
    border-radius: 50%;
    z-index: 2;
`

const Circle = styled.div`
    position: absolute;
    left: -40px;
    bottom: -20px;
    height: 40px;
    width: 40px;
    background-image: url('${process.env.PUBLIC_URL}/images/unchecked-${props => props.iconColor}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.selected { 
        background-image: url('${process.env.PUBLIC_URL}/images/checked-${props => props.iconColor}.png'); 
    }
`

const Label = styled.div`
    position: absolute;
    right: -115px;
    bottom: -20px;
    width: 90px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-indent: 10px;
`

const Stem = styled.div`
    position: absolute;
    bottom: -2px;
    right: -20px;
    width: 20px;
    height: 5px;
    border-style: solid;
    border-width: 0 0 2px 0;
`

const NoItems = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${props => props.color};
    font: 20px verdana, sans-serif;
`

// items is an array of strings, onChange returns an ordered array of selected indices
//
const RadioButtonList = ({ 
    title, value, items, marginTop, marginBottom, onChange
}) => {
    const [ selectedItem, setSelectedItem ] = useState(value !== undefined ? value : -1)
    
    // Other hooks
    const theme = useTheme()
    const { white, dark, rgbDark, rgbaWhiteBg, rgbaDarkBg } = theme
    const [ textColor, borderColor, iconColor, shadow ] = theme.isDarkBackground() ? 
        [ white, white, white, rgbaWhiteBg ] : 
        [ rgbDark, rgbDark, dark, rgbaDarkBg ];

    // Handlerss => 
    const onCircleClick = index => {
        onChange(selectedItem === index ? -1 : index)
        setSelectedItem( s => s === index ? -1 : index )
    }

    return (
        <ListContainer 
            textColor={textColor} marginTop={marginTop ? marginTop : 0} marginBottom={marginBottom ? marginTop : 0}
        >
            <Title>{title}</Title>
            <List>                
            {
                items.length > 0 ?
                    items.map((item, i) => 
                        <Item key={i} borderColor={borderColor}>
                            <Joint />
                            <Label>
                                {item}
                            </Label>
                            <Stem />
                            <Circle 
                                iconColor={iconColor} 
                                className={i === selectedItem ? 'selected' : ''}
                                onClick={() => onCircleClick(i)} 
                            />
                        </Item>
                    )
                    :
                    <NoItems color={shadow}>{`Empty set.`}</NoItems>
            }
            </List>
        </ListContainer>
    )
}

export default RadioButtonList
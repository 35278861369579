import { useMediaQuery } from 'react-responsive'

export const usePhone = () => {
    const isNarrow = useMediaQuery({ maxWidth: 414 })
    const isWide = useMediaQuery({ maxWidth: 896 })
    const isShort = useMediaQuery({ maxHeight: 414 })
    const isTall = useMediaQuery({ maxHeight: 896 })
    const isPortrait = useMediaQuery({ orientation: 'portrait' })
    const isLandscape = useMediaQuery({ orientation: 'landscape' })

    return isNarrow && isTall && isPortrait || isWide && isShort && isLandscape;
}
export const useTablet = () => useMediaQuery({ minWidth: 768, maxWidth: 991 })
export const useDesktopOrLaptop = () => useMediaQuery({ minDeviceWidth: 1224 }, { deviceWidth: 1600 })
export const usePortrait = () => useMediaQuery({ orientation: 'portrait' })
export const useLandscape = () => useMediaQuery({ orientation: 'landscape' })
import React, { useState, useMemo } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'
import Caption from './Caption'
import Null from './Null'

const UnderlineContainer = styled.div`
    position: relative;
    width: 320px;
    height: 40px;
    margin-top: ${props => props.marginTop}px;
`

const UnderlineIcon = styled.div`
    position: absolute;
    top: -10px;
    left: 0px;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0);
    background-image: url('../../../images/${props => props.iconUrl}-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const UnderlineInput = styled.div`
    position: absolute;
    top: -10px;
    left: 65px;
    width: 250px;
    height: 40px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
`

const Underline = styled.div`
    position: absolute;
    bottom: 0px;
    left: 60px;
    right: 0px;
    border-style: solid;
    border-width: 1px;
    border-color: ${props => props.color};
    background-color: ${props => props.color};
`

const OvalContainer = styled.div`
    width: 100%;
    height: 60px;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${props => props.marginTop}px;
`

const OvalIcon = styled.div`
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: ${props => props.bgColor};
    background-image: url('../../../images/${props => props.iconUrl}-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px 50px;
`

const OvalInput = styled.div`
    position: relative;
    width: 250px;
    height: 60px;
    margin-left: 10px;
    border-radius: 100px;
    border: none;
    background-color: ${props => props.bgColor};
`

const SpyIcon = styled.div`
    position: absolute;
    top: ${props => props.top};
    right: ${props => props.right};
    width: 40px;
    height: 50px;
    background-image: url('../../../images/spy-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Select = styled.input`
    position: absolute;
    top: 10px;
    left: 25px;
    width: 200px;
    height: 40px;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    font: 18px verdana, sans-serif;
    color: ${props => props.color};
    text-indent: 30px;
    
    &::placeholder {
        color: ${props => props.color};
        opacity: 0.6;
    }
`

// Search term changed since last spy tap
let valueChanged = false

const SearchBarService = ({ search, caption, name, marginTop, iconUrl, placeholder }) => {
    const [ captionVisible, setCaptionVisible ] = useState(false)
    const [ searchTerm, setSearchTerm ] = useState('')
    const [ dataLoaded, setDataLoaded ] = useState(false)

    const theme = useTheme()
    const { white, dark, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const bgColor = theme.isDarkBackground() ? rgbaWhiteBg : rgbaDarkBg;
    const iconColor = theme.isDarkBackground() ? white : dark;
    const textColor = theme.isDarkBackground() ? white : rgbDark;

    // Search stuff
    const onTouchSpy = async () => {

        // If data was fetched and no value change, don't repeat the search
        if (dataLoaded && !valueChanged) return

        // Reset flag
        valueChanged = false

        // Kick off search
        setDataLoaded(false)
        search(searchTerm)

        // Data was loaded
        setDataLoaded(true)
    }

    // Other handlers
    const onTouchIcon = e => setCaptionVisible(!captionVisible)
    const onSearchTermChange = e => {
        setSearchTerm(e.target.value)
        valueChanged = true
    }
    
    // Theme conditionals
    const Container = theme.isInputOval() ? OvalContainer : UnderlineContainer;
    const Icon = theme.isInputOval() ? OvalIcon : UnderlineIcon;
    const Input = theme.isInputOval() ? OvalInput : UnderlineInput;
    const stemOffset = theme.isInputOval() ? '30' : '25';
    const spyTop = theme.isInputOval() ? '5px' : '-5px';
    const spyRight = theme.isInputOval() ? '10px' : '0px';

    // Visibility
    const CaptionOrNot = caption && captionVisible ? Caption : Null;

    const spy = useMemo(() => 
        <SpyIcon color={iconColor} top={spyTop} right={spyRight} onClick={onTouchSpy} onTouchStart={onTouchSpy} />, 
        [ iconColor, spyTop, spyRight, onTouchSpy ]
    )

    return (
        <Container marginTop={marginTop}>
            <Icon bgColor={bgColor} iconUrl={iconUrl} color={iconColor} onTouchStart={onTouchIcon}>
                <CaptionOrNot text={caption} width='200' bottom='60' left='0' stemOffset={stemOffset} />
            </Icon>
            <Input bgColor={bgColor}>
                <Select type='text' name={name} color={textColor} value={searchTerm} 
                    onChange={onSearchTermChange} placeholder={placeholder} />
                { spy }
            </Input>
            {theme.isInputUnderline() && <Underline color={textColor} />} 
        </Container>
    )
}

export default SearchBarService

import { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import bcrypt from 'bcryptjs'

// Returns true if /clients appears in the current url, else false
//
export const useFromClients = () => {
    const currentURL = useLocation().pathname
    return currentURL.includes("clients")
}

export const isEmpty = obj => {
    for (let key in obj)
        if (obj.hasOwnProperty(key))
            return false
    return true
}

export const isAppleDevice = /Mac|iPhone|iPad/.test(navigator.platform)

export const useMounted = () => {
    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true

        return () => isMounted.current = false
    }, [])

    return isMounted.current
}

export const firstName = fullName => /^(\w+)/.exec(fullName)[1]

// Setup config/headers with token
//
export const tokenConfig = token => {

    // Set headers
    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    }
    config.headers['x-auth-token'] = token

    return config
}

export const timeoutConfig = ( config, timeout = 10000 ) => {
    config['timeout'] = timeout

    return config
}

// Return an image object given a file path
//
export const getImage = path => new Promise((resolve) => {
    const image = new Image()
    image.onload = function() {
        resolve(image)
    }
    image.src = path
}) 

// Promisified password encryption
//
export const encryptPassword = password => new Promise((resolve, reject) => {
    bcrypt.genSalt(10, (err, salt) => {
        if (err) {
            reject(err)
            return
        }
        bcrypt.hash(password, salt, (err, hash) => {
            if (err) {
                reject(err)
            } else {
                resolve(hash)
            }
        })
    })
})

// Return the pixel width of given text and CSS font
//
export const textWidth = (text, font) => {    
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    context.font = font
    const metrics = context.measureText(text)

    // Return the width in pixels
    return metrics.width
}
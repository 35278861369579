import React, { Fragment, useEffect } from 'react'
import styled from 'styled-components'

import Spinner from '../../../../controls/Spinner'
import Null from '../../../../controls/Null'
import Photo from '../../../../controls/Photo'
import Separator from '../../../../controls/Separator'
import { useTheme } from '../../../../contexts/Theme'
import { isEmpty } from '../../../../../utils'
import useHomeContext from '../HomeContext'

const HostContainer = styled.div`
    width: ${p => p.pctWidth ? p.pctWidth : '90'}%;
    height: auto;
    font: 18px verdana, sans-serif;
    color: ${props => props.textColor};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`

const HostPhotoContainer = styled.div`
    width: ${p => p.size}px;
    height: ${p => p.size}px;
`

const HostLabel = styled.div`
    width: 200px;
    height: 90px;
`

const NoPhoto = styled.div`
    width: 100%;
    height: 100%;
    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: 18px verdana, sans-serif;
    color: rgba(255, 255, 255, 0.6);
`

const Host = ({ pctWidth }) => {
    // Hooks
    const { activeClient, hostMember, getHostMember, hostMemberLoading } = useHomeContext()
    const theme = useTheme()

    useEffect(() => {
        getHostMember(activeClient)
    }, [ activeClient, getHostMember ])

    if (isEmpty(hostMember)) return <Null />

    // Destructure the theme
    const { white, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const [ textColor, borderColor ] = theme.isDarkBackground() ? [ white, rgbaWhiteBg ] : [ rgbDark, rgbaDarkBg ];

    // Destructure the host
    const { name, salon, profilePhoto } = hostMember

    return (
        <Fragment>
        {
            hostMemberLoading
            ?
            <Spinner diameter={100} />
            :
            <Fragment>
                <HostContainer textColor={textColor} pctWidth={pctWidth}> 
                    <HostPhotoContainer size={100}>
                    {
                        profilePhoto
                        ?
                        <Photo width={100} photo={profilePhoto} />
                        :
                        <NoPhoto>No photo available</NoPhoto>
                    }
                    </HostPhotoContainer>
                    <HostLabel>
                        Your Array &reg; beauty consultant is {name} at {salon.name}
                    </HostLabel> 
                </HostContainer>
                <Separator color={borderColor} marginTop={20} />
            </Fragment>
        }
        </Fragment>
    )
}

export default Host
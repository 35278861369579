import React, { Fragment } from 'react'

import Header from '../../../../controls/Header'
import HeaderDesktop from '../../../../media/HeaderDesktop'
import useHomeContext from '../HomeContext'
import { usePhone } from '../../../../../hooks/useMedia'

const HomeHeader = () => {
    // Other hooks
    const { logoff } = useHomeContext()
    const isPhone = usePhone()

    const onBack = () => logoff()

    return (
        <Fragment>
        {
            isPhone
            ?
            <Header arrow={{
                color: 'red',
                to: '/',
                destination: 'Logout'
            }} onBack={onBack} separator />
            :
            <HeaderDesktop height={100} leftContent={<Header arrow={{
                color: 'red',
                to: '/',
                destination: 'Logout'
            }} onBack={onBack} />} />
        } 
        </Fragment>
    )
}

export default HomeHeader
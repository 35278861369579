import React, { Fragment } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import axios from 'axios'

import Button from '../../../../controls/Button'
import AddressInputModal from '../../../../controls/AddressInputModal'
import useOrderContext from '../OrderContext'
import { useTheme } from '../../../../contexts/Theme'
import { tokenConfig, timeoutConfig } from '../../../../../utils'

const Explanation = styled.div`
    width: 80%;
    height: auto;
    color: ${props => props.color};
    font: 20px verdana, sans-serif;
    text-align: center;
    margin-top: ${props => props.marginTop}px;
`

const AddressPrompt = ({ marginTop }) => {
    const {
        setPayStatus, setSpinner, setStatusMessage,
        activeClient, updateActiveClient, authToken,
        enteringShippingAddress,
        updateEnteringShippingAddress,
        defaultShippingAddressSet,
        updateDefaultShippingAddress,
        updateShippingAddressEntered
    } = useOrderContext() 
           
    // Other hooks
    const theme = useTheme()
      
    // Destructure the theme
    const { white, rgbDark } = theme
    const color = theme.isDarkBackground() ? white : rgbDark;

    const onShippingAddressReady = async address => {

        updateEnteringShippingAddress(false)
        updateShippingAddressEntered(true)
        updateDefaultShippingAddress(address)

        try {

            setPayStatus(true)
            setSpinner(true)

            address.isDefault = true
            address.isEnabled = true

            const shippingAddresses = [ ...activeClient.shippingAddresses, address ]
            
            // Update the client DB            
            await axios.patch(`/api/clients/${activeClient._id}`, { shippingAddresses }, timeoutConfig(tokenConfig(authToken)))
            
            setSpinner(false)
            setStatusMessage('Shipping address successfully saved.')

            // DB successfully updated, update the active client
            updateActiveClient("shippingAddresses", shippingAddresses)

        } catch(err) {

            if (err.response) { // status 400 (incl. authentication), 404 (not found), 500
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, addresses not updated.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, addresses not updated, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, addresses not updated`)
            }

            setSpinner(false)
        }
    }

    return (
        <Fragment>
        <CSSTransition 
            in={enteringShippingAddress} 
            classNames="modal" 
            timeout={1000}
            unmountOnExit
        >
            <AddressInputModal onKill={() => updateEnteringShippingAddress(false)} onSubmit={onShippingAddressReady} />
        </CSSTransition>
        {
            !defaultShippingAddressSet &&
            <Fragment>
                <Explanation color={color} marginTop={marginTop ? marginTop : 0}>
                    You must set a default shipping address before an order can be completed.
                </Explanation>
                <Button 
                    onClick={() => updateEnteringShippingAddress(true)} width={300} height={80} 
                    marginTop={20} label='Enter Default Shipping Address' 
                />
            </Fragment>
        }
        </Fragment>
    )
}

export default AddressPrompt
import React, { useContext } from 'react'

const ThemeContext = React.createContext()

export const terms = {
    white: "white",
    light: "light",
    dark: "dark",
    orange: "orange",
    oval: "oval",
    underline: "underline",
}

const values = {
    rgbDark: 'rgb(0, 0, 84)',
    rgbaDarkBg: 'rgba(0, 0, 84, 0.3)',
    rgbaWhiteBg: 'rgba(255, 255, 255, 0.3)'
}

const queries = {
    isDarkBackground: function() {
        return this.background === this.dark
    },
    isLightBackground: function() {
        return this.background === this.light
    },
    isInputOval: function() {
        return this.inputStyle === this.oval
    },    
    isInputUnderline: function() {
        return this.inputStyle === this.underline
    }
}

const sets = {
    setDarkOval: function() {
        this.inputStyle = this.oval
        this.background = this.dark
    },
    setDarkUnderline: function() {
        this.inputStyle = this.underline
        this.background = this.dark
    },
    setLightOval: function() {
        this.inputStyle = this.oval
        this.background = this.light
    },
    setLightUnderline: function() {
        this.inputStyle = this.underline
        this.background = this.light
    }
}

export const ThemeProvider = (props) => {
    const { inputStyle, background } = props
    const value = {
        ...terms,
        ...values,
        ...queries,
        ...sets,
        inputStyle, // oval || underline
        background  // light || dark
    }

    return <ThemeContext.Provider value={value} {...props} />
}

export const useTheme = () => {
    const themeContext = useContext(ThemeContext)
    if (!themeContext) throw new Error('Theme context not available.')

    return themeContext
}
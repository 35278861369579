import React, { Fragment } from 'react'

import Portrait from '../../../controls/Portrait'
import Wallpaper from '../../../controls/Wallpaper'
import Landscape from '../../../controls/Landscape'
import LandscapeSegment from '../../../controls/LandscapeSegment'
import SideMenu from '../../../controls/SideMenu'
import OrderProxy from './OrderProxy'
import { usePhone, useLandscape } from '../../../../hooks/useMedia'
import useSwitchTheme from '../../../../hooks/useSwitchTheme'

const Order = () => {
    // Local state

    // Other hooks
    useSwitchTheme()
    const isPhone = usePhone()
    const isLandscape = useLandscape()

    return (
        <Fragment>
            <Wallpaper />
            {
                isPhone 
                ?
                (
                    isLandscape 
                    ?
                    <Landscape align='flex-start'>
                        <OrderProxy> 
                            <SideMenu />
                            <OrderProxy.PayStatus />
                            <LandscapeSegment pctWidth={45} pctHeight={100}>
                                <OrderProxy.Header />
                                <OrderProxy.Ribbon />
                            </LandscapeSegment>
                            <LandscapeSegment pctWidth={55}>
                                <OrderProxy.AddressPrompt />
                                <OrderProxy.PaymentPrompt />
                                <OrderProxy.Invoice />
                                <OrderProxy.PayButton />
                            </LandscapeSegment>
                        </OrderProxy>
                    </Landscape>
                    :
                    <Portrait>
                        <OrderProxy> 
                            <SideMenu />
                            <OrderProxy.PayStatus />
                            <OrderProxy.Header />
                            <OrderProxy.AddressPrompt />
                            <OrderProxy.PaymentPrompt />
                            <OrderProxy.Ribbon />
                            <OrderProxy.Invoice />
                            <OrderProxy.PayButton />
                        </OrderProxy>
                    </Portrait>
                )
                :
                (
                    isLandscape 
                    ?
                    <Portrait>
                        <OrderProxy>
                            <OrderProxy.Header />
                            <Landscape>
                                <OrderProxy.PayStatus />
                                <LandscapeSegment pctWidth={45} pctHeight={100}>
                                    <OrderProxy.AddressPrompt />
                                    <OrderProxy.PaymentPrompt />
                                    <OrderProxy.Ribbon />
                                </LandscapeSegment>
                                <LandscapeSegment pctWidth={55}>
                                    <OrderProxy.Invoice />
                                    <OrderProxy.PayButton pctWidth={60} />
                                </LandscapeSegment>
                            </Landscape>
                        </OrderProxy>
                    </Portrait>
                    :
                    <Portrait>
                        <OrderProxy>
                            <OrderProxy.PayStatus/>
                            <OrderProxy.Header />
                            <OrderProxy.AddressPrompt />
                            <OrderProxy.PaymentPrompt />
                            <OrderProxy.Ribbon />
                            <OrderProxy.Invoice />
                            <OrderProxy.PayButton pctWidth={50} />
                        </OrderProxy>
                    </Portrait>
                )
            }
        </Fragment>
    )
}

export default Order
import React, { Fragment } from 'react'

import Header from '../../../../controls/Header'
import HeaderDesktop from '../../../../media/HeaderDesktop'
import useClientSignupContext from '../ClientSignupContext'
import { usePhone } from '../../../../../hooks/useMedia'
import { useTheme } from '../../../../contexts/Theme'

const ClientSignupHeader = () => {
    // Other hooks
    const { runResetFunctions, isCurrentMember, clearCurrentMember } = useClientSignupContext()
    const isPhone = usePhone()
    const theme = useTheme()
    
    const to = isCurrentMember() ? '/members' : '/clients';
    const destination = isCurrentMember() ? 'members' : 'clients';

    // Destructure theme
    const backColor = theme.isDarkBackground() ? "yellow" : "blue";

    const onBack = () => {
        runResetFunctions()
        isCurrentMember() && clearCurrentMember()
    }

    return (
        <Fragment>
        {
            isPhone
            ?
            <Header arrow={{
                color: backColor,
                to,
                destination
            }} title='Add Client' onBack={onBack} separator />
            :
            <HeaderDesktop height={100} leftContent={<Header arrow={{
                color: backColor,
                to,
                destination
            }} title='Add Client' onBack={onBack} />} />
        } 
        </Fragment>
    )
}

export default ClientSignupHeader
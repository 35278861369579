import React from 'react'

import Footer from './parts/Footer'
import SalonOrderHeader from './parts/Header'
import MainMenu from './parts/MainMenu'
import PrimeRibbon from './parts/PrimeRibbon'
import TuneRibbon from './parts/TuneRibbon'
import CreamsRibbon from './parts/CreamsRibbon'
import SerumsRibbon from './parts/SerumsRibbon'
import SalonOrderInvoice from './parts/Invoice'
import PayButton from './parts/PayButton'
import Checkout from './parts/Checkout'
import PayStatus from './parts/PayStatus'
import { SalonOrderProvider } from './SalonOrderContext'
import useSalonOrder from './useSalonOrder'
import useActiveMember from '../../../../hooks/useActiveMember'
import useRecoverObjects from '../../../../hooks/useRecoverObjects'

import Null from '../../../controls/Null'

// The proxy calls the hooks for this page and sets up its context
//
const SalonOrderProxy = ({ children }) => {
    const { ...rest } = useSalonOrder()
    const { activeMember, salon, authToken } = useActiveMember()

    // Check for and recover from page refresh
    if (useRecoverObjects({ activeMember })) return <Null />

    return (
        <SalonOrderProvider value={{ activeMember, salon, authToken, ...rest }}>{children}</SalonOrderProvider>
    )
}

// Component parts of the page, can be laid out any way we want
//
SalonOrderProxy.Header = SalonOrderHeader
SalonOrderProxy.MainMenu = MainMenu
SalonOrderProxy.PrimeRibbon = PrimeRibbon
SalonOrderProxy.TuneRibbon = TuneRibbon
SalonOrderProxy.CreamsRibbon = CreamsRibbon
SalonOrderProxy.SerumsRibbon = SerumsRibbon
SalonOrderProxy.Invoice = SalonOrderInvoice
SalonOrderProxy.PayButton = PayButton
SalonOrderProxy.Checkout = Checkout
SalonOrderProxy.PayStatus = PayStatus
SalonOrderProxy.Footer = Footer

export default SalonOrderProxy
import React from 'react'

import PhotoUpload from '../../../../controls/PhotoUpload'
import useSignupFormContext from '../SignupFormContext'

const ProfilePhoto = () => {
    // Hooks
    const { photoValue, setPhotoFiles, onPhotoReady } = useSignupFormContext()

    return (
        <PhotoUpload 
            value={photoValue} setPhotoFiles={setPhotoFiles} 
            onPhotoReady={onPhotoReady} marginTop={20} 
        />
    )
}

export default ProfilePhoto
import React, { useState} from 'react'

import Input from './Input'
import Submit from './Submit'
import useAddressContext, { AddressProvider } from '../contexts/Address'

const Addr1 = ({ width, percentWidth, placeholder = 'Address line 1', marginTop }) => {
    const { address, onAddr1Change } = useAddressContext()

    return (
        <Input 
            type='text' name='addr1' value={address.addr1} onChange={onAddr1Change} marginTop={marginTop}
            width={width} placeholder={placeholder} percentWidth={percentWidth} required 
        />
    ) 
}

const Addr2 = ({ width, percentWidth, placeholder = 'Address line 2', marginTop }) => {
    const { address, onAddr2Change } = useAddressContext()

    return (
        <Input 
            type='text' name='addr2' value={address.addr2} onChange={onAddr2Change} 
            width={width} placeholder={placeholder} percentWidth={percentWidth} marginTop={marginTop} 
        />
    ) 
}

const City = ({ width, percentWidth, placeholder = 'City', marginTop }) => {
    const { address, onCityChange } = useAddressContext()

    return (
        <Input 
            type='text' name='city' value={address.city} onChange={onCityChange} marginTop={marginTop} 
            width={width} placeholder={placeholder} percentWidth={percentWidth} required 
        />
    ) 
}

const State = ({ width, percentWidth, placeholder = 'State', marginTop }) => {
    const { address, onStateChange } = useAddressContext()

    return (
        <Input 
            type='text' name='state' value={address.state} onChange={onStateChange} marginTop={marginTop} 
            width={width} placeholder={placeholder} percentWidth={percentWidth} required 
        />
    ) 
}

const Zip = ({ width, percentWidth, placeholder = 'Zip', marginTop }) => {
    const { address, onZipChange } = useAddressContext()

    return (
        <Input 
            type='text' name='zip' value={address.zip} onChange={onZipChange} marginTop={marginTop} 
            width={width} placeholder={placeholder} percentWidth={percentWidth} required 
        />
    ) 
}

const Country = ({ width, percentWidth, placeholder = 'Country', marginTop }) => {
    const { address, onCountryChange } = useAddressContext()

    return (
        <Input 
            type='text' name='country' value={address.country} onChange={onCountryChange} 
            width={width} placeholder={placeholder} percentWidth={percentWidth} 
        />
    ) 
}

const Done = props => {
    return (
        <Submit {...props} />
    )
}

const Address = ({ children, onDone }) => {
    // State
    const [ address, setAddress ] = useState({
        addr1: '', addr2: '', city: '', state: '', zip: '', country: 'USA'
    })

    const onAddr1Change = e => setAddress({ ...address, addr1: e.target.value })
    const onAddr2Change = e => setAddress({ ...address, addr2: e.target.value })
    const onCityChange = e => setAddress({ ...address, city: e.target.value })
    const onStateChange = e => setAddress({ ...address, state: e.target.value })
    const onZipChange = e => setAddress({ ...address, zip: e.target.value })
    const onCountryChange = e => setAddress({ ...address, country: e.target.value })

    const onSubmit = e => {
        e.preventDefault()
        
        onDone(address)
    }

    return (
        <AddressProvider 
            value={{ address, onAddr1Change, onAddr2Change, onCityChange, onStateChange, onZipChange, onCountryChange }} 
        >
            <form onSubmit={onSubmit}>
            { children }
            </form>
        </AddressProvider>
    )
}

// Address components, lay these out any way you want ... 

Address.Addr1 = Addr1
Address.Addr2 = Addr2
Address.City = City
Address.State = State
Address.Zip = Zip
Address.Country = Country
Address.Done = Done

export default Address;
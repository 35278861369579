import React from 'react'

import Footer from './parts/Footer'
import ClientHeader from './parts/Header'
import Identifier from './parts/Identifier'
import ActivityCount from './parts/ActivityCount'
import Technicals from './parts/Technicals'
import CurrentHaircut from './parts/CurrentHaircut'
import History from './parts/History'
import { ClientProvider } from './ClientContext'
import useActiveMember from '../../../../hooks/useActiveMember'
import useCurrentClient from '../../../../hooks/useCurrentClient'
import useRecoverObjects from '../../../../hooks/useRecoverObjects'

import Null from '../../../controls/Null'

// The proxy calls the hooks for this page and sets up its context
//
const ClientProxy = ({ children }) => {
    const { activeMember } = useActiveMember()
    const { currentClient } = useCurrentClient()

    // Check for and recover from page refresh
    if (useRecoverObjects({ activeMember, currentClient })) return <Null />

    return (
        <ClientProvider value={{ currentClient }}>{children}</ClientProvider>
    )
}

// Component parts of the page, can be laid out any way we want
//
ClientProxy.Header = ClientHeader
ClientProxy.Identifier = Identifier
ClientProxy.ActivityCount = ActivityCount
ClientProxy.Technicals = Technicals
ClientProxy.CurrentHaircut = CurrentHaircut
ClientProxy.History = History
ClientProxy.Footer = Footer

export default ClientProxy
import React, { Fragment, useCallback, useState, useEffect } from 'react'

import { TEMPLATES } from '../useAddHaircut'
import RadioButtonList from '../../../../controls/RadioButtonList'
import Button from '../../../../controls/Button'
import useAddHaircutContext from '../AddHaircutContext'
import {
    SERUM_RADIUS_NAME,
    SERUM_BLUR_NAME,
    SERUM_PLUMB_NAME,
    SERUM_STRATA_NAME,
    SERUM_TILT_NAME
} from '../../../../../constants'

const templateItems = [
    SERUM_RADIUS_NAME,
    SERUM_BLUR_NAME,
    SERUM_PLUMB_NAME,
    SERUM_STRATA_NAME,
    SERUM_TILT_NAME
]

let lastSelected
let firstMounted = true

const Templates = ({ marginBottom }) => {
    const [ selected, setSelected ] = useState(lastSelected)

    // Other hooks
    const { stage, onStageChange, onTemplateChange, addResetFunction } = useAddHaircutContext() 

    // Initial mount effect
    useEffect(() => {
        if (!firstMounted) return;

        firstMounted = false
        addResetFunction(resetBackup)
    })

    const resetBackup = () => {
        firstMounted = true
        lastSelected = undefined
    }

    const handleTemplateChange = useCallback(index => {
        onTemplateChange(index, templateItems[index]) 
        if (index === -1) {
            setSelected(lastSelected = undefined)
            return;
        }        
        setSelected(lastSelected = index)
    }, [ onTemplateChange ])

    const onNext = useCallback(() => onStageChange(TEMPLATES), [ onStageChange ])

    return (
        <Fragment>
        {
            (stage === TEMPLATES) &&
            <Fragment>
                <RadioButtonList items={templateItems} value={selected} marginTop={20} onChange={handleTemplateChange} />
                <Button 
                    onClick={onNext} label='Next' width={200} height={75} marginBottom={marginBottom} 
                    marginTop={30} fontSize={24} disabled={selected === undefined}
                />
            </Fragment>
        }
        </Fragment>
    )
}

export default Templates
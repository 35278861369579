import { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

let lastResetFunctions = []
let lastSalonMembers = []

let initialLoad = true

const resetMe = () => { 
    lastResetFunctions = []
    lastSalonMembers = []
    initialLoad = true 
}

const useMembers = () => {
    // State
    const [ salonMembers, setSalonMembers ] = useState(lastSalonMembers)
    const [ spinner, setSpinner ] = useState(false)    

    // Get the active member
    const activeMember = useSelector(state => state.activeMember)
    const resetFunctions = useRef(lastResetFunctions)

    // Initial load effect
    //
    useEffect(() => {
        if (!initialLoad) return;
        addResetFunction(resetMe)
        initialLoad = false
    }, [])

    // Salon available effect, load salon's members
    //
    useEffect(() => {
        const loadSalon = async () => {
            try {
                setSpinner(true)

                // Get the salon's members
                const rsp = await axios.get(`/api/members/${activeMember.salon.salonId}/salon`)
                lastSalonMembers = [ ...rsp.data ]
                setSalonMembers(rsp.data)
                setSpinner(false)
            } catch(err) {
                setSpinner(false)
            }
        }
        activeMember?.salon && loadSalon()
    }, [ activeMember ])

    const addResetFunction = func => {
        resetFunctions.current.push(func)
        lastResetFunctions.push(func)
    }

    const runResetFunctions = () => resetFunctions.current.forEach(func => func?.())

    return {
        spinner,
        addResetFunction, runResetFunctions,
        salonMembers
    }
}

export default useMembers
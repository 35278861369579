import React, { useState, useEffect } from 'react'
import axios from 'axios'

import SearchBar from '../../../../controls/SearchBar'
import { timeoutConfig, isEmpty } from '../../../../../utils'
import useSignupContext from '../SignupContext'
import useSignupFormContext from '../SignupFormContext'
 
let lastSalonName = ''

const Salon = () => {
    // Local state
    const [ salonName, setSalonName ] = useState('')

    // Hooks
    const { addResetFunction } = useSignupContext()
    const { activeMember, onSelectSalon } = useSignupFormContext()

    // Initial mount and landscape-to-portrait switch
    useEffect(() => {
        addResetFunction(resetBackup)
        lastSalonName && setSalonName(lastSalonName)
    }, [])

    // Active member loaded effect
    useEffect(() => {
        if (isEmpty(activeMember)) return;
        if (activeMember.salon?.name) {
            setSalonName(activeMember.salon.name)
            lastSalonName = activeMember.salonname
        }
    }, [ activeMember ])

    const resetBackup = () => {
        lastSalonName = ''
    }

    const onSelect = salon => {
        onSelectSalon(salon)
        setSalonName(salon.name)
        lastSalonName = salon.name
    }
     
    return (
        <SearchBar 
            iconUrl='salon' 
            name='salon'
            value={salonName}
            marginTop='30'
            placeholder='Your salon'
            caption="Tap spy to select your salon, or first enter search term to narrow the list."
            search={searchTerm => axios.get(`/api/salons/${searchTerm}`, timeoutConfig({}))}
            nameField='name'
            onSelect={onSelect}
        />
    )
}
 
export default Salon
import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { useTheme } from '../contexts/Theme'

const StyledButton = styled.button`    
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    font: ${props => props.fontSize ? props.fontSize : '20'}px verdana, sans-serif;
    color: ${props => props.color};
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid ${props => props.borderColor};
    border: ${p => p.borderless && 'none'};
    border-radius: 100px;
    margin-top: ${props => props.marginTop}px;
    margin-bottom: ${props => props.marginBottom}px;

    &.pressed { box-shadow: 2px 2px 2px 2px ${props => props.borderColor} inset; }
    &.disabled { opacity: 0.5 }
`
// If "to" is passed, this will be a link
//
const Button = ({ onClick, label, width, height, marginTop, marginBottom, to, fontSize, disabled, borderless }) => {
    const theme = useTheme()
    const { white, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const [ pressed, setPressed ] = useState(false)

    const [ textColor, borderColor ] = theme.isDarkBackground() ? [ white, rgbaWhiteBg ] : [ rgbDark, rgbaDarkBg ];

    return (
        <Fragment>
            <StyledButton 
                width={width || '100'} 
                height={height || '50'}
                onTouchStart={() => disabled ? null : setPressed(true)}
                onTouchEnd={() => setPressed(false)}
                onMouseDown={() => disabled ? null : setPressed(true)}
                onMouseUp={() => setPressed(false)}
                onClick={() => disabled ? null : onClick ? onClick() : null}
                color={textColor}
                borderColor={borderColor}
                borderless={borderless}
                marginTop={marginTop}
                marginBottom={marginBottom}
                fontSize={fontSize}
                className={`${disabled ? 'disabled' : pressed ? ' pressed' : ''}`}
            >
            { to ? <Link to={to} style={{textDecoration: 'none', color: textColor}}>{label}</Link> : label }
            </StyledButton>
        </Fragment>
    )
}

export default Button
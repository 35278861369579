import React from 'react'

import HistoryLadder, { clientHistorySetup, clientHistoryInserts } from '../../../../controls/HistoryLadder'
import useClientContext from '../ClientContext'

const History = ({ marginTop }) => {
    // Hooks
    const { currentClient } = useClientContext()

    return (
        <HistoryLadder 
            itemGroups={clientHistorySetup({ client: currentClient })}
            postMerge={clientHistoryInserts} 
            height={400} marginTop={marginTop ? marginTop : 20} marginBottom={100} 
        />
    )
}

export default History
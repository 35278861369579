import React, { useState, useEffect } from 'react'

import AboutMe from '../../../../controls/AboutMe'
import useSignupContext from '../SignupContext'
import useSignupFormContext from '../SignupFormContext'

let lastAbout = ''

const About = () => {
    // State
    const [ about, setAbout ] = useState(null)

    // Hooks
    const { onAboutChange } = useSignupFormContext()
    const { addResetFunction } = useSignupContext()

    useEffect(() => {
        addResetFunction(resetBackup)
        lastAbout && setAbout(lastAbout)
    }, [])

    const resetBackup = () => {
        lastAbout = ''
    }

    const onChange = e => {
        onAboutChange(e)
        setAbout(e.target.value)
        lastAbout = e.target.value
    }

    return (
        <AboutMe
            caption='Enter some information about yourself,
                including accomplishments and skills.'
            value={about}
            placeholder='Please tell us a bit about yourself.'
            marginTop={30}
            onChange={onChange}
        />
    )
}

export default About
import React from 'react'

import Submit from '../../../../controls/Submit'

const SignupSubmit = () => {
    return (
        <Submit width={'100'} marginTop={'40'} text='Save' />
    )
}

export default SignupSubmit
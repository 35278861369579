import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import store from './redux/store'
import App from './App'
import { ErrorProvider } from './components/contexts/Error'
import { terms, ThemeProvider } from './components/contexts/Theme'
import { HelpProvider } from './components/contexts/Help'
import { STRIPE_PUBLISHABLE_KEY } from './constants'

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

ReactDOM.render(
    <Provider store={store} >
        <BrowserRouter>
            <ErrorProvider>
                <Elements stripe={stripePromise}>
                    {/* Default theme */}
                    <ThemeProvider inputStyle={terms.underline} background={terms.light}>
                        <HelpProvider>
                            <App />
                        </HelpProvider>
                    </ThemeProvider>
                </Elements>
            </ErrorProvider>
        </BrowserRouter>
    </Provider>, 
    document.getElementById('root')
)

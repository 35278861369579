import React, { Fragment } from 'react'

import Header from '../../../../controls/Header'
import HeaderDesktop from '../../../../media/HeaderDesktop'
import { useTheme } from '../../../../contexts/Theme'
import useProfileContext from '../ProfileContext'
import { usePhone } from '../../../../../hooks/useMedia'

const ProfileHeader = () => {
    // Other hooks
    const { activeClient, runResetFunctions, logoff } = useProfileContext()
    const isPhone = usePhone()
    
    // Reference the theme 
    const theme = useTheme()
    const backColor = activeClient.firstVisit ? 'red' : (theme.isDarkBackground() ? 'yellow' : 'blue');

    const onBack = () => {
        runResetFunctions?.()
        logoff()
    }

    const onHome = () => runResetFunctions?.()

    return (
        <Fragment>
        {
            isPhone
            ?
            <Header arrow={{
                color: backColor,
                to: activeClient.firstVisit ? '/' : '/client-home',
                destination: activeClient.firstVisit ? 'Logout' : 'Home'
            }} title='Profile' onBack={() => activeClient.firstVisit ? onBack() : onHome()} separator />
            :
            <HeaderDesktop height={100} leftContent={<Header arrow={{
                color: backColor,
                to: activeClient.firstVisit ? '/' : '/client-home',
                destination: activeClient.firstVisit ? 'Logout' : 'Home'
            }} title='Profile' onBack={() => activeClient.firstVisit ? onBack() : onHome()} />} />
        } 
        </Fragment>
    )
}

export default ProfileHeader
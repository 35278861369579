import React, { createContext, useContext } from 'react'

const Order = createContext(undefined)

export const OrderProvider = ({ children, value }) => {
    return (
        <Order.Provider value={value}>{children}</Order.Provider>
    )
}

const useOrderContext = () => {
    const context = useContext(Order)

    if (context === undefined) throw new Error('useOrderContext must be used within an OrderProvider')

    return context;
}

export default useOrderContext
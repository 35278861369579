import React, { useState } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'
import { useHelp } from '../contexts/Help'
import Caption from './Caption'

const ListContainer = styled.div`
    width: 90%;
    height: auto;
    margin-top: ${props => props.marginTop}px;
    margin-bottom: ${props => props.marginBottom}px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: ${props => props.textColor};
`

const Title = styled.div`
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    font: 24px verdana, sans-serif;
    text-align: center;
    flex-shrink: 0;
`

const List = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 10px;
    margin-bottom: 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

const WhatsThis = styled.div`
    position: absolute;
    top: -10px;
    left: -40px;
    width: 40px;
    height: 49px;
    background-image: url('${process.env.PUBLIC_URL}/images/whats-this-${props => props.iconColor}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Asset = styled.div`
    position: relative;
    right: 50px;
    width: 60px;
    height: 70px;
    border-color: ${props => props.borderColor};
    border-style: solid;
    border-width: 0 2px 2px 0;
    font: 16px verdana, sans-serif;
`

const Joint = styled.div`
    position: absolute;
    right: -10px;
    bottom: -10px;
    height: 20px;
    width: 20px;
    background-color: rgb(255, 128, 64);
    border-radius: 50%;
    z-index: 2;
`

const Circle = styled.div`
    position: absolute;
    left: -40px;
    bottom: -20px;
    height: 40px;
    width: 40px;
    background-image: url('${process.env.PUBLIC_URL}/images/unchecked-${props => props.iconColor}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.selected { 
        background-image: url('${process.env.PUBLIC_URL}/images/checked-${props => props.iconColor}.png'); 
    }
`

const Label = styled.div`
    position: absolute;
    right: -115px;
    bottom: -20px;
    width: 90px;
    height: 40px;
    text-align: center;
`

const TrashCan = styled.div`
    position: absolute;
    top: 0;
    right: -40px;
    width: 34px;
    height: 40px; 
    background-image: url('${process.env.PUBLIC_URL}/images/trash-can-${props => props.iconColor}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Stem = styled.div`
    position: absolute;
    bottom: -2px;
    right: -20px;
    width: 20px;
    height: 5px;
    border-style: solid;
    border-width: 0 0 2px 0;
`

const NoItems = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${props => props.color};
    font: 20px verdana, sans-serif;
`

const AssetList = ({ 
    title, assetName, assets, formatLabel, marginTop, marginBottom, caption, onTrash, onDefaultChanged
}) => {
    const [ selectedAsset, setSelectedAsset ] = useState(assets.findIndex(asset => asset.isDefault))
    const [ captionVisible, setCaptionVisible ] = useState(false)
    
    // Other hooks
    const theme = useTheme()
    const help = useHelp()
    const { white, dark, rgbDark, rgbaWhiteBg, rgbaDarkBg } = theme
    const [ textColor, borderColor, iconColor, shadow ] = theme.isDarkBackground() ? 
        [ white, white, white, rgbaWhiteBg ] : 
        [ rgbDark, rgbDark, dark, rgbaDarkBg ];
    const helpRequested = help.helpWasRequested()

    // Handlers
    const onWhatsThis = () => setCaptionVisible(!captionVisible)
    const onCircleClick = index => {
        setSelectedAsset(selectedAsset === index ? -1 : index) 
        onDefaultChanged && onDefaultChanged(index) 
    }

    // Get index in case asset list has changed since last refresh
    if (assets.findIndex(asset => asset.isDefault) !== selectedAsset)
        setSelectedAsset(assets.findIndex(asset => asset.isDefault))

    return (
        <ListContainer 
            textColor={textColor} marginTop={marginTop ? marginTop : 0} marginBottom={marginBottom ? marginTop : 0}
        >
            <Title>{title}</Title>
            <List>                
            {
                assets.length > 0 ?
                    assets.map((asset, i) => 
                        <Asset key={i} borderColor={borderColor}>
                            { helpRequested && i === 0 && caption && 
                                <WhatsThis iconColor={iconColor} onClick={onWhatsThis}>
                                    { captionVisible && <Caption text={caption} width='200' bottom='60' left='0' stemOffset={20} /> }
                                </WhatsThis> 
                            }
                            <Joint />
                            <Label>
                                {formatLabel(asset)}
                                <TrashCan iconColor={iconColor} onClick={() => onTrash(i)} />
                            </Label>
                            <Stem />
                            <Circle 
                                iconColor={iconColor} 
                                className={i === selectedAsset ? 'selected' : ''}
                                onClick={() => onCircleClick(i)} 
                            />
                        </Asset>
                    )
                    :
                    <NoItems color={shadow}>{`No ${assetName} set.`}</NoItems>
            }
            </List>
        </ListContainer>
    )
}

export default AssetList
import React, { useState, useEffect } from 'react'

import DatePicker from '../../../../controls/DatePicker'
import { isEmpty } from '../../../../../utils'
import useSignupContext from '../SignupContext'
import useSignupFormContext from '../SignupFormContext'

let lastBirthday = null

const Birthday = () => {
    // State
    const [ birthday, setBirthday ] = useState(null)

    // Hooks
    const { activeMember, onBirthdayReady } = useSignupFormContext()
    const { addResetFunction } = useSignupContext()

    useEffect(() => {
        addResetFunction(resetBackup)
        lastBirthday && setBirthday(lastBirthday)
    }, [])

    // Active member loaded effect
    useEffect(() => {
        if (isEmpty(activeMember)) return;
        if (activeMember.birthdate) {
            setBirthday(activeMember.birthdate)
            lastBirthday = activeMember.birthdate
        }
    }, [ activeMember ])

    const resetBackup = () => {
        lastBirthday = null
    }

    const onReady = dateString => {
        onBirthdayReady(dateString)
        setBirthday(dateString)
        lastBirthday = dateString
    }

    return (
        <DatePicker
            caption='Select your birthday (Optional)'
            onDateReady={onReady}
            value={birthday}
            iconUrl='baby-carriage'
            marginTop={30}
            noYear
        />
    )
}

export default Birthday
import React from 'react'

import Header from './parts/Header'
import Footer from './parts/Footer'
import Status from './parts/Status'
import Form from './parts/ClientSignupForm'
import useClientSignup from './useClientSignup'
import { ClientSignupProvider } from './ClientSignupContext'
import useActiveMember from '../../../../hooks/useActiveMember'
import useCurrentMember from '../../../../hooks/useCurrentMember'
import useCurrentClient from '../../../../hooks/useCurrentClient'
import useRecoverObjects from '../../../../hooks/useRecoverObjects'

import Null from '../../../controls/Null'

// The proxy calls the hooks for this page and sets up its context
//
const ClientSignupProxy = ({ children }) => {
    const { activeMember, ...rest } = useActiveMember()
    const { currentMember, clearCurrentMember, isCurrentMember } = useCurrentMember()
    const { ...client } = useCurrentClient()
    const { ...signup } = useClientSignup()

    // Check for and recover from page refresh
    if (useRecoverObjects({ activeMember, currentMember })) return <Null />

    return (
        <ClientSignupProvider value={{ 
            activeMember, currentMember, clearCurrentMember, isCurrentMember, 
            ...rest, ...client, ...signup 
        }}>{children}</ClientSignupProvider>
    )
}

// Component parts of the page, can be laid out any way we want
//
ClientSignupProxy.Header = Header
ClientSignupProxy.Status = Status
ClientSignupProxy.Form = Form
ClientSignupProxy.Footer = Footer

export default ClientSignupProxy
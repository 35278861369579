import React, { useMemo } from 'react'

import GridMenu from '../../../../controls/GridMenu'
import useSalonOrderContext from '../SalonOrderContext'

const MainMenu = ({ widthPct, cellSize, marginTop }) => {
    const { onPrimeClicked, onTuneClicked, onCreamsClicked, onSerumsClicked } = useSalonOrderContext()

    const items = useMemo(
        () => [
            { label: 'Prime', bgColor: 'rgb(128, 128, 192)', color: 'white', onClick: onPrimeClicked },
            { label: 'Tune', bgColor: 'rgb(112, 146, 190)', color: 'white', onClick: onTuneClicked },
            { label: 'Creams', bgColor: 'rgb(200, 191, 231)', color: 'white', onClick: onCreamsClicked },
            { label: 'Serums', bgColor: 'rgb(181, 230, 29)', color: 'white', onClick: onSerumsClicked }
        ], [onPrimeClicked, onTuneClicked, onCreamsClicked, onSerumsClicked]
    )

    return (
        <GridMenu gridWidthPct={widthPct} cellSize={cellSize} items={items} marginTop={marginTop} />
    )
}

export default MainMenu
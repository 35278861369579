import { 
    SET_CURRENT_MEMBER,
    CLEAR_CURRENT_MEMBER,
    BACKUP_CURRENT_MEMBER,
    RESTORE_CURRENT_MEMBER,
    SET_CURRENT_MEMBER_PROPERTY,
    ADD_CLIENT_TO_CURRENT_MEMBER
} from '../actions/types'

const initialState = {}

const currentMember = (state = initialState, action) => {

    switch (action.type) {
        case SET_CURRENT_MEMBER:
        {
            const member = { ...action.payload }
            localStorage.setItem('currentMember', JSON.stringify(member))
            return member
        }
        case CLEAR_CURRENT_MEMBER:
            localStorage.removeItem('currentMember')
            return {}
        case BACKUP_CURRENT_MEMBER:
        {
            const member = { ...action.payload }
            localStorage.setItem('currentMember', JSON.stringify(member))
            return member
        }
        case RESTORE_CURRENT_MEMBER: {
            const member = localStorage.getItem('currentMember')
            return JSON.parse(member)
        }
        case SET_CURRENT_MEMBER_PROPERTY: {
            const member = { ...state }
            member[action.payload.key] = action.payload.value
            localStorage.setItem('currentMember', JSON.stringify(member))
            return member
        }
        case ADD_CLIENT_TO_CURRENT_MEMBER: {
            const member = { ...state }
            member.clients = [ action.payload, ...member.clients ]
            localStorage.setItem('currentMember', JSON.stringify(member))
            return member
        }
        default:
            return state
    }
}

export default currentMember
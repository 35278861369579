import React, { Fragment, useState, useRef } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import { SET_ACTIVE_MEMBER, SET_ACTIVE_CLIENT, SET_ACTIVE_ADMIN, SET_TOKEN } from '../../redux/actions/types'
import { 
    MEMBER, CLIENT, ADMIN, USER_STANDBY, USER_LOADING, USER_LOADED, USER_LOAD_ERROR 
} from '../../constants'
import { isEmpty, timeoutConfig } from '../../utils'
import { usePhone } from '../../hooks/useMedia'
import LoginPhone from '../media/LoginPhone'
import LoginDesktop from '../media/LoginDesktop'

const Login = () => {
    // Local state
    const [ userid, setUserid ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ user, setUser ] = useState()
    const [ answer, setAnswer ] = useState()
    const [ answerReply, setAnswerReply ] = useState(false)
    const [ loginError, setLoginError ] = useState(null)
    const [ loadStatus, setLoadStatus ] = useState(USER_STANDBY)

    // Other hooks
    const dispatch = useDispatch()    
    const activeAdmin = useSelector(state => state.activeAdmin)    
    const activeMember = useSelector(state => state.activeMember)
    const activeClient = useSelector(state => state.activeClient)
    const keepMe = useRef(0)
    const isPhone = usePhone() 

    if (!isEmpty(activeAdmin)) {
        return activeAdmin.firstVisit ? <Navigate to="/admin-profile" replace /> : <Navigate to="/admin-home" replace />;
    }
    
    if (!isEmpty(activeMember)) {
        console.log('MEMBER: ', activeMember)
        return (
            activeMember.firstVisit 
            ? 
            <Navigate to="/member-signup" replace /> 
            :
            activeMember.isAdmin 
            ? 
            <Navigate to="/members" replace /> 
            :
            <Navigate to="/clients" replace />
        );
    }
    
    if (!isEmpty(activeClient)) {
        return activeClient.firstVisit ? <Navigate to="/client-profile" replace /> : <Navigate to="/client-home" replace />;
    }    

    const onUseridChange = e => {
        setUserid(e.target.value)
        setLoginError(null)
    }
    const onPasswordChange = e => {
        setPassword(e.target.value)
        setLoginError(null)
    }
    const onAnswerChange = e => setAnswer(e.target.value)

    const onSubmitAnswer = async () => {

        try {

            if (answer !== user.secretQuestionAnswer) { // Incorrect answer
                setUser(null)
                setAnswerReply('Your answer does not match our records. Please see the webmaster for a new access code.')
                return;
            }

            // Answer matches question ...

            setLoadStatus(USER_LOADING)

            switch (user.userType) {
                case CLIENT:
                    await axios.patch(`/api/clients/${user._id}/reset`, timeoutConfig({}))
                    break;
                case MEMBER:
                    await axios.patch(`/api/members/${user._id}/reset`, timeoutConfig({}))
                    break;
                case ADMIN:
                    await axios.patch(`/api/admins/${user._id}/reset`, timeoutConfig({}))
                    break;
                default: break;
            }
            
            setLoadStatus(USER_LOADED)
            setAnswerReply(`Your new access code has been sent to your mobile number ending in ${user.phone.slice(-4)}`)
            setUser(null)

        } catch(err) {
            if (err.response) { // status 400 (incl. authentication), 404 (not found), 500
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setAnswerReply(`${errMsg}, changes not saved.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setAnswerReply(`Server took too long to respond, changes not saved, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setAnswerReply(`Error: ${err?.message}, changes not saved`)
            }
            setLoadStatus(USER_LOAD_ERROR)
            setUser(null)
        }
    }

    const onOkReply = () => setAnswerReply(null) 

    const onSubmit = e => {
        e.preventDefault()
        
        // Set load status
        setLoadStatus(USER_LOADING)
        
        // The login request
        axios.post('/api/login', { userid, password, keepMe: keepMe.current })
            .then(rsp => { 
                console.log(rsp.data)

                // All good, set the active user state
                switch (rsp.data.user.userType) {
                    case MEMBER:
                        dispatch({
                            type: SET_ACTIVE_MEMBER,
                            payload: rsp.data.user
                        }) 
                        break;
                    case CLIENT:
                        dispatch({
                            type: SET_ACTIVE_CLIENT,
                            payload: rsp.data.user
                        }) 
                        break;
                    case ADMIN:
                        dispatch({
                            type: SET_ACTIVE_ADMIN,
                            payload: rsp.data.user
                        }) 
                        break;
                    default:
                        break;
                }

                // Set the token
                dispatch({
                    type: SET_TOKEN,
                    payload: rsp.data.token
                })

                // Set the load status
                setLoadStatus(USER_LOADED)
            })
            .catch(err => {
                setLoginError(err.response.data.error)
                setLoadStatus(USER_LOAD_ERROR)
            })
    }

    const onForgotPassword = async e => {
        e.preventDefault()
        
        try {            
        
            // Set load status
            setLoadStatus(USER_LOADING)
            
            // Get the user
            const rsp = await axios.get(`/api/login/${userid}/user`)

            // No error, set the user for reset conversation
            switch (rsp.data.userType) {
                case CLIENT: 
                    setUser(rsp.data.client)
                    break;
                case MEMBER:
                    setUser(rsp.data.member)
                    break;
                case ADMIN:
                    setUser(rsp.data.admin)
                    break;
                default: break;
            }

            // Set the load status
            setLoadStatus(USER_LOADED)

        } catch(err) {
            setLoginError(err)
            setLoadStatus(USER_LOAD_ERROR)
        }
    }

    const onKeepMe = e => keepMe.current = e.target.value
    
    return (
        <Fragment>
        {
            isPhone 
            ?
            <LoginPhone 
                onPasswordChange={onPasswordChange} onUseridChange={onUseridChange} onSubmit={onSubmit} 
                user={user} onAnswerChange={onAnswerChange} onKeepMe={onKeepMe} onForgotPassword={onForgotPassword} 
                onOkReply={onOkReply} onSubmitAnswer={onSubmitAnswer} answerReply={answerReply} loadStatus={loadStatus}
                loginError={loginError} 
            />
            :
            <LoginDesktop 
                onPasswordChange={onPasswordChange} onUseridChange={onUseridChange} onSubmit={onSubmit} 
                user={user} onAnswerChange={onAnswerChange} onKeepMe={onKeepMe} onForgotPassword={onForgotPassword} 
                onOkReply={onOkReply} onSubmitAnswer={onSubmitAnswer} answerReply={answerReply} loadStatus={loadStatus}
                loginError={loginError} 
            />
        }
        </Fragment>
    )
}

export default Login

import React, { Fragment } from 'react'

import Portrait from '../../../controls/Portrait'
import Wallpaper from '../../../controls/Wallpaper'
import Landscape from '../../../controls/Landscape'
import LandscapeSegment from '../../../controls/LandscapeSegment'
import SideMenu from '../../../controls/SideMenu'
import MembersProxy from './MembersProxy'
import { usePhone, useLandscape } from '../../../../hooks/useMedia'
import useSwitchTheme from '../../../../hooks/useSwitchTheme'

const Members = () => {
    // Local state

    // Other hooks
    useSwitchTheme()
    const isPhone = usePhone()
    const isLandscape = useLandscape()

    return (
        <Fragment>
            <Wallpaper />
            {
                isPhone 
                ?
                (
                    isLandscape 
                    ?
                    <Landscape align='flex-start'>
                        <MembersProxy> 
                            <SideMenu />
                            <LandscapeSegment pctWidth={45} pctHeight={100}>
                                <MembersProxy.Header />
                                <MembersProxy.MemberCount />
                            </LandscapeSegment>
                            <LandscapeSegment pctWidth={55}>
                                <MembersProxy.MemberSearch summaryWidthPct={70} />
                            </LandscapeSegment>
                        </MembersProxy>
                    </Landscape>
                    :
                    <Portrait>
                        <MembersProxy> 
                            <SideMenu />
                            <MembersProxy.Header />
                            <MembersProxy.MemberCount />
                            <MembersProxy.MemberSearch summaryWidthPct={85} />
                        </MembersProxy>
                    </Portrait>
                )
                :
                (
                    isLandscape 
                    ?
                    <Portrait>
                        <MembersProxy>
                            <MembersProxy.Header />
                            <Landscape>
                                <LandscapeSegment pctWidth={45} pctHeight={100}>
                                    <MembersProxy.MemberCount pctWidth={70} />
                                </LandscapeSegment>
                                <LandscapeSegment pctWidth={55}>
                                    <MembersProxy.MemberSearch viewportWidthPct={80} summaryWidthPct={70} />
                                </LandscapeSegment>
                            </Landscape>
                        </MembersProxy>
                    </Portrait>
                    :
                    <Portrait>
                        <MembersProxy>
                            <MembersProxy.Header />
                            <MembersProxy.MemberCount pctWidth={60} />
                            <MembersProxy.MemberSearch viewportWidthPct={60} />
                        </MembersProxy>
                    </Portrait>
                )
            }
        </Fragment>
    )
}

export default Members
import React, { useState, useEffect } from 'react'

import RadioButtonGroup from '../../../../controls/RadioButtonGroup'
import { useTheme } from '../../../../contexts/Theme'
import useClientSignupContext from '../ClientSignupContext'
import useClientSignupFormContext from '../ClientSignupFormContext'

let lastDiameter = 1

let initialMount = true

const Diameter = () => {
    const [ diameter, setDiameter ] = useState(lastDiameter)

    // Hooks
    const { addResetFunction } = useClientSignupContext()
    const { onDiameterChange } = useClientSignupFormContext()

    // Destructure theme
    const theme = useTheme()
    const labelText = theme.isDarkBackground() ? "yellow" : "blue";

    // Page mount effect
    //
    useEffect(() => {
        if (!initialMount) return;

        addResetFunction(resetBackup)
        initialMount = false
    }, [])

    const resetBackup = () => {
        lastDiameter = 1
        initialMount = true
    }
    
    const onChange = v => {
        onDiameterChange(v)
        setDiameter(v)
        lastDiameter = v
    }

    return (
        <RadioButtonGroup
            caption="The client's hair thickness, from coarse to fine."
            title='Diameter' panelColor='rgba(0, 0, 0, 0)' selectColor='rgb(86, 86, 171)' 
            buttonColor='rgb(112, 146, 190)' labelColor={labelText}
            options={[
                { image: 'hair-coarse.png', label: 'coarse'},
                { image: 'hair-medium.png', label: 'medium'},
                { image: 'hair-fine.png', label: 'fine'}
            ]}
            value={diameter} onChange={onChange} marginTop={10} 
        />
    )
}

export default Diameter
import React, { useState, useEffect } from 'react'

import IconInput from '../../../../controls/IconInput'
import useClientSignupContext from '../ClientSignupContext'
import useClientSignupFormContext from '../ClientSignupFormContext'

let lastEmail = ''

const Email = () => {
    const [ email, setEmail ] = useState('')

    // Hooks
    const { addResetFunction } = useClientSignupContext()
    const { onEmailChange } = useClientSignupFormContext()

    // Page mounted/portrait-to-landscape effect
    //
    useEffect(() => {
        addResetFunction(resetBackup)
        lastEmail && setEmail(lastEmail)
    }, [])

    const resetBackup = () => {
        lastEmail = ''
    }
    
    const onChange = e => {
        onEmailChange(e)
        setEmail(e.target.value)
        lastEmail = e.target.value
    }

    return (
        <IconInput
            type='text' 
            iconUrl='email' 
            name='email'
            value={email} 
            placeholder='Client email (required)'
            marginTop={30} 
            onChange={onChange}
            caption='Client e-mail for info and updates.'
            required
        />
    )
}

export default Email
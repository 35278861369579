import React, { createContext, useContext } from 'react'

const Clients = createContext(undefined)

export const ClientsProvider = ({ children, value }) => {
    return (
        <Clients.Provider value={value}>{children}</Clients.Provider>
    )
}

const useClientsContext = () => {
    const context = useContext(Clients)

    if (context === undefined) throw new Error('useClientsContext must be used within a ClientsProvider')

    return context;
}

export default useClientsContext
import React, { Fragment } from 'react'

import FooterMenu from '../../../../controls/FooterMenu'
import useClientSignupContext from '../ClientSignupContext'

const Footer = ({ leftPct }) => {
    const { isCurrentMember } = useClientSignupContext()

    return (
        <Fragment>
        {
            !isCurrentMember() &&
            <FooterMenu items={ [
                { label: 'Clients', selected: true, to: '/clients' },
                { label: 'Me', selected: false, to: '/me' }
            ] } leftPct={leftPct} />
        }
        </Fragment>
    )
}

export default Footer
import { useState, useRef, useEffect } from 'react'

let lastResetFunctions = []

const useClientSignup = () => {
    const [ status, setStatus ] = useState(false)
    const [ spinner, setSpinner ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState('')
    const [ statusMessage, setStatusMessage ] = useState('')
    const [ onStatusOk, setOnStatusOk ] = useState(null)
    
    const resetFunctions = useRef([])

    useEffect(() => {
        resetFunctions.current = lastResetFunctions
    }, [])

    const addResetFunction = func => {
        resetFunctions.current.push(func)
        lastResetFunctions.push(func)
    }

    const runResetFunctions = () => {
        resetFunctions.current.forEach(func => func?.())
        lastResetFunctions = []
    }

    return {
        status, setStatus,
        spinner, setSpinner,
        errorMessage, setErrorMessage,
        statusMessage, setStatusMessage,
        onStatusOk, setOnStatusOk,
        addResetFunction, runResetFunctions
    }
}

export default useClientSignup
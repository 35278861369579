import React, { Fragment } from 'react'
import styled from 'styled-components'

import MenuDesktop from './MenuDesktop'
import { useTheme } from '../contexts/Theme'

const Header = styled.div`
    position: relative;
    width: 100%;
    height: ${props => props.height}px;
    border-bottom: 3px groove ${props => props.borderColor};
`
// leftContent: leftmost component
//
const HeaderDesktop = ({ leftContent, height }) => {
    // Destructure the theme
    const theme = useTheme()
    const { rgbaWhiteBg, rgbaDarkBg } = theme
    const borderColor = theme.isDarkBackground() ? rgbaWhiteBg : rgbaDarkBg;

    return (
        <Fragment>
            <Header borderColor={borderColor} height={height}>
                { leftContent }
                <MenuDesktop right={10} />
            </Header>
        </Fragment>
    )
}

export default HeaderDesktop
import React, { Fragment, useCallback, useState, useEffect } from 'react'

import { TOPOGRAPHIES } from '../useAddHaircut'
import RadioButtonList from '../../../../controls/RadioButtonList'
import Button from '../../../../controls/Button'
import useAddHaircutContext from '../AddHaircutContext'
import {
    CREAM_SOFTEN_SHORT_NAME,
    CREAM_TEXTURED_SHORT_NAME,
    CREAM_SMOOTH_SHORT_NAME
} from '../../../../../constants'

const topographyItems = [
    CREAM_SOFTEN_SHORT_NAME,
    CREAM_TEXTURED_SHORT_NAME,
    CREAM_SMOOTH_SHORT_NAME
]

let lastSelected
let firstMounted = true

const Topographies = ({ marginBottom }) => {
    const [ selected, setSelected ] = useState(lastSelected)

    const { stage, onStageChange, onTopographyChange, addResetFunction } = useAddHaircutContext() 

    // Initial mount effect
    useEffect(() => {
        if (!firstMounted) return;

        firstMounted = false
        addResetFunction(resetBackup)
    })

    const resetBackup = () => {
        firstMounted = true
        lastSelected = undefined
    }

    const handleTopographyChange = useCallback(index => {
        onTopographyChange(index, topographyItems[index]) 
        if (index === -1) {
            setSelected(lastSelected = undefined)
            return;
        }        
        setSelected(lastSelected = index)
    }, [ onTopographyChange ])

    const onNext = useCallback(() => onStageChange(TOPOGRAPHIES), [])

    return (
        <Fragment>
        {
            (stage === TOPOGRAPHIES) &&
            <Fragment>
                <RadioButtonList 
                    items={topographyItems} value={selected} marginTop={20} onChange={handleTopographyChange} 
                />
                <Button 
                    onClick={onNext} label='Next' width={200} height={75} marginBottom={marginBottom} 
                    marginTop={30} fontSize={24} disabled={selected === undefined}
                />
            </Fragment>
        }
        </Fragment>
    )
}

export default Topographies
import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react'

import Invoice from '../../../../controls/Invoice'
import useOrderContext from '../OrderContext'
import { useTheme } from '../../../../contexts/Theme'

const OrderInvoice = () => {
    // Global state
    const { 
        selection,
        invoiceItems, updateInvoiceItems, updateReflectedItems, updateSubtotal, updateTax,
        updateShippingAndHandling, updateTotal,
        discount
    } = useOrderContext()
    const [ itemTapped, setItemTapped ] = useState(false)

    // Other hooks 
    const theme = useTheme()
    const [ headerBg, headerText ] = 
        theme.isDarkBackground() ?
        [ 'rgb(225, 225, 225)', 'rgb(15, 18, 57)' ] :
        [ 'rgb(15, 18, 57)', 'rgb(225, 225, 225)' ];
    const taxRate = useRef(0.085)

    useEffect(() => {
        setItemTapped(
            selection.primeSelected || selection.tuneSelected || selection.cutSelected 
        )
    }, [ selection, setItemTapped ])

    // Invoice changed handler
    // invoiceItems: [ { name, price, quantity } ... ]
    //
    const onInvoiceChange = useCallback(changedItems => {
        updateReflectedItems(changedItems)

        // Tally items
        const bottleCount = changedItems.reduce((acc, item) => acc + item.quantity, 0)
            
        // Redo calculations
        const subtotal = changedItems.reduce((acc, item) => acc + item.price * item.quantity, 0)
        updateSubtotal(subtotal)
        const tax = subtotal * taxRate.current
        updateTax(tax)
        const shippingAndHandling = bottleCount < 3 ? 10 : 0;
        updateShippingAndHandling(shippingAndHandling)
        updateTotal(subtotal + tax + shippingAndHandling)

        // Update quantities for invoice items and backup
        updateInvoiceItems(changedItems)

    }, [ updateReflectedItems, updateSubtotal, updateTax, updateShippingAndHandling, updateTotal, updateInvoiceItems ])

    return (
        <Fragment>
        {
            itemTapped && 
            <Invoice 
                items={invoiceItems.current} onChange={onInvoiceChange} taxRate={taxRate.current}
                discount={discount} headerBgColor={headerBg} headerTextColor={headerText} marginBottom={10}
            />
        }
        </Fragment>
    )
}

export default OrderInvoice
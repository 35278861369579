import React, { Fragment } from 'react'

import Portrait from '../../../controls/Portrait'
import Wallpaper from '../../../controls/Wallpaper'
import Landscape from '../../../controls/Landscape'
import LandscapeSegment from '../../../controls/LandscapeSegment'
import SideMenu from '../../../controls/SideMenu'
import ClientProxy from './ClientProxy'
import { usePhone, useLandscape } from '../../../../hooks/useMedia'
import useSwitchTheme from '../../../../hooks/useSwitchTheme'

const Client = () => {
    // Local state

    // Other hooks
    useSwitchTheme()
    const isPhone = usePhone()
    const isLandscape = useLandscape()

    return (
        <Fragment>
            <Wallpaper />
            {
                isPhone 
                ?
                (
                    isLandscape 
                    ?
                    <Landscape align='flex-start'>
                        <ClientProxy> 
                            <SideMenu />
                            <ClientProxy.Footer leftPct={50} />
                            <LandscapeSegment pctWidth={45} pctHeight={100}>
                                <ClientProxy.Header />
                                <ClientProxy.Identifier />
                                <ClientProxy.ActivityCount />
                                <ClientProxy.Technicals />
                            </LandscapeSegment>
                            <LandscapeSegment pctWidth={55}>
                                <ClientProxy.CurrentHaircut />
                                <ClientProxy.History />
                            </LandscapeSegment>
                        </ClientProxy>
                    </Landscape>
                    :
                    <Portrait>
                        <ClientProxy> 
                            <SideMenu />
                            <ClientProxy.Footer />
                            <ClientProxy.Header />
                            <ClientProxy.Identifier />
                            <ClientProxy.ActivityCount />
                            <ClientProxy.Technicals />
                            <ClientProxy.CurrentHaircut />
                            <ClientProxy.History />
                        </ClientProxy>
                    </Portrait>
                )
                :
                (
                    isLandscape 
                    ?
                    <Portrait>
                        <ClientProxy>
                            <ClientProxy.Header />
                            <Landscape align='flex-start'>
                                <ClientProxy.Footer leftPct={50} />
                                <LandscapeSegment pctWidth={45} pctHeight={100}>
                                    <ClientProxy.Identifier />
                                    <ClientProxy.ActivityCount pctWidth='60' />
                                    <ClientProxy.Technicals />
                                </LandscapeSegment>
                                <LandscapeSegment pctWidth={55}>
                                    <ClientProxy.CurrentHaircut pctWidth='60' />
                                    <ClientProxy.History marginTop={40} />
                                </LandscapeSegment>
                            </Landscape>
                        </ClientProxy>
                    </Portrait>
                    :
                    <Portrait>
                        <ClientProxy>
                            <ClientProxy.Footer />
                            <ClientProxy.Header />
                            <ClientProxy.Identifier pctWidth='60' />
                            <ClientProxy.ActivityCount pctWidth='40' />
                            <ClientProxy.Technicals />
                            <ClientProxy.CurrentHaircut pctWidth='50' />
                            <ClientProxy.History marginTop={40} />
                        </ClientProxy>
                    </Portrait>
                )
            }
        </Fragment>
    )
}

export default Client
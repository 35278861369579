import React, { Fragment } from 'react'
import { Navigate } from 'react-router-dom'

import useClientSignupContext from '../ClientSignupContext'
import useClientSignupFormContext from '../ClientSignupFormContext'

const Done = () => {
    const { dataSubmitted } = useClientSignupFormContext()
    const { runResetFunctions, isCurrentMember } = useClientSignupContext()

    dataSubmitted && runResetFunctions()

    const to = isCurrentMember() ? "/members" : "/clients";

    return (
        <Fragment>
        {
            dataSubmitted
            &&
            <Navigate to={to} replace />
        }
        </Fragment>
    )
}

export default Done
import React, { useState, useEffect } from 'react'

import RadioButtonGroup from '../../../../controls/RadioButtonGroup'
import { useTheme } from '../../../../contexts/Theme'
import useClientSignupContext from '../ClientSignupContext'
import useClientSignupFormContext from '../ClientSignupFormContext'

let lastWidth = 1

let initialMount = true

const Width = ({ leftBorder }) => {
    const [ width, setWidth ] = useState(lastWidth)

    // Hooks
    const { addResetFunction } = useClientSignupContext()
    const { onWidthChange } = useClientSignupFormContext()

    // Destructure theme
    const theme = useTheme()
    const labelText = theme.isDarkBackground() ? "yellow" : "blue";

    // Page mounted effect
    //
    useEffect(() => {
        if (!initialMount) return;

        addResetFunction(resetBackup)
        initialMount = false
    }, [])

    const resetBackup = () => {
        lastWidth = 1
        initialMount = true
    }
    
    const onChange = v => {
        onWidthChange(v)
        setWidth(v)
        lastWidth = v
    }

    return (
        <RadioButtonGroup
            caption="The client's cut volume, from low to high."
            title='Width' panelColor='rgba(0, 0, 0, 0)' selectColor='rgb(86, 86, 171)' 
            buttonColor='rgb(112, 146, 190)' labelColor={labelText}
            options={[
                { image: 'facial-width-narrow.png', label: 'min'},
                { image: 'facial-width-medium.png', label: 'medium'},
                { image: 'facial-width-wide.png', label: 'max'}
            ]}
            value={width} onChange={onChange} leftBorder={leftBorder} 
        />
    )
}

export default Width
import React, { Fragment } from 'react'

import Portrait from '../../../controls/Portrait'
import Wallpaper from '../../../controls/Wallpaper'
import Landscape from '../../../controls/Landscape'
import LandscapeSegment from '../../../controls/LandscapeSegment'
import SideMenu from '../../../controls/SideMenu'
import SalonOrderProxy from './SalonOrderProxy'
import { usePhone, useLandscape } from '../../../../hooks/useMedia'
import useSwitchTheme from '../../../../hooks/useSwitchTheme'

const SalonOrder = () => {
    // Local state

    // Other hooks
    useSwitchTheme()
    const isPhone = usePhone()
    const isLandscape = useLandscape()

    return (
        <Fragment>
            <Wallpaper />
            {
                isPhone 
                ?
                (
                    isLandscape 
                    ?
                    <Landscape align='flex-start'>
                        <SalonOrderProxy> 
                            <SideMenu />
                            <SalonOrderProxy.Footer leftPct={50} />
                            <SalonOrderProxy.PayStatus />
                            <LandscapeSegment pctWidth={45} pctHeight={100}>
                                <SalonOrderProxy.Header />
                                <SalonOrderProxy.MainMenu widthPct={90} cellSize={80} marginTop={10} />
                                <SalonOrderProxy.PrimeRibbon />
                                <SalonOrderProxy.TuneRibbon />
                                <SalonOrderProxy.CreamsRibbon />
                                <SalonOrderProxy.SerumsRibbon />
                            </LandscapeSegment>
                            <LandscapeSegment pctWidth={55}>
                                <SalonOrderProxy.Invoice />
                                <SalonOrderProxy.PayButton />
                                <SalonOrderProxy.Checkout />
                            </LandscapeSegment>
                        </SalonOrderProxy>
                    </Landscape>
                    :
                    <Portrait>
                        <SalonOrderProxy> 
                            <SideMenu />
                            <SalonOrderProxy.PayStatus />
                            <SalonOrderProxy.Footer />
                            <SalonOrderProxy.Header />
                            <SalonOrderProxy.MainMenu widthPct={90} cellSize={80} marginTop={10} />
                            <SalonOrderProxy.PrimeRibbon />
                            <SalonOrderProxy.TuneRibbon />
                            <SalonOrderProxy.CreamsRibbon />
                            <SalonOrderProxy.SerumsRibbon />
                            <SalonOrderProxy.Invoice />
                            <SalonOrderProxy.PayButton />
                            <SalonOrderProxy.Checkout />
                        </SalonOrderProxy>
                    </Portrait>
                )
                :
                (
                    isLandscape 
                    ?
                    <Portrait>
                        <SalonOrderProxy>
                            <SalonOrderProxy.Header />
                            <Landscape>
                                <SalonOrderProxy.PayStatus />
                                <SalonOrderProxy.Footer leftPct={50} />
                                <LandscapeSegment pctWidth={45} pctHeight={100}>
                                    <SalonOrderProxy.MainMenu widthPct={90} cellSize={80} marginTop={10} />
                                    <SalonOrderProxy.PrimeRibbon />
                                    <SalonOrderProxy.TuneRibbon />
                                    <SalonOrderProxy.CreamsRibbon />
                                    <SalonOrderProxy.SerumsRibbon />
                                </LandscapeSegment>
                                <LandscapeSegment pctWidth={55}>
                                    <SalonOrderProxy.Invoice />
                                    <SalonOrderProxy.PayButton pctWidth={60} />
                                    <SalonOrderProxy.Checkout pctWidth={60} />
                                </LandscapeSegment>
                            </Landscape>
                        </SalonOrderProxy>
                    </Portrait>
                    :
                    <Portrait>
                        <SalonOrderProxy>
                            <SalonOrderProxy.PayStatus />
                            <SalonOrderProxy.Footer />
                            <SalonOrderProxy.Header />
                            <SalonOrderProxy.MainMenu widthPct={60} cellSize={80} marginTop={10} />
                            <SalonOrderProxy.PrimeRibbon />
                            <SalonOrderProxy.TuneRibbon />
                            <SalonOrderProxy.CreamsRibbon />
                            <SalonOrderProxy.SerumsRibbon />
                            <SalonOrderProxy.Invoice />
                            <SalonOrderProxy.PayButton pctWidth={50} />
                            <SalonOrderProxy.Checkout pctWidth={50} />
                        </SalonOrderProxy>
                    </Portrait>
                )
            }
        </Fragment>
    )
}

export default SalonOrder
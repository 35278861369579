import React, { useEffect } from 'react'

import IconInput from '../../../../controls/IconInput'
import useSignupContext from '../SignupContext'
import useSignupFormContext from '../SignupFormContext'

let lastRepeatPassword = ''

const RepeatPassword = () => {
    // Hooks
    const { addResetFunction } = useSignupContext()
    const { repeatPassword, setRepeatPassword } = useSignupFormContext()

    useEffect(() => {
        addResetFunction(resetBackup)
        lastRepeatPassword && setRepeatPassword(lastRepeatPassword)
    }, [])

    const resetBackup = () => {
        lastRepeatPassword = ''
    }

    const onChange = e => {
        setRepeatPassword(e.target.value)
        lastRepeatPassword = e.target.value
    }

    return (
        <IconInput 
            type='password' 
            iconUrl='password' 
            name='repeatPassword'
            placeholder='Repeat new password'
            marginTop={30} 
            value={repeatPassword}
            onChange={onChange}
            required
        />
    )
}

export default RepeatPassword
import React, { Fragment } from 'react'
import styled from 'styled-components'

import { START, TECHNICALS, TEMPLATES, TOPOGRAPHIES, MIX, PHOTO } from '../useAddHaircut'
import { useTheme } from '../../../../contexts/Theme'
import useAddHaircutContext from '../AddHaircutContext'
import { CREATOR_NAME, CONTINUUM_NAME } from '../../../../../constants'

const Instruction = styled.div`
    width: 80%;
    height: auto;
    color: ${props => props.color};
    font: 20px verdana, sans-serif;
    text-align: center;
    margin-top: ${props => props.marginTop}px;
`

const AddHaircutInstruction = ({ marginTop }) => {
    // Hooks
    const { stage, name, prime, tune, hideInstruction } = useAddHaircutContext()
    const theme = useTheme()
    
    // Destructure the theme
    const { white, rgbDark } = theme
    const textColor = theme.isDarkBackground() ? white : rgbDark;

    return (
        <Fragment>
        {
            !hideInstruction &&
            (
                stage === START ?
                <Instruction color={textColor} marginTop={marginTop ?? 20}>
                    {`Please follow the guide to pair ${CREATOR_NAME} products with your haircut methods. When 
                    the haircut is complete the matched ${CONTINUUM_NAME} products will be selected for ${name}.`}
                </Instruction>
                : stage === TECHNICALS ?
                <Instruction color={textColor} marginTop={marginTop ?? 20}>
                    {`To begin, if necessary adjust ${name}'s technical characteristics to reflect this haircut.`}
                </Instruction>
                : stage === TEMPLATES ?
                <Instruction color={textColor} marginTop={marginTop ?? 20}>
                    {`Based on the technicals selected in the previous section, ${prime} and ${tune} will be the
                    ${CREATOR_NAME} products we recommend you use. Next, pick one of the following templates to apply.
                    This will define the recommended serum to use.`}
                </Instruction>
                : stage === TOPOGRAPHIES ?
                <Instruction color={textColor} marginTop={marginTop ?? 20}>
                    {`Pick a topography to apply. This will define the recommended cream to use.`}
                </Instruction>
                : stage === MIX ?
                <Instruction color={textColor} marginTop={marginTop ?? 20}>
                    {`Pick a cream/serum mix to apply. This will complete the catalyst process.`}
                </Instruction>
                : stage === PHOTO ?
                <Instruction color={textColor} marginTop={marginTop ?? 20}>
                    {`Tap or click the icon and follow the prompts to take or add pictures of your haircut. 
                    These will be uploaded to Array and displayed with the haircut and on your gallery.`}
                </Instruction>
                : 
                <Instruction color={textColor} marginTop={marginTop ?? 20}>
                    {`Name your haircut and save to Array. The application will match
                    the appropriate ${CONTINUUM_NAME} products for this client and haircut.`}
                </Instruction>
            )
        }
        </Fragment>
    )
}

export default AddHaircutInstruction
import React, { Fragment, useState, useRef } from 'react'
import styled from 'styled-components'
import { Navigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Header from '../controls/Header'
import Portrait from '../controls/Portrait'
import SideMenu from '../controls/SideMenu'
import FooterMenu from '../controls/FooterMenu'
import Wallpaper from '../controls/Wallpaper'
import Photo from '../controls/Photo'
import Tabs from '../controls/Tabs'
import Calendar from '../controls/Calendar'
import Null from '../controls/Null'
import useSwitchTheme from '../../hooks/useSwitchTheme'
import useActivateHelp from '../../hooks/useActivateHelp'
import { useTheme } from '../contexts/Theme'
import { useIsMePage, useIsClientsPage } from '../../Main'
import { isEmpty } from '../../utils'
import {
    CLEAR_ACTIVE_MEMBER,
    CLEAR_TOKEN, 
    RESTORE_ACTIVE_MEMBER, 
    RESTORE_TOKEN
} from '../../redux/actions/types'

const Message = styled.div`
    width: 340px;
    height: auto;
    margin-top: ${p => p.marginTop}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${props => props.color};
    font: ${p => p.fontSize}px verdana, sans-serif;
`

const ProfilePhotoContainer = styled.div`
    position: relative;
    width: 200px;
    height: auto;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const PhotoContainer = styled.div`
    width: ${p => p.size}px;
    height: ${p => p.size}px;
`

const NoPhoto = styled.div`
    width: 100%;
    height: 100%;
    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: 18px verdana, sans-serif;
    color: rgba(255, 255, 255, 0.6);
`

const EditTool = styled.img`
    position: absolute;
    right: -50px;
    top: 60%;
    height: 70px;
    width: 70px;
`

const MemberMe = () => {
    // State
    const [ gallerySelected, setGallerySelected ] = useState(true)
    const [ calendarSelected, setCalendarSelected ] = useState(false)
    const [ postsSelected, setPostsSelected ] = useState(false)
    const [ messagesSelected, setMessagesSelected ] = useState(false)
    const [ loggingOff, setLoggingOff ] = useState(false)

    // Other hooks
    const toProfile = useRef()
    const dispatch = useDispatch()
    const activeMember = useSelector(state => state.activeMember) 
	const isClientsPage = useIsClientsPage()
    const isMePage = useIsMePage()
    const theme = useTheme()
    useSwitchTheme()
    useActivateHelp()

    // Logoff doesn't need to re-render
    loggingOff && <Navigate to='/' replace />

    // Check for and recover from page refresh
    if (isEmpty(activeMember)) {
        // Restore active member and token from local storage post-refresh
        dispatch({ type: RESTORE_ACTIVE_MEMBER })
        dispatch({ type: RESTORE_TOKEN })

        // Don't want to render with missing data
        return <Null />
    }

    const logoff = () => {
        setLoggingOff(true)
        dispatch({ type: CLEAR_ACTIVE_MEMBER })
        dispatch({ type: CLEAR_TOKEN })
    }
    
    // Destructure the theme
    const { white, dark, rgbDark } = theme
    const [ textColor, iconColor ] = theme.isDarkBackground() 
        ? [ white, white ] 
        : [ rgbDark, dark ];

    const onGallerySelected = () => {
        setGallerySelected(true)
        setCalendarSelected(false)
        setMessagesSelected(false)
        setPostsSelected(false)
    }

    const onCalendarSelected = () => {
        setGallerySelected(false)
        setCalendarSelected(true)
        setMessagesSelected(false)
        setPostsSelected(false)
    }
    const onPostsSelected = () => {
        setGallerySelected(false)
        setCalendarSelected(false)
        setMessagesSelected(false)
        setPostsSelected(true)
    }
    const onMessagesSelected = () => {
        setGallerySelected(false)
        setCalendarSelected(false)
        setMessagesSelected(true)
        setPostsSelected(false)
    }

    const tabs = [
        { label: 'Gallery', onClick: onGallerySelected },
        { label: 'Calendar', onClick: onCalendarSelected },
        { label: 'Messages', onClick: onMessagesSelected },
        { label: 'Posts', onClick: onPostsSelected }
    ]

    return (
        <Fragment>
            <SideMenu /> 
            <Link ref={toProfile} to="/me/profile" style={{display: "none"}} />
            <FooterMenu items={ [
                { label: 'Clients', selected: isClientsPage, to: '/clients' },
                { label: 'Me', selected: isMePage, to: '/me' }
            ] } />
            <Portrait>
                <Wallpaper />
                <Header arrow={{
                    color: 'red',
                    to: '/',
                    destination: 'Logout'
                }} onBack={logoff} separator />
                <Message color={textColor} marginTop={20} fontSize={24}>{activeMember.name}</Message> 
                <ProfilePhotoContainer>
                    <PhotoContainer size={150}>
                    {
                        activeMember.profilePhoto
                        ?
                        <Photo width={150} photo={activeMember.profilePhoto} />
                        :
                        <NoPhoto>No Profile Photo</NoPhoto>
                    }
                    </PhotoContainer> 
                    <EditTool 
                        src={`${process.env.PUBLIC_URL}/images/edit-tool-${iconColor}.png`} 
                        onClick={() => toProfile.current.click()} 
                    />
                </ProfilePhotoContainer>
                { activeMember.about && <Message color={textColor} marginTop={20} fontSize={20}>{activeMember.about}</Message> }
                <Tabs tabs={tabs} />
                {
                    calendarSelected &&
                    <Calendar member={activeMember} />
                }
            </Portrait>
        </Fragment>
    )
}

export default MemberMe

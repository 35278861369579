import React, { Fragment } from 'react'
import styled from 'styled-components'

import { useTheme } from '../../../../contexts/Theme'
import { firstName } from '../../../../../utils'
import useProfileContext from '../ProfileContext'

const Cell = styled.div`
    display: table-cell;
`

const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

const Greeting = styled.div`
    width: ${p => p.pctWidth ? p.pctWidth : '80'}%;
    height: auto;
    font: ${props => props.fontSize}px verdana, sans-serif;
    color: ${props => props.textColor};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    margin-top: 20px;
`

const Title = () => {
    // Other hooks
    const { activeClient, managingAddresses, managingCards } = useProfileContext()
    const theme = useTheme()
    
    const { white, rgbDark } = theme
    const textColor = theme.isDarkBackground() ? white : rgbDark;

    return (
        <Fragment>
        {
            activeClient?.firstVisit 
            &&
            <Cell>
                <Container>
                    <Greeting textColor={textColor} fontSize={24}>Welcome to Array &reg;, {firstName(activeClient.name)}</Greeting>
                    {
                        managingAddresses
                        ?
                        <Greeting textColor={textColor} fontSize={18}>
                            Add and remove shipping addresses, the checked address is the default.
                        </Greeting>
                        :
                        managingCards
                        ?
                        <Greeting textColor={textColor} fontSize={18}>
                            Add and remove credit cards, the checked card is the default.
                        </Greeting>
                        :
                        <Greeting textColor={textColor} fontSize={18}>
                            please set your password, security reset question and answer, and any other information you care to provide at this time.
                        </Greeting>
                    }
                </Container>
            </Cell>
        }
        </Fragment>
    )
}

export default Title
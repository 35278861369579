import React, { createContext, useContext } from 'react'

const AddHaircut = createContext(undefined)

export const AddHaircutProvider = ({ children, value }) => {
    return (
        <AddHaircut.Provider value={value}>{children}</AddHaircut.Provider>
    )
}

const useAddHaircutContext = () => {
    const context = useContext(AddHaircut)

    if (context === undefined) throw new Error('useAddHaircutContext must be used within an AddHaircutProvider')

    return context;
}

export default useAddHaircutContext
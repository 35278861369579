import React, { Fragment, useState } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'

const Button = styled.input`
    width: ${props => props.width}px;
    height: ${props => props.height ? props.height : parseInt(props.width)/2}px;
    font: ${props => props.fontSize ? props.fontSize : '18'}px verdana, sans-serif;
    color: ${props => props.color};
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid ${props => props.borderColor};
    border-radius: 100px;
    margin-top: ${props => props.marginTop}px;
    margin-bottom: ${props => props.marginBottom}px;

    &.pressed { box-shadow: 2px 2px 2px 2px ${props => props.borderColor} inset; }
`

const Submit = ({ width, height, marginTop, marginBottom, fontSize, text }) => {
    const theme = useTheme()
    const { white, dark, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const [ pressed, setPressed ] = useState(false)

    const textColor = theme.isDarkBackground() ? white : rgbDark;
    const imageColor = theme.isDarkBackground() ? white : dark;
    const borderColor = theme.isDarkBackground() ? rgbaWhiteBg : rgbaDarkBg;

    return (
        <Fragment>
            {
                text ?
                <Button type='submit'
                    onTouchStart={() => setPressed(true)}
                    onTouchEnd={() => setPressed(false)}
                    onMouseDown={() => setPressed(true)}
                    onMouseUp={() => setPressed(false)}
                    width={width}
                    height={height}
                    fontSize={fontSize} 
                    color={textColor}
                    borderColor={borderColor}
                    marginTop={marginTop}
                    marginBottom={marginBottom}
                    className={`${pressed ? ' pressed' : ''}`}
                    value={text}
                /> :
                <Button type='image'
                    onTouchStart={() => setPressed(true)}
                    onTouchEnd={() => setPressed(false)}
                    onMouseDown={() => setPressed(true)}
                    onMouseUp={() => setPressed(false)}
                    width={width}
                    height={height} 
                    color={textColor}
                    src={`${process.env.PUBLIC_URL}/images/submit-${imageColor}.png`}
                    borderColor={borderColor}
                    marginTop={marginTop}
                    marginBottom={marginBottom}
                    className={`${pressed ? ' pressed' : ''}`}
                />
            }
        </Fragment>
    )
}

export default Submit
import { useEffect, useState, useReducer } from 'react'
import axios from 'axios'

import useProfileContext from './ProfileContext'
import { useError } from '../../../contexts/Error'
import { isEmpty, tokenConfig, timeoutConfig, encryptPassword } from '../../../../utils'
import { SET_ACTIVE_CLIENT } from '../../../../redux/actions/types'

let lastClient = {}

const SET_UPDATED_PROPERTY = "SET_UPDATED_PROPERTY"
const RESTORE_CLIENT = "RESTORE_CLIENT"
const clientReducer = (client, action) => {
    switch (action.type) {
        case SET_UPDATED_PROPERTY: 
        client = { ...client, [action.payload.key]: action.payload.value }
            return client
        case RESTORE_CLIENT: 
            client = { ...lastClient }
            return client
        default: 
            return client
    }
}

const handleInputChange = (e, key, dispatch) => {
    dispatch({
        type: SET_UPDATED_PROPERTY,
        payload: {
            key: key,
            value: e.target.value
        }
    })
}

const handleValueChange = (v, key, dispatch) => {
    dispatch({
        type: SET_UPDATED_PROPERTY,
        payload: {
            key: key,
            value: v
        }
    })
}

const restoreClient = dispatch => {
    dispatch({ type: RESTORE_CLIENT })
}

const useProfileForm = () => {
    // State
    const [ password, setPassword ] = useState('')
    const [ repeatPassword, setRepeatPassword ] = useState('')
    const [ updatedClient, clientDispatch ] = useReducer(clientReducer, {})

    // Reference the higher-level hook
    const { 
        activeClient, authToken, setActiveClient,
        setStatus, setOnStatusOk, 
        setStatusMessage, setSpinner, setErrorMessage,
        addResetFunction
    } = useProfileContext()
    const { 
        passwordError, 
        questionAndAnswerRequiredError, 
        questionAndAnswerError, 
        scrollTop 
    } = useError()

    // Page mounted/portrait-to-landscape effect
    //
    useEffect(() => {
        addResetFunction(resetBackup)
        if (!isEmpty(lastClient)) restoreClient(clientDispatch)
    }, [])

    const resetBackup = () => {
        lastClient = {}
    }
    
    const onNameChange = e => {
        handleInputChange(e, 'name', clientDispatch)
        lastClient = { ...lastClient, name: e.target.value }
    }
    
    const onPhoneChange = e => {
        handleInputChange(e, 'phone', clientDispatch)
        lastClient = { ...lastClient, phone: e.target.value }
    }
    
    const onEmailChange = e => {
        handleInputChange(e, 'email', clientDispatch)
        lastClient = { ...lastClient, email: e.target.value }
    }

    const onQuestionChange = i => {
        handleValueChange(i, 'secretQuestion', clientDispatch)
        lastClient = { ...lastClient, secretQuestion: i }
    }

    const onAnswerChange = e => {
        handleInputChange(e, 'secretQuestionAnswer', clientDispatch)
        lastClient = { ...lastClient, secretQuestionAnswer: e.target.value }
    }

    const onBirthdayReady = dateString => {
        handleValueChange(dateString, 'birthdate', clientDispatch)
        lastClient = { ...lastClient, birthdate: dateString }
    }

    // Accumulate error messages over form validations, return true if valid form else false
    //
    const validateForm = () => { 
        let message = '', error = false;

        // Check password and repeat
        if (password) { // Password was entered
            const { error: err, message: msg } = passwordError(password, repeatPassword)
            if (err) {
                message = msg
                error = true
            }
        }

        if (activeClient.firstVisit) { // Must pick a question and an answer
            const { error: err, message: msg } = questionAndAnswerRequiredError(
                updatedClient.secretQuestion,
                updatedClient.secretQuestionAnswer
            )            
            if (err) {
                message = `${message}${message ? ', ' : ''}${msg}`
                error = true
            }
        }
            
        // Check consistent secret question and answer
        const { error: err, message: msg } = questionAndAnswerError(
            updatedClient.secretQuestion, 
            updatedClient.secretQuestionAnswer
        )            
        if (err) {
            message = `${message}${message ? ', ' : ''}${msg}`
            error = true
        }
        
        if (error) {
            setErrorMessage(message)
            scrollTop(0)
            return false
        }

        // No error
        setErrorMessage(null)
        return true
    }

    const onSubmit = async e => {
        e.preventDefault()

        if (!validateForm()) // Invalid form
            return;

        // Form is valid ...

        try {

            setOnStatusOk(() => () => setStatus(false))
            setStatus(true)
            setSpinner(true)

            // Encrypt the password
            if (password) {
                const hash = await encryptPassword(password)
                updatedClient.password = hash
            }
            
            // First visit done
            updatedClient.firstVisit = false
         
            // Update the client DB            
            let rsp = await axios.patch(`/api/clients/${activeClient._id}`, updatedClient, timeoutConfig(tokenConfig(authToken)))

            // DB successfully updated, update the active client
            setActiveClient(rsp.data)

            setSpinner(false)
            setStatusMessage('Successfully saved your changes.')

        } catch(err) {

            if (err.response) { // status 400 (incl. authentication), 404 (not found), 500
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, changes not saved.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, changes not saved, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, changes not saved`)
            }
            
            setSpinner(false)
        }
    }

    return {
        password, setPassword,
        repeatPassword, setRepeatPassword,
        onNameChange, onPhoneChange, 
        onEmailChange,
        onQuestionChange, onAnswerChange, 
        onBirthdayReady,
        onSubmit
    }
}

export default useProfileForm
import React, { Fragment, useState, useRef, useEffect, useReducer } from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { Form } from 'reactstrap'
import axios from 'axios'

import Portrait from '../controls/Portrait'
import SideMenu from '../controls/SideMenu'
import FooterMenu from '../controls/FooterMenu'
import Column from '../controls/Column'
import Wallpaper from '../controls/Wallpaper'
import Header from '../controls/Header'
import PhotoUpload from '../controls/PhotoUpload'
import IconInput from '../controls/IconInput'
import Dropdown from '../controls/Dropdown'
import DatePicker from '../controls/DatePicker'
import AboutMe from '../controls/AboutMe'
import Submit from '../controls/Submit'
import ErrorMessage from '../controls/ErrorMessage'
import MessageBox from '../controls/MessageBox'
import Null from '../controls/Null'
import { useTheme } from '../contexts/Theme'
import useSwitchTheme from '../../hooks/useSwitchTheme'
import { useError } from '../contexts/Error'
import { secretQuestions } from '../../constants'
import { 
    SET_ACTIVE_MEMBER,
    RESTORE_ACTIVE_MEMBER, 
    RESTORE_TOKEN,
    SET_MEMBER_PROPERTY
} from '../../redux/actions/types'
import { useIsMePage, useIsClientsPage } from '../../Main'
import { isEmpty, tokenConfig, timeoutConfig, encryptPassword, firstName } from '../../utils'
import { classicLight, classicDark } from '../../overlayColors'

const lightOverlay = classicLight
const darkOverlay = classicDark

const handleInputChange = (e, key, dispatch, ref) => {
    dispatch({
        type: SET_UPDATED_PROPERTY,
        payload: {
            key: key,
            value: e.target.value
        }
    })

    ref.current = e.target.value
}

const handleValueChange = (v, key, dispatch) => {
    dispatch({
        type: SET_UPDATED_PROPERTY,
        payload: {
            key: key,
            value: v
        }
    })
}

const SET_UPDATED_PROPERTY = "SET_UPDATED_PROPERTY"
const memberReducer = (member, action) => {
    switch (action.type) {
        case SET_UPDATED_PROPERTY: 
            member = { ...member, [action.payload.key]: action.payload.value }
            return member
        default: 
            return member
    }
}

const MemberProfile = () => {
    // State
    const [ errorMessage, setErrorMessage ] = useState(null)
    const [ password, setPassword ] = useState('')
    const [ repeatPassword, setRepeatPassword ] = useState('')
    const [ messageBoxVisible, setMessageBoxVisible ] = useState(false)
    const [ spinnerVisible, setSpinnerVisible ] = useState(false)
    const [ statusMessage, setStatusMessage ] = useState('')
    const [ photoFiles, setPhotoFiles ] = useState(null)
    const [ submittedData, setSubmittedData ] = useState(false)
    const [ updatedMember, memberDispatch ] = useReducer(memberReducer, {})

    // Other hooks
    const theme = useTheme()
    const errors = useRef(false)
    const { passwordError, questionAndAnswerError, scrollTop } = useError()
    const dispatch = useDispatch()
    const activeMember = useSelector(state => state.activeMember)
    const authToken = useSelector(state => state.token)
    const nameValue = useRef(activeMember.name)
    const phoneValue = useRef(activeMember.phone)
    const emailValue = useRef(activeMember.email)
    const aboutValue = useRef(activeMember.about)
    const birthdateValue = useRef(activeMember.birthdate)
    const refreshed = useRef(false) 
    const answerValue = useRef()
	const isClientsPage = useIsClientsPage()
    const isMePage = useIsMePage()
    useSwitchTheme()

    // Head to me page
    submittedData && <Navigate  to="/me" replace />

    // Check for and recover from page refresh
    if (isEmpty(activeMember)) {
        // Restore active member and token from local storage post-refresh
        dispatch({ type: RESTORE_ACTIVE_MEMBER })
        dispatch({ type: RESTORE_TOKEN })

        // Set flag to initialize values correctly
        refreshed.current = true

        // Don't want to render with missing data
        return <Null />
    } else if (refreshed.current) { // Correct initialization post-refresh
        nameValue.current = activeMember.name
        phoneValue.current = activeMember.phone
        emailValue.current = activeMember.email
        aboutValue.current = activeMember.about
        birthdateValue.current = activeMember.birthdate

        refreshed.current = false
    }
    
    // Reference the theme
    const backColor = theme.isDarkBackground() ? 'yellow' : 'blue';

    // Handlers
    const onPhotoReady = photo => handlePhotoReady(photo)
    const onNameChange = e => handleInputChange(e, 'name', memberDispatch, nameValue)
    const onPasswordChange = e => setPassword(e.target.value)
    const onRepeatPasswordChange = e => setRepeatPassword(e.target.value)
    const onPhoneChange = e => handleInputChange(e, 'phone', memberDispatch, phoneValue)
    const onEmailChange = e => handleInputChange(e, 'email', memberDispatch, emailValue)
    const onQuestionChange = i => handleValueChange(i, 'secretQuestion', memberDispatch)
    const onAnswerChange = e => handleInputChange(e, 'secretQuestionAnswer', memberDispatch, answerValue)
    const onAboutChange = e => handleInputChange(e, 'about', memberDispatch, aboutValue)

    // Accumulate error messages over form validations, return true if valid form else false
    //
    const validateForm = () => { 
        let message = '', error = false;

        // Check password and repeat
        if (password) { // Password was entered
            const { error: err, message: msg } = passwordError(password, repeatPassword)
            if (err) {
                message = msg
                error = true
            }
        }
            
        // Check consistent secret question and answer
        const { error: err, message: msg } = questionAndAnswerError(
            updatedMember.secretQuestion, 
            updatedMember.secretQuestionAnswer
        )            
        if (err) {
            message = `${message}${message ? ', ' : ''}${msg}`
            error = true
        }
        
        if (error) {
            setErrorMessage(message)
            scrollTop(0)
            return false
        }

        // No error
        setErrorMessage(null)
        return true
    }

    const onSubmit = async e => {
        e.preventDefault()

        if (!validateForm()) // Invalid form
            return;

        // Form is valid ...

        try {
            
            if (password) {
                let hash = await encryptPassword(password) 

                // Hash available
                updatedMember.password = hash
            }
console.log('UPDATED MEMBER: ', updatedMember)
            // Save the page data
            setMessageBoxVisible(true)
            setSpinnerVisible(true)
            let rsp = await axios.patch(
                `/api/members/${activeMember._id}`, updatedMember, timeoutConfig(tokenConfig(authToken))
            )

            // Make this the current client
            dispatch({
                type: SET_ACTIVE_MEMBER,
                payload: rsp.data
            })

            // Upload the photo file, if there is one
            if (!photoFiles) {
                setSpinnerVisible(false)
                setStatusMessage(`Successfully saved the member data.`)
                return
            }
            
            const formData = new FormData()
            formData.append('file', photoFiles[0])
            rsp = await axios.post('/upload/photos', formData, timeoutConfig({
                headers: { "Content-Type": "multipart/form-data" }
            }))

            setSpinnerVisible(false)
            //const { fileName, filePath } = rsp.data
            setStatusMessage(`Successfully saved the member data and uploaded profile photo.`)

        } catch(err) {

            if (err.response) { // status 400, 404 (not found), 500
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, member not updated.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, member not updated, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, member not updated`)
            }

            setSpinnerVisible(false)
            errors.current = true           
        }
    }
    const handleMessageBoxOk = e => {
        setMessageBoxVisible(false)
        if (errors.current) {
            errors.current = false
        } else {
            setSubmittedData(true)
        }
    }

    const handlePhotoReady = photo => {
        photo.filename = photoFiles[0].name
        memberDispatch({
            type: SET_UPDATED_PROPERTY,
            payload: {
                key: 'profilePhoto',
                value: photo
            }
        })
        dispatch({
            type: SET_MEMBER_PROPERTY,
            payload: {
                key: 'profilePhoto',
                value: photo
            }
        })
    }

    const handleBirthdateReady = dateString => {
        memberDispatch({
            type: SET_UPDATED_PROPERTY,
            payload: {
                key: 'birthdate',
                value: dateString
            }
        })
        dispatch({
            type: SET_MEMBER_PROPERTY,
            payload: {
                key: 'birthdate',
                value: dateString
            }
        })
    }

    const ErrorMessageOrNot = errorMessage ? ErrorMessage : Null;
    const photoValue = activeMember.profilePhoto ? activeMember.profilePhoto : null;

    return  (
        <Fragment>
            <SideMenu />
            <Portrait>
                <Wallpaper />
                <Header arrow={{
                    color: backColor,
                    to: '/me',
                    destination: firstName(activeMember.name)
                }} title='Register' separator />
                <FooterMenu items={ [
                    { label: 'Clients', selected: isClientsPage, to: '/clients' },
                    { label: 'Me', selected: isMePage, to: '/me' }
                ] } />
                <ErrorMessageOrNot>{errorMessage}</ErrorMessageOrNot>
                <CSSTransition 
                    in={messageBoxVisible} 
                    classNames="message" 
                    timeout={1000}
                    unmountOnExit
                >
                    <MessageBox 
                        spinner={spinnerVisible} message={statusMessage}
                        lightBackground={lightOverlay} darkBackground={darkOverlay} 
                        onOk={handleMessageBoxOk} label='Ok'
                        width='320' height='240' 
                    />
                </CSSTransition>
                <Form onSubmit={onSubmit}>
                    <Column>
                        <PhotoUpload 
                            value={photoValue} setPhotoFiles={setPhotoFiles} 
                            onPhotoReady={onPhotoReady} marginTop={30} 
                        />
                        <IconInput 
                            type='text' 
                            iconUrl='person' 
                            name='name'
                            value={nameValue.current} 
                            placeholder='Your full name'
                            marginTop={30} 
                            onChange={onNameChange}
                            caption="Your first and last name."
                        />
                        <IconInput 
                            type='password' 
                            iconUrl='password' 
                            name='password'
                            placeholder='New password'
                            marginTop={30} 
                            onChange={onPasswordChange}
                            caption="Your password (required, must be at least eight characters)."
                        />
                        <IconInput 
                            type='password' 
                            iconUrl='password' 
                            name='repeatPassword' 
                            placeholder='Repeat new password'
                            marginTop={30} 
                            onChange={onRepeatPasswordChange}
                        />
                        <IconInput 
                            type='text' 
                            iconUrl='phone' 
                            name='phone'
                            value={phoneValue.current} 
                            placeholder='Your cell'
                            marginTop={30} 
                            onChange={onPhoneChange}
                            caption='Enter your mobile phone number'
                        />
                        <IconInput 
                            type='text' 
                            iconUrl='email' 
                            name='email'
                            value={emailValue.current} 
                            placeholder='Your e-mail'
                            marginTop={30} 
                            onChange={onEmailChange}
                            caption='Your e-mail for info and updates. (not required)'
                        />
                        <Dropdown
                            options={secretQuestions}
                            caption='Pick a security reset question from the list.'
                            name='secretQuestion'
                            iconUrl='security-questions'
                            placeholder='Security questions'
                            onChange={onQuestionChange}
                            marginTop={30} 
                            alternating
                        />
                        <IconInput 
                            type='text' 
                            iconUrl='security-questions' 
                            name='secretQuestionAnswer'
                            placeholder='Security question answer'
                            marginTop={30} 
                            onChange={onAnswerChange}
                            caption='Answer the security reset question.'
                        />
                        <DatePicker
                            caption='Select your birthday (optional)'
                            onDateReady={handleBirthdateReady}
                            value={birthdateValue.current}
                            iconUrl='baby-carriage'
                            marginTop={30}
                            noYear
                        />
                        <AboutMe
                            caption='Enter some information about yourself,
                                including accomplishments and skills.'
                            value={aboutValue.current}
                            placeholder='Please tell us a bit about yourself.'
                            marginTop={30}
                            onChange={onAboutChange}
                        />
                        <Submit width={'100'} marginTop={'40'} marginBottom={50} />
                    </Column>
                </Form>
            </Portrait>
        </Fragment>
    )
}

export default MemberProfile

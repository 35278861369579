import React from 'react'

import Name from './Name'
import Phone from './Phone'
import Email from './Email'
import Birthday from './Birthday'
import Diameter from './Diameter'
import Emoliency from './Emoliency'
import Height from './Height'
import Width from './Width'
import Curl from './Curl'
import Submit from './Submit'
import Done from './Done'
import { ClientSignupFormProvider } from '../ClientSignupFormContext'
import useClientSignupForm from '../useClientSignupForm'

const ClientSignupForm = ({ children }) => {
    // Hooks
    const { onSubmit, ...rest } = useClientSignupForm()

    return (
        <ClientSignupFormProvider value={{ ...rest }}>            
            <form onSubmit={onSubmit}>
                {children}                
            </form>
        </ClientSignupFormProvider>
    )
}

// Signup form components, lay these out any way you want ... 

ClientSignupForm.Name = Name
ClientSignupForm.Phone = Phone
ClientSignupForm.Email = Email
ClientSignupForm.Birthday = Birthday
ClientSignupForm.Diameter = Diameter
ClientSignupForm.Emoliency = Emoliency
ClientSignupForm.Height = Height
ClientSignupForm.Width = Width
ClientSignupForm.Curl = Curl
ClientSignupForm.Submit = Submit
ClientSignupForm.Done = Done

export default ClientSignupForm
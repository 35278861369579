import React, { Fragment } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'

import Button from '../../../../controls/Button'
import AddressInputModal from '../../../../controls/AddressInputModal'
import { useTheme } from '../../../../contexts/Theme'
import useOrderContext from '../OrderContext'

const Explanation = styled.div`
    width: 80%;
    height: auto;
    color: ${props => props.color};
    font: 20px verdana, sans-serif;
    text-align: center;
    margin-top: ${props => props.marginTop}px;
`

const AddressMissingPrompt = () => {
    // State
    const {
        enteringShippingAddress,
        updateEnteringShippingAddress,
        defaultShippingAddressSet,
        updateDefaultShippingAddress, 
        shippingAddressEntered, 
        updateShippingAddressEntered 
    } = useOrderContext()

    // Query the theme
    const theme = useTheme()
    const { white, rgbDark } = theme
    const color = theme.isDarkBackground() ? white : rgbDark;

    const shippingAddressReady = address => {
        updateEnteringShippingAddress(false)
        updateShippingAddressEntered(true)
        updateDefaultShippingAddress(address)
    }

    return (
        <Fragment>
            <CSSTransition 
                in={enteringShippingAddress} 
                classNames="modal" 
                timeout={1000}
                unmountOnExit
            >
                <AddressInputModal onKill={() => updateEnteringShippingAddress(false)} onSubmit={shippingAddressReady} />
            </CSSTransition>
            {
                !defaultShippingAddressSet && !shippingAddressEntered &&
                <Fragment>
                    <Explanation color={color} marginTop={50}>
                        This client has not defined a default shipping address 
                        and must do so before an order can be completed.
                    </Explanation>
                    <Button 
                        onClick={() => updateEnteringShippingAddress(true)} width={280} height={50} 
                        marginTop={20} label='Enter Shipping Address' 
                    />
                </Fragment>
            }
        </Fragment>
    )
}

export default AddressMissingPrompt
import React, { useState, useRef, useMemo, useEffect, Fragment } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'
import ThemeMenuItem from './ThemeMenuItem'
import HelpMenuItem from './HelpMenuItem'
import { lightBlue } from '../../overlayColors'

const Container = styled.div`
    position: fixed;
    top: ${props => props.top}px;
    right: ${props => props.right}px;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    z-index: 5;
    transition: right 1s;
`

const Pull = styled.img`
    position: absolute;
    top: 0;
    left: 1px;
    width: ${props => props.width}px;
    height: ${props => props.width*5}px;
`

// box-shadow: 8px 4px 1px 2px ${p => p.shadowColor};,
// inset 0 -2px 6px ${p => p.shadowColor};
// border: 1px solid ${p => p.borderColor};
const Panel = styled.div`
    position: absolute;
    top: 0;
    left: ${props => props.left}px;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    border-radius: 5px 0 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: ${props => props.bgColor};
`

// Sizing
const PULL_WIDTH = 20;
const PANEL_WIDTH = 110;
const CONTAINER_WIDTH = PANEL_WIDTH + PULL_WIDTH;
const CONTAINER_HEIGHT = 200;

const SideMenu = ({ top }) => {
    const [ right, setRight ] = useState(-PANEL_WIDTH)
    const [ displayed, setDisplayed ] = useState(false)
    const [ refresh, setRefresh ] = useState(false)

    const origX = useRef()
    const movingLeft = useRef(false)
    const hideThemes = useRef(null)

    const theme = useTheme()
    const { rgbDark, white, dark, rgbaLightBg, rgbaDarkBg } = theme
    const [ borderColor, itemColor, bgColor, menuColor, shadowColor ] = theme.isLightBackground() 
        ?
        [ rgbDark, white, lightBlue.topColor, dark, rgbaDarkBg ]
        :
        [ white, dark, white, white, rgbaLightBg ]

    const handleTouchStart = e => {
        origX.current = Math.floor(e.touches[0].clientX)
    }
    const handleTouchMove = e => {
        const diff = origX.current - Math.floor(e.touches[0].clientX)
        movingLeft.current = diff > 0
        if ((diff > 0 && right < 0) || (diff < 0 && right > -PANEL_WIDTH)) { 
            setRight(right + diff)
            origX.current = Math.floor(e.touches[0].clientX)
        }
    }
    const handleTouchEnd = e => {
        if (movingLeft.current) {
            setRight(0)
            movingLeft.current = false
        } else {
            setRight(-PANEL_WIDTH)
        }
    }
    const handleRefreshMe = () => setRefresh(true)
    const onClick = () => {
        if (displayed) {
            setRight(-PANEL_WIDTH)
            setDisplayed(false)
            hideThemes.current()
            return;
        }
        // Currently hidden
        setRight(0)
        setDisplayed(true)
    }

    useEffect(() => {
        setRefresh(false)
    }, [ refresh ])

    const themeMenuItem = useMemo(() => <ThemeMenuItem 
        onRefresh={handleRefreshMe} size={60} color={itemColor} bgColor={bgColor} hideMe={f => hideThemes.current = f} 
    />, [itemColor, bgColor])

    const helpMenuItem = useMemo(() => <HelpMenuItem size={60} />, [])

    return (
        <Fragment>
            <Container
                top={top ? top : 100} 
                right={right} 
                width={CONTAINER_WIDTH}
                height={CONTAINER_HEIGHT}
            >
                <Pull
                    width={PULL_WIDTH}
                    onClick={onClick} 
                    src={`${process.env.PUBLIC_URL}/images/menu-pull-${menuColor}.png`} 
                />
                <Panel 
                    left={PULL_WIDTH}
                    width={PANEL_WIDTH}
                    height={CONTAINER_HEIGHT}
                    bgColor={bgColor}
                    shadowColor={shadowColor}
                    borderColor={borderColor} 
                >
                    {themeMenuItem}
                    {helpMenuItem}
                </Panel>
            </Container>
        </Fragment>
    )
}

export default SideMenu
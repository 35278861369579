import React, { Fragment } from 'react'

import Header from '../../../../controls/Header'
import HeaderDesktop from '../../../../media/HeaderDesktop'
import { useTheme } from '../../../../contexts/Theme'
import { usePhone } from '../../../../../hooks/useMedia'

const ClientHeader = () => {
    // Other hooks
    const theme = useTheme()
    const isPhone = usePhone()

    // Destructure the theme
    const backColor = theme.isDarkBackground() ? 'yellow' : 'blue';

    return (
        <Fragment>
        {
            isPhone
            ?
            <Header arrow={{
                color: backColor, 
                to: '/clients',
                destination: 'Clients'
            }} />
            :
            <HeaderDesktop height={100} leftContent={<Header arrow={{
                color: backColor,
                to: '/clients',
                destination: 'Clients'
            }} />} />
        } 
        </Fragment>
    )
}

export default ClientHeader
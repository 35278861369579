import React, { Fragment } from 'react'

import Header from '../../../../controls/Header'
import HeaderDesktop from '../../../../media/HeaderDesktop'
import useSignupContext from '../SignupContext'
import { usePhone } from '../../../../../hooks/useMedia'

const SignupHeader = () => {
    // Other hooks
    const { runResetFunctions, logoff } = useSignupContext()
    const isPhone = usePhone()

    const onBack = () => {
        runResetFunctions?.()
        logoff()
    }

    return (
        <Fragment>
        {
            isPhone
            ?
            <Header arrow={{
                color: 'red',
                to: '/',
                destination: 'Logout'
            }} title='Register' onBack={onBack} separator />
            :
            <HeaderDesktop height={100} leftContent={<Header arrow={{
                color: 'red',
                to: '/',
                destination: 'Logout'
            }} title='Register' onBack={onBack} />} />
        } 
        </Fragment>
    )
}

export default SignupHeader
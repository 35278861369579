import { useRef, useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'

import { tokenConfig, timeoutConfig } from '../utils'
import { SET_CLIENT_PROPERTY, SET_ACTIVE_CLIENT } from '../redux/actions/types'

let lastDefaultShippingAddress = null

const useActiveClient = () => {
    // State
    const [ defaultShippingAddressSet, setDefaultShippingAddressSet ]  = useState(false)
    const [ defaultPaymentMethodSet, setDefaultPaymentMethodSet ]  = useState(false)

    // Other hooks
    const defaultPaymentMethod = useRef(null)
    const defaultShippingAddress = useRef(null)

    // Get the active (logged in) client
    const activeClient = useSelector(state => state.activeClient)
    const dispatch = useDispatch()
    const authToken = useSelector(state => state.token)
    
    // Destructure the client 
    const { name, haircuts, paymentMethods, shippingAddresses } = activeClient
    const haircut = haircuts?.[0]

    // Effect to set default payment method and shipping address
    //
    useEffect(() => {
        // Initialize default payment method and shipping address if they are defined
        defaultPaymentMethod.current = paymentMethods?.find(pm => pm.isDefault)
        defaultShippingAddress.current = shippingAddresses?.find(sa => sa.isDefault)
        if (defaultPaymentMethod.current) {
            setDefaultPaymentMethodSet(true)
        } 
        if (defaultShippingAddress.current) {
            setDefaultShippingAddressSet(true)
        } else if (lastDefaultShippingAddress) {
            defaultShippingAddress.current = lastDefaultShippingAddress
            setDefaultShippingAddressSet(true)
        } 
    }, [ activeClient, paymentMethods, shippingAddresses ])

    useEffect(() => {
        if (!lastDefaultShippingAddress) { // Ignore null backup
            return;
        }
        defaultShippingAddress.current = lastDefaultShippingAddress
    }, [])

    const updateDefaultShippingAddress = useCallback(async address => {

        defaultShippingAddress.current = address
        lastDefaultShippingAddress = address // Backup

        try {

            address.isEnabled = true
            address.isDefault = true

            const shippingAddresses = [ ...activeClient.shippingAddresses, address ]            
            await axios.patch(`/api/clients/${activeClient._id}`, { shippingAddresses }, timeoutConfig(tokenConfig(authToken)))
            updateActiveClient("shippingAddresses", shippingAddresses)

        } catch(err) {}

    }, [])

    const updateActiveClient = useCallback((key, value) => {
        dispatch({
            type: SET_CLIENT_PROPERTY,
            payload: {
                key: key,
                value: value
            }
        })
    }, [ dispatch ])

    const setActiveClient = useCallback(client => {
        dispatch({
            type: SET_ACTIVE_CLIENT,
            payload: client
        })
    }, [ dispatch ])

    const resetClientBackup = () => {
        lastDefaultShippingAddress = null
    } 

    return {
        activeClient,
        authToken,
        updateActiveClient,
        setActiveClient,
        name,
        haircut,
        defaultPaymentMethod,
        defaultShippingAddress,
        defaultPaymentMethodSet,
        defaultShippingAddressSet,
        updateDefaultShippingAddress,
        resetClientBackup
    }
}

export default useActiveClient
import React, { Fragment, useState, useRef } from 'react'
import styled from 'styled-components'

import Photo, { SQUARE } from './Photo'

const Viewport = styled.div`
    position: relative;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    overflow: hidden;
`

const Film = styled.div.attrs(props => ({
    style: {
        right: props.right,
    },
}))`
    position: absolute;
    top: 0;
    width: ${props => props.filmWidth}px;
    height: ${props => props.size}px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background-color: white;
`

const GAP_WIDTH = 10

const SwipeGroup = ({ photos, size }) => {
    const [ right, setRight ] = useState(0)

    const origX = useRef()
    const previousX = useRef()
    const swipedRight = useRef(false)
    const swipedLeft = useRef(false)
    const visibleIndex = useRef(0)

    const handleTouchStart = e => {
        origX.current = Math.floor(e.touches[0].clientX)
        previousX.current = Math.floor(e.touches[0].clientX)
    }
    const handleTouchMove = e => {
        const delta = previousX.current - Math.floor(e.touches[0].clientX)
        setRight(right + delta)
        previousX.current = Math.floor(e.touches[0].clientX)
    }
    const handleTouchEnd = e => {
        const swipeDistance = previousX.current - origX.current
        swipedRight.current = swipeDistance > 0
        swipedLeft.current = swipeDistance < 0
        if (swipedRight.current && swipeDistance > Math.floor(size/2)) {
            visibleIndex.current < photos.length - 1 && visibleIndex.current++
        }
        if (swipedLeft.current && -swipeDistance > Math.floor(size/2)) {
            visibleIndex.current > 0 && visibleIndex.current--
        }
        setRight(-(size + GAP_WIDTH)*visibleIndex.current)
    }
    
    return (
        <Fragment>
            <Viewport size={size}>
                <Film 
                    size={size} 
                    right={`${right}px`}  
                    filmWidth={Math.floor(photos.length*size + (photos.length - 1)* GAP_WIDTH)}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                {
                    photos.slice(0).reverse().map((photo, i) => <Photo key={i} 
                        width={size} shape={SQUARE} photo={photo} 
                    />)
                }
                </Film>
            </Viewport>
        </Fragment>
    )
}

export default SwipeGroup
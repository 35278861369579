import React from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'

const Container = styled.div`
    position: relative;
    width: ${p => p.pctWidth ? p.pctWidth + '%' : p.width + 'px'};
    height: 40px;
    margin-top: ${props => props.marginTop}px;
`

const Placeholder = styled.label`
    position: absolute;
    top: 10px;
    left: 10px;
    font: 18px verdana, sans-serif;
    color: ${props => props.color};
    opacity: 0.4;
    transform-origin: 0 50%;
    transition: transform 200ms, opacity 200ms;

    .input:focus ~ &,
    .input:not(:placeholder-shown) ~ & {
        transform: translateY(-20px) translateX(10px) scale(0.75);
        opacity: 1.0;
    } 
`

const InputField = styled.input`
    width: 100%;
    height: 100%;
    text-align: center;
    font: 18px verdana, sans-serif;
    color: ${props => props.color};
    outline: none;
    border: 0;
    background-color: rgba(0, 0, 0, 0);
`

const Underline = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-style: solid;
    border-width: 1px;
    border-color: ${props => props.color};
    background-color: ${props => props.color};
`

const Input = ({ type, name, value, placeholder, marginTop, onChange, width, percentWidth, required }) => {
    
    const theme = useTheme()
    const { white, rgbDark } = theme
    const textColor = theme.isDarkBackground() ? white : rgbDark;

    return (
        <Container width={width} pctWidth={percentWidth} marginTop={marginTop}>
            <InputField 
                type={type} 
                name={name}
                value={value}
                id={name}
                onChange={onChange} 
                placeholder=' '
                className='input' 
                color={textColor}
                required={required} 
            /> 
            <Placeholder for={name} color={textColor}>{placeholder}</Placeholder>
            <Underline color={textColor} />
        </Container>
    )
}

export default Input

export const invert = ({ topColor, bottomColor }) => ({
    topColor: bottomColor,
    bottomColor: topColor
})

/////////////////////////////////////////
//      Dark backgrounds
/////////////////////////////////////////

export const classicDark = {
    topColor: 'rgb(0, 0, 84)',
    bottomColor: 'rgb(0, 0, 84)'
}

export const darkBlue = {
    topColor: 'rgb(15, 18, 57)',
    bottomColor: 'rgb(63, 72, 204)'
}

export const burgundy = {
    topColor: 'rgb(185, 28, 115)',
    bottomColor: 'rgb(40, 2, 39)'
}

export const aubergine = {
    topColor: 'rgb(40, 2, 39)',
    bottomColor: 'rgb(185, 28, 115)'
}

export const purple = {
    topColor: 'rgb(105, 19, 204)',
    bottomColor: 'rgb(55, 92, 240)'
}

export const rust = {
    topColor: 'rgb(183, 65, 14)',
    bottomColor: 'rgb(15, 18, 57)'
}

export const green = {
    topColor: 'rgb(5, 102, 8)',
    bottomColor: 'rgb(128, 128, 0)'
}

export const navy = {
    topColor: 'rgb(0, 0, 128)',
    bottomColor: 'rgb(128, 0, 22)'
}

export const deepPurple = {
    topColor: 'rgb(70, 5, 167)',
    bottomColor: 'rgb(119, 3, 169)'
}

export const crimson = {
    topColor: 'rgb(169, 13, 53)',
    bottomColor: 'rgb(0, 0, 160)'
}

export const turquoise = {
    topColor: 'rgb(0, 90, 117)',
    bottomColor: 'rgb(70, 5, 167)'
}

/////////////////////////////////////////
//      Light backgrounds
/////////////////////////////////////////

export const classicLight = {
//     topColor: 'rgb(239, 239, 239)',
//     bottomColor: 'rgb(239, 239, 239)'
    topColor: 'rgb(255, 255, 255)',
    bottomColor: 'rgb(255, 255, 255)'
}

export const lightBlue = {
    topColor: 'rgb(152, 217, 234)',
    bottomColor: 'rgb(255, 255, 255)'
}

export const silver = {
    topColor: 'rgb(255, 255, 255)',
    bottomColor: 'rgb(185, 185, 185)'
}

export const violet = {
    topColor: 'rgb(200, 191, 231)',
    bottomColor: 'rgb(108, 84, 190)'
}

export const yellow = {
    topColor: 'rgb(255, 255, 68)',
    bottomColor: 'rgb(192, 192, 192)'
}

export const orange = {
    topColor: 'rgb(255, 159, 64)',
    bottomColor: 'rgb(255, 255, 255)'
}

export const lightGreen = {
    topColor: 'rgb(186, 241, 203)',
    bottomColor: 'rgb(255, 255, 255)'
}

export const pink = {
    topColor: 'rgb(255, 187, 255)',
    bottomColor: 'rgb(255, 255, 255)'
}

import styled from 'styled-components'

const Landscape = styled.section`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: ${p => p.align ? p.align : 'center'};
    justify-content: flex-start;
`

export default Landscape
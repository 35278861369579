import React, { Fragment, useState, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'

import Input from '../../../../controls/Input'
import Button from '../../../../controls/Button'
import CardInput, { StripeError } from '../../../../controls/CardInput'
import Null from '../../../../controls/Null'
import Switch from '../../../../controls/Switch'
import useSalonOrderContext from '../SalonOrderContext'
import { useTheme } from '../../../../contexts/Theme'
import { tokenConfig, timeoutConfig } from '../../../../../utils'

const PayContainer = styled.div`
    width: ${p => p.pctWidth ? p.pctWidth : '95'}%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 50px;
`

const ZipContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`

const Explanation = styled.div`
    width: 80%;
    height: auto;
    color: ${props => props.color};
    font: 20px verdana, sans-serif;
    text-align: center;
    margin-top: ${props => props.marginTop}px;
`

const Checkout = ({ pctWidth }) => {
    // Global state
    const [ savePayment, setSavePayment ] = useState(false)
    const { 
        invoiceItems, reflectedItems, subtotal, tax, shippingAndHandling, total, 
        updateDiscount, updateTax, updateTotal, 
        setPayStatus, setSpinner, setStatusMessage, 
        activeMember, salon, authToken
    } = useSalonOrderContext()
    const [ zip, setZip ] = useState('')
    const [ billingEqualsShipping, setBillingEqualsShipping ] = useState(true)
       
    // Other hooks  
    const discountRef = useRef(0)
    const totalRef = useRef(0)
    const taxRate = useRef(0.085)
    const applicableDiscounts = useRef([])
    const stripe = useStripe()
    const theme = useTheme()

    const onSavePayment = useCallback(e => {
        setSavePayment(e.target.value)
        console.log(`savePayment=${e.target.value}`)
    }, [])

    // Destructure the theme
    const { white, rgbDark } = theme
    const color = theme.isDarkBackground() ? white : rgbDark;

    // Check salon has been fetched
    if (!salon) return <Null />

    // Destructure the active member and salon
    const { stripeCustomerId, name: salonName, _id: salonId, addr1, addr2, city, state, zip: salonZip, country } = salon
    const salonPayment = salon?.paymentMethods ? salon.paymentMethods[0] : null;

    const getDiscounts = async () => {

        const discountQueryItems = []

        // Populate the items for applicable discounts query
        reflectedItems.current.forEach(item => {
            discountQueryItems.push({
                name: item.name,
                amount: item.price * item.quantity,
                quantity: item.quantity
            })
        })

        // Get applicable discounts
        try {

            const rsp = await axios.post('/api/discounts/applicable', {
                salonName: salonName,
                items: discountQueryItems
            }, timeoutConfig(tokenConfig(authToken)) )

            // Discounts, if any, retrieved, process them ... 

            applicableDiscounts.current = rsp.data

            // Apply each discount to calculate the total discount amount
            applicableDiscounts.current.forEach(discount => {
                if (discount.amountOff) {
                    discountRef.current += discount.amountOff;
                } else if (discount.percentOff) {
                    const amount = subtotal.current * discount.percentOff / 100;
                    discountRef.current += amount;
                }
            })

            // Adjust the tax and total
            if (applicableDiscounts.current.length) {
                discountRef.current > subtotal.current && (discountRef.current = subtotal.current);
                updateDiscount(discountRef.current)
                updateTax(taxRate.current * (subtotal.current - Math.round((discountRef.current + Number.EPSILON) * 100) / 100))
                totalRef.current = subtotal.current - discountRef.current + tax.current;
                updateTotal(totalRef.current)
            }

        } catch(err) { 
            /* Pass on this error */ 
        }
    }

    // Checkout credit card ready handler
    //
    const onCheckout = async (element, disable) => {

        // Use refs here in case the state changes
        totalRef.current = total
        
        // Disable card submission
        disable(true)

        // Get discounts and apply to totals
        await getDiscounts(applicableDiscounts)

        let paymentIntent

        // This goes to Stripe
        const payment = {
            customer: stripeCustomerId,
            amount: Math.round(totalRef.current.toFixed(2)*100),
            confirm: false,
            offSession: false,
            description: "Back bar order",
            receiptEmail: activeMember.email,
            metadata: {
                member: activeMember.name,
                salon: salonName,
                discount: `${discountRef.current.toFixed(2)}`,
                tax: `${tax.current.toFixed(2)}`
            }
        }

        // Package the address
        const shippingAddress = { addr1, addr2, city, state, zip: salonZip, country }

        // This is for the orders collection
        const order = {
            orderDate: new Date().toISOString(),
            clientId: salonId,
            email: activeMember.email, // So requesting member gets email notification
            items: [],
            shippingAddress,
            clientName: salonName,
            subtotal: subtotal.current.toFixed(2),
            discount: discountRef.current.toFixed(2),
            shipping: shippingAndHandling.current.toFixed(2),
            tax: tax.current.toFixed(2),
            total: totalRef.current.toFixed(2),
            salonOrder: true
        }

        // Populate the order items array
        reflectedItems.current.forEach(item => {
            order.items.push({
                itemName: item.name,
                unitPrice: item.price,
                quantity: item.quantity,
                itemTotal: (item.price * item.quantity).toFixed(2),
                image: invoiceItems.current.filter(i => i.name === item.name).map(m => m.image)?.[0]
            })
            payment.metadata[`${item.name}_quantity`] = `${item.quantity}`
            payment.metadata[`${item.name}_amount`] = `${(item.price * item.quantity).toFixed(2)}`
        })

        try {

            setPayStatus(true)
            setSpinner(true)

            // Create the stripe payment intent
            let rsp = await axios.post( '/pay/payment-intents/', payment, timeoutConfig(tokenConfig(authToken)) )

            // Payment intent successfully created ... 

            paymentIntent = rsp.data.id
            
            // Confirm the payment
            let result = await stripe.confirmCardPayment(
                rsp.data.client_secret,
                { 
                    payment_method: { 
                        card: element,
                        billing_details: {
                            name: salonName
                        } 
                    },
                    setup_future_usage: "off_session"
                }
            )

            if (result.error) throw new StripeError( { data: { message: result.error.message } } )

            // Order save successful ...

            // Retrieve the payment method object created by stripe            
            result = await axios.get(`/pay/payment-methods/${result.paymentIntent.payment_method}`)
            
            // Dereference the Stripe payment method
            const { id, card } = result.data
            const { brand: cardName, last4: lastFour } = card

            // Set the payment method id on the order 
            order.stripePaymentMethodId = id

            // Create the order in the Array DB
            rsp = await axios.post('/api/orders/', order, timeoutConfig(tokenConfig(authToken)))

            // Order successfully saved ...

            if (savePayment) { 

                // New payment method
                const pm = {
                    paymentType: 'card',
                    cardName,
                    lastFour,
                    stripePaymentMethodId: id
                }

                // Insert at head of list
                const paymentMethods = [ pm, ...salon.paymentMethods ]
            
                // Update the salon DB with the new payment method            
                await axios.patch(`/api/salons/${salonId}`, { paymentMethods }, timeoutConfig(tokenConfig(authToken)))

                // All good ...
            }

            setSpinner(false)
            setStatusMessage(
                'The purchase has been successful. ' +
                (applicableDiscounts.current.length ? 
                `Discounts ${applicableDiscounts.current.reduce((a, d, i) => a += `${i > 0 ? ', ' : ''}${d.code}`, '')} were applied. ` : '') +
                `${cardName} ending in ${lastFour} will be charged. ` + 
                `The stylist will receive an email confirmation in the next few minutes.`)

        } catch (err) {

            if (err.response) { // status 400, 402 (Stripe error), 404 (not found), 500
                switch (err.response.status) {
                    case 500: // Server error
                        setStatusMessage('Server error, order not completed.')
                        console.log('Error! ', 'Server error')
                        break;
                    case 402: { // Stripe payment intent error
                        const errObj = err.response.data.error
                        setStatusMessage(`Error: ${errObj?.raw.type}, order not completed.`)
                        break;
                    }
                    default: // 400, 404 (Client not found), session expired, stripe confirm error
                        console.log('Error! ', err.response.data)
                        setStatusMessage(`${err.response.data.message}, order not completed.`)
                }
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, order not completed, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, order not completed`)
            }

            setSpinner(false)

            // Cancel the payment intent
            paymentIntent && axios.post(`/pay/payment-intents/${paymentIntent}/cancel`)
        }
    } 

    const onCardError = message => null

    const onZipReady = e => {
        setBillingEqualsShipping(false)
    }

    return (
        <Fragment>
        {
            (total > 0) && salon && !salonPayment &&  
            <PayContainer pctWidth={pctWidth}>
                <Explanation color={color} marginTop={10}>
                    This salon has no default payment method. Enter card details below to checkout and complete this order.
                </Explanation>
                <CardInput 
                    marginTop={20} onReady={onCheckout} onError={onCardError}  
                    zip={billingEqualsShipping ? salonZip : zip}
                />
                <Explanation color={color} marginTop={20}>
                    Enter the billing zip code if different:
                </Explanation>
                <ZipContainer>
                    <Input 
                        type='text' name='zip' value={zip} width={120} 
                        placeholder='Zip' marginTop={20} onChange={e => setZip(e.target.value)}
                    />
                    <Button onClick={onZipReady} width={80} height={40} marginTop={20} label='Done' />
                </ZipContainer>
                <Switch 
                    width={60} height={30} label='Save payment method' onChange={onSavePayment} 
                    marginTop={20} value={savePayment}
                />
            </PayContainer>
        }
        </Fragment>
    )
}

export default Checkout
import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'

import { tokenConfig, timeoutConfig, isEmpty } from '../utils'
import { SET_CURRENT_MEMBER_PROPERTY, SET_CURRENT_MEMBER, CLEAR_CURRENT_MEMBER } from '../redux/actions/types'

const useCurrentMember = () => {

    // Get the current member
    const currentMember = useSelector(state => state.currentMember)
    const dispatch = useDispatch()
    const authToken = useSelector(state => state.token)
     
    const { name, clients } = currentMember ?? {}

    const updateCurrentMember = useCallback((key, value) => {
        dispatch({
            type: SET_CURRENT_MEMBER_PROPERTY,
            payload: {
                key: key,
                value: value
            }
        })
    }, [ dispatch ])

    // Change the current member
    //
    const setCurrentMember = useCallback(async member => {
        try {
            const rsp = await axios.get(`/api/members/${member.memberId}`)
            dispatch({
                type: SET_CURRENT_MEMBER,
                payload: rsp.data
            })
        } catch(err) {}
    }, [ dispatch ]) 

    // Get rid of the current member
    //
    const clearCurrentMember = useCallback(member => {
        dispatch({ type: CLEAR_CURRENT_MEMBER })
    }, [ dispatch ])

    // Is current member in play?
    //
    const isCurrentMember = () => {
        if (!currentMember) return false;
        if (isEmpty(currentMember)) return false;
        return true;
    }

    return {
        currentMember,
        clients,
        authToken,
        updateCurrentMember,
        setCurrentMember,
        clearCurrentMember,
        isCurrentMember,
        name
    }
}

export default useCurrentMember
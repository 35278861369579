import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'
import {
    PRIME_NAME,
    TUNE_NAME,
    CUT_NAME,
    CREATOR_PRIME_NAME,
    CREATOR_TUNE_NAME,
    CREAM_SMOOTH_SHORT_NAME,
    CREAM_SOFTEN_SHORT_NAME,
    CREAM_TEXTURED_SHORT_NAME,
    SERUM_RADIUS_NAME,
    SERUM_BLUR_NAME,
    SERUM_PLUMB_NAME,
    SERUM_STRATA_NAME,
    SERUM_TILT_NAME
} from '../../constants'

const Container = styled.div`
    position: relative;
    width: 100%;
    height: ${props => props.height}px;
    overflow-x: scroll;
    overflow-y: visible;
    margin-top: ${props => props.marginTop}px;

    &.ribbon-enter {
        opacity: 0;
    }
    &.ribbon-enter-active {
        opacity: 1;
        transition: opacity 500ms;
    }
    &.ribbon-exit {
        opacity: 1;
    }
    &.ribbon-exit-active {
        opacity: 0;
        transform: scale(0.5) translateY(0);
        transition: opacity 500ms, transform 500ms;
    }
`

const RibbonImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: auto;
`

const ProductContainer = styled.div`
    position: absolute;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    height: auto;
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const ProductLabel = styled.div`
    width: 100%;
    height: 50px;
    font: 18px verdana, sans-serif;
    color: ${props => props.textColor};
    text-align: center;
`

const ProductImage = styled.img`
    height: ${props => props.height}px;
    width: auto;

    &.selected {
        -webkit-filter: drop-shadow(0 0 20px ${props => props.shadowColor});
        -moz-filter: drop-shadow(0 0 20px ${props => props.shadowColor});
        -ms-filter: drop-shadow(0 0 20px ${props => props.shadowColor});
        -o-filter: drop-shadow(0 0 20px ${props => props.shadowColor});
        filter: drop-shadow(0 0 20px ${props => props.shadowColor});
    }
`
// All available product codes
//
export const PRIME12 = 'PRIME12'
export const TUNE12 = 'TUNE12'
export const CUT12 = 'CUT12'
export const PRIME1_16 = 'PRIME1_16'
export const PRIME2_16 = 'PRIME2_16'
export const PRIME3_16 = 'PRIME3_16'
export const TUNE1_16 = 'TUNE1_16'
export const TUNE2_16 = 'TUNE2_16'
export const TUNE3_16 = 'TUNE3_16'
export const CREAM_SOFT = 'CREAM_SOFT'
export const CREAM_SMOOTH = 'CREAM_SMOOTH'
export const CREAM_TEXTURED = 'CREAM_TEXTURED'
export const SERUM_RADIUS = 'SERUM_RADIUS'
export const SERUM_BLUR = 'SERUM_BLUR'
export const SERUM_PLUMB = 'SERUM_PLUMB'
export const SERUM_STRATA = 'SERUM_STRATA'
export const SERUM_TILT = 'SERUM_TILT'

// All products that can be made available on the ribbon
//
const products = [
    {
        name: `${PRIME_NAME} 8 Oz.`,
        code: PRIME12,
        height: 70,
        image: 'shampoo-bottle.png'
    },
    {
        name: `${TUNE_NAME} 8 Oz.`,
        code: TUNE12,
        height: 70,
        image: 'conditioner-bottle.png'
    },
    {
        name: `${CUT_NAME} 6 Oz.`,
        code: CUT12,
        height: 70,
        image: 'cut-bottle.png'
    },
    {
        name: `${CREATOR_PRIME_NAME}1 16 Oz.`,
        code: PRIME1_16,
        height: 80,
        image: 'prime-1-bottle.png'
    },
    {
        name: `${CREATOR_PRIME_NAME}2 16 Oz.`,
        code: PRIME2_16,
        height: 80,
        image: 'prime-2-bottle.png'
    },
    {
        name: `${CREATOR_PRIME_NAME}3 16 Oz.`,
        code: PRIME3_16,
        height: 80,
        image: 'prime-3-bottle.png'
    },
    {
        name: `${CREATOR_TUNE_NAME}1 16 Oz.`,
        code: TUNE1_16,
        height: 80,
        image: 'tune-1-bottle.png'
    },
    {
        name: `${CREATOR_TUNE_NAME}2 16 Oz.`,
        code: TUNE2_16,
        height: 80,
        image: 'tune-2-bottle.png'
    },
    {
        name: `${CREATOR_TUNE_NAME}3 16 Oz.`,
        code: TUNE3_16,
        height: 80,
        image: 'tune-3-bottle.png'
    },
    {
        name: `${CREAM_SMOOTH_SHORT_NAME}`,
        code: CREAM_SMOOTH,
        height: 50,
        image: 'cream-smooth-jar.png'
    },
    {
        name: `${CREAM_SOFTEN_SHORT_NAME}`,
        code: CREAM_SOFT,
        height: 50,
        image: 'cream-soften-jar.png'
    },
    {
        name: `${CREAM_TEXTURED_SHORT_NAME}`,
        code: CREAM_TEXTURED,
        height: 50,
        image: 'cream-textured-jar.png'
    },
    {
        name: `${SERUM_RADIUS_NAME}`,
        code: SERUM_RADIUS,
        height: 80,
        image: 'serum-radius-bottle.png'
    },
    {
        name: `${SERUM_BLUR_NAME}`,
        code: SERUM_BLUR,
        height: 80,
        image: 'serum-blur-bottle.png'
    },
    {
        name: `${SERUM_PLUMB_NAME}`,
        code: SERUM_PLUMB,
        height: 80,
        image: 'serum-plumb-bottle.png'
    },
    {
        name: `${SERUM_STRATA_NAME}`,
        code: SERUM_STRATA,
        height: 80,
        image: 'serum-strata-bottle.png'
    },
    {
        name: `${SERUM_TILT_NAME}`,
        code: SERUM_TILT,
        height: 80,
        image: 'serum-tilt-bottle.png'
    }
]

const positions = [
    { x: 61, y: 146 }, { x: 278, y: 183 }, { x: 474, y: 231 }, 
    { x: 686, y: 166 }, { x: 875, y: 217 }, { x: 1041, y: 141 }
]

const containerHeight = 210;
const scale = containerHeight / 318;

// items are an array of product codes
// onChange is called with the product object of the just-tapped product
//
const Ribbon = ({ items = [], onChange, marginTop }) => {
    // State
    const [ selectedProduct, setSelectedProduct ] = useState(null)

    // Other hooks
    const theme = useTheme()

    // Change effect
    useEffect(() => {
        const [ product ] = products.filter(p => p.code === selectedProduct)
        onChange(product)
    }, [selectedProduct])

    // Deconstruct the theme
    const { white, dark, rgbDark } = theme
    const [ iconColor, textColor, shadowColor ] = theme.isDarkBackground() ?
        [ white, white, 'rgba(255, 242, 0, 0.8)' ] : 
        [ dark, rgbDark, 'rgba(0, 255, 255, 0.8)' ];

    return (
        <Container marginTop={marginTop} height={containerHeight} >
            <RibbonImage src={`${process.env.PUBLIC_URL}/images/ribbon-${iconColor}.png`} />
            {/* Display requested products */}
            {
                products.filter(prod => items.includes(prod.code)).map((p, i) =>  
                    <ProductContainer key={i} 
                        top={positions[i].y * scale - 50 - p.height / 2} 
                        left={positions[i].x * scale - 30}
                    >
                        <ProductLabel textColor={textColor}>{p.name}</ProductLabel>
                        <ProductImage 
                            className={ selectedProduct === p.code ? 'selected' : '' }
                            height={p.height} 
                            src={`${process.env.PUBLIC_URL}/images/${p.image}`}
                            onClick={() => setSelectedProduct(p.code)}
                            shadowColor={shadowColor} 
                        />
                    </ProductContainer>
                )
            }
        </Container>
    )
}

export default Ribbon;
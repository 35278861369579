import React, { useEffect } from 'react'

import IconInput from '../../../../controls/IconInput'
import useSignupContext from '../SignupContext'
import useSignupFormContext from '../SignupFormContext'

let lastPassword = ''

const Password = () => {
    // Hooks
    const { addResetFunction } = useSignupContext()
    const { password, setPassword } = useSignupFormContext()

    useEffect(() => {
        addResetFunction(resetBackup)
        lastPassword && setPassword(lastPassword)
    }, [])

    const resetBackup = () => {
        lastPassword = ''
    }

    const onChange = e => {
        setPassword(e.target.value)
        lastPassword = e.target.value
    }

    return (
        <IconInput 
            type='password' 
            iconUrl='password' 
            name='password'
            placeholder='New password'
            marginTop={30} 
            value={password}
            onChange={onChange}
            caption="Your password (required, must be at least eight characters)."
            required
        />
    )
}

export default Password
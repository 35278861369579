export const MEMBER = 'member'
export const CLIENT = 'client'
export const ADMIN = 'admin'

export const USER_STANDBY = 1
export const USER_LOADING = 2
export const USER_LOADED = 3
export const USER_LOAD_ERROR = 4

export const CREATOR_NAME = "createArray"
export const CONTINUUM_NAME = "continueArray"

// Creator products
export const CREATOR_PRIME_NAME = "theCleanse"
export const CREATOR_TUNE_NAME = "theTune"
export const CREAM_SMOOTH_NAME = "cutTopography Cream Smooth"
export const CREAM_SOFTEN_NAME = "cutTopography Cream Soften"
export const CREAM_TEXTURED_NAME = "cutTopography Cream Textured"
export const CREAM_SMOOTH_SHORT_NAME = "Smooth"
export const CREAM_SOFTEN_SHORT_NAME = "Soften"
export const CREAM_TEXTURED_SHORT_NAME = "Textured"
export const SERUM_RADIUS_NAME = "cutRadius"
export const SERUM_BLUR_NAME = "cutBlur"
export const SERUM_PLUMB_NAME = "cutTaper"
export const SERUM_STRATA_NAME = "cutStrata"
export const SERUM_TILT_NAME = "cutTilt"

// Continuum products
export const PRIME_NAME = "Prime"
export const TUNE_NAME = "Tune"
export const CUT_NAME = "Wear"

// Stripe publishable keys
export const STRIPE_PUBLISHABLE_KEY = "pk_live_mmTEWnBstbDQyBp8SFzui2wQ00dXAteUBk"
const STRIPE_PUBLISHABLE_TEST_KEY = "pk_test_XzI6HQaEq4pyGERkWxjuCIGx00itSxbDUI"
const STRIPE_PUBLISHABLE_LIVE_KEY = "pk_live_mmTEWnBstbDQyBp8SFzui2wQ00dXAteUBk"

export const secretQuestions = [
    "What is the name of your first pet?",
    "What is the name of the town where you were born?",
    "What was the make of your first car?",
    "What is the name of the road you grew up on?",
    "What was your favorite subject in high school?"
]
import React, { Fragment, useState, useEffect } from 'react'

import DatePicker from '../../../../controls/DatePicker'
import { isEmpty } from '../../../../../utils'
import useProfileContext from '../ProfileContext'
import useProfileFormContext from '../ProfileFormContext'

let lastBirthday = null

const Birthday = () => {
    // State
    const [ birthday, setBirthday ] = useState(null)

    // Hooks
    const { onBirthdayReady } = useProfileFormContext()
    const { activeClient, addResetFunction, managingAddresses, managingCards } = useProfileContext()

    useEffect(() => {
        addResetFunction(resetBackup)
        lastBirthday && setBirthday(lastBirthday)
    }, [])

    // Active member loaded effect
    useEffect(() => {
        if (isEmpty(activeClient)) return;
        if (activeClient.birthdate) {
            setBirthday(activeClient.birthdate)
            lastBirthday = activeClient.birthdate
        }
    }, [ activeClient ])

    const resetBackup = () => {
        lastBirthday = null
    }

    const onReady = dateString => {
        onBirthdayReady(dateString)
        setBirthday(dateString)
        lastBirthday = dateString
    }

    return (
        <Fragment>
        {
            (!managingAddresses && !managingCards)
            &&
            <DatePicker
                caption='Select your birthday (Optional)'
                onDateReady={onReady}
                value={birthday}
                iconUrl='baby-carriage'
                marginTop={30}
                noYear
            />
        }
        </Fragment>
    )
}

export default Birthday
import React, { createContext, useContext } from 'react'

const Address = createContext(undefined)

export const AddressProvider = ({ children, value }) => {
    return (
        <Address.Provider value={value}>{children}</Address.Provider>
    )
}

const useAddressContext = () => {
    const context = useContext(Address)

    if (context === undefined) throw new Error('useAddressContext must be used within an AddressProvider')

    return context;
}

export default useAddressContext
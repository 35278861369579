import React, { Fragment, useState, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import axios from 'axios'
import { Form } from 'reactstrap'

import Header from '../controls/Header'
import Column from '../controls/Column'
import HeaderDesktop from '../media/HeaderDesktop'
import SideMenu from '../controls/SideMenu'
import Wallpaper from '../controls/Wallpaper'
import Null from '../controls/Null'
import MessageBox from '../controls/MessageBox'
import IconInput from '../controls/IconInput'
import Submit from '../controls/Submit'
import { usePhone, useLandscape } from '../../hooks/useMedia'
import useSwitchTheme from '../../hooks/useSwitchTheme'
import {
    RESTORE_ACTIVE_ADMIN, 
    RESTORE_TOKEN
} from '../../redux/actions/types'
import { isEmpty, tokenConfig, timeoutConfig } from '../../utils'
import { classicLight, classicDark } from '../../overlayColors'

const Halves = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`

const Half = styled.div`
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const lightOverlay = classicLight
const darkOverlay = classicDark

const handleInputChange = (e, key, dispatch) => {
    dispatch({
        type: SET_NEW_PROPERTY,
        payload: {
            key: key,
            value: e.target.value
        }
    })
}

const SET_NEW_PROPERTY = "SET_NEW_PROPERTY"
const salonReducer = (salon, action) => {
    switch (action.type) {
        case SET_NEW_PROPERTY: 
            salon = { ...salon, [action.payload.key]: action.payload.value }
            return salon
        default: 
            return salon
    }
}

const AddSalon = () => {
    // Local state
    const [ newSalon, salonDispatch ] = useReducer(salonReducer, { country: 'USA' })
    const [ messageBoxVisible, setMessageBoxVisible ] = useState(false)
    const [ spinnerVisible, setSpinnerVisible ] = useState(false)
    const [ statusMessage, setStatusMessage ] = useState('')

    // Other hooks
    const dispatch = useDispatch()
    const activeAdmin = useSelector(state => state.activeAdmin)
    const authToken = useSelector(state => state.token)
    const isPhone = usePhone()
    const isLandscape = useLandscape()
    useSwitchTheme()
    
    // Check for and recover from page refresh
    if (isEmpty(activeAdmin)) {
        // Restore active admin and token from local storage post-refresh
        dispatch({ type: RESTORE_ACTIVE_ADMIN })
        dispatch({ type: RESTORE_TOKEN })

        // Don't want to render with missing data
        return <Null />
    }

    // Handlers
    const onNameChange = e => handleInputChange(e, 'name', salonDispatch)
    const onEmailChange = e => handleInputChange(e, 'email', salonDispatch)
    const onPhoneChange = e => handleInputChange(e, 'phone', salonDispatch)
    const onWebsiteChange = e => handleInputChange(e, 'website', salonDispatch)
    const onAddr1Change = e => handleInputChange(e, 'addr1', salonDispatch)
    const onAddr2Change = e => handleInputChange(e, 'addr2', salonDispatch)
    const onCityChange = e => handleInputChange(e, 'city', salonDispatch)
    const onStateChange = e => handleInputChange(e, 'state', salonDispatch)
    const onZipChange = e => handleInputChange(e, 'zip', salonDispatch)
    const onCountryChange = e => handleInputChange(e, 'country', salonDispatch)

    const onSubmit = async e => {
        e.preventDefault()

        try {
            
            setMessageBoxVisible(true)
            setSpinnerVisible(true)

            // Create a stripe customer object for the salon
            const { name, phone, email } = newSalon
            const post = { name, phone, email, description: `${name} customer object` }
            let rsp = await axios.post('/pay/customers', post, timeoutConfig(tokenConfig(authToken)))

            newSalon.stripeCustomerId = rsp.data.id
            
            // Save the new salon to the DB
            rsp = await axios.post( '/api/salons', newSalon, timeoutConfig( tokenConfig(authToken), 10000 ) )

            console.log(rsp)

            setSpinnerVisible(false)
            setStatusMessage(`Successfully registered the salon.`)

        } catch (err) {
            if (err.response) { // Status 500, 400
                const errMsg = err.response.status === 500 ? 'Server error' : err.response.data.message;
                console.log('Error! ', err.response.data)
                setStatusMessage(`${errMsg}, salon not created.`)
            } else if (err?.code === 'ECONNABORTED') { // Timeout
                setStatusMessage(`Server took too long to respond, salon not created, try again later.`)
            } else { // Not likely
                console.log('ERR: ', err?.code)
                console.log('ERR: ', err?.message) 
                setStatusMessage(`Error: ${err?.message}, salon not created`)
            }

            setSpinnerVisible(false)
        }
    }

    return (
        <Fragment>
            <Wallpaper />
            <CSSTransition 
                in={messageBoxVisible} 
                classNames="message" 
                timeout={1000}
                unmountOnExit
            >
                <MessageBox  
                    spinner={spinnerVisible} message={statusMessage}
                    lightBackground={lightOverlay} darkBackground={darkOverlay}
                    onOk={()=> setMessageBoxVisible(false)} label='Ok'
                    width='320' height='240' 
                />
            </CSSTransition>
            { /* Header section */
                isPhone
                ?
                <Fragment>
                    <SideMenu top={isLandscape ? 50 : 0} />
                    <Header arrow={{
                        color: 'yellow',
                        to: '/admin-home',
                        destination: 'Home'
                    }} title='Add Salon' separator />
                </Fragment>
                :
                <HeaderDesktop height={100} leftContent={<Header arrow={{
                    color: 'yellow',
                    to: 'admin-home',
                    destination: 'Home'
                }} title='Add Salon' />} />
            }
            <Form onSubmit={onSubmit}>
            {
                isLandscape
                ?
                <Column>
                    <Halves>
                        <Half>           
                            <IconInput 
                                type='text' 
                                iconUrl='name' 
                                name='name'
                                value={newSalon.name} 
                                placeholder='Salon name (required)'
                                marginTop={30} 
                                onChange={onNameChange}
                                required
                            />
                            <IconInput 
                                type='text' 
                                iconUrl='email' 
                                name='email'
                                value={newSalon.email} 
                                placeholder='Salon email (required)'
                                marginTop={30} 
                                onChange={onEmailChange}
                                required
                            />
                            <IconInput 
                                type='text' 
                                iconUrl='phone' 
                                name='phone'
                                value={newSalon.phone} 
                                placeholder='Salon phone'
                                marginTop={30} 
                                onChange={onPhoneChange}
                            />
                            <IconInput 
                                type='text' 
                                iconUrl='website' 
                                name='website'
                                value={newSalon.website} 
                                placeholder='Salon website'
                                marginTop={30} 
                                onChange={onWebsiteChange}
                            />
                        </Half>
                        <Half>
                            <IconInput 
                                type='text' 
                                iconUrl='address' 
                                name='addr1'
                                value={newSalon.addr1} 
                                placeholder='Address Line 1 (required)'
                                marginTop={30} 
                                onChange={onAddr1Change}
                                required
                            />
                            <IconInput 
                                type='text' 
                                iconUrl='address' 
                                name='addr2'
                                value={newSalon.addr2} 
                                placeholder='Address Line 2'
                                marginTop={30} 
                                onChange={onAddr2Change}
                            />
                            <IconInput 
                                type='text' 
                                iconUrl='address' 
                                name='city'
                                value={newSalon.addr2} 
                                placeholder='City (required)'
                                marginTop={30} 
                                onChange={onCityChange}
                                required
                            />
                            <IconInput 
                                type='text' 
                                iconUrl='address' 
                                name='state'
                                value={newSalon.state} 
                                placeholder='State (required)'
                                marginTop={30} 
                                onChange={onStateChange}
                                required
                            />
                            <IconInput 
                                type='text' 
                                iconUrl='address' 
                                name='zip'
                                value={newSalon.zip} 
                                placeholder='Zip (required)'
                                marginTop={30} 
                                onChange={onZipChange}
                                required
                            />
                            <IconInput 
                                type='text' 
                                iconUrl='address' 
                                name='country'
                                value={newSalon.country} 
                                placeholder='Country'
                                marginTop={30} 
                                onChange={onCountryChange}
                                required
                            />
                        </Half>
                    </Halves>
                    <Submit width={'100'} marginTop={'40'} />
                </Column>
                :
                <Column>           
                    <IconInput 
                        type='text' 
                        iconUrl='name' 
                        name='name'
                        value={newSalon.name} 
                        placeholder='Salon name (required)'
                        marginTop={30} 
                        onChange={onNameChange}
                        required
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='email' 
                        name='email'
                        value={newSalon.email} 
                        placeholder='Salon email (required)'
                        marginTop={30} 
                        onChange={onEmailChange}
                        required
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='phone' 
                        name='phone'
                        value={newSalon.phone} 
                        placeholder='Salon phone'
                        marginTop={30} 
                        onChange={onPhoneChange}
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='website' 
                        name='website'
                        value={newSalon.website} 
                        placeholder='Salon website'
                        marginTop={30} 
                        onChange={onWebsiteChange}
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='address' 
                        name='addr1'
                        value={newSalon.addr1} 
                        placeholder='Address Line 1 (required)'
                        marginTop={30} 
                        onChange={onAddr1Change}
                        required
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='address' 
                        name='addr2'
                        value={newSalon.addr2} 
                        placeholder='Address Line 2'
                        marginTop={30} 
                        onChange={onAddr2Change}
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='address' 
                        name='city'
                        value={newSalon.addr2} 
                        placeholder='City (required)'
                        marginTop={30} 
                        onChange={onCityChange}
                        required
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='address' 
                        name='state'
                        value={newSalon.state} 
                        placeholder='State (required)'
                        marginTop={30} 
                        onChange={onStateChange}
                        required
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='address' 
                        name='zip'
                        value={newSalon.zip} 
                        placeholder='Zip (required)'
                        marginTop={30} 
                        onChange={onZipChange}
                        required
                    />
                    <IconInput 
                        type='text' 
                        iconUrl='address' 
                        name='country'
                        value={newSalon.country} 
                        placeholder='Country'
                        marginTop={30} 
                        onChange={onCountryChange}
                        required
                    />
                    <Submit width={'100'} marginTop={'40'} />
                </Column> 
            }
            </Form>
        </Fragment>
    )
}

export default AddSalon
import { 
    SET_HOST_MEMBER, 
    CLEAR_HOST_MEMBER, 
    BACKUP_HOST_MEMBER,
    RESTORE_HOST_MEMBER
} from '../actions/types'

const initialState = {}

const hostMember = (state = initialState, action) => {

    switch (action.type) {
        case SET_HOST_MEMBER:
        {
            const member = { ...action.payload }
            localStorage.setItem('hostMember', JSON.stringify(member))
            return member
        }
        case BACKUP_HOST_MEMBER:
        {
            const member = { ...action.payload }
            localStorage.setItem('hostMember', JSON.stringify(member))
            return member
        }
        case CLEAR_HOST_MEMBER:
            localStorage.removeItem('hostMember')
            return {}
        case RESTORE_HOST_MEMBER: {
            const member = localStorage.getItem('hostMember')
            return JSON.parse(member)
        }
        default:
            return state
    }
}

export default hostMember
import React, { Fragment, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { useTheme } from '../contexts/Theme'
import { useLandscape, usePhone } from '../../hooks/useMedia'

const MenuContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: ${props => props.isLandscape ? 'row' : 'column'};
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: ${props => props.marginTop}px;
`

const SubmenuContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const Label = styled.div`
    width: auto;
    height: 40px;
    overflow: visible;
    font: 20px verdana, sans-serif;
    color: ${props => props.color};
    text-indent: ${props => props.textIndent}px;
    display: flex;
    align-items: center;

    &.hidden { display: none; }
`

const HierarchicalMenu = ({ menu }) => {
    // State
    const [ indexClicked, setIndexClicked ] = useState(-1)

    // Other hooks
    const textIndent = useRef(20)
    const theme = useTheme()
    const isLandscape = useLandscape()
    const isPhone = usePhone()

    const onMenuClicked = index => setIndexClicked(i => i === index ? -1 : index)       
    
    // Destructure the theme
    const { white, rgbDark } = theme
    const [ textColor ] = theme.isDarkBackground() ? [ white ] : [ rgbDark ];

    return (
        <Fragment>
            <MenuContainer isLandscape={isLandscape} marginTop={isPhone ? 0 : 100}>
            {
                menu.map((item, i) =>
                    <Fragment>
                    {
                        item.menu 
                        ?
                        <SubmenuContainer>
                            <Label key={uuid()} onClick={() => onMenuClicked(i)} textIndent={textIndent.current} color={textColor}>
                                {item.label}
                            </Label>
                            {
                                item.menu.map((subitem, j) =>
                                    <Label 
                                        key={uuid()} className={indexClicked === i ? '' : 'hidden'} 
                                        textIndent={textIndent.current + 30} color={textColor}
                                    >
                                        <Link to={subitem.to} style={{color: textColor, textDecoration: 'none'}}>
                                            {subitem.label}
                                        </Link>
                                    </Label>
                                )  
                            }
                        </SubmenuContainer>
                        :
                        <Label key={i} textIndent={textIndent.current} color={textColor}>
                            <Link to={item.to} style={{color: textColor, textDecoration: 'none'}}>
                                {item.label}
                            </Link>
                        </Label>
                    }
                    </Fragment>
                )
            } 
            </MenuContainer>           
        </Fragment>
    )
}

export default HierarchicalMenu
import React from 'react'

import Footer from './parts/Footer'
import ClientsHeader from './parts/Header'
import Cart from './parts/Cart'
import ClientCount from './parts/ClientCount'
import ClientSearch from './parts/ClientSearch'
import { ClientsProvider } from './ClientsContext'
import useClients from './useClients'
import useActiveMember from '../../../../hooks/useActiveMember'
import useCurrentMember from '../../../../hooks/useCurrentMember'
import useCurrentClient from '../../../../hooks/useCurrentClient'
import useRecoverObjects from '../../../../hooks/useRecoverObjects'
import useLogoff from '../../../../hooks/useLogoff'

import Null from '../../../controls/Null'

// The proxy calls the hooks for this page and sets up its context
//
const ClientsProxy = ({ children }) => {
    const { ...rest } = useClients()
    const { activeMember, ...restMember } = useActiveMember()
    const { currentMember, clearCurrentMember, isCurrentMember } = useCurrentMember()
    const { setCurrentClient } = useCurrentClient()
    const { loggingOff, logoff } = useLogoff({ activeMember })

    // Check for and recover from page refresh
    if (useRecoverObjects({ activeMember, currentMember })) return <Null />

    // Check for logoff
    if (loggingOff) return <Null />

    return (
        <ClientsProvider value={{ 
            activeMember, currentMember, clearCurrentMember, isCurrentMember,
            logoff, setCurrentClient, ...restMember, ...rest 
        }}>{children}</ClientsProvider>
    )
}

// Component parts of the page, can be laid out any way we want
//
ClientsProxy.Header = ClientsHeader
ClientsProxy.Cart = Cart
ClientsProxy.ClientCount = ClientCount
ClientsProxy.ClientSearch = ClientSearch
ClientsProxy.Footer = Footer

export default ClientsProxy
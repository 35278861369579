import React, { useState } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'
import Caption from './Caption'

const Bubble = styled.div`
    position: relative;
    width: 40px;
    height: 49px;
    margin-bottom: ${props => props.marginBottom}px;
    background-image: url('${process.env.PUBLIC_URL}/images/whats-this-${props => props.iconColor}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const HelpBubble = ({ caption, marginBottom, flipCaption }) => {
    const [ captionVisible, setCaptionVisible ] = useState(false)

    const theme = useTheme()
    const { white, dark } = theme
    const iconColor = theme.isDarkBackground() ? white : dark;

    // Handlers
    const onBubbleClick = () => setCaptionVisible(!captionVisible)

    return (
        <Bubble iconColor={iconColor} onClick={onBubbleClick} marginBottom={marginBottom}>
            { captionVisible && 
                <Caption text={caption} width='200' bottom='60' 
                    left={flipCaption ? -160 : 0} stemOffset={flipCaption ? 180 : 20} 
                /> }
        </Bubble> 
    )
}

export default HelpBubble
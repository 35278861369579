import React from 'react'

import ProfileHeader from './parts/Header'
import Greeting from './parts/Greeting'
import Form from './parts/ProfileForm'
import ManageAddresses, { AddressManager } from './parts/ManageAddresses'
import ManageCards, { CardManager } from './parts/ManageCards'
import Status from './parts/Status'
import useProfile from './useProfile'
import { ProfileProvider } from './ProfileContext'
import useActiveClient from '../../../../hooks/useActiveClient'
import useRecoverObjects from '../../../../hooks/useRecoverObjects'
import useLogoff from '../../../../hooks/useLogoff'

import Null from '../../../controls/Null'

// The proxy calls the hooks for this page and sets up its context
//
const ProfileProxy = ({ children }) => {
    const { activeClient, ...restClient } = useActiveClient()
    const { ...profile } = useProfile()
    const { loggingOff, logoff } = useLogoff({ activeClient })

    // Check for and recover from page refresh
    if (useRecoverObjects({ activeClient })) return <Null />

    // Check for logoff
    if (loggingOff) return <Null />

    return (
        <ProfileProvider value={{ logoff, activeClient, ...restClient, ...profile }}>{children}</ProfileProvider>
    )
}

// Component parts of the page, can be laid out any way we want
//
ProfileProxy.Header = ProfileHeader
ProfileProxy.Greeting = Greeting
ProfileProxy.Status = Status
ProfileProxy.Form = Form
ProfileProxy.AddressManager = AddressManager
ProfileProxy.ManageAddresses = ManageAddresses
ProfileProxy.CardManager = CardManager
ProfileProxy.ManageCards = ManageCards

export default ProfileProxy
import React, { createContext, useContext } from 'react'

const SalonOrder = createContext(undefined)

export const SalonOrderProvider = ({ children, value }) => {
    return (
        <SalonOrder.Provider value={value}>{children}</SalonOrder.Provider>
    )
}

const useSalonOrderContext = () => {
    const context = useContext(SalonOrder)

    if (context === undefined) throw new Error('useSalonOrderContext must be used within a SalonOrderProvider')

    return context;
}

export default useSalonOrderContext
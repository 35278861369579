import React, { Fragment } from 'react'

import Portrait from '../../../controls/Portrait'
import Wallpaper from '../../../controls/Wallpaper'
import Landscape from '../../../controls/Landscape'
import LandscapeSegment from '../../../controls/LandscapeSegment'
import SideMenu from '../../../controls/SideMenu'
import HomeProxy from './HomeProxy'
import { usePhone, useLandscape } from '../../../../hooks/useMedia'
import useSwitchTheme from '../../../../hooks/useSwitchTheme'
import useActivateHelp from '../../../../hooks/useActivateHelp'

const Home = () => {
    // Local state

    // Other hooks
    useSwitchTheme()
    useActivateHelp()
    const isPhone = usePhone()
    const isLandscape = useLandscape()

    return (
        <Fragment>
            <Wallpaper />
            {
                isPhone 
                ?
                (
                    isLandscape 
                    ?
                    <Landscape align='flex-start'>
                        <HomeProxy> 
                            <SideMenu />
                            <LandscapeSegment pctWidth={45} pctHeight={100}>
                                <HomeProxy.Header />
                                <HomeProxy.Greeting />
                                <HomeProxy.Host />
                                <HomeProxy.Features />
                                <HomeProxy.Actions />
                            </LandscapeSegment>
                            <LandscapeSegment pctWidth={55}>
                                <HomeProxy.CurrentHaircut />
                                <HomeProxy.Activity />
                            </LandscapeSegment>
                        </HomeProxy>
                    </Landscape>
                    :
                    <Portrait>
                        <HomeProxy> 
                            <SideMenu />
                            <HomeProxy.Header />
                            <HomeProxy.Greeting />
                            <HomeProxy.Host />
                            <HomeProxy.Features />
                            <HomeProxy.CurrentHaircut />
                            <HomeProxy.Actions />
                            <HomeProxy.Activity />
                        </HomeProxy>
                    </Portrait>
                )
                :
                (
                    isLandscape 
                    ?
                    <Portrait>
                        <HomeProxy>
                            <HomeProxy.Header />
                            <Landscape align='flex-start'>
                                <LandscapeSegment pctWidth={45} pctHeight={100}>
                                    <HomeProxy.Greeting />
                                    <HomeProxy.Host pctWidth={70} />
                                    <HomeProxy.Features />
                                    <HomeProxy.Actions />
                                </LandscapeSegment>
                                <LandscapeSegment pctWidth={55}>
                                    <HomeProxy.CurrentHaircut pctWidth={60} />
                                    <HomeProxy.Activity />
                                </LandscapeSegment>
                            </Landscape>
                        </HomeProxy>
                    </Portrait>
                    :
                    <Portrait>
                        <HomeProxy>
                            <HomeProxy.Header />
                            <HomeProxy.Greeting />
                            <HomeProxy.Host pctWidth={45} />
                            <HomeProxy.Features />
                            <HomeProxy.CurrentHaircut pctWidth={50} />
                            <HomeProxy.Actions />
                            <HomeProxy.Activity />
                        </HomeProxy>
                    </Portrait>
                )
            }
        </Fragment>
    )
}

export default Home
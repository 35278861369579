import React, { createContext, useContext } from 'react'

const Signup = createContext(undefined)

export const SignupProvider = ({ children, value }) => {
    return (
        <Signup.Provider value={value}>{children}</Signup.Provider>
    )
}

const useSignupContext = () => {
    const context = useContext(Signup)

    if (context === undefined) throw new Error('useSignupContext must be used within a SignupProvider')

    return context;
}

export default useSignupContext
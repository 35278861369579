import React, { Fragment, useState, useCallback } from 'react'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'
import Caption from './Caption'

const OvalContainer = styled.div`
    width: 320px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${props => props.marginTop}px;
`

const OvalInput = styled.div`
    width: 250px;
    height: 60px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: none;
    background-color: ${props => props.bgColor};
`

const OvalIcon = styled.div`
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: ${props => props.bgColor};
    background-image: url('../../../images/${props => props.iconUrl}-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px 50px;
`

const OvalInputField = styled.input`
    width: 100%;
    height: 40px;
    text-align: center;
    outline: none;
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    font: 18px verdana, sans-serif;
    color: ${props => props.color};

    &::placeholder {
        color: ${props => props.color};
        opacity: 0.6;
    }
`

const UnderlineContainer = styled.div`
    position: relative;
    width: 320px;
    height: 40px;
    margin-top: ${props => props.marginTop}px;
`

const UnderlineIcon = styled.div`
    position: absolute;
    top: -10px;
    left: 0px;
    width: 50px;
    height: 50px;
    background-image: url('../../../images/${props => props.iconUrl}-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Placeholder = styled.label`
    position: absolute;
    top: 10px;
    left: 70px;
    font: 18px verdana, sans-serif;
    color: ${props => props.color};
    opacity: 0.4;
    transform-origin: 0 50%;
    transition: transform 200ms, opacity 200ms;

    .input:focus ~ &,
    .input:not(:placeholder-shown) ~ & {
        transform: translateY(-30px) translateX(10px) scale(0.75);
        opacity: 1.0;
    } 
`

const UnderlineInputField = styled.input`
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 260px;
    height: 40px;
    text-align: center;
    font: 18px verdana, sans-serif;
    color: ${props => props.color};
    outline: none;
    border: 0;
    background-color: rgba(0, 0, 0, 0);
`

const Underline = styled.div`
    position: absolute;
    bottom: 0px;
    left: 60px;
    right: 0;
    border-style: solid;
    border-width: 1px;
    border-color: ${props => props.color};
    background-color: ${props => props.color};
`

// const OvalIcon_ = React.memo(OvalIcon)
// const UnderlineIcon_ = React.memo(UnderlineIcon)

const IconInput = ({ type, name, value, placeholder, iconUrl, marginTop, onChange, caption, required }) => {
    const [ captionVisible, setCaptionVisible ] = useState(false)

    const theme = useTheme()
    const { white, dark, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const textColor = theme.isDarkBackground() ? white : rgbDark;
    const bgColor = theme.isDarkBackground() ? rgbaWhiteBg : rgbaDarkBg ;
    const iconColor = theme.isDarkBackground() ? white : dark;
    const stemOffset = theme.isInputOval() ? '30' : '25';

    const onTouchIcon = useCallback(() => setCaptionVisible(!captionVisible), [captionVisible])

    return (
        theme.isInputOval() 
            ?
            <OvalContainer marginTop={marginTop}>
                <OvalIcon iconUrl={iconUrl} bgColor={bgColor} color={iconColor} onTouchStart={onTouchIcon}>
                    {
                        caption && captionVisible && (
                            <Caption text={caption} width='200' bottom='60' left='0' stemOffset={stemOffset}/> 
                        )
                    }
                </OvalIcon>
                <OvalInput bgColor={bgColor}>
                    <OvalInputField 
                        type={type} 
                        name={name}
                        value={value} 
                        onChange={onChange} 
                        placeholder={placeholder} 
                        color={textColor}
                        required={required} 
                    />
                </OvalInput>
            </OvalContainer> 
            :
            <UnderlineContainer marginTop={marginTop}>
                <UnderlineIcon iconUrl={iconUrl} color={iconColor} onClick={onTouchIcon}>
                {
                    caption && captionVisible && (
                        <Caption text={caption} width='200' bottom='60' left='0' stemOffset={stemOffset}/> 
                    )
                }
                </UnderlineIcon>
                <Fragment>
                    <UnderlineInputField 
                        type={type} 
                        name={name}
                        id={name}
                        value={value}
                        onChange={onChange} 
                        placeholder=' '
                        className='input' 
                        color={textColor}
                        required={required} 
                    /> 
                    <Placeholder for={name} color={textColor}>{placeholder}</Placeholder>
                    <Underline color={textColor} />
                </Fragment>
            </UnderlineContainer>
    )
}

export default IconInput
import React, { Fragment } from 'react'
import styled from 'styled-components'

import Spinner from '../../../../controls/Spinner'
import useMembersContext from '../MembersContext'
import { useTheme } from '../../../../contexts/Theme'

const Title = styled.div`
    position: relative;
    width: ${p => p.pctWidth ?? '90'}%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.color};
    margin-top: 40px;
`

const Count = styled.div`
    height: 100%;
    width: auto;
    font: 30px verdana, sans-serif;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const MemberCount = ({ pctWidth }) => {
    // State
    const { activeMember, salonMembers, spinner } = useMembersContext()
    
    // Other hooks
    const theme = useTheme()

    // Theme
    const { white, rgbDark } = theme
    const textColor = theme.isDarkBackground() ? white : rgbDark;

    return (
        <Fragment>
            { spinner && <Spinner /> }
            <Title color={textColor} pctWidth={pctWidth}>
                <Count>{`${activeMember.salon.name} has ${salonMembers.length} member${salonMembers.length === 1 ? '' : 's'}`}</Count>
            </Title>
        </Fragment>
    )
}

export default MemberCount
import React, { Fragment, useState, useRef, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'

import { useTheme } from '../contexts/Theme'
import Caption from './Caption'
import Null from './Null'

const UnderlineContainer = styled.div`
    position: relative;
    width: 320px;
    height: 40px;
    margin-top: ${props => props.marginTop}px;
`

const UnderlineIcon = styled.div`
    position: absolute;
    top: -10px;
    left: 0px;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0);
    background-image: url('../../../images/${props => props.iconUrl}-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const UnderlineInput = styled.div`
    position: absolute;
    top: -10px;
    left: 65px;
    width: 250px;
    height: 40px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
`

const Underline = styled.div`
    position: absolute;
    bottom: 0px;
    left: 60px;
    right: ${p => p.right}px;
    border-style: solid;
    border-width: 1px;
    border-color: ${props => props.color};
    background-color: ${props => props.color};
`

const OvalContainer = styled.div`
    width: 100%;
    height: 60px;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${props => props.marginTop}px;
`

const OvalIcon = styled.div`
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: ${props => props.bgColor};
    background-image: url('../../../images/${props => props.iconUrl}-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px 50px;
`

const OvalInput = styled.div`
    position: relative;
    width: 250px;
    height: 60px;
    margin-left: 10px;
    border-radius: 100px;
    border: none;
    background-color: ${props => props.bgColor};
`

const Select = styled.input`
    position: absolute;
    top: 10px;
    left: ${props => props.left}px;
    width: ${props => props.width}px;
    height: 40px;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    font: 18px verdana, sans-serif;
    color: ${props => props.color};
    text-align: center;
     
    &::placeholder {
        color: ${props => props.color};
        opacity: 0.6;
    }
`

const DropdownIcon = styled.div`
    position: absolute;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    width: 40px;
    height: 50px;
    background-image: url('../../../images/dropdown-${props => props.color}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Options = styled.div`
    position: absolute;
    top: 50px;
    left: ${props => props.left}px;
    width: ${props => props.width}px;
    height: 200px;
    background-color: ${props => props.bgColor};
    font: 18px verdana, sans-serif;
    color: ${props => props.color};
    overflow: scroll;
    z-index: 3;

    &.options-enter {
        opacity: 0;
        transform: scale(0.8) translateY(-50px);
    }
    &.options-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 500ms, transform 500ms;
    }
    &.options-exit {
        opacity: 1;
    }
    &.options-exit-active {
        opacity: 0;
        transform: scale(0.8) translateY(-50px);
        transition: opacity 500ms, transform 500ms;
    }
`

const Option = styled.div`
    width: 60%;
    height: 25px;
    background-color: rgba(0, 0, 0, 0);
    overflow: visible;
    padding: 5px 0 0 10px;

    &.selected { 
        background-color: rgb(38, 38, 255);
        color: white;
    }
`

const DatePicker = ({ caption, value, onDateReady, iconUrl, marginTop, noYear }) => {
    
    // State
    const [ captionVisible, setCaptionVisible ] = useState(false)
    const [ monthsVisible, setMonthsVisible ] = useState(false)
    const [ daysVisible, setDaysVisible ] = useState(false)
    const [ yearsVisible, setYearsVisible ] = useState(false)
    const [ yearSelected, setYearSelected ] = useState(value ? yearMap()[extractYear(value)] : -1)
    const [ monthSelected, setMonthSelected ] = useState(value ? monthMap()[extractMonth(value)] : -1)
    const [ daySelected, setDaySelected ] = useState(value ? dayMap()[extractDay(value.slice(0, 10))] : -1)
    const [ yearEntered, setYearEntered ] = useState(value ? extractValidYear(value) : '')
    const [ monthEntered, setMonthEntered ] = useState(value ? extractMonth(value) : '')
    const [ dayEntered, setDayEntered ] = useState(value ? extractDay(value) : '')

    useEffect(() => {
        if (!value) return;
        setMonthSelected(monthMap()[extractMonth(value)])
        setDaySelected(dayMap()[extractDay(value.slice(0, 10))])
        setYearSelected(yearMap()[extractYear(value)])
    }, [value])

    // Refs
    const dateString = useRef( value ? `${extractYear(value)}-${monthEntered}-${dayEntered}` : '1900--' )

    // Theme
    const theme = useTheme()
    const { white, dark, rgbDark, rgbaDarkBg, rgbaWhiteBg } = theme
    const bgColor = theme.isDarkBackground() ? rgbaWhiteBg : rgbaDarkBg;
    const iconColor = theme.isDarkBackground() ? white : dark;
    const textColor = theme.isDarkBackground() ? white : rgbDark;
    const optionsColor = theme.isDarkBackground() ? rgbDark : white;
    const stemOffset = theme.isInputOval() ? '30' : '25';
    const ddTop = theme.isInputOval() ? '5' : '0';
    const monthLeft = theme.isInputOval() ? '0' : '0';
    const dayLeft = theme.isInputOval() ? '70' : '80';
    const yearLeft = theme.isInputOval() ? '135' : '160';
    const ddMonthLeft = theme.isInputOval() ? '45' : '40';
    const ddDayLeft = theme.isInputOval() ? '110' : '120';
    const ddYearLeft = theme.isInputOval() ? '200' : '220';
    const optionsMonthLeft = theme.isInputOval() ? '10' : '0';
    const optionsDayLeft = theme.isInputOval() ? '80' : '80';
    const optionsYearLeft = theme.isInputOval() ? '150' : '160';
    const optionsMonthWidth = theme.isInputOval() ? '68' : '80';
    const optionsDayWidth = theme.isInputOval() ? '66' : '80';
    const optionsYearWidth = theme.isInputOval() ? '90' : '100';

    // Handlers
    const onTouchMonths = () => setMonthsVisible(!monthsVisible)
    const onTouchDays = () => setDaysVisible(!daysVisible)
    const onTouchYears = () => setYearsVisible(!yearsVisible)
    const onTouchIcon = () => setCaptionVisible(!captionVisible)
    const onTouchStartMonth = key => {
        dateString.current = insertMonth(dateString.current,  monthOptions()[key])
        setMonthSelected(key)
        onDateReady(dateString.current)
    }
    const onTouchEndMonth = () => setMonthsVisible(false)
    const onTouchStartDay = key => {
        dateString.current = insertDay(dateString.current,  dayOptions()[key])
        setDaySelected(key)
        onDateReady(dateString.current)
    }
    const onTouchEndDay = () => setDaysVisible(false)
    const onTouchStartYear = key => {
        dateString.current = insertYear(dateString.current,  yearOptions()[key])
        setYearSelected(key)
        onDateReady(dateString.current)
    }
    const onTouchEndYear = () => setYearsVisible(false)
    const onChangeMonth = e => setMonthEntered(e.target.value)
    const onChangeDay = e => setDayEntered(e.target.value)
    const onChangeYear = e => setYearEntered(e.target.value)

    // Components by theme    
    const Container = theme.isInputOval() ? OvalContainer : UnderlineContainer;
    const Icon = theme.isInputOval() ? OvalIcon : UnderlineIcon;
    const CaptionOrNot = caption && captionVisible ? Caption : Null;
    const Input = theme.isInputOval() ? OvalInput : UnderlineInput;

    const monthValue = monthSelected >= 0 ? monthOptions()[monthSelected] : monthEntered;
    const dayValue = daySelected >= 0 ? dayOptions()[daySelected] : dayEntered;
    const yearValue = yearSelected >= 0 ? yearOptions()[yearSelected] : yearEntered;

    return (        
        <Container marginTop={marginTop}>
            <Icon bgColor={bgColor} iconUrl={iconUrl} color={iconColor} onClick={onTouchIcon}>
                <CaptionOrNot text={caption} width='200' bottom='60' left='0' stemOffset={stemOffset} />
            </Icon>
            <Input bgColor={bgColor}>

                {/* Month controls */}                
                <Select type='text' name='month' color={textColor} left={monthLeft} width='40' 
                    onChange={onChangeMonth} value={monthValue} placeholder='Month' maxLength={2} 
                /> 
                <DropdownIcon onClick={onTouchMonths} top={ddTop} left={ddMonthLeft} color={iconColor} />
                <CSSTransition 
                    in={monthsVisible} 
                    classNames="options" 
                    timeout={500}
                    unmountOnExit
                >
                    <Options left={optionsMonthLeft} width={optionsMonthWidth} color={textColor} bgColor={optionsColor}>{
                        monthsVisible && (
                            monthOptions().map((month, i) => <Option key={i} 
                                onTouchStart={e => onTouchStartMonth(i)}
                                onTouchEnd={e => onTouchEndMonth()}
                                onMouseDown={e => onTouchStartMonth(i)}
                                onMouseUp={e => onTouchEndMonth()}
                                className={i === monthSelected ? 'selected' : ''}>
                                {month}
                            </Option>)
                        )
                    }
                    </Options>
                </CSSTransition>

                {/* Day controls */}             
                <Select type='text' name='day' color={textColor} left={dayLeft} width='40' 
                    onChange={onChangeDay} value={dayValue} placeholder='Day' maxLength={2} 
                /> 
                <DropdownIcon onClick={onTouchDays} top={ddTop} left={ddDayLeft} color={iconColor} />
                <CSSTransition 
                    in={daysVisible} 
                    classNames="options" 
                    timeout={500}
                    unmountOnExit
                >
                    <Options left={optionsDayLeft} width={optionsDayWidth} color={textColor} bgColor={optionsColor}>{
                        daysVisible && (
                            dayOptions().map((day, i) => <Option key={i} 
                                    onTouchStart={e => onTouchStartDay(i)}
                                    onTouchEnd={e => onTouchEndDay()} 
                                    onMouseDown={e => onTouchStartDay(i)}
                                    onMouseUp={e => onTouchEndDay()}
                                    className={i === daySelected ? 'selected' : ''}
                                >
                                {day}
                                </Option>
                            )
                        )
                    }
                    </Options>
                </CSSTransition>

                {/* Year controls */}                
                {
                    !noYear &&
                    <Fragment>
                        <Select type='text' name='year' color={textColor} left={yearLeft} width='60' 
                            onChange={onChangeYear} value={yearValue} placeholder='YYYY' maxLength={4} 
                        /> 
                        <DropdownIcon onTouchStart={onTouchYears} top={ddTop} left={ddYearLeft} color={iconColor} />
                        <CSSTransition 
                            in={yearsVisible} 
                            classNames="options" 
                            timeout={500}
                            unmountOnExit
                        >
                            <Options left={optionsYearLeft} width={optionsYearWidth} color={textColor} bgColor={optionsColor}>{
                                yearsVisible && (
                                    yearOptions().map((year, i) => <Option 
                                            key={i} 
                                            onTouchStart={e => onTouchStartYear(i)}
                                            onTouchEnd={e => onTouchEndYear()}
                                            className={i === yearSelected ? 'selected' : ''}
                                        >
                                        {year}
                                        </Option>
                                    )
                                )
                            }
                            </Options>
                        </CSSTransition>
                    </Fragment>
                }
            </Input>
            {theme.isInputUnderline() && <Underline right={noYear ? '100' : '0'} color={textColor} />}
        </Container>
    )
}

export const standardDate = dateString => 
    `${extractMonth(dateString)}/${extractDay(dateString)}/${extractYear(dateString)}`

const extractDay = dateString => {
    const start = dateString.indexOf("-", 5) + 1
    if (start === dateString.length) return ""
    return dateString.slice(start, start + 2)
}
const extractMonth = dateString => {
    const start = dateString.indexOf("-") + 1
    if (dateString[start] === '-') return ""
    return dateString.slice(start, start + 2)
}
const extractYear = dateString => dateString.slice(0, 4)
const extractValidYear = dateString => {
    const year = extractYear(dateString)
    return year === '1900' ? '' : year;
}
const insertDay = (dateString, day) => {
    const year = dateString.slice(0, 4)
    const month = extractMonth(dateString)
    return `${year}-${month}-${day}`
}
const insertMonth = (dateString, month) => {
    const year = dateString.slice(0, 4)
    const day = extractDay(dateString)
    return `${year}-${month}-${day}`
}
const insertYear = (dateString, year) => {
    const month = extractMonth(dateString)
    const day = extractDay(dateString)
    return `${year}-${month}-${day}`
}

const monthOptions = () => [
    '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
]

const monthMap = () => {
    const opts = monthOptions(), map = {};
    opts.forEach((op, i) => map[op] = i)
    return map
}

const dayOptions = () => [
    '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', 
    '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
    '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
]

const dayMap = () => {
    const opts = dayOptions(), map = {};
    opts.forEach((op, i) => map[op] = i)
    return map
}

const yearOptions = () => {
    const years = [] 

    // Get upper bound year
    let startYear = (new Date()).getFullYear()
    for (let i = 16; i < 80; i++) {
        let year = startYear - i
        years.push(year.toString())
    }

    return years
}

const yearMap = () => {
    const opts = yearOptions(), map = {};
    opts.forEach((op, i) => map[op] = i)
    map["1900"] = -1
    return map
}

export default DatePicker
import React, { Fragment } from 'react'

import Header from '../../../../controls/Header'
import HeaderDesktop from '../../../../media/HeaderDesktop'
import useClientsContext from '../ClientsContext'
import { usePhone } from '../../../../../hooks/useMedia'

const ClientsHeader = () => {
    // Other hooks
    const { runResetFunctions, logoff, isCurrentMember, clearCurrentMember } = useClientsContext()
    const isPhone = usePhone()

    const to = isCurrentMember() ? '/members' : '/';
    const destination = isCurrentMember() ? 'members' : 'Logout';

    const onBack = () => {
        runResetFunctions()
        isCurrentMember() ? clearCurrentMember() : logoff();
    }

    return (
        <Fragment>
        {
            isPhone
            ?
            <Header arrow={{
                color: 'red',
                to,
                destination
            }} title='Clients' onBack={onBack} separator />
            :
            <HeaderDesktop height={100} leftContent={<Header arrow={{
                color: 'red',
                to,
                destination
            }} title='Clients' onBack={onBack} />} />
        } 
        </Fragment>
    )
}

export default ClientsHeader
import { 
    SET_CURRENT_CLIENT,
    CLEAR_CURRENT_CLIENT,
    BACKUP_CURRENT_CLIENT,
    RESTORE_CURRENT_CLIENT,
    SET_CURRENT_CLIENT_PROPERTY 
} from '../actions/types'

const initialState = {}

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_CLIENT:
        {
            const client = { ...action.payload }
            localStorage.setItem('currentClient', JSON.stringify(client))
            return client
        }
        case CLEAR_CURRENT_CLIENT:
            localStorage.removeItem('currentClient')
            return {}
        case BACKUP_CURRENT_CLIENT:
        {
            const client = { ...action.payload }
            localStorage.setItem('currentClient', JSON.stringify(client))
            return client
        }
        case RESTORE_CURRENT_CLIENT: {
            const client = localStorage.getItem('currentClient')
            return JSON.parse(client)
        }
        case SET_CURRENT_CLIENT_PROPERTY: {
            const client = { ...state }
            client[action.payload.key] = action.payload.value
            localStorage.setItem('currentClient', JSON.stringify(client))
            return client
        }
        default:
            return state
    }
}
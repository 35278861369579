import React, { Fragment, useCallback } from 'react'

import { PHOTO } from '../useAddHaircut'
import { SQUARE } from '../../../../controls/Photo'
import PhotoUpload from '../../../../controls/PhotoUpload'
import Button from '../../../../controls/Button'
import useAddHaircutContext from '../AddHaircutContext'

const Photo = ({ marginTop }) => {

    const { stage, onStageChange, onPhotoReady, updatePhotoFiles, addResetFunction } = useAddHaircutContext()

    const onNext = useCallback(() => onStageChange(PHOTO), [])

    return (
        <Fragment>
        {
            (stage === PHOTO) &&
            <Fragment>
                <PhotoUpload
                    marginTop={30} setPhotoFiles={updatePhotoFiles} onPhotoReady={onPhotoReady}
                    addResetFunction={addResetFunction} shape={SQUARE} multiple  
                />
                <Button onClick={onNext} label='Next' width={200} height={75} marginTop={marginTop} fontSize={24} />
            </Fragment>
        }
        </Fragment>
    )
}

export default Photo
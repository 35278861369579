import React, { Fragment, useCallback, useState, useEffect } from 'react'

import { MIX } from '../useAddHaircut'
import RadioButtonList from '../../../../controls/RadioButtonList'
import Button from '../../../../controls/Button'
import useAddHaircutContext from '../AddHaircutContext'

const mixItems = [
    '40/60',
    '50/50'
]

let lastSelected
let firstMounted = true

const Mix = ({ marginBottom }) => {
    const [ selected, setSelected ] = useState(lastSelected)

    const { stage, onStageChange, onMixChange, addResetFunction } = useAddHaircutContext() 

    // Initial mount effect
    useEffect(() => {
        if (!firstMounted) return;

        firstMounted = false
        addResetFunction(resetBackup)
    })

    const resetBackup = () => {
        firstMounted = true
        lastSelected = undefined
    }

    const handleMixChange = useCallback(index => {
        onMixChange(index, mixItems[index]) 
        if (index === -1) {
            setSelected(lastSelected = undefined)
            return;
        }        
        setSelected(lastSelected = index)
    }, [ onMixChange ])

    const onNext = useCallback(() => onStageChange(MIX), [ onStageChange ])

    return (
        <Fragment>
        {
            (stage === MIX) &&
            <Fragment>
                <RadioButtonList 
                    items={mixItems} value={selected} marginTop={20} onChange={handleMixChange} 
                />
                <Button 
                    onClick={onNext} label='Next' width={200} height={75} marginBottom={marginBottom} 
                    marginTop={30} fontSize={24} disabled={selected === undefined}
                />
            </Fragment>
        }
        </Fragment>
    )
}

export default Mix
import React, { useState, useEffect } from 'react'

import RadioButtonGroup from '../../../../controls/RadioButtonGroup'
import { useTheme } from '../../../../contexts/Theme'
import useClientSignupContext from '../ClientSignupContext'
import useClientSignupFormContext from '../ClientSignupFormContext'

let lastEmoliency = 1

let initialMount = true

const Emoliency = () => {
    const [ emoliency, setEmoliency ] = useState(lastEmoliency)

    // Hooks
    const { addResetFunction } = useClientSignupContext()
    const { onEmoliencyChange } = useClientSignupFormContext()

    // Destructure theme
    const theme = useTheme()
    const labelText = theme.isDarkBackground() ? "yellow" : "blue";

    // Page mounted effect
    //
    useEffect(() => {
        if (!initialMount) return;

        addResetFunction(resetBackup)
        initialMount = false
    }, [])

    const resetBackup = () => {
        lastEmoliency = 1
        initialMount = true
    }
    
    const onChange = v => {
        onEmoliencyChange(v)
        setEmoliency(v)
        lastEmoliency = v
    }

    return (
        <RadioButtonGroup
            caption="The client's scalp oiliness, from dry to oily."
            title='Emoliency' panelColor='rgba(0, 0, 0, 0)' selectColor='rgb(86, 86, 171)' 
            buttonColor='rgb(112, 146, 190)' labelColor={labelText}
            options={[
                { image: 'scalp-dry.png', label: 'dry'},
                { image: 'scalp-normal-to-dry.png', label: 'medium'},
                { image: 'scalp-normal-to-oily.png', label: 'oily'}
            ]}
            value={emoliency} onChange={onChange} 
        />
    )
}

export default Emoliency